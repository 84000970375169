import axios from 'axios';
import { cloneDeep } from 'lodash';
import Vue from 'vue';
import endpoints from '@/js/urls';

const initialState = {
  logsFilter: {
    startDate: new Date(Date.now() - (7 * 60 * 60 * 24 * 1000)),
    endDate: new Date(),
    selectedLanguage: 'any',
    selectedVariant: '',
    freeText: null,
    passThroughNodeIds: [],
    ignoreNodeIds: [],
    startTime: 0,
    endTime: 24,
    selectedDataOrigins: [],
    selectedRatings: [],
    startUrls: [],
    // moved from chatlogs page
    requireImmediateOrder: false,
    requireOnlyOne: false,
    coverageFilter: 'all',
    selfServedFilter: 'all',
    mustHaveTraceback: false,
  },
  // Null untill initialized: Component must handle this scenario
  availableDataOrigins: null,
  availableLanguages: null,
  availableStartUrls: null,
  availableVariants: null,
  chatlogPlaybacks: {},
  selectedBot: null,
  isFetchingStartUrls: false,
};

const getters = {
  logsFilter(state) {
    return state.logsFilter;
  },
  availableDataOrigins: (state) => state.availableDataOrigins,
  availableLanguages: (state) => state.availableLanguages,
  availableStartUrls: (state) => state.availableStartUrls,
  availableVariants: (state) => state.availableVariants,
  currentPlaybackSkipTo: (state) => (chatLogId) => state.chatlogPlaybacks?.[chatLogId]?.skipTo || 0,
  isFetchingStartUrls: (state) => state.isFetchingStartUrls,
};

const mutations = {
  resetChatlogs(state) {
    Object.assign(state, cloneDeep(initialState));
  },
  updateLogsFilter(state, { key, newValue }) {
    Vue.set(state.logsFilter, key, newValue);
  },
  setAvailableDataOrigins(state, dataOrigins) {
    Vue.set(state, 'availableDataOrigins', dataOrigins);
  },
  setAvailableLanguages(state, languages) {
    Vue.set(state, 'availableLanguages', languages);
  },
  setAvailableStartUrls(state, startUrls) {
    Vue.set(state, 'availableStartUrls', startUrls);
  },
  setAvailableVariants(state, variants) {
    Vue.set(state, 'availableVariants', variants);
  },
  updatePlaybackTimestamp(state, { chatLogId, timestamp }) {
    /*
      I tried doing some highlighting of the messages when being played back
      but it's super unreliable so it's scrapped for now.
    */
    if (chatLogId in state.chatlogPlaybacks) {
      Vue.set(state.chatlogPlaybacks[chatLogId], 'current', timestamp);
    } else {
      Vue.set(state.chatlogPlaybacks, chatLogId, {
        current: timestamp,
      });
    }
  },
  playbackSkipTo(state, { chatLogId, timestamp }) {
    if (chatLogId in state.chatlogPlaybacks) {
      const currentValue = state.chatlogPlaybacks[chatLogId].skipTo;
      Vue.set(
        state.chatlogPlaybacks[chatLogId],
        'skipTo',
        timestamp === currentValue ? timestamp - 0.01 : timestamp,
      );
      // very small offset so the watcher in the audio component
      // catches this. There's probably a better way to do this.
    } else {
      Vue.set(state.chatlogPlaybacks, chatLogId, {
        skipTo: timestamp,
      });
    }
  },
  setSelectedBot(state, id) {
    state.selectedBot = id;
  },
  setIsFetchingStartUrls(state, isFetching) {
    Vue.set(state, 'isFetchingStartUrls', isFetching);
  },
};

const actions = {
  /**
   * Fetches available data-origins from backend and then selects every option
   */
  async fetchDataOrigins({ rootState, state, commit }) {
    // We should only fetch values from backend if data-origins has not previously been fetched.
    if (state.availableDataOrigins === null) {
      const botId = rootState.botManipulation.activeBot.id;
      const response = await axios.get(endpoints.chatlogsOrigin, {
        headers: {
          Authorization: `JWT ${rootState.auth.jwt}`,
        },
        params: {
          bot_id: botId,
        },
      });
      // Mapping from "raw value" to "display name".
      // Needed in order to provide a nice displayname for DATA_ORIGIN_NOT_SET. Values from backend
      // are mapped to their own name.
      const originsFromBackend = [];
      response.data.distinct_data_origin.forEach((element) => {
        originsFromBackend.push({
          rawValue: element,
          displayName: element,
        });
      });

      /** Backend will only tell us what origins that has been actively specified in chatlogs.
      * We need to still be able to fetch logs that was created before we started annotating logs
      * with dataorigins. Hence we insert an artificial entry in dataorigins for logs that has no
      * data origin set.
      */
      originsFromBackend.push({
        rawValue: 'DATA_ORIGIN_NOT_SET',
        displayName: 'No data-origin set',
      });
      commit('setAvailableDataOrigins', originsFromBackend);
      // At initial load, preselect all data-origins - this will give the enduser results that
      // he/she can then eventuallyt narrow down, by unchecking some data-origins.
      commit('updateLogsFilter', { key: 'selectedDataOrigins', newValue: originsFromBackend });
    }
  },
  async fetchVariants({ rootState, state, commit }) {
    if (state.availableVariants === null) {
      const botId = rootState.botManipulation.activeBot.id;
      const response = await axios.get(endpoints.chatlogsVariants, {
        headers: {
          Authorization: `JWT ${rootState.auth.jwt}`,
        },
        params: {
          bot_id: botId,
        },
      });
      commit('setAvailableVariants', response.data.distinct_variant_id);
    }
  },
  /**
   * Fetches available data-origins from backend and then selects every option
   */
  async fetchLanguages({
    dispatch, rootState, state, commit,
  }) {
    try {
      // We should only fetch values from backend if data-origins has not previously been fetched.
      if (state.availableLanguages === null) {
        const botId = state.selectedBot ? state.selectedBot
          : rootState.botManipulation.activeBot.id;
        const response = await axios.get(endpoints.chatlogsLanguages, {
          headers: {
            Authorization: `JWT ${rootState.auth.jwt}`,
          },
          params: {
            bot_id: botId,
          },
        });
        const availableLanguages = response.data.distinct_language_normalized.map(
          (x) => ({ value: x, text: x }),
        );
        commit('setAvailableLanguages', availableLanguages);
      }
    } catch (error) {
      dispatch('sidebar/showWarning', {
        title: 'Failed to fetch languages',
        text: error.message,
        variant: 'danger',
      }, { root: true });
    }
  },
  async fetchStartUrls({
    dispatch, rootState, state, commit,
  }) {
    try {
      // We should only fetch values from backend if start urls have not previously been fetched.
      if (state.availableStartUrls === null && !state.isFetchingStartUrls) {
        commit('setIsFetchingStartUrls', true);
        const botId = state.selectedBot ? state.selectedBot
          : rootState.botManipulation.activeBot.id;
        const response = await axios.get(endpoints.chatlogsStartUrls, {
          headers: {
            Authorization: `JWT ${rootState.auth.jwt}`,
          },
          params: {
            bot_id: botId,
          },
        });
        const availableStartUrls = response.data.distinct_start_url_trimmed.map(
          (x) => ({ value: x, text: x }),
        );
        commit('setAvailableStartUrls', availableStartUrls);
      }
    } catch (error) {
      dispatch('sidebar/showWarning', {
        title: 'Failed to fetch start urls',
        text: error.message,
        variant: 'danger',
      }, { root: true });
    } finally {
      commit('setIsFetchingStartUrls', false);
    }
  },
  async fetchSingleLog({ rootState, dispatch }, { botId, chatId, externalId }) {
    try {
      const config = {
        headers: { Authorization: `JWT ${rootState.auth.jwt}` },
        params: {
          bot_id: botId,
          chat_id: chatId,
          external_id: externalId,
        },
      };
      const response = await axios.get(endpoints.chatlogsSingle, config);
      return response.data;
    } catch (error) {
      dispatch('sidebar/showWarning', {
        title: 'Failed to fetch conversation',
        text: error.message,
        variant: 'danger',
      }, { root: true });
      return null;
    }
  },
  async searchLogs({ rootState, state }, {
    botId, variantId, startDate = null, endDate = null, selectedLanguage,
    freeText, page = 1, orderBy, requireNodes, ignoreNodes, requireImmediateOrder, requireOnlyOne,
    coverageFilter, selfServedFilter, mustHaveTraceback, resultsPerPage, selectedRatings,
    exportFormat = null, timezone = null, showActions = null, hasQueryLabel = null,
    labelDataSet = null, isLabelable = null, ignoreNodeChanges = null, selectedDataOrigins = null,
    startUrls = null, trainingPage = null,
  }) {
    const originsRaw = (selectedDataOrigins === null ? state.logsFilter.selectedDataOrigins
      : selectedDataOrigins).map((x) => x.rawValue);
    const selectedRatingsValues = selectedRatings;
    const result = await axios.get(endpoints.chatlogsSearch, {
      params: {
        bot_id: botId,
        variant_id: variantId,
        start_date: startDate,
        end_date: endDate,
        language: selectedLanguage,
        free_text: freeText,
        selected_data_origins: originsRaw,
        page,
        order_by: orderBy || null,
        requireNodes,
        ignoreNodes,
        ignore_node_changes: ignoreNodeChanges,
        require_immediate_order: requireImmediateOrder,
        require_only_one: requireOnlyOne,
        coverage_filter: coverageFilter,
        self_served_filter: selfServedFilter,
        must_have_traceback: mustHaveTraceback,
        results_per_page: resultsPerPage,
        export_format: exportFormat,
        timezone,
        show_actions: showActions,
        selected_ratings: selectedRatingsValues,
        has_query_label: hasQueryLabel,
        label_data_set: labelDataSet,
        is_labelable: isLabelable,
        start_urls: startUrls,
        training_page: trainingPage,
      },
      headers: {
        Authorization: `JWT ${rootState.auth.jwt}`,
      },
      responseType: exportFormat === 'excel' ? 'blob' : 'json',
    });
    if (result.status === 200) {
      return result.data;
    }
    throw new Error('Failed to perform search on backend, ', result);
  },
  async fetchAudio({ rootState }, { botId, chatId, cancelToken }) {
    const config = {
      params: {
        bot_id: botId,
        chat_id: chatId,
      },
      headers: {
        Authorization: `JWT ${rootState.auth.jwt}`,
      },
      responseType: 'arraybuffer',
    };
    if (cancelToken !== undefined) {
      config.cancelToken = cancelToken.token;
    }
    const result = await axios.get(endpoints.chatlogsAudio, config);
    if (result.status === 200) {
      return {
        type: result.headers['content-type'],
        data: result.data,
      };
    }
    throw new Error('Failed to fetch Audio data from backend, ', result);
  },
};

export default {
  namespaced: true,
  state: cloneDeep(initialState),
  actions,
  getters,
  mutations,
};
