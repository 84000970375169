<template>
  <div class="p-3">
    <h4>
      Neutrals
    </h4>
    <div class="font-weight-bold">
      Master bot:
    </div>
    <b-list-group
      v-if="neutralData.files.length"
      class="mt-2"
    >
      <b-list-group-item
        v-for="(file, idx) in neutralData.files"
        :key="idx"
        button
        @click.prevent="showVariantFileNeutralsModal(idx, 'master')"
      >
        <b-overlay :show="currentTranslation.isTranslating && idx === currentTranslation.id">
          <b-row class="align-items-center">
            <b-col>
              <strong>{{ toNameFilter(file) }}</strong>
            </b-col>
            <b-col cols="auto">
              <b-button
                v-if="showTranslationComponents"
                :key="`${idx}translate`"
                v-b-tooltip.hover.noninteractive.viewport
                size="sm"
                class="neutrals-btn"
                :disabled="currentTranslation.isTranslating || !hasSelectedLanguage"
                :title="hasSelectedLanguage ? '' : 'Variant routing language not selected'"
                variant="primary"
                @click.stop="translateNeutral(file, idx)"
              >
                Translate
              </b-button>
            </b-col>
          </b-row>
          <template #overlay>
            <b-row class="text-center">
              <b-col cols="12">
                <b-spinner small />
                Translating
              </b-col>
              <b-col cols="12">
                Last updated: {{ currentTranslation.timestamp.toLocaleTimeString() }}
              </b-col>
            </b-row>
          </template>
        </b-overlay>
      </b-list-group-item>
    </b-list-group>
    <b-card
      v-else
      no-body
    >
      <p class="p-2">
        There are no neutral text examples in master bot.
      </p>
    </b-card>
    <div class="font-weight-bold mt-2">
      Variant data:
    </div>
    <b-list-group
      v-if="selectedVariantNeutralData.files.length"
      class="mt-2"
    >
      <b-list-group-item
        v-for="(file, idx) in selectedVariantNeutralData.files"
        :key="idx"
        button
        @click.prevent="showVariantFileNeutralsModal(idx, 'variant')"
      >
        <b-row class="align-items-center">
          <b-col>
            <strong>
              {{ toNameFilter(file) }}
            </strong>
          </b-col>
          <b-col cols="auto">
            <b-button
              variant="danger"
              size="sm"
              class="neutrals-btn"
              @click.stop="removeVariantNeutralsFile(idx)"
            >
              Remove
            </b-button>
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>
    <b-card
      v-else
      no-body
    >
      <p class="p-2">
        Click below button to add variant data.
      </p>
    </b-card>
    <b-btn
      v-b-modal.selected-variant-upload-neutrals-modal
      variant="primary"
      class="mt-2"
    >
      Upload file
    </b-btn>
    <h4 class="mt-4">
      Initialization
    </h4>

    <div v-if="initActivityIds.length">
      <b-card
        v-for="activityId in initActivityIds"
        :key="activityId"
        no-body
        class="mb-1"
      >
        <b-container
          v-if="initActivityFromId(activityId).type === SET_VARIABLE"
          class="py-2 border r-25"
        >
          <b-row>
            <b-col
              cols="12"
              class="my-auto"
            >
              <strong>  Variable name: </strong>
            </b-col>
            <b-col>
              <b-form-input
                size="sm"
                :value="initActivityFromId(activityId).key"
                :disabled="true"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              class="my-1"
            >
              <strong>  Variable code: </strong>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="auto"
              class="my-auto"
            >
              <label class="my-auto ml-3">
                Master:
              </label>
            </b-col>
            <b-col>
              <b-form-input
                size="sm"
                :value="initActivityFromId(activityId).code"
                :disabled="true"
              />
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col
              cols="auto"
              class="pt-1"
            >
              <label
                class="ml-3"
              >
                Variant:
              </label>
            </b-col>
            <b-col>
              <botscript-validation
                size="sm"
                :expanded="selectedVariantInitActivity(activityId)
                  && selectedVariantInitActivity(activityId).length > 58"
                :validations="['unsavedChanges']"
                :value="selectedVariantInitActivity(activityId)"
                placeholder="No value set. Master version will be used."
                @onChange="code => setSelectedVariantInitActivityCode({ activityId, code })"
                @dirty="(v) => $emit('dirty', v)"
              />
            </b-col>
          </b-row>
        </b-container>
      </b-card>
    </div>
    <b-card
      v-else
      no-body
    >
      <p class="p-2">
        Add initialization activities in master bot to override them in variants.
      </p>
    </b-card>
    <b-button
      class="mt-1 px-3"
      variant="primary"
      @click="saveVariantConfig"
    >
      Save
    </b-button>
    <div>
      <h4 class="mt-3">
        Generative AI
      </h4>
      <b-form-group
        :description="`Master: ${masterPersona}`"
        label="GPT persona"
        class="w-100"
      >
        <b-dropdown
          menu-class="bg-white p-0"
          toggle-class="bg-white text-dark"
        >
          <template #button-content>
            <span v-if="getSelectedVariantPersona">
              <font-awesome-icon class="mr-1" :icon="getSelectedVariantPersona.icon" />
              {{ getSelectedVariantPersona.text }}
            </span>
            <span v-else>Select variant persona</span>
          </template>
          <b-dropdown-item
            v-for="(persona, index) in gptPersonas"
            :key="index"
            @click="gptVariantPersona = persona.value"
          >
            <b-row style="min-width:500px;">
              <b-col class="my-auto" cols="auto" style="width:60px;">
                <font-awesome-icon class="h2 my-auto" :icon="persona.icon" />
              </b-col>
              <b-col cols="10" class="">
                <h5 class="font-weight-bold my-1">
                  {{ persona.text }}
                </h5>
                <p style="white-space:normal;">
                  {{ persona.description }}
                </p>
              </b-col>
            </b-row>
          </b-dropdown-item>
        </b-dropdown>
        <b-dropdown
          class="ml-2"
          text="Additional conditions"
          boundary="viewport"
          menu-class="bg-white p-0"
          toggle-class="bg-white text-dark"
        >
          <b-dropdown-form form-class="w-auto p-3">
            <b-form-group
              label="Negative sentiment"
              :description="`Master: ${masterPersonaConditions.negative || 'Default'}`"
            >
              <b-input-group prepend="If a customer is angry, use" append="persona">
                <b-form-select
                  :value="personaCondition('negative')"
                  :options="additionalPersonaOptions"
                  @input="v=>setCondition('negative', v)"
                />
              </b-input-group>
            </b-form-group>
            <b-form-group
              label="Neutral sentiment"
              :description="`Master: ${masterPersonaConditions.neutral || 'Default'}`"
            >
              <b-input-group prepend="If a customer is neutral, use" append="persona">
                <b-form-select
                  :value="personaCondition('neutral')"
                  :options="additionalPersonaOptions"
                  @input="v=>setCondition('neutral', v)"
                />
              </b-input-group>
            </b-form-group>
            <b-form-group
              class="mb-0"
              label="Positive sentiment"
              :description="`Master: ${masterPersonaConditions.positive || 'Default'}`"
            >
              <b-input-group prepend="If a customer is happy, use" append="persona">
                <b-form-select
                  :value="personaCondition('positive')"
                  :options="additionalPersonaOptions"
                  @input="v=>setCondition('positive', v)"
                />
              </b-input-group>
            </b-form-group>
          </b-dropdown-form>
        </b-dropdown>
      </b-form-group>
      <b-form-group
        label="Allowed emojis"
        :description="`Master: ${masterEmojis}`"
      >
        <b-form-tags
          v-model="selectedVariantEmojis"
          add-on-change
          no-outer-focus
        >
          <template
            #default="{
              tags, inputAttrs, inputHandlers, disabled, removeTag,
            }"
          >
            <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
              <li v-for="tag in tags" :key="tag" class="list-inline-item">
                <b-form-tag
                  :title="tag"
                  :disabled="disabled"
                  variant="secondary"
                  @remove="removeTag(tag)"
                >
                  {{ tag }}
                </b-form-tag>
              </li>
            </ul>
            <b-form-select
              v-bind="inputAttrs"
              :disabled="disabled || availableEmojiOptions.length === 0"
              :options="availableEmojiOptions"
              v-on="inputHandlers"
            >
              <template #first>
                <option disabled value="">
                  Choose emoji...
                </option>
              </template>
            </b-form-select>
          </template>
        </b-form-tags>
      </b-form-group>
    </div>
    <b-modal
      id="selected-variant-upload-neutrals-modal"
      title="Upload neutrals"
      ok-title="Upload"
      @ok="uploadVariantNeutrals"
    >
      <b-form-group
        label="Name"
        label-for="nameForm"
      >
        <b-form-input
          id="nameForm"
          ref="neutralTitleInput"
          v-model="uploadNeutralsModal.name"
          placeholder="Give a descriptive name..."
        />
      </b-form-group>
      <b-form-group
        label="Neutral text examples"
        label-for="neutralsFileForm"
        description="The file should be a utf-8 encoded text-file with one neutral example per
         line."
      >
        <b-form-file
          id="neutralsFileForm"
          v-model="uploadNeutralsModal.file"
          placeholder="Choose file..."
        />
      </b-form-group>
    </b-modal>

    <b-modal
      ref="selected-variant-neutrals-file-modal"
      size="xl"
      ok-only
    >
      <b-list-group>
        <b-list-group-item
          v-for="(text, ii) in modalExamplesData.text"
          :key="ii"
        >
          {{ text }}
        </b-list-group-item>
      </b-list-group>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { cloneDeep } from 'lodash';
import axios from 'axios';
import { toNameFilter } from '@/js/vuefilters';
import { SET_VARIABLE } from '@/js/activity';
import BotscriptValidation from '@/components/BotscriptValidation.vue';
import endpoints from '@/js/urls';
import { gptPersonas, emojiOptions } from '@/js/constants';

export default {
  name: 'VariantConfig',
  components: {
    BotscriptValidation,
  },
  data() {
    return {
      gptPersonas,
      emojiOptions,
      modalExamplesData: [],
      uploadNeutralsModal: {
        name: '',
        file: null,
      },
      SET_VARIABLE,
      activeTranslation: {
        isTranslating: false,
        id: null,
        timestamp: null,
      },
    };
  },
  computed: {
    ...mapGetters('variants', [
      'selectedVariantConfig',
      'selectedVariantInitActivities',
      'selectedVariant',
      'hasSelectedLanguage',
      'generativeAIConfig',
    ]),
    ...mapGetters('botManipulation/activeBot', ['neutralData']),
    ...mapGetters('botManipulation/activeBot', ['nodeById']),
    ...mapGetters('userSettings', ['showTranslationFeatures']),
    ...mapGetters('botManipulation/activeBot/config/generativeAI', [
      'getGptPersona',
      'getAllowedEmojis',
      'getPersonaConditions',
    ]),
    showTranslationComponents() {
      return this.showTranslationFeatures;
    },
    initActivityIds() {
      return this.nodeById('init').activityIds;
    },
    initActivityFromId() {
      return (activityId) => this.nodeById('init').activities[activityId];
    },
    selectedVariantNeutralData() {
      // TODO: The default value here should probably be taken from newbot.json or osmething.
      return this.selectedVariantConfig.neutralData || { files: [], editable: [] };
    },
    selectedVariantInitActivity() {
      return (activityId) => this.selectedVariantInitActivities[activityId];
    },
    currentTranslation() {
      return this.activeTranslation;
    },
    masterPersona() {
      return this.gptPersonas.find((e) => e.value === this.getGptPersona).text;
    },
    masterEmojis() {
      return this.getAllowedEmojis;
    },
    masterPersonaConditions() {
      return this.getPersonaConditions;
    },
    gptVariantPersona: {
      get() {
        return this.generativeAIConfig.gptPersona;
      },
      set(value) {
        this.setSingleGenerativeAIConfig({ key: 'gptPersona', value });
        this.pushGenerativeAIConfig();
      },
    },
    selectedVariantEmojis: {
      get() {
        return this.generativeAIConfig.allowedEmojis;
      },
      set(value) {
        this.setSingleGenerativeAIConfig({ key: 'allowedEmojis', value });
        this.pushGenerativeAIConfig();
      },
    },
    getSelectedVariantPersona() {
      return this.gptPersonas.find((e) => e.value === this.gptVariantPersona);
    },
    availableEmojiOptions() {
      return this.emojiOptions.filter((opt) => this.selectedVariantEmojis.indexOf(opt) === -1);
    },
    additionalPersonaOptions() {
      return [{ text: 'Default', value: null }]
        .concat(this.gptPersonas.map((e) => ({ text: e.text, value: e.value })));
    },
  },
  methods: {
    ...mapActions('variants', [
      'setAndPushVariantConfigField',
      'uploadVariantNeutralsFile',
      'refreshAllSelectedVariantData',
      'saveVariantConfig',
      'pushGenerativeAIConfig',
    ]),
    ...mapActions('sidebar', ['showWarning']),
    ...mapMutations('variants', [
      'setSelectedVariantInitActivityCode',
      'setPersonaCondition',
      'setSingleGenerativeAIConfig',
    ]),
    ...mapMutations('task', ['addTask']),
    toNameFilter,
    showVariantFileNeutralsModal(idx, type) {
      if (type === 'master') {
        this.modalExamplesData = this.neutralData.files[idx];
      } else {
        this.modalExamplesData = this.selectedVariantNeutralData.files[idx];
      }
      this.$refs['selected-variant-neutrals-file-modal'].show();
    },
    async removeVariantNeutralsFile(idx) {
      const currentNeutralDataCopy = cloneDeep(this.selectedVariantNeutralData);
      currentNeutralDataCopy.files.splice(idx, 1);
      await this.setAndPushVariantConfigField({
        value: currentNeutralDataCopy,
        indexList: ['neutralData'],
      });
    },
    async uploadVariantNeutrals() {
      // in case no name is given use provided filename
      if (!this.uploadNeutralsModal.name) {
        const fileName = this.uploadNeutralsModal.file.name;
        this.uploadNeutralsModal.name = fileName.indexOf('.') > 0
          ? fileName.slice(0, fileName.lastIndexOf('.'))
          : fileName;
      }
      await this.uploadVariantNeutralsFile({
        fileName: this.uploadNeutralsModal.name,
        fileObj: this.uploadNeutralsModal.file,
      });
      this.uploadNeutralsModal = {
        name: '',
        file: null,
      };
    },
    async translateNeutral(file, id) {
      try {
        this.activeTranslation.timestamp = new Date();
        this.activeTranslation.id = id;
        this.activeTranslation.isTranslating = true;
        const resp = await axios.post(endpoints.variantTranslation, {
          neutrals_name: file.name,
          bot_variant_id: this.selectedVariant,
        }, {
          headers: {
            Authorization: `JWT ${this.$store.state.auth.jwt}`,
          },
        });
        this.addTask(
          {
            celeryId: resp.data.translation_task,
            callbackUpdate: () => {
              this.activeTranslation.timestamp = new Date();
            },
            callbackDone: () => {
              this.cleanActiveTranslation();
              this.refreshAllSelectedVariantData();
            },
            callbackFailed: () => {
              this.cleanActiveTranslation();
              this.showWarning({
                title: 'Failed to translate neutrals',
                text: 'Failed to translate neutrals file.',
                variant: 'danger',
              });
            },
          },
        );
      } catch (error) {
        this.showWarning({
          title: 'Failed to translate neutrals',
          text: 'Failed to translate neutrals file.',
          variant: 'danger',
        });
        this.cleanActiveTranslation();
        throw error;
      }
    },
    cleanActiveTranslation() {
      this.activeTranslation.timestamp = null;
      this.activeTranslation.id = null;
      this.activeTranslation.isTranslating = false;
    },
    personaCondition(sentiment) {
      return this.generativeAIConfig.personaConditions[sentiment];
    },
    setCondition(sentiment, value) {
      this.setPersonaCondition({ sentiment, value });
      this.pushGenerativeAIConfig();
    },
  },
};
</script>

<style scoped>
.neutrals-btn{
  width: 90px;
}
</style>
