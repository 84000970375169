<template>
  <div>
    <b-row no-gutters>
      <b-col />
      <b-col cols="auto" class="my-auto text-right pl-4 d-flex">
        <configuration-dropdown
          v-if="!isDetails"
          v-bind="{ data: topicsObj }"
          class="mr-2"
          default-number-of-topics="8"
          @updateTopicConfig="updateTopicConfig"
        />
        <b-button-group>
          <b-button
            v-for="({ type, icon }) in chartTypes"
            :key="type"
            variant="primary"
            :pressed="type == selectedChartType"
            class="chart-type-button"
            @click.stop="selectedChartType = type"
          >
            <font-awesome-icon :icon="icon" />
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>
    <b-row v-if="!hasData">
      <b-col class="py-4 text-center">
        There is no data to show.
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col v-bind="bColAttrs">
        <table-data
          v-if="selectedChartType === 'table' && tableFields.length"
          sticky-header="450px"
          :items="topics"
          :fields="tableFields"
          hover
          class="w-100 pr-2"
          @row-clicked="rowClicked"
        />
        <typed-chart
          v-else
          :key="selectedChartType"
          :chart-data="chartData"
          :options="chartOptions"
          chart-type="bar"
          class="h-100"
        />
      </b-col>
      <b-col
        v-if="showDetails"
        v-bind="bColAttrs"
      >
        <details-section
          v-bind="{
            chartOptions,
            visualizationId,
            topicIndex: selectedTopicIndex,
            topicsObj,
            specificityValue,
          }"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import TableData from 'supwiz/components/TableData.vue';
import TypedChart from 'supwiz/components/TypedChart.vue';
import DetailsSection from '@/components/StatisticsTopics/DetailsSection.vue';
import ConfigurationDropdown from '@/components/StatisticsTopics/ConfigurationDropdown.vue';

export default {
  name: 'DataDisplayTopic',
  components: {
    ConfigurationDropdown,
    DetailsSection,
    TypedChart,
    TableData,
  },
  props: {
    isDetails: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      required: true,
    },
    tableFields: {
      type: Array,
      default: () => [],
    },
    visualizationId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      showDetails: false,
      selectedChartType: 'bar',
      selectedTopicIndex: null,
      topicCount: 8,
      specificityValue: 0.65,
    };
  },
  computed: {
    chartTypes() {
      const types = [{ type: 'bar', icon: 'chart-column' }];
      if (this.tableFields.length) {
        types.push({ type: 'table', icon: 'table' });
      }
      return types;
    },
    hasData() {
      return !!this.topics.length;
    },
    bColAttrs() {
      return {
        cols: 12,
        lg: this.showDetails ? 6 : 12,
        style: 'min-height:450px',
      };
    },
    chartData() {
      const labels = this.getLabels();
      const datasets = this.getDatasets();
      return {
        labels,
        datasets,
      };
    },
    topics() {
      return this.data?.topics || [];
    },
    topicsObj() {
      return this.data;
    },
    chartOptions() {
      return {
        indexAxis: 'y',
        responsive: true,
        maintainAspectRatio: false,
        onClick: (event, activeElements) => {
          if (activeElements.length) {
            const { index } = activeElements[0];
            this.selectedTopicIndex = index;
            this.showDetails = true;
          }
        },
        scales: {
          x: {
            beginAtZero: true,
          },
          y: {
            ticks: {
              autoSkip: false,
              stepSize: 10,
              callback(value) {
                const maxLength = 25;
                // this.getLabelForValue is from chart.js
                const label = this.getLabelForValue(value);
                if (label.length < maxLength) return label;
                return `${label.slice(0, maxLength - 3)}...`;
              },
            },
          },
        },
        plugins: {
          datalabels: {
            display: false,
          },
          legend: {
            display: false,
          },
          title: {
            display: true,
            text: 'Click on chart to see details',
            position: 'top',
          },
          tooltip: {
            callbacks: {
              label: (tooltipItems) => `Volume: ${tooltipItems.formattedValue}`,
            },
          },
        },
        onHover: (event, chartElement) => {
          // eslint-disable-next-line no-param-reassign
          event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
        },
      };
    },
  },
  watch: {
    topics() {
      this.showDetails = false;
    },
  },
  methods: {
    getLabels() {
      return this.topics.slice(0, this.topicCount || this.topics.length).map(({ name }) => name);
    },
    rowClicked(item, index) {
      this.selectedTopicIndex = index;
      this.showDetails = true;
    },
    getDatasets() {
      const datasets = [];
      datasets.push({
        label: '',
        backgroundColor: [],
        fill: false,
        borderColor: [],
        data: [],
      });
      this.topics.forEach((item, index) => {
        const color = this.selectedTopicIndex === index ? '#00354C' : '#005F89';
        datasets[0].data.push(item.count);
        datasets[0].backgroundColor.push(color);
        datasets[0].borderColor.push(color);
      });
      const filteredData = datasets;
      return filteredData;
    },
    updateTopicConfig(payload) {
      this.topicCount = payload.topicCount;
      this.specificityValue = payload.specificityValue;
    },
  },
};
</script>
