import { render, staticRenderFns } from "./MenuItemNested.vue?vue&type=template&id=8db8953e&scoped=true&"
import script from "./MenuItemNested.vue?vue&type=script&lang=js&"
export * from "./MenuItemNested.vue?vue&type=script&lang=js&"
import style0 from "./MenuItemNested.vue?vue&type=style&index=0&id=8db8953e&prod&scoped=true&lang=css&"
import style1 from "./MenuItemNested.vue?vue&type=style&index=1&id=8db8953e&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../products/botstudio/frontend/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8db8953e",
  null
  
)

export default component.exports