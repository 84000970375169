<template>
  <main
    role="main"
  >
    <b-card
      title="Conversation Logs"
      class="r-75"
      body-class="p-3"
    >
      <p>
        Here you can inspect the conversations that the bot had with visitors.
      </p>
      <hr>
      <date-time-lang-picker
        variant
        date
        time
        lang
        shortcuts
        :getter="logsFilter"
        :setter="updateLogsFilter"
      />
      <b-row class="mt-3">
        <b-col cols="6">
          <b-form-textarea
            v-model="freeText"
            class="mt-1"
            placeholder="Text in visitor messages to search for"
            rows="5"
            max-rows="5"
            @keydown.enter.shift.exact.prevent
            @keyup.enter.shift.exact="fetchLogsForFilter"
          />
        </b-col>
        <b-col cols="6">
          <chip-list
            class="px-2 mb-1 bg-white"
            :completions="nodeNames"
            :value="selectedNodeNames"
            placeholder="Require visit to nodes"
            @input="setRequireNode"
          />
          <b-form-checkbox
            v-model="requireImmediateOrder"
            switch
            class="mx-1 mt-1 d-inline-block"
            :disabled="requireOnlyOne"
          >
            Require nodes must be in immediate order
          </b-form-checkbox>
          <b-form-checkbox
            v-model="requireOnlyOne"
            switch
            class="mx-1 mt-1 d-inline-block"
          >
            Require only visit to at least one of the selected nodes
          </b-form-checkbox>
          <b-form-radio-group
            v-model="coverageFilter"
            class="mx-1 mt-1 d-inline-block"
          >
            <b-form-radio value="flow-covered">
              Flow Covered chats
            </b-form-radio>
            <b-form-radio value="supsearch-covered">
              SupSearch covered chats
            </b-form-radio>
            <b-form-radio value="non-covered">
              Non-covered chats
            </b-form-radio>
            <b-form-radio value="all">
              Don't filter by coverage
            </b-form-radio>
          </b-form-radio-group>
          <b-form-radio-group
            v-model="selfServedFilter"
            class="mx-1 mt-1 d-inline-block"
          >
            <b-form-radio value="self-served">
              Self-served chats
            </b-form-radio>
            <b-form-radio value="not self-served">
              Not self-served chats
            </b-form-radio>
            <b-form-radio value="all">
              Don't filter by self-served
            </b-form-radio>
          </b-form-radio-group>
          <chip-list
            class="p-2 mb-1 bg-white"
            :completions="nodeNames"
            :value="ignoreNodeNames"
            placeholder="Exclude visit to nodes"
            @input="setIgnoreNodes"
          />
          <b-form-checkbox
            v-model="mustHaveTraceback"
            switch
            class="mx-1 mt-1 mb-1 d-inline-block"
          >
            Conversation must have traceback
          </b-form-checkbox>
          <b-overlay
            :show="isFetchingStartUrls"
          >
            <multi-select
              v-model="startUrls"
              :options="startUrlOptions"
              typename="url"
              :searchable="startUrlOptions.length > 5"
            />
          </b-overlay>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col>
          <b-overlay
            :show="loadingOrigins"
            :opacity="0.9"
          >
            <template #overlay>
              <div class="text-center">
                <p class="text-secondary">
                  <b-spinner
                    small
                    class="mr-2"
                    style="margin-bottom:2px;"
                  />Loading data sources...
                </p>
              </div>
            </template>
            <b-btn
              variant="primary"
              :disabled="disableSearch"
              @click="fetchLogsForFilter()"
            >
              Search
            </b-btn>

            <div
              class="d-inline"
            >
              <b-dropdown
                id="dropdown-1"
                class="ml-2"
                text="Choose data sources"
              >
                <b-dropdown-form>
                  <b-form-checkbox
                    v-for="source in availableDataOrigins"
                    :key="source.rawValue"
                    v-model="selectedOrigins"
                    :value="source"
                    class="text-nowrap align-middle"
                  >
                    {{ source.displayName }}
                  </b-form-checkbox>
                </b-dropdown-form>
              </b-dropdown>
              <span
                v-if="selectedOrigins.length === 0"
                class="ml-2 text-warning"
              >
                <font-awesome-icon
                  icon="exclamation-circle"
                />
                Choose sources
              </span>
            </div>
            <b-dropdown
              id="dropdown-1"
              class="ml-2"
              :text="chatRatings.length ? `Chat ratings (${chatRatings.length})` : 'Chat ratings'"
            >
              <b-dropdown-form>
                <b-form-checkbox-group
                  v-model="chatRatings"
                  :options="ratingsOptions"
                  stacked
                  class="text-nowrap align-middle"
                />
              </b-dropdown-form>
            </b-dropdown>
          </b-overlay>
        </b-col>
        <b-col cols="auto">
          <b-btn
            v-b-tooltip.hover.v-warning
            class="ml-2"
            variant="primary"
            :title="exportDisclaimer"
            @click="downloadLogs()"
          >
            <font-awesome-icon
              icon="download"
            />
            Export logs to JSON
          </b-btn>
          <b-btn
            v-b-tooltip.hover.v-warning
            class="ml-2"
            variant="primary"
            :title="exportDisclaimer"
            @click="downloadExcel()"
          >
            <font-awesome-icon
              icon="download"
            />
            Export logs to Excel
          </b-btn>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-alert
            v-model="showDismissibleAlert"
            variant="warning"
            dismissible
          >
            Failed to get logs
          </b-alert>
        </b-col>
      </b-row>
    </b-card>

    <!-- Table of logs matching filter -->
    <b-card
      class="r-75 mt-3"
      body-class="p-3"
    >
      <b-row
        class="mb-2"
      >
        <b-col
          v-if="tableCaption"
          cols="auto"
          class="my-auto pr-2 text-muted"
        >
          {{ tableCaption }}
        </b-col>
        <b-col class="my-auto">
          <b-pagination
            v-model="pagination.currentPage"
            :total-rows="pagination.numPages * pagination.perPage"
            :per-page="pagination.perPage"
            size="sm"
            class="mb-0"
          />
        </b-col>
        <b-col
          v-if="isVoicebot"
          cols="auto"
          class="my-auto"
        >
          <b-form-checkbox
            v-model="showSSML"
            class="pr-2"
          >
            Show SSML
          </b-form-checkbox>
        </b-col>
        <b-col cols="auto">
          <b-button
            v-b-tooltip.hover.noninteractive.vieport="'Create training dataset'"
            v-b-modal.training-dataset
            size="sm"
            variant="primary"
          >
            <font-awesome-icon icon="folder-plus" />
          </b-button>
        </b-col>
        <b-col
          cols="auto"
          class="my-auto"
        >
          <b-form-checkbox
            v-model="showActions"
            class="show-integrations"
          >
            Show integrations
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-table
            id="chatlogsTable"
            :items="itemsProvider"
            :fields="tableFields"
            :per-page="pagination.perPage"
            :current-page="pagination.currentPage"
            :tbody-tr-attr="styleForRow"
            fixed
            show-empty
            empty-text="No results found. Try a different search."
            hover
            :busy="loading"
            @row-clicked="expandAdditionalInfo"
          >
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle" />
              </div>
            </template>

            <template #cell(started)="row">
              <pretty-date-time
                :raw-time="row.item.summary.started"
              />
            </template>
            <template #cell(misc)="row">
              <div>
                <span
                  v-if="logHasFallbackError(row.item.logEvents)"
                  v-b-tooltip.hover
                  class="mr-2"
                  title="A soft (fallback) error occurred during this conversation."
                >
                  <font-awesome-icon
                    size="lg"
                    icon="exclamation-circle"
                    class="text-warning"
                  />
                </span>
                <span
                  v-if="logHasError(row.item.logEvents)"
                  v-b-tooltip.hover
                  class="mr-2"
                  title="An error occurred during this conversation."
                >
                  <font-awesome-icon
                    size="lg"
                    icon="exclamation-circle"
                    class="text-danger"
                  />
                </span>
                <span
                  v-if="row.item.chatMeta.translated === true"
                  v-b-tooltip.hover
                  class="mr-2"
                  title="This chat was translated"
                >
                  <font-awesome-icon
                    size="lg"
                    icon="language"
                  />
                </span>
                <b-btn
                  v-if="chatSystemChatLink(row.item)"
                  v-b-tooltip.hover
                  title="View conversation log in chat system (opens external link)"
                  size="sm"
                  class="mr-1"
                  variant="info"
                  :href="chatSystemChatLink(row.item)"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <font-awesome-icon icon="external-link-alt" />
                </b-btn>
                <b-btn
                  v-b-tooltip.hover
                  title="View additional details and get direct link"
                  size="sm"
                  variant="primary"
                  @click="goToChatlogDetailsPage(row.item.chatId)"
                >
                  <font-awesome-icon icon="external-link-alt" />
                </b-btn>
              </div>
            </template>
            <template #row-details="row">
              <div class="d-flex justify-content-between">
                <b-btn
                  variant="danger"
                  @click.stop="setDeleteChatbotLog(row.item.chatId)"
                >
                  Delete entry
                </b-btn>
                <voicebot-audio
                  v-if="hasAudio(row.item)"
                  :chat-log-data="row.item"
                  button-text="Play recording"
                />
              </div>
              <chat-details
                :chat-events="row.item.logEvents"
                :chat-log-id="row.item.chatId"
                :chat-log-platform="row.item.chatMeta.platform"
                :show-actions="showActions"
                :show-ssml="showSSML"
              />
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-pagination
            v-model="pagination.currentPage"
            :total-rows="pagination.numPages * pagination.perPage"
            :per-page="pagination.perPage"
            size="sm"
            class="mb-0"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-modal
      id="deleteChatbotLogModal"
      title="Delete log entry"
      ok-title="Delete"
      @ok="proxyDeleteChatbotLog"
    >
      <p>
        Are you sure you want to delete this conversation log entry?
      </p>
      <b-alert
        show
        variant="warning"
      >
        <strong>IMPORTANT:</strong> Be aware that this deletion will cascade to all training data
        and statistics calculations included in this entry.
      </b-alert>
    </b-modal>
    <create-dataset-modal origin="conversation logs" />
  </main>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import axios from 'axios';
import ChipList from 'supwiz/components/ChipList.vue';
import ChatDetails from '@/pages/ChatLogs/ChatDetails.vue';
import {
  truncateString, chatSystemChatLink, chatlogTransformer, filterDateBuilder,
} from '@/js/utils';
import PrettyDateTime from '@/components/PrettyDateTime.vue';
import DateTimeLangPicker from '@/components/DateTimeLangPicker.vue';
import VoicebotAudio from '@/components/VoicebotAudio.vue';
import endpoints from '@/js/urls';
import MultiSelect from 'supwiz/components/MultiSelect.vue';
import CreateDatasetModal from '@/pages/Training/CreateDatasetModal.vue';

export default {
  name: 'ChatlogsPage',
  components: {
    PrettyDateTime,
    ChatDetails,
    ChipList,
    DateTimeLangPicker,
    VoicebotAudio,
    CreateDatasetModal,
    MultiSelect,
  },
  data() {
    return {
      pagination: {
        perPage: 20,
        currentPage: 1,
        numPages: 1,
        totalItems: null,
      },
      tableFields: [
        {
          key: 'started',
          label: 'Started',
          thClass: 'w-auto',
          tdClass: 'align-middle table-nobreak',
        },
        {
          key: 'summary.messageCount',
          label: 'Messages',
          thClass: 'w-auto',
          tdClass: 'align-middle',
        },
        {
          key: 'summary.initialUserMessage',
          label: 'First message',
          thClass: 'w-50',
          tdClass: 'align-middle',
          // Truncate to 80 characters
          formatter: (value) => truncateString(value, 70),
        },
        {
          key: 'misc',
          label: '',
          thClass: 'w-auto',
          tdClass: 'align-middle text-right',
        },
      ],
      ratingsOptions: [
        { value: 1, text: '1 star' },
        { value: 2, text: '2 stars' },
        { value: 3, text: '3 stars' },
        { value: 4, text: '4 stars' },
        { value: 5, text: '5 stars' },
      ],
      showDismissibleAlert: false,
      showActions: false,
      toDeleteChatbotLogId: null,
      loading: false,
      loadingOrigins: false,
      showSSML: false,
    };
  },
  computed: {
    ...mapGetters('chatlogs', [
      'logsFilter',
      'availableDataOrigins',
      'availableStartUrls',
      'isFetchingStartUrls',
    ]),
    ...mapGetters('botManipulation', [
      'activeBotId',
    ]),
    ...mapGetters('botManipulation/activeBot', [
      'nodesAsList',
      'specialNodes',
      'nodeById',
      'nodeByName',
    ]),
    ...mapGetters('botManipulation/activeBot/config', [
      'getPlatforms',
    ]),
    ...mapGetters('userSettings', ['isVoicebotPlatform']),
    requireImmediateOrder: {
      get() {
        if (this.requireOnlyOne) {
          return false;
        }
        return this.logsFilter.requireImmediateOrder;
      },
      set(newValue) {
        this.updateLogsFilter({ key: 'requireImmediateOrder', newValue });
      },
    },
    selectedOrigins: {
      get() {
        return this.logsFilter.selectedDataOrigins;
      },
      set(newValue) {
        this.updateLogsFilter({ key: 'selectedDataOrigins', newValue });
      },
    },
    freeText: {
      get() {
        return this.logsFilter.freeText;
      },
      set(newValue) {
        this.updateLogsFilter({ key: 'freeText', newValue });
      },
    },
    passThroughNodeIds: {
      get() {
        return this.logsFilter.passThroughNodeIds;
      },
    },
    chatRatings: {
      get() {
        return this.logsFilter.selectedRatings;
      },
      set(newValue) {
        this.updateLogsFilter({ key: 'selectedRatings', newValue });
      },
    },
    requireOnlyOne: {
      get() {
        return this.logsFilter.requireOnlyOne;
      },
      set(newValue) {
        this.updateLogsFilter({ key: 'requireOnlyOne', newValue });
      },
    },
    coverageFilter: {
      get() {
        return this.logsFilter.coverageFilter;
      },
      set(newValue) {
        this.updateLogsFilter({ key: 'coverageFilter', newValue });
      },
    },
    selfServedFilter: {
      get() {
        return this.logsFilter.selfServedFilter;
      },
      set(newValue) {
        this.updateLogsFilter({ key: 'selfServedFilter', newValue });
      },
    },
    mustHaveTraceback: {
      get() {
        return this.logsFilter.mustHaveTraceback;
      },
      set(newValue) {
        this.updateLogsFilter({ key: 'mustHaveTraceback', newValue });
      },
    },
    selectedVariant: {
      get() {
        return this.logsFilter.selectedVariant;
      },
      set(newValue) {
        this.updateLogsFilter({ key: 'selectedVariant', newValue });
      },
    },
    startUrls: {
      get() {
        return this.logsFilter.startUrls;
      },
      set(newValue) {
        this.updateLogsFilter({ key: 'startUrls', newValue });
      },
    },
    ignoreNodeIds() {
      return this.logsFilter.ignoreNodeIds;
    },
    nodeNames() {
      const alreadySelectedNodes = this.logsFilter.passThroughNodeIds
        .concat(this.logsFilter.ignoreNodeIds)
        .map((nodeId) => this.nodeById(nodeId));
      const unfilteredNodes = this.nodesAsList.concat(this.specialNodes)
        .filter((node) => !alreadySelectedNodes.includes(node))
        .map((node) => node.name);
      return unfilteredNodes;
    },
    selectedNodeNames() {
      return this.logsFilter.passThroughNodeIds.map((nodeId) => this.nodeById(nodeId).name);
    },
    ignoreNodeNames() {
      return this.logsFilter.ignoreNodeIds.map((nodeId) => this.nodeById(nodeId).name);
    },
    /**
     * Pyt your logic here that determines if a search can be initiated
     */
    disableSearch() {
      return this.selectedOrigins.length === 0;
    },
    logHasFallbackError() {
      return (logEvents) => logEvents.some((e) => e.type === 'fallback');
    },
    logHasError() {
      return (logEvents) => logEvents.some((e) => e.type === 'error');
    },
    chatSystemChatLink() {
      return (chatlogData) => chatSystemChatLink(chatlogData);
    },
    tableCaption() {
      if (!this.pagination.totalItems) {
        return '';
      }
      const start = 1 + ((this.pagination.currentPage - 1) * 20);
      const end = Math.min(20 + ((this.pagination.currentPage - 1) * 20),
        this.pagination.totalItems);
      return `${start}-${end} of ${this.pagination.totalItems}`;
    },
    isVoicebot() {
      return this.isVoicebotPlatform(this.getPlatforms);
    },
    exportDisclaimer() {
      return 'Only the first 500 logs are exported. Use the conversation logs API for larger exports.';
    },
    startUrlOptions() {
      return this.availableStartUrls ? this.availableStartUrls : [];
    },
  },
  mounted() {
    // Handle the case in which we were router-pushed here from Statistics page; this
    // also allows for shareable links.
    if (this.$route.query.includeNodes !== undefined) {
      const includeNodeIds = this.$route.query.includeNodes;
      if (!Array.isArray(includeNodeIds)) {
        this.updateLogsFilter({ key: 'passThroughNodeIds', newValue: [includeNodeIds] });
      } else {
        this.updateLogsFilter({ key: 'passThroughNodeIds', newValue: includeNodeIds });
      }
    }
    if (this.$route.query.requireImmediateOrder !== undefined) {
      this.requireImmediateOrder = Boolean(this.$route.query.requireImmediateOrder);
    }
    if (this.$route.query.requireOnlyOne !== undefined) {
      this.requireOnlyOne = Boolean(this.$route.query.requireOnlyOne);
    }
    if (this.$route.query.coverageFilter !== undefined) {
      this.coverageFilter = this.$route.query.coverageFilter;
    }
    if (this.$route.query.selfServedFilter !== undefined) {
      this.selfServedFilter = this.$route.query.selfServedFilter;
    }
    if (this.$route.query.selectedVariant !== undefined) {
      this.selectedVariant = this.$route.query.selectedVariant;
    }
    if (this.$route.query.startUrls !== undefined) {
      const startUrls = this.$route.query.startUrls;
      if (!Array.isArray(startUrls)) {
        this.updateLogsFilter({ key: 'startUrls', newValue: [startUrls] });
      } else {
        this.updateLogsFilter({ key: 'startUrls', newValue: startUrls });
      }
    }
    this.loadingOrigins = true;
    this.fetchDataOrigins().then(() => {
      this.loadingOrigins = false;
      this.$root.$emit('bv::refresh::table', 'chatlogsTable');
    });
    this.fetchStartUrls();
  },
  methods: {
    ...mapActions('chatlogs', [
      'searchLogs',
      'fetchDataOrigins',
      'fetchStartUrls',
    ]),
    ...mapMutations('chatlogs', [
      'updateLogsFilter',
    ]),
    captureStartTime(event) {
      this.startTime = event;
    },
    captureEndTime(event) {
      this.endTime = event;
    },
    async fetchLogsForFilter() {
      // Trigger refresh of table via provider function
      this.pagination.numPages = 0;
      this.pagination.currentPage = 1;
      this.pagination.totalItems = 0;
      this.$root.$emit('bv::refresh::table', 'chatlogsTable');
    },
    setRequireNode(requiredNodes) {
      const nodeIds = requiredNodes.map((nodeName) => this.nodeByName(nodeName).id);
      this.updateLogsFilter({ key: 'passThroughNodeIds', newValue: nodeIds });
    },
    setIgnoreNodes(ignoreNodes) {
      const nodeIds = ignoreNodes.map((nodeName) => this.nodeByName(nodeName).id);
      this.updateLogsFilter({ key: 'ignoreNodeIds', newValue: nodeIds });
    },
    expandAdditionalInfo(row) {
      // eslint-disable-next-line no-param-reassign
      row._showDetails = !row._showDetails;
    },
    hasAudio(chatLogData) {
      if (Object.prototype.hasOwnProperty.call(chatLogData.chatMeta, 'recording_url')) {
        return chatLogData.chatMeta.recording_url != null;
      }
      return false;
    },
    setDeleteChatbotLog(chatId) {
      this.toDeleteChatbotLogId = chatId;
      this.$bvModal.show('deleteChatbotLogModal');
    },
    async proxyDeleteChatbotLog() {
      await axios.delete(endpoints.chatlogsSearch, {
        params: { chat_id: this.toDeleteChatbotLogId },
        headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` },
      });
      this.toDeleteChatbotLogId = null;
      await this.fetchLogsForFilter();
    },
    async itemsProvider(context) {
      if (this.disableSearch) {
        return [];
      }
      const currentPage = context.currentPage;
      const perPage = context.perPage;

      // Fetch new logs
      const botId = this.activeBotId;
      const freeText = this.freeText;
      const requireNodes = this.passThroughNodeIds;
      const ignoreNodes = this.ignoreNodeIds;
      const selectedRatings = this.chatRatings;
      const {
        selectedVariant,
        startDate,
        endDate,
        selectedLanguage,
        startTime,
        endTime,
        startUrls,
      } = this.logsFilter;

      const startDateTime = filterDateBuilder(startDate, startTime);
      const endDateTime = filterDateBuilder(endDate, endTime);

      let results;
      this.loading = true;
      try {
        results = await this.searchLogs({
          botId,
          variantId: selectedVariant,
          startDate: startDateTime.toISOString(),
          endDate: endDateTime.toISOString(),
          selectedLanguage,
          freeText,
          requireNodes,
          ignoreNodes,
          requireImmediateOrder: this.requireImmediateOrder,
          requireOnlyOne: this.requireOnlyOne,
          coverageFilter: this.coverageFilter,
          selfServedFilter: this.selfServedFilter,
          mustHaveTraceback: this.mustHaveTraceback,
          page: currentPage,
          resultsPerPage: perPage,
          selectedRatings,
          startUrls,
        });
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
        this.showDismissibleAlert = true;
        return [];
      }

      // If previously shown, now dismiss alert
      this.showDismissibleAlert = false;

      // Pagination info
      this.pagination.numPages = results.page_limit;
      this.pagination.currentPage = results.page;
      this.pagination.totalItems = results.total_items;

      const mappedLogs = results.chats.map(chatlogTransformer);
      return mappedLogs;
    },
    async downloadLogs(exportFormat = 'json') {
      const currentPage = this.pagination.currentPage;
      const perPage = this.pagination.perPage;
      const botId = this.activeBotId;
      const freeText = this.freeText;
      const requireNodes = this.passThroughNodeIds;
      const ignoreNodes = this.ignoreNodeIds;
      const selectedRatings = this.chatRatings;
      const {
        selectedVariant,
        startDate,
        endDate,
        selectedLanguage,
        startTime,
        endTime,
        startUrls,
      } = this.logsFilter;

      const startDateTime = filterDateBuilder(startDate, startTime);
      const endDateTime = filterDateBuilder(endDate, endTime);

      let results = null;
      try {
        results = await this.searchLogs({
          botId,
          variantId: selectedVariant,
          startDate: startDateTime.toISOString(),
          endDate: endDateTime.toISOString(),
          selectedLanguage,
          freeText,
          requireNodes,
          ignoreNodes,
          requireImmediateOrder: this.requireImmediateOrder,
          requireOnlyOne: this.requireOnlyOne,
          coverageFilter: this.coverageFilter,
          selfServedFilter: this.selfServedFilter,
          mustHaveTraceback: this.mustHaveTraceback,
          page: currentPage,
          resultsPerPage: perPage,
          selectedRatings,
          startUrls,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          exportFormat,
        });
      } catch (error) {
        console.log(error);
        this.showDismissibleAlert = true;
        return [];
      }
      let fileData;
      if (exportFormat === 'json') {
        // write the rows to a string and encode it as a URI
        const DownloadHeader = 'data:text/json;charset=utf-8,';
        fileData = DownloadHeader + encodeURIComponent(JSON.stringify(results.chats));
      } else if (exportFormat === 'excel') {
        // get a URL for the excel data blob
        fileData = URL.createObjectURL(results);
      }
      // enforce download of the file
      const link = document.createElement('a');
      link.setAttribute('href', fileData);
      const fileExtension = exportFormat === 'json' ? 'json' : 'xlsx';
      link.setAttribute('download', `chat_logs.${fileExtension}`);
      document.body.appendChild(link); // Required for FF
      link.click();
      link.remove();
      return null; // Otherwise ESlint complains.
    },
    async downloadExcel() {
      await this.downloadLogs('excel');
    },
    goToChatlogDetailsPage(chatId) {
      const botId = this.$route.params.botId;
      const newPage = this.$router.resolve({ name: 'conversation-log-single', params: { botId, chatId } });
      window.open(newPage.href, '_blank');
    },
    styleForRow(item, type) {
      if (type === 'row') {
        return {
          style: 'cursor:pointer',
        };
      }
      return null;
    },
  },
};
</script>
<style>
.show-integrations{
  z-index: 0
}
</style>
