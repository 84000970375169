<template>
  <main>
    <b-card
      title="Natural Language Understanding"
      class="r-75"
      body-class="p-3"
    >
      <p class="font-weight-bold">
        On this page you can train and manage the language understanding of your bots.
      </p>
      <p>
        Language models will typically be provided by SupWiz and they provide a basic understanding
        of a language. We train classifiers on top of the language models to enable the bot
        to distinguish between different types of user queries.
      </p>
      <p>
        Select or create a classifier below to get started.
      </p>
    </b-card>
    <b-card
      class="r-75 mt-3"
      body-class="p-3"
      title="Classifiers & Language Models"
    >
      <b-tabs>
        <b-tab title="Classifiers">
          <classifiers />
        </b-tab>
        <b-tab title="Language models">
          <language-models />
        </b-tab>
      </b-tabs>
    </b-card>
  </main>
</template>

<script>

import LanguageModels from '@/pages/NLU/ModelsOverview/LanguageModels.vue';
import Classifiers from '@/pages/NLU/ModelsOverview/Classifiers.vue';

export default {
  name: 'NLUModelsOverview',
  components: {
    LanguageModels,
    Classifiers,
  },
};
</script>
