/* eslint-disable max-len */
<template>
  <div>
    <script-evaluator />
    <b-card
      title="The BotScript language"
      class="r-75 mt-3"
      body-class="p-3"
    >
      The BotScript language is based on the Python language. It is parsed using a parser
      that recognizes the Python 3.6 language, and all code that is valid BotScript language can
      be semantically parsed as Python 3.6 operations. However, only a small subset of the
      language is supported, as described below (click to expand each)
    </b-card>

    <b-card
      class="my-3 shadow-sm"
      :no-body="!isCollapsedState.typesCard"
    >
      <template #header>
        <h4
          class="mb-0"
          style="cursor:pointer"
          @click="collapseSection('typesCard')"
        >
          <font-awesome-icon
            class="mr-2"
            :icon="isCollapsedState['typesCard'] ? 'angle-up' : 'angle-down'"
          />
          Types
        </h4>
      </template>
      <b-collapse
        v-model="isCollapsedState['typesCard']"
      >
        The BotScript language only supports a subset of the types included in the Python
        language: <code>bool, int, float, str, list, dict, set, tuple, slice</code>. Moreover,
        it supports certain operations on special <code>NER</code> objects corresponding to
        Named Entities.
      </b-collapse>
    </b-card>

    <b-card
      class="my-3 shadow-sm"
      :no-body="!isCollapsedState.listsCard"
    >
      <template #header>
        <h4
          class="mb-0"
          style="cursor:pointer"
          @click="collapseSection('listsCard')"
        >
          <font-awesome-icon
            class="mr-2"
            :icon="isCollapsedState['listsCard'] ? 'angle-up' : 'angle-down'"
          />
          List creation and list access
        </h4>
      </template>
      <b-collapse
        v-model="isCollapsedState.listsCard"
      >
        You can use bracket-syntax for creating lists like so:
        <code>['apple','bananas','oranges']</code> <br>
        Note the angular brackets (<code>[</code> and <code>]</code>) and elements are separated
        by commas. <br>
        Lists are zero-indexed, meaning the first element in the list is on index 0. <br>
        Assuming the above list was assigned a variable <code>fruits</code>,
        then <code>fruits[0]</code> would reveal <code>'apple'</code>.<br>
        You can append an element to a list, like so: <code>[1,2,3]+[4]</code>
      </b-collapse>
    </b-card>

    <b-card
      class="my-3 shadow-sm"
      :no-body="!isCollapsedState.objectCard"
    >
      <template #header>
        <h4
          class="mb-0"
          style="cursor:pointer"
          @click="collapseSection('objectCard')"
        >
          <font-awesome-icon
            class="mr-2"
            :icon="isCollapsedState['objectCard'] ? 'angle-up' : 'angle-down'"
          />
          Object creation and object access
        </h4>
      </template>
      <b-collapse
        v-model="isCollapsedState.objectCard"
      >
        You can create objects (or "dicts") like so
        <code>{'TicketId':'TICK09786', 'authors': ['Peter Smith', 'Sara Nelson']}</code> <br>
        You access a property of the object using dictionary lookup syntax:
        <code>object['propertyName']</code>. <br>
        Assuming the above was assigned a variable <code>api_response</code>,
        then <code>api_response['TicketId']</code> would reveal <code>'TICK09786'</code>
      </b-collapse>
    </b-card>

    <b-card
      class="my-3 shadow-sm"
      :no-body="!isCollapsedState.setCard"
    >
      <template #header>
        <h4
          class="mb-0"
          style="cursor:pointer"
          @click="collapseSection('setCard')"
        >
          <font-awesome-icon
            class="mr-2"
            :icon="isCollapsedState['setCard'] ? 'angle-up' : 'angle-down'"
          />
          Set creation
        </h4>
      </template>
      <b-collapse
        v-model="isCollapsedState.setCard"
      >
        You create sets using curly braces, wrapping the set content. <br>
        Separate each member of the set by comma (<code>,</code>).<br> For instance, after
        assigning
        (note: 2 appearing twice below in assignment <em>is</em> intentional) <br>
        <code>my_set = {1,2,2,3}</code>  <br>
        <code>my_set</code> now contains members: 1, 2 and 3.
      </b-collapse>
    </b-card>

    <b-card
      class="my-3 shadow-sm"
      :no-body="!isCollapsedState.stringsCard"
    >
      <template #header>
        <h4
          class="mb-0"
          style="cursor:pointer"
          @click="collapseSection('stringsCard')"
        >
          <font-awesome-icon
            class="mr-2"
            :icon="isCollapsedState['stringsCard'] ? 'angle-up' : 'angle-down'"
          />
          Strings
        </h4>
      </template>
      <b-collapse
        v-model="isCollapsedState.stringsCard"
      >
        Use <code>'\n'</code> as a newline-character to split text onto two (or more) lines. <br>
        Combine strings using <code>+</code>, for instance
        <code>'Your email is: ' + email</code> (assuming an <code>email</code> variable exists and
        it
        contains a string.)<br>
        <h4
          class="mt-3"
          style="cursor:pointer"
        >
          String functions
        </h4>
        <b-list-group>
          <b-list-group-item>
            <i>join</i>. <code>join(s, x)</code> takes a string <code>s</code> and a list or tuple
            <code>x = [x_1, x_2, ..., x_n]</code> and gives
            <code>str(x_1) + s + str(x_2) + s + ... + s + str(x_n)</code>.
            E.g. <code>join(', ', [1, 2, 3])</code> will give the string <code>'1, 2, 3'</code>.
          </b-list-group-item>
          <b-list-group-item>
            <i>split, split_by</i>. <code>split_by(s, t)</code> return a list of the words in the
            string, <code>s</code>, using <code>t</code> as the delimiter string.
            <code>split(s)</code> splits the string on whitespaces.
          </b-list-group-item>
          <b-list-group-item>
            <i>random_random</i>. <code>random_random()</code> returns an float in the interval
            [0,1).
          </b-list-group-item>
          <b-list-group-item>
            <i>replace</i>. <code>replace(s, a, b)</code> returns the string <code>s</code> where
            all occurrences of <code>a</code> has been replaced by <code>b</code>.
          </b-list-group-item>
          <b-list-group-item>
            <i>re_findall</i>. <code>re_findall(pattern, input [, flags])</code> returns a list of
            all matches of <code>pattern</code> in the string <code>input</code>.
            Optional <code>flags</code> can be <code>re.IGNORECASE</code>,
            <code>re.MULTILINE</code>, and <code>re.DOTALL</code>.
          </b-list-group-item>
          <b-list-group-item>
            <i>re_sub</i>. <code>re_sub(pattern, replace, string[, flags])</code> returns the
            <code>string</code> where all matches of <code>pattern</code> is replaced
            with <code>replace</code>.
            Optional <code>flags</code> can be <code>re.IGNORECASE</code>,
            <code>re.MULTILINE</code>, and <code>re.DOTALL</code>.
          </b-list-group-item>
          <b-list-group-item>
            <i>endswith, startswith</i>. <code>startswith(s, t)</code> returns <code>True</code>
            if the string <code>s</code> starts with <code>t</code> and <code>False</code>
            otherwise.
            Similarly, <code>endswith(s, t)</code> returns <code>True</code> if <code>s</code>
            ends with <code>t</code> and <code>False</code> otherwise.
          </b-list-group-item>
          <b-list-group-item>
            <i>removeprefix, removesuffix</i>. <code>removeprefix(s, t)</code> returns
            <code>string</code> where the pre/suf-fix string <code>t</code> is removed from
            string <code>s</code> if it exists; otherwise <code>s</code> is returned. E.g.
            <code>removeprefix("+4542948939", "+45")</code> returns <code>"42948939"</code>.
            <code>removeprefix("42948939", "+45")</code> returns <code>"42948939"</code>.
            <code>removesuffix("+4542948939-M", "-M")</code> returns <code>"+4542948939"</code>.
            <code>removesuffix("+4542948939", "-M")</code> returns <code>"+4542948939"</code>.
          </b-list-group-item>
          <b-list-group-item>
            <i>addprefix</i>. <code>addprefix(s, ps)</code> where <code>s</code> is a
            <code>string</code>, and <code>ps</code> is a <code>list</code> of prefixes
            of type <code>string</code> and returns a <code>string</code>.
            It returns <code>s</code> if it has one of the provided prefixes
            in <code>ps</code>, otherwise the first string in <code>ps</code> is added.  E.g.

            <code>addprefix("foobar.com", ["https://", "http://"])</code> returns
            <code>"https://foobar.com</code>.
            <code>addprefix("http://foobar.com", ["https://", "http://"])</code> returns
            <code>"http://foobar.com</code>.
            <code>addprefix("42948939", ["0045, "00"])</code> returns <code>"004542948939"</code>.
          </b-list-group-item>
          <b-list-group-item>
            <i>mask_email</i>. <code>mask_email(s)</code> where <code>s</code> is a
            <code>string</code> containing an e-mail address.
            It returns a new string with a masked version of the input e-mail or the original string
            if it is not an e-mail.
            Examples:

            <code>mask_email("test@gmail.com")</code> returns
            <code>"t***@*******m"</code>.
            <code>mask_email("test@")</code> returns
            <code>"test@"</code>.
          </b-list-group-item>
        </b-list-group>
      </b-collapse>
    </b-card>

    <b-card
      class="my-3 shadow-sm"
      :no-body="!isCollapsedState.entitiesCard"
    >
      <template #header>
        <h4
          class="mb-0"
          style="cursor:pointer"
          @click="collapseSection('entitiesCard')"
        >
          <font-awesome-icon
            class="mr-2"
            :icon="isCollapsedState['entitiesCard'] ? 'angle-up' : 'angle-down'"
          />
          Entities
        </h4>
      </template>
      <b-collapse
        v-model="isCollapsedState.entitiesCard"
      >
        There exists a number of special functions that takes a NER object;
        <b-list-group>
          <b-list-group-item>
            <code>first(x)</code> returns the first occurrence of the named entity
            <code>x</code>.
          </b-list-group-item>
          <b-list-group-item>
            <code>latest(x)</code> returns the latest occurrence of the named entity
            <code>x</code>.
          </b-list-group-item>
          <b-list-group-item>
            <code>ner_all(x)</code> returns a list of all occurrences of the named
            entity <code>x</code>.
          </b-list-group-item>
          <b-list-group-item>
            <code>ner_clear(x)</code> clears all occurrences of the named entity
            <code>x</code>.
          </b-list-group-item>
          <b-list-group-item>
            <code>ner_pop(x)</code> pops/removes and then returns the latest
            occurrences of the named entity <code>x</code>.
          </b-list-group-item>
          <b-list-group-item>
            <code>ner_exists(x)</code> returns <code>True</code> if the named entity
            <code>x</code> has occurred at least once and <code>False</code> otherwise.
          </b-list-group-item>
        </b-list-group>
      </b-collapse>
    </b-card>

    <b-card
      class="my-3 shadow-sm"
      :no-body="!isCollapsedState.dateTimeCard"
    >
      <template #header>
        <h4
          class="mb-0"
          style="cursor:pointer"
          @click="collapseSection('dateTimeCard')"
        >
          <font-awesome-icon
            class="mr-2"
            :icon="isCollapsedState['dateTimeCard'] ? 'angle-up' : 'angle-down'"
          />
          Date and time
        </h4>
      </template>
      <b-collapse
        v-model="isCollapsedState.dateTimeCard"
      >
        <b-list-group>
          <b-list-group-item>
            You can get the current time and date for a specific timezone, using
            <code>datetime_now(tz)</code> where <code>tz</code> denotes a timezone, for instance
            <code>datetime_now('Europe/Copenhagen')</code> will return the current time in
            Copenhagen/Denmark.<br>
            The returned datetime object has the following properties:
            <ul>
              <li><code>year</code></li>
              <li><code>month</code>: integer in range 1-12 (1: January, 12: December)</li>
              <li><code>day</code>: day of the month</li>
              <li><code>hour</code></li>
              <li><code>minute</code></li>
              <li><code>second</code></li>
              <li><code>weekday</code>: day of the week in range 1-7 (1: Monday, 7: Sunday)</li>
              <li><code>yearday</code>: day of the year</li>
              <li>
                <code>timestamp</code>: isoformatted human-readable time
                (e.g: <code>'2020-10-21T11:19:13.328418+02:00'</code>)
              </li>
              <li>
                <code>timezone</code>: timezone info for the object
                (e.g: <code>'Europe/Copenhagen'</code>)
              </li>
              <li>
                <code>epochs</code>: UNIX timestamp (seconds since Jan 01 1970)
              </li>
            </ul>
            The properties can be extracted from the object using the dictionary lookup syntax:
            <code>datetime_now('Europe/Copenhagen')['year']</code>. <br>The function takes one
            optional argument: a valid <code>pytz</code> timezone
            (<a href="https://gist.github.com/heyalexej/8bf688fd67d7199be4a1682b3eec7568">
              list here
            </a>).
            If no argument is passed UTC time is returned.
          </b-list-group-item>
          <b-list-group-item>
            <i>datetime_from_isostring</i>. <code>datetime_from_isostring(s)</code> where
            <code>s</code> is a <code>string</code> containing an isoformat date/datetime string.
            It returns the corresponding datetime dictionary as described for
            <code>datetime_now</code> described above.
          </b-list-group-item>
          <b-list-group-item>
            <i>datetime_from_timestamp</i>. <code>datetime_from_timestamp(ts[, tz])</code> where
            <code>ts</code> is a timestamp of type <code>int</code> or <code>float</code>.
            An optional argument <code>tz</code> can be provided as a timezone. The
            <code>tz</code> argument must be an <code>int</code>, and is the offset from UTC in
            hours. This function returns the corresponding datetime dictionary as described for
            <code>datetime_now</code> described above.
          </b-list-group-item>
        </b-list-group>
      </b-collapse>
    </b-card>

    <b-card
      class="my-3 shadow-sm"
      :no-body="!isCollapsedState.operationsCard"
    >
      <template #header>
        <h4
          class="mb-0"
          style="cursor:pointer"
          @click="collapseSection('operationsCard')"
        >
          <font-awesome-icon
            class="mr-2"
            :icon="isCollapsedState['operationsCard'] ? 'angle-up' : 'angle-down'"
          />
          Operations
        </h4>
      </template>
      <b-collapse
        v-model="isCollapsedState.operationsCard"
      >
        The BotScript language supports a subset of the operations used in the Python language.
        They are summarized below.
        <b-list-group>
          <b-list-group-item>
            <i>Boolean operations</i>. The operations <code>and</code> and <code>or</code> are
            supported on booleans.
          </b-list-group-item>
          <b-list-group-item>
            <i>Binary operations</i>. The operations <code>+, -, *, /, //, %</code> are supported
            for <code>float, int</code>. Furthermore, <code>+</code> is supported for
            <code>str, list, tuple</code>.
          </b-list-group-item>
          <b-list-group-item>
            <i>Unary operations</i>. The unary operations <code>+, -</code> are supported for
            <code>float, int</code>, and <code>not</code> is supported for <code>bool</code>.
          </b-list-group-item>
          <b-list-group-item>
            <i>If/Then/Else</i>. The syntax <code>x if y else z</code> is supported if
            <code>y</code>
            evaluates to a <code>bool</code>.
          </b-list-group-item>
          <b-list-group-item>
            <i>Subscript</i>. Subscripting, i.e. <code>x[y]</code>, is supported on
            <code>list</code>,
            <code>dict</code> and <code>str</code>.
          </b-list-group-item>
          <b-list-group-item>
            <i>Compare</i>. The following compare operators are supported:
            <code>==</code>, <code>!=</code>, <code>&lt;</code>, <code>&lt;=</code>,
            <code>&gt;</code>, <code>&gt;=</code>.
          </b-list-group-item>
          <b-list-group-item>
            <i>Inclusion</i>. The following inclusion operators are supported on
            <code>list</code>, <code>dict</code>, <code>set</code> and <code>str</code>:
            <code>in</code>, <code>not in</code>.<br>
            For instance <br>
            <code>'message' in {'message':'test message'}</code>, <br>
            <code>1 in [1,2,3]</code>,<br>
            <code>'high' in 'thigh'</code> and <br>
            <code>1 in {1,2,3}</code>
            all reveals True,
            whereas <br> <code>'message' in {'firstName':'Per'}</code>,<br>
            <code>7 in [12,22,32]</code>,<br>
            <code>'tom' in 'spiderman'</code> and
            <code>4 in {1,2,3}</code> <br> all reveals False.<br><br>
            Note that when checking for text-inclusion, the check is case-sensitive, such that<br>
            <code>'high' in 'thigh'</code><br>
            reveals True, but <br>
            <code>'High' in 'thigh'</code><br>
            reveals False.
          </b-list-group-item>
        </b-list-group>
      </b-collapse>
    </b-card>
    <b-card
      class="my-3 shadow-sm"
      :no-body="!isCollapsedState.functionsCard"
    >
      <template #header>
        <h4
          class="mb-0"
          style="cursor:pointer"
          @click="collapseSection('functionsCard')"
        >
          <font-awesome-icon
            class="mr-2"
            :icon="isCollapsedState['functionsCard'] ? 'angle-up' : 'angle-down'"
          />
          General functions
        </h4>
      </template>
      <b-collapse
        v-model="isCollapsedState['functionsCard']"
      >
        Only a small number of builtin functions are available in the BotStudio language. It is
        not possible to define new functions.
        <b-list-group>
          <b-list-group-item>
            <i>sum</i>. <code>sum(x)</code> takes a list <code>x</code> of numbers and returns the
            sum.
            E.g. <code>sum([1, 2, 3])</code> will give <code>6</code>.
          </b-list-group-item>
          <b-list-group-item>
            The functions <code>str, int, float, dict, tuple, list, set, bool</code> convert the
            input to the specified type, and will fail the bot otherwise. E.g. <code>str(2)</code>
            will give <code>'2'</code>, and <code>dict([(1, 2), (3, 4)])</code> will give the
            dictionary <code>{1: 2, 3: 4}</code>.
          </b-list-group-item>
          <b-list-group-item>
            <i>len</i>. <code>len(x)</code> takes a <code>list</code>, <code>tuple</code>,
            <code>set</code> or <code>dict</code> and gives the number of elements in
            <code>x</code>.
            E.g. <code>len([1, 2, 4])</code> gives <code>3</code>.
          </b-list-group-item>
          <b-list-group-item>
            <i>lower, upper</i>. Takes a string and transforms it into lower or upper case.
            E.g. <code>lower('Hello')</code> gives <code>'hello'</code> and
            <code>upper('Hello')</code> gives <code>'HELLO'</code>.
          </b-list-group-item>
          <b-list-group-item>
            <i>keys, values, items</i>. Takes <code>dict</code> and returns a <code>list</code> of
            the keys, values, or tuples of key-values pairs in the dictionary.
            E.g.
            <code>keys({'first_name':'Per', 'last_name':'Hansen'})</code> gives
            <code>['first_name', 'last_name']</code>,
            <code>values({'first_name':'Per', 'last_name':'Hansen'})</code> gives
            <code>['Per','Hansen']</code>, and
            <code>items({'first_name':'Per', 'last_name':'Hansen'})</code> gives
            <code>[('first_name','Per'),('last_name','Hansen')]</code>.
          </b-list-group-item>
          <b-list-group-item>
            <i>dict_update</i>. <code>dict_update(to_update, others)</code> takes two dictionaries
            <code>to_update</code> and <code>others</code>. Adds or overrides the key-value-pairs
            in <code>dst</code> with key-value-pairs from <code>others</code>.
            <code>dict_update</code> also returns the new value for <code>to_update</code>
            Assuming <code>to_update</code> is currently assigned
            <code>{'a': 'Before', 'b': 'running dict_update'}</code>, then
            <code>dict_update(to_update, {'a': 'After', 'c': 'completed'})</code> gives
            <code>{'a': 'After', 'b': 'running dict_update', 'c': 'completed'}</code> <i>and</i>
            <code>to_update</code> is now assigned
            <code>{'a':'After', 'b':'running dict_update', 'c':'completed'}</code>
          </b-list-group-item>
          <b-list-group-item>
            <i>dict_keys_exists</i>. <code>dict_keys_exists(a_dict, keys)</code> takes a
            dictionary <code>a_dict</code> and a list of keys <code>keys</code>. It returns
            <code>True</code> if the following element exists
            <code>a_dict[keys[0]][keys[1]]...[keys[n]]</code>, <code>False</code> otherwise.
          </b-list-group-item>
          <b-list-group-item>
            <i>map</i>. <code>map(x, y, xs)</code> takes three arguments. The first,
            <code>x</code>, is the name of variable that is not otherwise within the scope. The
            second, <code>y</code> is an expression that might include <code>x</code>. And
            <code>xs</code> is a list, tuple or set. It takes each element <code>x</code> of
            <code>xs</code> and replaces it with the evaluation of the expression <code>y</code>.
            E.g. <code>map(x, x*x+2, [1, 2, 3])</code> gives <code>[3, 6, 11]</code>.
          </b-list-group-item>
          <b-list-group-item>
            <i>foldl</i>. <code>foldl(x, rs, y, r, xs)</code> takes five arguments.
            The first <code>x</code>, and second <code>rs</code>, are the names of variables that
            are
            not otherwise within the scope.
            The third, <code>y</code> is an expression that might include
            <code>x</code> and <code>rs</code>.
            The forth, <code>r</code>, is an initial value for <code>rs</code>.
            And <code>xs</code> is a list, tuple or set.
            <code>foldl</code> takes each element <code>x</code> of <code>xs</code> and latest
            <code>rs</code>
            and set the result of evaluating of the expression <code>y</code> to <code>rs</code>.
            The return value is the latest <code>rs</code>.
            E.g. <code>foldl(x, rs, x*x+2+rs, 1, [1, 2, 3])</code> returns <code>21</code>.
            Note that <code>foldl</code> (<i>fold left</i>) is nearly the same as Python's
            <i>reduce</i>.
          </b-list-group-item>
          <b-list-group-item>
            <i>remove_if</i>. <code>remove_if(x, y, xs)</code> works in a manner similar to
            <code>map</code>, but <code>y</code> instead evaluates to a boolean and the resulting
            list contains all the elements from <code>xs</code>, where the corresponding
            <code>y</code> evaluates to <code>False</code>. <code>xs</code> is left unmodified.
            E.g. <code>remove_if(x,x>2,[1,2,3])</code> returns <code>[1,2]</code>
          </b-list-group-item>
          <b-list-group-item>
            <i>exists</i>. <code>exists(x)</code> takes the name of a variable <code>x</code> and
            returns <code>True</code> if <code>x</code> exists as a variable in the scope and
            <code>False</code> otherwise.
          </b-list-group-item>
          <b-list-group-item>
            <i>raises_error</i>. <code>raises_error(x)</code> takes code as input and evaluates it
            to see if it raises any errors. In case the code contains any errors
            <code>raises_error(x)</code> returns <code>True</code>, otherwise <code>False</code>.
          </b-list-group-item>
          <b-list-group-item>
            <i>zip</i>. <code>zip</code> works like the Python implementation of <code>zip</code>
            except all arguments must be lists or tuples, all of them must have the same length,
            and the return value is always a list.
            E.g. <code>zip([1, 2], ('a', 'b'))</code> returns <code>[(1, 'a'), (2, 'b')]</code>.
          </b-list-group-item>
          <b-list-group-item>
            <i>any, all</i>. <code>any(xs)</code> takes a list of booleans <code>xs</code> and
            returns <code>True</code> if any of the elements are <code>True</code> and
            <code>False</code> otherwise. Likewise <code>all(xs)</code> returns <code>True</code>
            if all the elements are <code>True</code> and <code>False</code> otherwise.
          </b-list-group-item>
        </b-list-group>
      </b-collapse>
    </b-card>

    <b-card
      class="my-3 shadow-sm"
      :no-body="!isCollapsedState.constantsCard"
    >
      <template #header>
        <h4
          class="mb-0"
          style="cursor:pointer"
          @click="collapseSection('constantsCard')"
        >
          <font-awesome-icon
            class="mr-2"
            :icon="isCollapsedState['constantsCard'] ? 'angle-up' : 'angle-down'"
          />
          Constants
        </h4>
      </template>
      <b-collapse
        v-model="isCollapsedState['constantsCard']"
      >
        Only some constants are available from a few Python modules.
        <b-list-group>
          <b-list-group-item>
            <i>math.e</i>: Euler's number: 2.718...
            <i>math.pi</i>: The number of pi: 3.1415[...]
            <i>math.nan</i>: Not a number
            <i>math.inf</i>: Inifinity
          </b-list-group-item>
          <b-list-group-item>
            <i>re.IGNORECASE, re.I</i>: Perform case-insensitive matching;
            expressions like [A-Z] will match lowercase letters, too.
            <i>re.MULTILINE, re.M</i>: When specified, the pattern character '^' matches at the
            beginning of the string and at the beginning of each line
            (immediately following each newline); and the pattern character '$' matches at the end
            of
            the string and at the end of each line (immediately preceding each newline).
            <i>re.DOTALL, re.S</i>: Makes the '.' special character match any character at all,
            including a newline
          </b-list-group-item>
        </b-list-group>
      </b-collapse>
    </b-card>

    <b-card
      class="my-3 shadow-sm"
      :no-body="!isCollapsedState.builtInVariablesCard"
    >
      <template #header>
        <h4
          class="mb-0"
          style="cursor:pointer"
          @click="collapseSection('builtInVariablesCard')"
        >
          <font-awesome-icon
            class="mr-2"
            :icon="isCollapsedState['builtInVariablesCard'] ? 'angle-up' : 'angle-down'"
          />
          Built-in variables
        </h4>
      </template>
      <b-collapse
        v-model="isCollapsedState['builtInVariablesCard']"
      >
        Some variables are part of every bot and cannot be overridden. These are listed here:
        <b-list-group>
          <b-list-group-item>
            <i>transcript</i>: A continuously accumulating transcript of the conversation so far,
            including both the user's and the bot's messages.
            The transcript is verbatim and is not anonymized.
          </b-list-group-item>
          <b-list-group-item>
            <i>thisMessage</i>: The last message the user wrote.
            The message has all entities anonymized. The message is also lower-cased.
          </b-list-group-item>
          <b-list-group-item>
            <i>thisMessageVerbatim</i>: The last message the user wrote without any altercations.
          </b-list-group-item>
          <b-list-group-item>
            <i>lastBotMessages</i>: The bot's message(s) since last user message.
            Usefull when the bot needs to repeat itself.
          </b-list-group-item>
        </b-list-group>
      </b-collapse>
    </b-card>

    <b-card
      class="my-3 shadow-sm"
      :no-body="!isCollapsedState.reservedVariablesCard"
    >
      <template #header>
        <h4
          class="mb-0"
          style="cursor:pointer"
          @click="collapseSection('reservedVariablesCard')"
        >
          <font-awesome-icon
            class="mr-2"
            :icon="isCollapsedState['reservedVariablesCard'] ? 'angle-up' : 'angle-down'"
          />
          Reserved variables
        </h4>
      </template>
      <b-collapse
        v-model="isCollapsedState['reservedVariablesCard']"
      >
        In addition to the built-in variables, additional names are also reserved as variable
        names, due to BotScripts ties to Python.<br>
        The following variable names are reserved:
        <code>False</code>, <code>None</code>, <code>True</code>, <code>and</code>,
        <code>as</code>, <code>assert</code>, <code>async</code>, <code>await</code>,
        <code>break</code>, <code>class</code>, <code>continue</code>, <code>def</code>,
        <code>del</code>,
        <code>elif</code>, <code>else</code>, <code>except</code>, <code>finally</code>,
        <code>for</code>, <code>from</code>, <code>global</code>, <code>if</code>,
        <code>import</code>, <code>in</code>, <code>is</code>, <code>lambda</code>,
        <code>nonlocal</code>, <code>not</code>, <code>or</code>, <code>pass</code>,
        <code>raise</code>, <code>return</code>, <code>try</code>, <code>while</code>,
        <code>with</code>, <code>yield</code>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import ScriptEvaluator from '@/pages/ScriptHelp/ScriptEvaluator.vue';

export default {
  name: 'ScriptHelpPage',
  components: { ScriptEvaluator },
  data() {
    return {
      // Mapping: collapseId -> (Boolean: Is collapseId collapsed)
      isCollapsedState: {
        typesCard: false,
        functionsCard: false,
        listsCard: false,
        objectCard: false,
        setCard: false,
        stringsCard: false,
        entitiesCard: false,
        dateTimeCard: false,
        operationsCard: false,
        constantsCard: false,
        builtInVariablesCard: false,
        reservedVariablesCard: false,
      },
    };
  },
  // mounted() {
  //   this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
  //     console.log(collapseId, isJustShown);
  //     this.isCollapsedState[collapseId] = !isJustShown;
  //   });
  // },
  methods: {
    collapseSection(collapseId) {
      this.isCollapsedState[collapseId] = !this.isCollapsedState[collapseId];
    },
  },
};
</script>
