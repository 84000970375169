import { render, staticRenderFns } from "./NotificationsCenter.vue?vue&type=template&id=2a8f41c1&"
import script from "./NotificationsCenter.vue?vue&type=script&lang=js&"
export * from "./NotificationsCenter.vue?vue&type=script&lang=js&"
import style0 from "./NotificationsCenter.vue?vue&type=style&index=0&id=2a8f41c1&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../products/botstudio/frontend/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports