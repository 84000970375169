<template>
  <div>
    <b-card
      title="Data sources"
      class="r-75"
      body-class="p-3"
    >
      <p>
        The following table shows an overview of data sources included in the dataset
        {{ dataset.name }}.
      </p>
      <p>
        When uploading data to a data source, the data needs to conform to a
        <b-link v-b-modal.data-format>
          specific format
        </b-link>.
      </p>
    </b-card>
    <b-card
      class="r-75 mt-3"
      body-class="p-3"
    >
      <b-table
        hover
        fixed
        empty-text="No data sources in dataset yet. Add a data source to get started"
        show-empty
        responsive
        :items="items"
        :fields="tableFields"
      >
        <template #cell(actions)="{ item: { id } }">
          <b-button
            size="sm"
            variant="outline-primary"
            title="Edit"
            class="mr-2"
            :disabled="isDeleting(id)"
            @click.stop="showEditSourceModal(id)"
          >
            <font-awesome-icon icon="edit" />
          </b-button>

          <b-button
            size="sm"
            variant="outline-danger"
            title="Delete"
            :disabled="isDeleting(id)"
            @click.stop="promptDeleteSource(id)"
          >
            <b-spinner
              v-if="isDeleting(id)"
              small
            />
            <font-awesome-icon icon="trash-alt" />
          </b-button>
        </template>
      </b-table>
      <div class="text-center mb-3">
        <b-spinner
          v-if="isCreating"
        />
      </div>
      <b-row>
        <b-col>
          <div class="text-center">
            <b-button
              variant="success"
              @click.stop="showAddSourceModal()"
            >
              <font-awesome-icon icon="plus" /> Add data source
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <data-exploration-add-data-source-modal
      modal-id="addSourceModal"
      :is-edit="modalSupport.isEdit"
      :source-id="modalSupport.sourceId"
    />
    <b-modal
      id="data-format"
      title="How to Format the Data"
      size="xl"
      ok-only
    >
      <h2>Options</h2>
      The file uploader accepts three data formats: <code>json</code>, <code>csv</code>
      and <code>xlsx</code>.
      The details for each can be found below.
      <h3 class="mt-3">
        JSON
      </h3>
      The data file can be a valid JSON array. The array should consist of objects, that have
      the following keys:
      <b-list-group>
        <b-list-group-item class="px-2 py-1">
          <code>text</code>: The text content that will be used for topic exploration and shown
          to the use when labeling.
        </b-list-group-item>
        <b-list-group-item class="px-2 py-1">
          <code>data_type</code>: Should be either <code>chat</code>, <code>email</code> or
          <code>ticket</code>.
        </b-list-group-item>
        <b-list-group-item class="px-2 py-1">
          <code>reference_id</code> (optional): A field that contains a reference to your original
          data entry. This might for instance be an identifier in another database. If there
          already exists a data entry with the same reference_id it is overwritten. No two
          reference_id's maybe be the same within the same uploaded file.
        </b-list-group-item>
      </b-list-group>
      <h6 class="mt-2">
        Example:
      </h6>
      <vue-json-pretty :data="jsonExample" />
      <h3>
        CSV
      </h3>
      The file can have <code>csv</code> format, where the separator is the semicolon
      character: <code>;</code>.
      The first line has to be the header. Every additional semicolon
      (besides the separator) and newline character (<code>\n</code>) must be replaced or
      removed from the file, otherwise the upload will fail. The reference_id column is optional.
      <h6 class="mt-2">
        Example:
      </h6>
      <pre class="mb-0">
text;data_type
Hello, this is an example email;email
Hello, This is another email;email
      </pre>
      <h3>
        Excel worksheet
      </h3>
      The file can be an Excel worksheet saved in its default format (<code>xlsx</code>).
      The data should be on the first sheet.<br>
      <h6 class="mt-2">
        Example:
      </h6>
      <table>
        <tr>
          <td>
            text
          </td>
          <td>
            data_type
          </td>
        </tr>
        <tr>
          <td>
            I cannot connect to wifi
          </td>
          <td>
            email
          </td>
        </tr>
        <tr>
          <td>
            After updating my computer, all my printers are gone
          </td>
          <td>
            email
          </td>
        </tr>
      </table>
    </b-modal>
  </div>
</template>
<script>
import {
  mapActions,
  mapGetters,
} from 'vuex';
import VueJsonPretty from 'vue-json-pretty';
import { applyThisArgs } from '@/js/storeHelpers';
import DataExplorationAddDataSourceModal from '@/pages/DataExploration/DataExplorationAddDataSourceModal.vue';
import 'vue-json-pretty/lib/styles.css';

export default {
  name: 'DataExplorationDataPage',
  components: {
    DataExplorationAddDataSourceModal, VueJsonPretty,
  },
  data() {
    return {
      modalSupport: {
        isEdit: false,
        sourceId: null,
      },
      jsonExample: [
        { text: 'Hello, \nThis is an example email.', data_type: 'email', reference_id: '310' },
        { text: 'Hello, \nThis is another email.', data_type: 'email', reference_id: '313' },
      ],
    };
  },
  computed: {
    ...mapGetters('dataExploration/dataSources', ['isCreating', 'isDeleting']),
    ...applyThisArgs(mapGetters('dataExploration', {
      dataset: 'getDatasetById',
    }), 'datasetId'),
    datasetId() {
      return parseInt(this.$route.params.datasetId, 10);
    },
    tableFields() {
      return [
        'name',
        'description',
        'type',
        'data_count',
        'label_count',
        'status',
        {
          key: 'actions',
          label: '',
          thClass: 'text-right',
          tdClass: 'text-right',
        },
      ];
    },
    items() {
      if (this.dataset) {
        const items = this.dataset.data_sources;
        for (const item of items) {
          const task = item.translation_task;
          if (task) {
            if (task.status === 'pending' && task.progress) {
              item.status = `${(task.progress * 100).toFixed(1)}%`;
            } else {
              item.status = task.status;
            }
          }
        }
        return items;
      }
      return [];
    },
  },
  created() {
    // Dataset label stats could have updated since last load.
    this.fetchAllDatasets();
  },
  methods: {
    ...mapActions('dataExploration', ['fetchAllDatasets']),
    ...mapActions('dataExploration/dataSources', [
      'deleteDataSource',
    ]),
    showEditSourceModal(sourceId) {
      // Configure the props for modal
      this.modalSupport.sourceId = sourceId;
      this.modalSupport.isEdit = true;
      // Solution for: The isEdit change is (apparently) not propagated to modal immediately
      this.$nextTick(() => {
        this.$bvModal.show('addSourceModal');
      });
    },
    showAddSourceModal() {
      // Configure the props for modal
      this.modalSupport.sourceId = null;
      this.modalSupport.isEdit = false;
      // Solution for: The isEdit change is (apparently) not propagated to modal immediately
      this.$nextTick(() => {
        this.$bvModal.show('addSourceModal');
      });
    },
    async promptDeleteSource(sourceId) {
      const question = 'Are you sure you want to delete this source?'
        + ' This will remove the source and all its associated labeled data.';
      const options = {
        okTitle: 'Delete',
        okVariant: 'danger',
        centered: true,
        title: 'Delete source',
      };
      if (await this.$bvModal.msgBoxConfirm(question, options)) {
        await this.deleteDataSource({ dataSourceId: sourceId });
      }
    },
  },
};
</script>

<style scoped>
table, th, td {
  border: 1px solid black;
}

</style>
