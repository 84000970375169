<!-- Because we add authentication check when we enter this component, when we
try to visit this page without logging in, we will be redirected to the login
page. In this case, we should never seen the message `please login to start
your journey` in principle. code is kept here just in case
-->
<template>
  <div class="container">
    <div v-if="loggedIn">
      <b-row v-if="isLoading">
        <b-col class="text-center">
          <b-spinner
            class="m-4"
            style="width: 4rem; height: 4rem;"
          />
        </b-col>
      </b-row>
      <div v-else>
        <b-row
          class="text-center justify-content-center align-items-center pt-5"
        >
          <b-col cols="4">
            <img
              style="max-width: 25%"
              src="../assets/supwiz_dark_text.svg"
              alt="SupWiz Logo"
            >
            <h3 class="pt-3">
              SupWiz BotStudio
            </h3>
            <hr class="my-1">
            <p
              v-if="userHasNoPermissions"
              class="text-danger"
            >
              Your user is assigned no permissions. You cannot use BotStudio
              before being assigned permissions. Ask your
              BotStudio-administrator to assign you relevant roles.
            </p>
            <p
              v-if="isUserTranscript"
              class="text-warning"
            >
              Your user is only assigned transcript permissions. View transcripts/conversation logs
              by using direct links given in your system.
            </p>
          </b-col>
        </b-row>
        <template
          v-if="!(userHasNoPermissions || isUserTranscript)"
        >
          <b-row class="justify-content-center text-left">
            <b-col
              cols="8"
              md="4"
              class="px-1"
            >
              <b-btn
                variant="outline-primary"
                block
                class="py-3 my-1 menu-button text-left"
                @click="goToRoute('bot')"
              >
                <font-awesome-icon
                  class="ml-3 mr-3"
                  icon="robot"
                  size="lg"
                />
                Bots
              </b-btn>
            </b-col>
          </b-row>

          <b-row
            v-if="!isUserObserver"
            class="justify-content-center justify-text-left"
          >
            <b-col
              cols="8"
              md="4"
              class="px-1"
            >
              <b-btn
                variant="outline-primary"
                block
                class="py-3 my-1 menu-button text-left"
                @click="goToRoute('nlu')"
              >
                <font-awesome-icon
                  class="ml-3 mr-4"
                  icon="atom"
                  size="lg"
                />
                NLU
              </b-btn>
            </b-col>
          </b-row>
          <b-row
            v-if="!isUserObserver"
            class="justify-content-center text-left"
          >
            <b-col
              cols="8"
              md="4"
              class="px-1"
            >
              <b-btn
                variant="outline-primary"
                block
                class="py-3 my-1 menu-button text-left"
                @click="goToRoute('dataExploration')"
              >
                <font-awesome-icon
                  class="ml-3 mr-3"
                  icon="tags"
                  size="lg"
                />
                Data Exploration
              </b-btn>
            </b-col>
          </b-row>
          <b-row
            v-if="isSuperUser"
            class="justify-content-center justify-text-left"
          >
            <b-col
              cols="8"
              md="4"
              class="px-1"
            >
              <b-btn
                variant="outline-primary"
                block
                class="py-3 my-1 menu-button text-left"
                @click="goToRoute('administration')"
              >
                <font-awesome-icon
                  class="ml-3 mr-3"
                  icon="user-lock"
                  size="lg"
                />
                Administration
              </b-btn>
            </b-col>
          </b-row>
        </template>
      </div>
    </div>
    <div v-else>
      <b-row
        class="text-center justify-content-md-center align-items-center pt-5"
      >
        <b-col cols="4">
          <img
            style="max-width: 25%"
            src="../assets/supwiz_dark_text.svg"
            alt="SupWiz Logo"
          >
          <h3 class="pt-3">
            SupWiz BotStudio
          </h3>
          <hr>
          <p class="text-secondary">
            Please sign in to start your journey
          </p>
          <b-btn
            :to="{ name: 'login' }"
            variant="primary"
          >
            Sign in
          </b-btn>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'HomePage',
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState('auth', ['loggedIn']),
    ...mapGetters('auth', [
      'userHasNoPermissions',
      'isUserTranscript',
      'isUserObserver',
      'getUserPermission',
      'isSuperUser',
      'isUserLimited',
    ]),
  },
  async created() {
    const redirectPath = localStorage.getItem('redirect-path');
    if (redirectPath) {
      localStorage.removeItem('redirect-path');
      if (redirectPath !== this.$route.path) {
        this.$router.push({ path: redirectPath });
      }
    }
  },
  methods: {
    goToRoute(name) {
      this.isLoading = true;
      this.$router.push({ name });
    },
  },
};
</script>

<style scoped>
.jumbotron {
  text-align: center;
}
.menu-button {
  font-size: 1.2em;
}
</style>
