<template>
  <div
    :style="wrapperStyle"
    style="width: 100%"
  >
    <b-input-group>
      <template #append>
        <b-dropdown
          :id="typeId"
          ref="dropdown"
          right
          :size="size"
          no-caret
        >
          <template #button-content>
            <font-awesome-icon icon="code" />
          </template>
          <b-dropdown-item-button
            v-if="!isInputValid"
            @click="markFirstError()"
          >
            Mark syntax error
          </b-dropdown-item-button>
          <b-dropdown-item-button @click="openPlayground()">
            Copy to playground
          </b-dropdown-item-button>
          <slot name="appendMenu" />
          <template v-if="showDivider">
            <b-dropdown-divider />
            <b-dropdown-form>
              <b-form-checkbox
                v-if="prettyCode"
                :checked="prettyCode"
                size="sm"
                @change="setPrettyCode"
              >
                Format code
              </b-form-checkbox>
            </b-dropdown-form>
          </template>
        </b-dropdown>
        <b-tooltip
          :target="typeId"
          no-fade
        >
          type: {{ type }}
        </b-tooltip>
        <slot name="append" />
      </template>
      <component
        :is="inputType"
        ref="input"
        :placeholder="placeholder"
        :state="isInputValid"
        :style="inputStyle"
        :value="value"
        :class="getInputClass"
        :size="size"
        :disabled="disabled"
        :rows="inputRows"
        @input="val => onChange(val)"
      />
    </b-input-group>
    <b-form-invalid-feedback
      :class="getFeedbackClass"
      :state="isInputValid"
    >
      <p
        v-for="(m, index) in messages"
        :key="index"
        class="mb-0"
      >
        {{ m }}
      </p>
    </b-form-invalid-feedback>
  </div>
</template>
<script>
import { lex, parse, prettyPrintTokens } from 'supwiz/botscript/parser';
import { typecheck } from 'supwiz/botscript/typer';
import { mapGetters } from 'vuex';

export default {
  name: 'BotscriptValidation',
  components: {
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    additionalTypes: {
      type: Object,
      default: () => {},
    },
    prettyCode: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Type BotScript here',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    validations: {
      type: Array,
      default: () => ['empty'],
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'md',
    },
    wrapperStyle: {
      type: String,
      default: '',
    },
    inputStyle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      typeId: 'typeId',
      isInputValid: true,
      isInputDirty: false,
      messages: [],
      initValue: null,
      caretPosition: 0,
      type: null,
      lexerErrors: [],
      parserErrors: [],
      typeErrors: [],
    };
  },
  computed: {
    ...mapGetters('botscript', [
      'getNERs',
    ]),
    code() {
      if (!this.value) return this.value;
      if (!this.prettyCode) return this.value;
      return this.makeValidCode(this.value);
    },
    inputType() {
      return this.expanded ? 'b-form-textarea' : 'b-form-input';
    },
    getInputClass() {
      return [
        'text-monospace',
        { 'invalid-input': !this.isInputValid && !this.checkIfEmpty(this.value) },
      ];
    },
    getFeedbackClass() {
      if (!this.isInputValid && !this.checkIfEmpty(this.value)) {
        return 'feedback mb-1 text-warning';
      }
      return 'feedback mb-1';
    },
    inputRows() {
      if (!this.value) return 2;

      let newlines = 0;
      let index = -1;
      // eslint-disable-next-line no-cond-assign
      while ((index = this.value.indexOf('\n', index + 1)) >= 0) newlines++;
      return newlines + 1;
    },
    showDivider() {
      return this.prettyCode;
    },
  },
  watch: {
    inputType() {
      this.caretPosition = this.$refs.input.selectionStart;
      this.$nextTick(() => {
        this.$refs.input.focus();
        this.$refs.input.setSelectionRange(this.caretPosition, this.caretPosition);
      });
    },
    type(newType, oldType) {
      if (newType === oldType) return;
      if (newType === null || oldType === null
          || newType.toString() !== oldType.toString()) {
        this.$emit('botscriptType', newType);
      }
    },
    additionalTypes() {
      this.validate(this.code);
    },
  },
  beforeMount() {
    this.typeId = `typeId_${this._uid}`;
  },
  mounted() {
    this.initValue = this.value;
    this.validate(this.value);
    this.setPrettyCode(this.prettyCode);
  },
  methods: {
    validate(value) {
      this.isInputValid = true;
      this.messages = [];
      if (this.validations.includes('unsavedChanges')) {
        this.isInputDirty = this.initValue !== value;
        if (this.isInputDirty) {
          this.isInputValid = false;
          this.addWarningMessage('*Unsaved changes.');
        }
        this.$emit('dirty', this.isInputDirty);
      }
      try {
        this.lexerErrors = [];
        const tokens = lex(value, this.lexerErrors);
        if (this.lexerErrors.length > 0) {
          this.isInputValid = false;
          this.addWarningMessage(`Parse error: ${this.lexerErrors[0].msg}`);
          return;
        }
        if (tokens.length === 1) {
          if (this.validations.includes('empty')) {
            this.isInputValid = false;
            this.addWarningMessage('This field should not be empty.');
          }
          return;
        }
        this.parserErrors = [];
        const ast = parse(tokens, this.parserErrors, value);
        if (this.parserErrors.length > 0) {
          this.isInputValid = false;
          this.addWarningMessage(`Parse error: ${this.parserErrors[0].msg}`);
          return;
        }
        if (this.validations.includes('zendeskTag')) {
          if (ast.type === 'literal string') {
            const regex = /^[a-zA-Z0-9_-]+$/g;
            if (!regex.test(ast.content.value)) {
              this.isInputValid = false;
              this.addWarningMessage('Invalid tag for Zendesk: tags must contain only '
                                     + 'alphanumeric characters, underscores, and dashes.');
              return;
            }
          }
        }
        if (this.validations.includes('typecheck-tag')) {
          if (ast.type === 'identifier') {
            this.isInputValid = false;
            this.addWarningMessage('Warning: you passed in a variable for the tag to set. Is'
                                   + ' this intentional, or did you mean to pass in a string? If '
                                   + ' you meant to pass a string, consider changing this to "'
                                   + `${ast.content.name}"; otherwise, to mark it as intentional `
                                   + ' and get rid of this warning, consider changing this to '
                                   + `str(${ast.content.name})`);
            return;
          }
        }
        this.typeErrors = [];
        const type = typecheck(ast, this.typeErrors, this.getNERs, this.additionalTypes);
        if (this.typeErrors.length > 0) {
          this.isInputValid = false;
          for (const err of this.typeErrors) {
            this.addWarningMessage(`Type error: ${err}`);
          }
        }
        if (['unknown', 'list', 'set', 'map', 'tuple'].indexOf(type.tag) === -1
            && this.validations.includes('typecheck-iterable')) {
          this.isInputValid = false;
          if (type.tag === 'str') {
            this.addWarningMessage('Warning: Botscript expression evaluates to string. If '
              + 'you iterate over a string, you will be given each letter separately. Is this '
              + 'intended?');
          } else {
            this.addWarningMessage(`Type error: Expected iterable but got ${type.toString()}`);
          }
        }
        if (type.tag === 'bool' && this.validations.includes('typecheck-nonbool')) {
          if (this.checkForEqualSigns(value)) {
            this.isInputValid = false;
            this.addWarningMessage('Warning, equals signs in this input can cause errors, please ensure this is the intended action.');
          }
        }
        if (['unknown', 'str'].indexOf(type.tag) === -1
            && this.validations.includes('typecheck-str')) {
          this.isInputValid = false;
          this.addWarningMessage(`Type error: Expected str but got ${type.toString()}`);
        }
        if (this.validations.includes('typecheck-known')
            && type.toString().indexOf('unknown') >= 0) {
          this.isInputValid = false;
          this.addWarningMessage(`Type error: Type is not fully specified, got ${type.toString()}`);
        }

        this.type = type;
      } catch (e) {
        this.type = null;
        this.isInputValid = false;
        this.addWarningMessage('Unknown parse error');
      }
    },
    checkIfEmpty(v) {
      if (v) {
        return false;
      }
      return true;
    },
    checkForEqualSigns(v) {
      if (v) {
        if (v.includes('=')) {
          return true;
        }
      }
      return false;
    },
    onChange(value) {
      this.validate(value);
      this.$emit('onChange', value);
      this.$emit('input', value);
    },
    onLeave(value) {
      this.$emit('onLeave', value);
    },
    addWarningMessage(m) {
      if (!this.messages.includes(m)) {
        this.messages.push(m);
      }
    },
    setSelectionRange(start, end) {
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$refs.input.focus();
          this.$refs.input.$el.setSelectionRange(start, end);
        });
      });
    },
    markFirstError() {
      this.validate(this.value.replaceAll('\n', ' '));
      if (this.lexerErrors.length > 0) {
        const error = this.lexerErrors[0];
        this.setSelectionRange(error.startChar, error.endChar);
      } else if (this.parserErrors.length > 0) {
        const error = this.parserErrors[0];
        this.setSelectionRange(error.startChar, error.endChar);
      } else if (this.typeErrors.length > 0) {
        // No indexes for type errors :(
      }
    },
    openPlayground() {
      const route = this.$router.resolve({ name: 'scripthelp', query: { code: this.value || '' } });
      window.open(route.href, '_blank');
    },
    makeValidCode(text) {
      if (!text) return text;
      let validText = text;
      validText = validText.replaceAll(/,\s*\r?\n\s*/g, ', ');
      validText = validText.replaceAll(/\s*\r?\n\s*/g, '');
      return validText;
    },
    setPrettyCode(makePretty) {
      if (makePretty && this.prettyCode) {
        const ppText = prettyPrintTokens(this.value);
        this.$emit('onChange', ppText);
        this.$emit('input', ppText);
      } else {
        const validCode = this.makeValidCode(this.value);
        this.$emit('onChange', validCode);
        this.$emit('input', validCode);
      }
      this.$refs.dropdown.hide(true);
    },
  },
};
</script>
<style scoped>
.invalid-input{
  border-color: #E99002;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23E99002'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3E%3C/svg%3E");
}
.invalid-input:focus {
  border-color: #E99002;
  box-shadow: 0 0 0 3px rgba(233, 144, 2, 0.25);
}
.feedback{
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
}
::v-deep .dropdown-menu:focus{
  box-shadow: inset 0 0 2px 2px #70d4ff00;
}
</style>
