<template>
  <div>
    <h3>Header and Signature Stripping</h3>
    <b-card
      no-body
    >
      <p>
        Header stripping removes added (e-mail) headers from the message.
        Signature Stripping removes signatures, old message from received user messages.
      </p>
    </b-card>
    <hr>
    <div class="mt-1">
      Start of Header line:
    </div>
    <string-list
      :strings="getHeaderStarts"
      placeholder="Add start of a Header"
      @change="setHeaderStartsWithHelper"
    />
    <hr>
    <div class="mt-1">
      Start of Signatures:
    </div>
    <string-list
      :strings="getSignatureStarts"
      placeholder="Add start of a signature"
      @change="setSignatureStartsWithHelper"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import StringList from 'supwiz/components/StringList.vue';

export default {
  name: 'SignatureStripping',
  components: {
    StringList,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters('botManipulation/activeBot/config', [
      'getHeaderStarts',
      'getSignatureStarts',
    ]),
  },
  methods: {
    ...mapMutations('botManipulation/activeBot/config', [
      'setHeaderStartsWithHelper',
      'setSignatureStartsWithHelper',
    ]),
  },
};
</script>
