import { render, staticRenderFns } from "./ChatEvent.vue?vue&type=template&id=e1524630&scoped=true&"
import script from "./ChatEvent.vue?vue&type=script&lang=js&"
export * from "./ChatEvent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e1524630",
  null
  
)

export default component.exports