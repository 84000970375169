<template>
  <main role="main">
    <b-alert
      variant="success"
      :show="!LMUploading && LMUploadSuccess === true"
      class="shadow-sm"
      dismissible
    >
      Upload succeeded!
    </b-alert>
    <b-table
      :items="describedLanguageModels"
      :fields="fields"
      show-empty
      empty-text="You have not uploaded any language model(s) yet."
      hover
    />
    <b-row>
      <b-col>
        <b-btn
          v-b-modal.upload-lm-modal
          variant="primary"
        >
          <font-awesome-icon icon="upload" /> Upload language model
        </b-btn>
      </b-col>
    </b-row>
    <b-modal
      id="upload-lm-modal"
      title="Upload language model"
      hide-footer
    >
      <b-alert
        variant="primary"
        :show="LMUploading"
        class="shadow-sm"
      >
        <font-awesome-icon
          icon="spinner"
          size="lg"
          spin
        />
        Language model is being uploaded. Do NOT leave this page.
      </b-alert>
      <b-alert
        variant="danger"
        :show="!LMUploading && LMUploadSuccess === false"
        class="shadow-sm"
      >
        Upload failed: {{ LMUploadError }}
      </b-alert>
      <!-- Form for uploading a new model -->
      <div v-if="!LMUploading">
        <b-form-group>
          <b-form-select
            id="identifierType"
            v-model="LMType"
            :state="!$v.LMType.$invalid"
            :options="typeOptions"
            aria-desccribedby="typeFeedback"
          />
          <b-form-invalid-feedback id="typeFeedback">
            <div v-if="!$v.LMType.required">
              Please select a type
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          label="Description"
          label-for="LMDescriptionForm"
        >
          <b-form-textarea
            id="LMDescriptionForm"
            v-model="LMDescription"
            placeholder="Give a description of your model..."
          />
        </b-form-group>
        <b-form-group
          label="File"
          label-for="LMFileForm"
        >
          <b-form-file
            id="LMFileForm"
            v-model="LMFile"
            placeholder="Choose a zipped language model..."
            accept=".zip"
            :state="!$v.LMFile.$invalid"
          />
        </b-form-group>
        <b-button
          class="p-2 mt-3"
          variant="primary"
          :disabled="$v.$invalid"
          @click="uploadLanguageModel"
        >
          Upload language model
        </b-button>
      </div>
    </b-modal>
  </main>
</template>

<script>
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { getConnectionErrorMsg } from '@/js/utils';

function languageModelDescription(languageModel) {
  const info = [{ description: 'Type', value: languageModel.display_type }];
  if (languageModel.type === 'ulm') {
    info.push(
      { description: 'Word embedding size', value: languageModel.args.dim_word_emb },
      { description: 'Hidden layers', value: languageModel.args.n_layers },
      { description: 'Hidden layer size', value: languageModel.args.dim_hidden },
    );
  } else {
    info.push(
      { description: 'Hidden layers', value: languageModel.args.num_hidden_layers },
      { description: 'Attention heads', value: languageModel.args.num_attention_heads },
      { description: 'Intermediate size', value: languageModel.args.intermediate_size },
      { description: 'Hidden layer size', value: languageModel.args.hidden_size },
    );
  }
  return info;
}

export default {
  name: 'LanguageModel',
  mixins: [validationMixin],
  data() {
    return {
      LMDescription: '',
      LMFile: null,
      LMUploading: false,
      LMUploadSuccess: null,
      LMUploadError: '',
      LMType: null,
      fields: [
        {
          key: 'display_type',
          label: 'Type',
          tdClass: 'align-middle',
        },
        {
          key: 'description',
          tdClass: 'align-middle',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('nlu/languageModel', [
      'languageModels',
    ]),
    typeOptions() {
      // TODO: This is duplicated in the backend - should be fetched from there instead.
      return [
        { value: null, text: '' },
        { value: 'roberta', text: 'Type 2B' },
        { value: 'bert', text: 'Type 2A' },
        // We no longer allow uploading Type 1 models. They should be deprecated soon.
        // { value: 'ulm', text: 'Type 1' },
      ];
    },
    describedLanguageModels() {
      return Object.values(this.languageModels).map((model) => ({
        ...model,
        information: languageModelDescription(model),
      }));
    },
  },
  methods: {
    ...mapActions('nlu/languageModel', [
      'fetchLanguageModels',
    ]),
    uploadLanguageModel() {
      this.LMUploading = true;
      this.LMUploadSuccess = false;
      this.LMUploadError = '';
      const formData = new FormData();
      formData.append('type', this.LMType);
      formData.append('description', this.LMDescription);
      formData.append('file', this.LMFile);
      axios.post('/api/language-model/', formData, {
        headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` },
      }).then(() => {
        this.$bvModal.hide('upload-lm-modal');
        this.LMUploading = false;
        this.LMUploadSuccess = true;
        this.LMUploadError = '';
        this.LMDescription = '';
        this.LMType = null;
        this.fetchLanguageModels();
      }).catch((e) => {
        this.LMUploading = false;
        this.LMUploadSuccess = false;
        // TODO: This doesn't handle 504 or 413 particularly well.
        this.LMUploadError = getConnectionErrorMsg(e);
      });
    },
  },
  validations: {
    LMType: { required },
    LMFile: { required },
  },
};
</script>

<style scoped>
</style>
