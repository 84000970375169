<template>
  <b-container
    fluid
    class="p-0 h-100"
  >
    <b-row
      no-gutters
      class="h-100"
    >
      <template v-if="!isReady">
        <b-col />
        <b-col cols="auto">
          <b-spinner
            class="m-4"
            style="width: 4rem; height: 4rem;"
          />
        </b-col>
        <b-col />
      </template>
      <template v-else>
        <template v-if="isMainBot">
          <b-col :cols="showBotDemo && !graphView ? 8 : 12">
            <router-view class="mb-5" />
          </b-col>
          <b-col
            v-show="showBotDemo && isMainBot"
            :id="graphView ? 'graph-demo-wrapper' : ''"
            :style="getDemoPanelStyles"
            cols="4"
            class="pl-3"
          >
            <b-card
              id="demo-wrapper"
              class="r-75"
              body-class="p-0"
              style="border:none;"
            >
              <app-bot-demo
                @hide-bot-demo="toggleDemoPanel(false)"
              />
            </b-card>
          </b-col>
          <b-button
            v-if="isMainBot && !showBotDemo"
            v-b-tooltip.hover
            title="Show demo panel"
            pill
            class="p-2"
            :class="isDashboard ? 'hide-btn' : ''"
            style="z-index:1000"
            :style="getDemoButtonStyles"
            variant="success"
            @click="toggleDemoPanel(true)"
          >
            <font-awesome-icon
              icon="robot"
              class="h1 my-auto"
            />
          </b-button>
        </template>
        <template v-else>
          <b-col>
            <router-view />
          </b-col>
        </template>
      </template>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import AppBotDemo from '@/components/BotDemo/app-bot-demo.vue';
import { userPermission } from '@/js/constants';

export default {
  name: 'SingleBot',
  components: {
    AppBotDemo,
  },
  beforeRouteEnter(to, from, next) {
    // This is called whenever this component is about to be rendered in the route view. At this
    // moment we do not have access to "this", and therefore we pass a function, which will have
    // access to next.
    next((vm) => {
      vm.downloadBot(to.params.botId); // don't await to avoid delaying routing
    });
  },
  async beforeRouteUpdate(to, from, next) {
    // This is called whenever a routing occurs, but this component survives. This means that we
    // navigated on to another page concerning a single bot. If the id has changed we will need
    // to clean up and downlod a new bot - otherwise we don't have to do anything.
    if (to.params.botId !== from.params.botId) {
      await this.cleanUpBotState();
      this.downloadBot(to.params.botId); // don't await to avoid delaying routing
    }
    next();
  },
  async beforeRouteLeave(to, from, next) {
    // This is called when we leave this to go to another component which does not
    // use a single bot. We therefore clean up the state when this is called.
    await this.cleanUpBotState();
    next();
  },
  data() {
    return {
      showBotDemo: true,
    };
  },
  computed: {
    ...mapState('graphView', ['showSidebar']),
    ...mapGetters('botManipulation/activeBot/config', [
      'isMainBot',
    ]),
    ...mapGetters('auth', ['getUserPermission']),
    ...mapGetters('userSettings', ['getBotStudioSettings']),
    isReady() {
      return this.$store.state.botManipulation.activeBotSet;
    },
    graphView() {
      return this.$route.name === 'graph';
    },
    getDemoPanelStyles() {
      if (this.graphView) {
        return this.showSidebar ? 'right:450px;' : 'right: 0px !important;';
      }
      return '';
    },
    getDemoButtonStyles() {
      if (this.graphView) {
        return this.showSidebar ? 'position:absolute; bottom: 0px; right:450px;' : 'position:absolute; bottom: 0px; right:0px;';
      }
      return 'position:fixed; bottom:4px;right:22px;';
    },
    isDashboard() {
      return this.$route.path.includes('dashboard');
    },
  },
  watch: {
    isDashboard(n) {
      this.showBotDemo = !n && this.getBotStudioSettings.show_demo_panel_default;
    },
  },
  created() {
    this.showBotDemo = !this.isDashboard && this.getBotStudioSettings.show_demo_panel_default;
  },
  methods: {
    async downloadBot(rawBotId) {
      const botId = rawBotId.substring(0, 36);
      if (rawBotId.length === 36) {
        // This is a regular bot and not a subflow.
        this.$store.commit('botManipulation/setActiveBotSet', { isSet: false });
        await this.$store.dispatch('botManipulation/setBot', botId);
      } else {
        // This is a subflow.
        const subflowId = rawBotId.substring(37);
        this.$store.commit('botManipulation/setActiveBotId', botId);
        await this.$store.dispatch('botManipulation/editSubFlow', subflowId);
      }
    },
    async cleanUpBotState() {
      this.$store.commit('task/clearTasks');
      this.$store.commit('healthSuggestions/resetSuggestions');
      this.$store.commit('diagnostics/resetDiagnostics');
      if (![userPermission.NO_PERMISSIONS, userPermission.OBSERVER]
        .includes(this.getUserPermission)) {
        this.$store.dispatch('unitTest/terminateRunningTest');
      }
      this.$store.commit('chatlogs/resetChatlogs');
      await this.$store.dispatch('botManipulation/resetBotState');
      await this.$store.dispatch('botSynchronization/resetBotState');
      this.$store.commit('demopanel/resetDemoPanelState');
      this.$store.commit('variants/setVariantsList', [], { root: true });
      this.$store.commit('graphView/saveGraph', null);
      this.$store.commit('health/resetAiThresholds', { thresholds: {} });
    },
    toggleDemoPanel(show) {
      this.showBotDemo = show;

      if (this.$route.name === 'insights') {
        this.$root.$emit('resizeDiagram');
      }
    },
  },
};
</script>

<style scoped>
#demo-wrapper{
  top: 0;
  height: calc(100vh - 92px);
  position: sticky;
}
#graph-demo-wrapper{
  bottom: 0px;
  top: 0px;
  position: absolute;
  z-index: 200;
  width: 25%;
}
.hide-btn{
  visibility: hidden;
}
</style>
