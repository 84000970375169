import { Visitor } from 'supwiz/botscript/parser.js';


// type system structure:
// there is a fixed set of types with a subtyping relation.
// the fixed set includes primitive types like int, composite types like tuples, as well as two special types:
//  * unknown, the type that contains everything, including expressions that don't typecheck
//  * nothing, the type that contains nothing. well, for practical purposes it contains None too...
// unknown is a supertype of everything while nothing is a subtype of everything
// all data structures are covariant
// for typechecking, one special thing is that any operation with 'unknown' is allowed, because we want to be conservative
// with what errors we report. as a result, this means that an expression could change to have a more specific type, and
// then become ill-typed.
// other than the unknown/nothing types, the only subtyping relationship is that int is a subtype of float
// ... this is kinda messy and maybe I should read up on gradual typing for how to design this better.

export class Type {
    // a type will always have a tag, such as "string" or "list"
    // however, some types may have inner "parts", e.g. you can have a list of strings;
    // the "parts" are strings and the "tag" is list.
    constructor(tag, parts) {
        this.tag = tag;
        this.parts = parts;
    }
    toString() {
        let result = this.tag;
        if (this.parts.length > 0) {
            result += '[';
            for (let i = 0; i < this.parts.length; i++) {
                result += this.parts[i].toString();
                if (i + 1 < this.parts.length) {
                    result += ', '
                }
            }
            result += ']'
        }
        return result;
    }
    // returns the most specific common supertype
    // if mixing the types would be an error, adds the error to the 'errors' list
    // TODO find some formal definition of 'mixing the types would be an error'
    unify(that, errors) {
        if (this.tag === 'unknown' || that.tag === 'unknown') {
            return UNKNOWN;
        }
        if (this.tag === 'nothing') {
            return that;
        }
        else if (that.tag === 'nothing') {
            return this;
        }
        if (this.tag === that.tag) {
            let uParts = [];
            if (this.tag === 'tuple' && (this.parts.length !== that.parts.length)) {
                errors.push('Tuples have different number of components: ' + this.toString() + " vs " + that.toString());
                return UNKNOWN;
            }
            let anyErrors = false;
            // non-tuple types always have the same number of parts
            for (let i = 0; i < this.parts.length; i++) {
                let preErrors = errors.length;
                uParts.push(this.parts[i].unify(that.parts[i], errors));
                let postErrors = errors.length;
                if (preErrors < postErrors) {
                    anyErrors = true;
                }
            }
            if (anyErrors) {
                return UNKNOWN;
            }
            else {
                return new Type(this.tag, uParts);
            }
        }
        if ((this.tag === 'int' && that.tag === 'float') || (this.tag === 'float' && that.tag === 'int')) {
            return FLOAT;
        }
        errors.push('Incompatible type tags: ' + this.toString() + ' vs ' + that.toString());
        return UNKNOWN;
    }
    canUnify(ts) {
        let ty = this;
        let errors = [];
        for (let t of ts) {
            ty = ty.unify(t, errors);
        }
        return errors.length === 0;
    }
    getElem(slice) {
        if (this.tag === 'list') {
            return slice? LIST(this.parts[0]) : this.parts[0];
        }
        else if (this.tag === 'set') {
            return this.parts[0];
        }
        else if (this.tag === 'str') {
            return STRING;
        }
        else if (this.tag === 'dict') {
            return this.parts[1];
        }
        throw new Error('Could not get element type for ' + this.tag);
    }
    getKey() {
        if (this.tag === 'dict') {
            return this.parts[0];
        }
        throw new Error('Could not get key type for ' + this.tag);
    }
    getComponents() {
        if (this.tag === 'tuple') {
            return this.parts;
        }
        throw new Error('Could not get component types for ' + this.tag);
    }
    getModule() {
        if (this.tag === 'module') {
            return this.parts[0];
        }
        throw new Error('Could not get module for ' + this.tag);
    }
}

export const UNKNOWN = new Type('unknown', []);
export const NOTHING = new Type('nothing', []);
export const INT = new Type('int', []);
export const BOOL = new Type('bool', []);
export const FLOAT = new Type('float', []);
export const STRING = new Type('str', []);
export const MOD_MATH = new Type('module', ['math']);
export const MOD_RE = new Type('module', ['re']);
// const NER = new Type('NER', []);
export function LIST(inner) {
    return new Type('list', [inner]);
}
export function SET(inner) {
    return new Type('set', [inner]);
}
export function DICT(key, value) {
    return new Type('dict', [key, value]);
}
export function TUPLE(comps) {
    return new Type('tuple', comps);
}
// As noted by the to do above, Union type is not supported at all, just enough to allow typing some builtin functions
// Fx. what should we do in the case of "int | str + int"?
export function UNION(comps) {
    return new Type('union', comps);
}
export const SLICE = new Type('slice', [INT]);

// given an expression, optionally returns a type of the expression
// if it cannot figure out the type, it returns UNKNOWN
class TypeVisitor extends Visitor {
    constructor(errors, ners) {
        super();
        this.errors = errors;
        this.ners = ners;
    }
    visitBinaryOperator(operatorType, left, right, context) {
        let tLeft = left.visit(this, context);
        let tRight = right.visit(this, context);
        if (operatorType === '+') {
            if (FLOAT.canUnify([tLeft, tRight])
                || STRING.canUnify([tLeft, tRight])
                || LIST(NOTHING).canUnify([tLeft, tRight])) {
                return tLeft.unify(tRight, this.errors);
            }
            let lTuple = TUPLE(tLeft.parts.map(() => NOTHING));
            let rTuple = TUPLE(tRight.parts.map(() => NOTHING));
            if (lTuple.canUnify([tLeft]) && rTuple.canUnify([tRight])) {
                lTuple = lTuple.unify(tLeft, this.errors);
                rTuple = rTuple.unify(tRight, this.errors);
                if (lTuple.tag === 'tuple' && rTuple.tag === 'tuple') {
                    return new Type('tuple', [].concat(lTuple.parts, rTuple.parts));
                }
                else {
                    return UNKNOWN;
                }
            }
            this.errors.push('Incompatible types for operator \'+\': ' + tLeft.toString() + ' and ' + tRight.toString());
            return UNKNOWN;
        }
        else if (['*', '%', '-', '/', '|', '&'].indexOf(operatorType) !== -1) {
            if (FLOAT.canUnify([tLeft, tRight])) {
                return tLeft.unify(tRight, this.errors);
            }
            this.errors.push('Incompatible types for operator \'' + operatorType + '\': ' + tLeft.toString() + ' and ' + tRight.toString() + ' - expected numeric types')
            return UNKNOWN;
        }
        else if (operatorType === '//') {
            if (FLOAT.canUnify([tLeft, tRight])) {
                return INT;
            }
            this.errors.push('Incompatible types for operator \'//\': ' + tLeft.toString() + ' and ' + tRight.toString() + ' - expected numeric types')
            return UNKNOWN;
        }
        else if (['==', '!=', '<=', '>=', '<', '>', 'is', 'is not'].indexOf(operatorType) !== -1) {
            if (tLeft.canUnify([tRight])) {
                tLeft.unify(tRight, this.errors);
                return BOOL;
            }
            this.errors.push('Incompatible types for operator \'' + operatorType + '\': ' + tLeft.toString() + ' and ' + tRight.toString() + ' - expected comparable types')
            return BOOL;
        }
        else if (['in', 'not in'].indexOf(operatorType) !== -1) {
            if (LIST(tLeft).canUnify([tRight])) {
                LIST(tLeft).unify(tRight, this.errors);
                return BOOL;
            }
            if (SET(tLeft).canUnify([tRight])) {
                SET(tLeft).unify(tRight, this.errors);
                return BOOL;
            }
            if (DICT(tLeft, NOTHING).canUnify([tRight])) {
                DICT(tLeft, NOTHING).unify(tRight, this.errors);
                return BOOL;
            }
            if (STRING.canUnify([tLeft, tRight])) {
                tLeft.unify(tRight);
                return BOOL;
            }
            this.errors.push('Incompatible types for operator \'' + operatorType + '\': ' + tLeft.toString() + ' and ' + tRight.toString() + ' - expected right-hand-side to be a collection of elements sharing type with left-hand-side')
            return BOOL;
        }
        else if (['and', 'or'].indexOf(operatorType) !== -1) {
            if (BOOL.canUnify([tLeft, tRight])) {
                return BOOL;
            }
            this.errors.push('Required booleans for operator ' + operatorType + ' but got ' + tLeft.toString() + ' and ' + tRight.toString());
            return BOOL;
        }
        this.errors.push('Unexpected operator \'' + operatorType + '\'');
        return UNKNOWN;
    }
    visitSlice(type, left, right, context) {
        if (type === 'unbounded') {
            return SLICE;
        }
        else if (type === 'begin') {
            let tLeft = left.visit(this, context);
            if (INT.canUnify([tLeft]) && tLeft.tag !== 'float') {
                return SLICE;
            }
            this.errors.push('Invalid type for slice \':\': ' + tLeft.toString() + ' - expected int');
        }
        else if (type === 'end') {
            let tRight = right.visit(this, context);
            if (INT.canUnify([tRight]) && tRight.tag !== 'float') {
                return SLICE;
            }
            this.errors.push('Invalid type for slice \':\': ' + tRight.toString() + ' - expected int');
        }
        else if (type === 'bounded') {
            let tLeft = left.visit(this, context);
            let tRight = right.visit(this, context);
            if (INT.canUnify([tLeft, tRight]) && tLeft.unify(tRight, []).tag !== 'float') {
                return SLICE;
            }
           this.errors.push('Incompatible types for slice \':\': ' + tLeft.toString() + ' and ' + tRight.toString() + ' - expected integers')
        }
        else {
            this.errors.push('Unexpected slice: ' + type);
        }
        return UNKNOWN;
    }
    visitUnaryOperator(operatorType, body, context) {
        let tBody = body.visit(this, context);
        if (operatorType === 'not') {
            if (BOOL.canUnify([tBody])) {
                BOOL.unify(tBody, this.errors);
                return BOOL;
            }
            this.errors.push('Incompatible type for operator \'not\': ' + tBody.toString() + ' - expected boolean');
            return BOOL;
        }
        else if (['-', '+'].indexOf(operatorType) !== -1) {
            if (FLOAT.canUnify([tBody])) {
                return INT.unify(tBody, this.errors);
            }
            this.errors.push('Incompatible type for operator \'' + operatorType + '\': ' + tBody.toString() + ' - expected numeric');
            return INT;
        }
        this.errors.push('Unexpected operator \'' + operatorType + '\'');
        return UNKNOWN;
    }
    visitIfElse(body, condition, branch, context) {
        let tBody = body.visit(this, context);
        let tCondition = condition.visit(this, context);
        let tBranch = branch.visit(this, context);
        if (!BOOL.canUnify([tCondition])) {
            this.errors.push('Condition in if-else must be bool, but its type is: ' + tCondition.toString());
        }
        if (!tBody.canUnify([tBranch])) {
            this.errors.push('Warning, branch and body of if-else have different types, is this intentional? ' + tBody.toString() + ' vs ' + tCondition.toString());
            return UNKNOWN;
        }
        return tBody.unify(tBranch, []);
    }
    visitTuple(parts, context) {
        let tParts = [];
        for (let i = 0; i < parts.length; i++) {
            tParts.push(parts[i].visit(this, context));
        }
        return TUPLE(tParts);
    }
    visitCollection(type, parts, context) {
        let tElems = [];
        for (let i = 0; i < parts.length; i++) {
            tElems.push(parts[i].visit(this, context));
        }
        if (!NOTHING.canUnify(tElems)) {
            let elemsStr = '';
            for (let i = 0; i < tElems.length; i++) {
                elemsStr += tElems[i].toString();
                if (i + 2 < tElems.length) {
                    elemsStr += ', ';
                }
                else if (i + 1 < tElems.length) {
                    elemsStr += ' and ';
                }
            }
            this.errors.push('Warning, ' + type + ' has incompatible types as elements, is this intentional? Inferred element types: ' + elemsStr);
        }
        let elemType = NOTHING;
        for (let el of tElems) {
            elemType = elemType.unify(el, []);
        }
        return elemType;
    }
    visitList(parts, context) {
        return LIST(this.visitCollection('list', parts, context));
    }
    visitSet(parts, context) {
        return SET(this.visitCollection('set', parts, context));
    }
    visitMap(kvPairs, context) {
        let tKeys = [];
        let tValues = [];
        for (let [ key, value ] of kvPairs) {
            tKeys.push(key.visit(this, context));
            tValues.push(value.visit(this, context));
        }
        if (!NOTHING.canUnify(tKeys)) {
            this.errors.push('Warning, map has incompatible types as keys, is this intentional?');
        }
        let keyType = NOTHING;
        let valueType = NOTHING;
        for (let i = 0; i < kvPairs.length; i++) {
            keyType = keyType.unify(tKeys[i], []);
            valueType = valueType.unify(tValues[i], []);
        }
        return DICT(keyType, valueType);
    }
    // eslint-disable-next-line no-unused-vars
    visitLiteral(literalType, content, context) {
        if (literalType === 'number') {
            if (content.indexOf('.') !== -1) {
                return FLOAT;
            }
            else {
                return INT;
            }
        }
        if (literalType === 'string') {
            return STRING;
        }
        if (literalType === 'constant') {
            if (content === 'None') {
                return NOTHING;
            }
            else {
                return BOOL;
            }
        }
        this.errors.push('Unsupported literal type ' + literalType);
        return UNKNOWN;
    }
    visitSubscript(body, subscript, context) {
        let tBody = body.visit(this, context);
        let tSubscript = subscript.visit(this, context);
        if (tBody.tag === 'unknown') {
            return UNKNOWN;
        }
        else if (tBody.tag === 'list' || tBody.tag === 'str') {
            if (SLICE.canUnify([tSubscript])) {
                return tBody.getElem(true);
            }
            if (!INT.canUnify([tSubscript]) && tSubscript.tag !== 'float') {
                this.errors.push('Expected integer when subscripting ' + tBody.tag + ', but got ' + tSubscript.toString());
            }
            return tBody.getElem(false);
        }
        else if (tBody.tag === 'tuple') {
            if (SLICE.canUnify([tSubscript])) {
                return UNKNOWN;
            }
            // TODO we could be smarter here if we special-cased constant literals
            if (INT.canUnify([tSubscript]) && tSubscript.tag !== 'float') {
                return UNKNOWN;
            }
            this.errors.push('Expected integer when subscripting ' + tBody.tag + ', but got ' + tSubscript.toString());
            return UNKNOWN;
        }
        else if (tBody.tag === 'dict') {
            let k = tBody.getKey();
            if (!k.canUnify([tSubscript])) {
                this.errors.push('Expected ' + k.toString() + ' but got ' + tSubscript.toString() + ' when indexing map');
            }
            return tBody.getElem(false);
        }
        this.errors.push('Cannot subscript on type: ' + tBody.toString());
        return UNKNOWN;
    }
    // eslint-disable-next-line no-unused-vars
    visitFieldAccess(body, field, context) {
        let tBody = body.visit(this, context);
        if (tBody.tag !== 'module') {
            this.errors.push('Expected module for field access \'.\', got: ' + tBody.toString());
            return UNKNOWN;
        }
        if (body.type !== 'identifier') {
            this.errors.push('Expected identifier for accessing module, got ' + body.type);
        }
        const modules = {
            'math' : { 'e': FLOAT, 'inf': FLOAT, 'nan': FLOAT, 'pi': FLOAT },
            're' : { 'IGNORECASE': INT, 'I': INT, 'MULTILINE': INT, 'M': INT, 'DOTALL': INT, 'S': INT },
        }
        let module = modules[tBody.getModule()];
        if (module) {
            if (field in module) {
                return module[field];
            }
            else {
                this.errors.push('Unknown field in module \'' + tBody.getModule() + '\': ' + field);
            }
        }
        else {
            this.errors.push('Unknown module: ' + tBody.getModule());
        }
        return UNKNOWN;
    }
    visitFunctionCall(func, args, context) {
        if (func === 'raises_error') {
            if (args.length !== 1) {
                this.errors.push('raises_error takes 1 parameter, yet got ' + args.length);
            }
            return BOOL;
        }
        if (func === 'exists') {
            if (args.length !== 1) {
                this.errors.push('exists takes 1 parameter, yet got ' + args.length);
            }
            else if (args[0].type !== 'identifier') {
                this.errors.push('exists must be given variable as parameter, not ' + args[0].type);
            }
            return BOOL;
        }
        if (func === 'map' || func === 'remove_if') {
            if (args.length !== 3) {
                this.errors.push('Expected a variable, an expression and a collection for ' + func);
                return UNKNOWN;
            }
            if (args[0].type !== 'identifier') {
                this.errors.push('Expected a variable for ' + func);
                return UNKNOWN;
            }
            let subcontext = {...context};
            let cType = args[2].visit(this, context);
            if (cType.tag === 'unknown') {
                subcontext[args[0].content.name] = UNKNOWN;
                args[1].visit(this, subcontext);
                return UNKNOWN;
            }
            else if (cType.tag === 'list' || cType.tag === 'set') {
                subcontext[args[0].content.name] = cType.getElem();
                let newType = args[1].visit(this, subcontext);
                if (func === 'map') {
                    return new Type(cType.tag, [newType]);
                }
                if (newType.tag !== 'bool' && newType.tag !== 'unknown') {
                    this.errors.push('remove_if condition must have type bool, but got ' + newType.toString());
                    return UNKNOWN;
                }
                return new Type(cType.tag, [cType.getElem()]);
            }
            else if (cType.tag === 'tuple') {
                let newComps = [];
                for (let oldType of cType.getComponents()) {
                    subcontext[args[0].content.name] = oldType;
                    newComps.push(args[1].visit(this, subcontext));
                }
                return TUPLE(newComps);
            }
            this.errors.push('Expected collection for ' + func + ' but got ' + cType.toString());
            return UNKNOWN;
        }
        if (func === 'foldl' || func === 'zip') {
            // TODO are these even used? skipping for now. especially fold seems like it would be too complicated to be recommended
            return UNKNOWN;
        }
        const NERFunctions = {
            first: STRING,
            latest: STRING,
            ner_all: LIST(STRING),
            ner_clear: NOTHING, // TODO This does return None, and I did group None under NOTHING, but it might be sketchy in this context?
            ner_pop: STRING,
            ner_exists: BOOL,
        }
        if (func in NERFunctions) {
            if (args.length !== 1) {
                this.errors.push('Expected single argument for NER function ' + func);
            }
            else if (args[0].type !== 'identifier') {
                this.errors.push('Expected NER identifier for NER function but got expression');
            }
            else if (this.ners && this.ners.indexOf(args[0].content.name) === -1) {
                if (this.ners.length === 0) {
                    this.errors.push(args[0].content.name + ' is not a NER, as no NERs are defined');
                }
                else {
                    this.errors.push(args[0].content.name + ' is not among known NERs: ' + this.ners.toString());
                }
            }
            return NERFunctions[func];
        }

        let argTys = [];
        for (let i = 0; i < args.length; i++) {
            argTys.push(args[i].visit(this, context))
        }

        const anyTag = ['unknown', 'nothing', 'str', 'int', 'float', 'list', 'set', 'dict', 'tuple', 'slice', 'union'] //, 'NER']
        const basicFunctions = {
            str: { validTags: [anyTag], optionalTags: 0, output: STRING },
            int: { validTags: [['bool', 'int', 'float', 'str']], optionalTags: 0, output: INT },
            float: { validTags: [['bool', 'int', 'float', 'str']], optionalTags: 0, output: FLOAT },
            bool: { validTags: [['bool', 'int', 'float']], optional: 0, outputTags: BOOL },
            len: { validTags: [['str', 'list', 'dict', 'set', 'tuple']], optionalTags: 0, output: INT },
            lower: { validTags: [['str']], optionalTags: 0, output: STRING },
            upper: { validTags: [['str']], optionalTags: 0, output: STRING },
            strip: { validTags: [['str']], optionalTags: 0, output: STRING },
            split: { validTags: [['str']], optionalTags: 0, output: LIST(STRING) },
            split_by: { validTags: [['str'], ['str']], optionalTags: 0, output: LIST(STRING) },
            replace: { validTags: [['str'], ['str'], ['str']], optionalTags: 0, output: STRING },
            endswith: { validTags: [['str'], ['str']], optionalTags:0 , output: BOOL },
            removeprefix: { validTags: [['str'], ['str']], optionalTags: 0, output: STRING },
            removesuffix: { validTags: [['str'], ['str']], optionalTags: 0, output: STRING },
            addprefix: { validTags: [['str'], ['list']], optionalTags: 0, output: STRING },
            mask_email: { validTags: [['str']], optionalTags: 0, output: STRING },
            datetime_from_isostring: { validTags: [['str']], optionalTags: 0, output: DICT(STRING, UNION([INT, STRING])) },
            datetime_from_timestamp: { validTags: [['int', 'float'], ['int']], optionalTags: 1, output: DICT(STRING, UNION([INT, STRING])) },
            startswith: { validTags: [['str'], ['str']], optionalTags: 0, output: BOOL },
            random_random: { validTags: [], optionalTags: 0, output: FLOAT },
            dict_keys_exists: { validTags: [['dict'], ['list']], optionalTags: 0, output: BOOL },
            re_findall: { validTags: [['str'], ['str'], ['int']], optionalTags: 1, output: LIST(STRING) },
            re_sub: { validTags: [['str'], ['str'], ['str'], ['int']], optionalTags: 1, output: STRING },
        }
        if (func in basicFunctions) {
            let {validTags, optionalTags, output} = basicFunctions[func];
            if (validTags.length < args.length) {
                this.errors.push('Too many number of arguments for function ' + func +
                    ', expected ' + validTags.length + ' but got ' + args.length);
            } else if (args.length < validTags.length - optionalTags) {
                this.errors.push('Too few number of arguments for function ' + func +
                    ', expected ' + (validTags.length - optionalTags) + ' but got ' + args.length);
            }
            for (let i = 0; i < Math.min(validTags.length, args.length); i++) {
                let aTy = argTys[i];
                if (validTags[i].indexOf(aTy.tag) === -1 && aTy.tag !== 'unknown') {
                    this.errors.push('Expected one of ' + validTags[i].toString() + ' for argument ' + (i+1) + ' of function ' + func + ' but got ' + aTy.toString());
                }
            }
            return output;
        }

        if (func === 'datetime_now') {
            if (args.length > 1) {
                this.errors.push('datetime_now takes at most 1 argument');
            }
            else if (args.length === 1 && !STRING.canUnify([argTys[0]])) {
                this.errors.push('Timezone must be string, yet got ' + argTys[0]);
            }
            return DICT(STRING, UNION([INT, STRING]));
        }

        if (func === 'datetime_relative_to_now') {
            if (args.length < 5) {
                this.errors.push('datetime_relative_to_now takes at least 5 argument');
            }
            else if (args.length > 6) {
                this.errors.push('datetime_relative_to_now takes at most 6 argument');
            } else {
                for (let i = 0; i < 5; i++) {
                    if (!INT.canUnify([argTys[i]])) {
                        this.errors.push('Argument ' + i + ' must be int, yet got ' + argTys[i]);
                    }
                }
                if (args.length === 6 && !STRING.canUnify([argTys[5]])) {
                    this.errors.push('Timezone must be string, yet got ' + argTys[5]);
                }
            }
            return DICT(STRING, UNION([INT, STRING]));
        }

        const self = this;

        function getSeqConverter(targetName, TARGET) {
            return {
                validTags: ['tuple', 'list', 'set'],
                output(input) {
                    if (input.tag === 'tuple') {
                        if (NOTHING.canUnify(input.getComponents())) {
                            let elem = NOTHING;
                            for (let comp of input.getComponents()) {
                                elem = elem.unify(comp, self.errors);
                            }
                            return TARGET(elem);
                        } else {
                            return TARGET(UNKNOWN);
                        }
                    } else if (input.tag === 'list' || input.tag === 'set') {
                        return TARGET(input.getElem());
                    } else if (input.tag === 'unknown') {
                        return TARGET(UNKNOWN);
                    } else {
                        self.errors.push('Cannot convert ' + input.toString() + ' to ' + targetName);
                        return UNKNOWN;
                    }
                }
            };
        }

        function getFloatSeqConvertor(targetName) {
            return {
                validTags: ['list', 'set', 'tuple'],
                output(input) {
                    if (input.tag === 'list' || input.tag === 'set') {
                        if (!FLOAT.canUnify([input.getElem()])) {
                            self.errors.push('Expected numeric element type for ' + targetName + ' , but input is ' + input.tag + ' with element type ' + input.getElem().toString());
                            return UNKNOWN;
                        }
                        return input.getElem();
                    }
                    else if (input.tag === 'tuple') {
                        if (!FLOAT.canUnify(input.getComponents())) {
                            self.errors.push('Expected numeric element type for ' + targetName + '  but input is ' + input.tag + ' with element types ' + input.toString());
                            return UNKNOWN;
                        }
                        let it = INT;
                        for (let comp of input.getComponents()) {
                            it = it.unify(comp, self.errors);
                        }
                        return it;
                    }
                    else {
                        return UNKNOWN;
                    }
                }
            }
        }

        const genericFunctions = {
            dict: {
                validTags: ['dict', 'list'],
                output(input) {
                    if (input.tag === 'dict') {
                        return input;
                    }
                    else if (input.tag === 'list') {
                        let elem = input.getElem();
                        if (elem.tag === 'unknown') {
                            return DICT(UNKNOWN, UNKNOWN);
                        }
                        else if (elem.tag === 'tuple') {
                            let comps = elem.getComponents();
                            if (comps.length !== 2) {
                                self.errors.push('Expected 2-element tuples as elements when converting list to dict');
                                return DICT(UNKNOWN, UNKNOWN);
                            }
                            return DICT(comps[0], comps[1])
                        }
                        else if (elem.tag === 'list') {
                            let kv = elem.getElem();
                            return DICT(kv, kv);
                        }
                        self.errors.push('Cannot convert ' + input.toString() + ' to dict; the inner elements ' + elem.toString() + ' are not key-value pairs');
                        return DICT(UNKNOWN, UNKNOWN);
                    }
                    return UNKNOWN;
                }
            },
            tuple: {
                validTags: ['tuple', 'list', 'set'],
                output(input) {
                    if (input.tag === 'tuple') {
                        return input;
                    }
                    else if (input.tag === 'list' || input.tag === 'set') {
                        // we don't know the length of the list/set so we cannot convert it to tuple
                        // TODO: low-hanging fruit, introduce 'homogeneous tuple' type? probably too obscure to be prioritized
                        return UNKNOWN;
                    }
                    else {
                        return UNKNOWN;
                    }
                }
            },
            list: getSeqConverter('list', LIST),
            set: getSeqConverter('set', SET),
            pop: {
                validTags: ['list'],
                output(input) { return (input.tag === 'list')? input.getElem() : UNKNOWN; }
            },
            keys: {
                validTags: ['dict'],
                output(input) { return (input.tag === 'dict')? LIST(input.getKey()) : UNKNOWN; }
            },
            values: {
                validTags: ['dict'],
                output(input) { return (input.tag === 'dict')? LIST(input.getElem()) : UNKNOWN; }
            },
            items: {
                validTags: ['dict'],
                output(input) { return (input.tag === 'dict')? LIST(TUPLE([input.getKey(), input.getElem()])) : UNKNOWN; }
            },
            sum: getFloatSeqConvertor('sum'),
            min: getFloatSeqConvertor('min'),
            max: getFloatSeqConvertor('max')
        }
        if (func in genericFunctions) {
            let {validTags, output} = genericFunctions[func];
            if (args.length !== 1) {
                this.errors.push('Wrong number of arguments for function ' + func + ', expected 1 but got ' + args.length);
                return UNKNOWN;
            }
            if (validTags.indexOf(argTys[0].tag) === -1 && argTys[0].tag !== 'unknown') {
                this.errors.push('Expected ' + validTags + ' for argument of function ' + func + ' but got ' + argTys[0].toString());
            }
            return output(argTys[0]);
        }
        if (['any', 'all'].indexOf(func) !== -1) {
            if (args.length !== 1) {
                this.errors.push('Got multiple arguments for ' + func + ', but expected only one. Did you forget to put them in a list?');
                return BOOL;
            }
            if (['unknown', 'list', 'tuple', 'set'].indexOf(argTys[0].tag) === -1) {
                this.errors.push('Invalid argument for ' + func + ', expected collection but got ' + argTys[0].toString());
                return BOOL;
            }
            if (argTys[0].tag === 'tuple') {
                if (!BOOL.canUnify(argTys[0].getComponents())) {
                    this.errors.push('Expected collection of booleans for ' + func + ' but got ' + argTys[0].toString());
                }
                return BOOL;
            }
            if (argTys[0].tag !== 'unknown') {
                if (!BOOL.canUnify([argTys[0].getElem()])) {
                    this.errors.push('Expected collection of booleans for ' + func + ' but got ' + argTys[0].toString());
                }
            }
            return BOOL;
        }
        if (func === 'join') {
            if (args.length !== 2) {
                this.errors.push('Expected 2 arguments for ' + func + ', separator string and collection to join, but got ' + args.length);
                return STRING;
            }
            if (!STRING.canUnify([argTys[0]])) {
                this.errors.push('Expected string separator but got ' + argTys[0].toString());
                return STRING;
            }
            if (LIST(STRING).canUnify([argTys[1]])) {
                return STRING;
            }
            if (argTys[1].tag === 'tuple') {
                if (!STRING.canUnify([argTys[1].getElem()])) {
                    this.errors.push('Expected tuple of strings for join but got ' + argTys[1].toString());
                }
            }
            return STRING;
        }
        if (func === 'dict_update') {
            if (argTys.length !== 2) {
                this.errors.push('dict_update takes exactly 2 arguments, yet was given ' + argTys.length);
                return DICT(UNKNOWN, UNKNOWN);
            }
            if (!DICT(UNKNOWN, UNKNOWN).canUnify(argTys)) {
                this.errors.push('Expected dictionaries for input to dict_update but got: ' + argTys.toString());
                return DICT(UNKNOWN, UNKNOWN);
            }
            if (!DICT(NOTHING, UNKNOWN).canUnify(argTys)) {
                this.errors.push('Warning, incompatible dictionaries for update, is this intentional? ' + argTys.toString());
                return DICT(UNKNOWN, UNKNOWN);
            }
            if (!DICT(NOTHING, NOTHING).canUnify(argTys)) {
                return DICT(NOTHING, UNKNOWN).unify(argTys[0], this.errors).unify(argTys[1], this.errors);
            }
            return argTys[0].unify(argTys[1], this.errors);
        }
        this.errors.push('No type for function ' + func + ' - are you sure this function exists?');
        return UNKNOWN; // TODO NYI
    }
    visitIdentifier(name, context) {
        if (this.ners && this.ners.indexOf(name) !== -1) {
            this.errors.push('\'' + name + '\' is a NER and should be accessed via NER functions. '
                    + 'Did you mean to write \'latest(' + name + ')\' instead?');
        } else if (name in context) {
            return context[name];
        }
        return UNKNOWN;
    }
    // eslint-disable-next-line no-unused-vars
    visitSyntaxError(context) {
        return UNKNOWN;
    }
}

export function typecheck(ast, errors, ners, additional_identifier_types = {}) {
    const visitor = new TypeVisitor(errors, ners);
    return ast.visit(visitor, {
        ...additional_identifier_types,
        thisMessage: STRING,
        thisMessageVerbatim: STRING,
        transcript: STRING,
        lastBotMessages: LIST(STRING),
        action_results: DICT(STRING, UNKNOWN),
        smart_options: DICT(STRING, DICT(INT, DICT(STRING, UNKNOWN))),
        bs_integration_result: DICT(STRING, UNKNOWN),
        description: STRING,
        math: MOD_MATH,
        re: MOD_RE,
    });
}
