import { render, staticRenderFns } from "./NodeWithLabels.vue?vue&type=template&id=4069df56&scoped=true&"
import script from "./NodeWithLabels.vue?vue&type=script&lang=js&"
export * from "./NodeWithLabels.vue?vue&type=script&lang=js&"
import style0 from "./NodeWithLabels.vue?vue&type=style&index=0&id=4069df56&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4069df56",
  null
  
)

export default component.exports