<template>
  <app-template
    v-bind="{
      pages: filteredSidebarList,
      showSidebar,
      userDetails,
      toolDetails: {
        name: 'BotStudio',
        logo: require('./assets/supwiz-logo-flat.svg'),
        env: botstudioEnvironment,
      },
      showSpinner: pageLoading,
      loggedIn,
    }"
    notifications-center
  >
    <template
      #after-nav
    >
      <b-card
        class="mt-auto"
        bg-variant="transparent"
        no-body
        style="color: #A4B7CF; min-width: 217px !important;"
      >
        <div
          class="text-center"
        >
          <b-button
            v-if="uncommitedChanges && isMainBot"
            variant="secondary"
            class="mb-2"
            :to="{ name: 'diff' }"
          >
            Unstaged changes
          </b-button>
        </div>
        <div
          v-if="!isMainBot"
          class="text-center"
        >
          <b-list-group-item
            variant="warning"
          >
            You are in subflow
            <b-button
              variant="warning"
              @click="exitSubFlowEdit"
            >
              Exit to main bot
            </b-button>
          </b-list-group-item>
        </div>
      </b-card>
    </template>

    <template #header>
      <base-header />
    </template>
    <template #page-content>
      <transition
        mode="out-in"
        name="fade"
      >
        <b-overlay
          :show="manualSync"
          class="h-100"
        >
          <router-view />
        </b-overlay>
      </transition>
      <app-session-time />
    </template>
  </app-template>
</template>

<script>
import AppTemplate from 'supwiz/components/template/AppTemplate.vue';
import { mapGetters, mapMutations, mapState } from 'vuex';
import { cloneDeep } from 'lodash';
import BaseHeader from '@/components/header/BaseHeader.vue';
import AppSessionTime from '@/components/app-session-time.vue';
import { truncateString, getBotstudioEnvironment } from '@/js/utils';
import { userIsAllowedToViewRoute } from '@/router';

export default {
  name: 'App',
  components: {
    AppSessionTime, AppTemplate, BaseHeader,
  },
  computed: {
    ...mapState('sidebar', ['pageLoading', 'showSidebar']),
    ...mapGetters('sidebar', ['loggedIn', 'pages']),
    ...mapGetters('auth', ['displayName']),
    ...mapState('botSynchronization', ['lastBotETagReceived', 'manualSync']),
    ...mapGetters('botManipulation', ['lastStagedBot', 'getBotId']),
    ...mapGetters('nlu/classifier', ['activeModel']),
    ...mapGetters('botManipulation/activeBot/config', [
      'isMainBot',
      'getBotName',
    ]),
    ...mapGetters('userSettings', ['getBotStudioSettings']),
    uncommitedChanges() {
      if (!this.lastStagedBot || !this.lastBotETagReceived) {
        return false;
      }
      return this.lastStagedBot.etag !== this.lastBotETagReceived;
    },
    userDetails() {
      return {
        displayName: this.displayName,
      };
    },
    cappedBotName() {
      return truncateString(this.getBotName, 25);
    },
    botstudioEnvironment() {
      return getBotstudioEnvironment();
    },
    filteredSidebarList() {
      const categories = [];
      if (this.$route.params.botId !== undefined || this.$route.params.modelId !== undefined
     || this.$route.params.datasetId !== undefined || this.$route.path.includes('administration')) {
        for (const category of this.pages) {
          let pages = cloneDeep(category.pages.filter(this.pageFilter));
          pages = pages.filter((element) => {
            if (element.children) {
            // eslint-disable-next-line no-param-reassign
              element.children = element.children.filter(this.pageFilter);
              return true;
            }
            return true;
          });
          if (pages.length > 0) {
          // Filter out pages that are not viewable by user's userpermission
            const allowedPages = pages.filter((page) => {
              if (page.children) {
                return page.children.filter((child) => this.pageLookUp(child)).length;
              }
              return this.pageLookUp(page);
            });
            if (allowedPages.length > 0) {
            // Only add categories that actually contain pages
              categories.push({
                ...category,
                pages: allowedPages
                  .map((page) => ({ ...page, route: this.nameToRoute(page) })),
              });
            }
          }
        }
        if (this.activeModel && this.activeModel.typeText === 'Uploaded' && categories.length) {
          return categories[0].pages.filter((e) => !e.hideWhenUploaded);
        }
      }
      return categories;
    },
  },
  methods: {
    ...mapMutations('graphView', ['clearGraphData']),
    pageLookUp(page) {
      const routeLookUp = page.linkName
        ? this.$router.resolve({ name: page.linkName }) : undefined;
      const routeForPage = routeLookUp !== undefined ? routeLookUp.route : undefined;
      if (routeForPage === undefined) {
        // Nicety: If the page links to a 'dead' route, remove it here.
        return false;
      }
      return userIsAllowedToViewRoute(routeForPage);
    },
    isRouteActive(name) {
      return this.$route.path.includes(name);
    },
    nameToRoute(name) {
      return { name, params: { botId: this.getBotId } };
    },
    pageFilter(page) {
      if (this.isMainBot && page.filterKey) {
        return this.getBotStudioSettings[page.filterKey];
      }
      return this.isMainBot || !page.hideInSubflow;
    },
    async exitSubFlowEdit() {
      this.clearGraphData();
      this.$router.push({ name: 'flow', params: { botId: this.$store.state.botManipulation.activeBotId } });
    },
  },
};
</script>
<style>
 body, html{
  height: 100vh;
  overflow-y: hidden;
  overflow-x: hidden;
}
#app {
  background-image:
    linear-gradient(to right, rgba(2,76,115,0.97), rgba(2,76,115,0.97)),
    url('./assets/background-pattern.svg');
}
.black-text{
  color:#495057;
}
.break-text{
  white-space:pre-line;
  word-break: break-all;
  word-wrap: break-word;
}
.cursor-pointer {
  cursor: pointer;
}
p{
  margin-bottom: 0px;
}
.permission-col{
  width: 70px !important;
}
.health-header{
  background-color:white;
}
.health-header:hover{
  background-color: #F7F7F7;
}
.health-alert{
  height:77px;
}
</style>
