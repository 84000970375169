<template>
  <div>
    <b-collapse :visible="showFilters">
      <b-card
        body-class="p-0"
        class="mt-2"
      >
        <date-time-lang-picker
          variant
          date
          time
          group-by
          lang
          shortcuts
          :group-by-options="groupByOptions"
          :getter="statsFilter"
          :setter="updateStatsFilter"
        />

        <b-row class="mt-3">
          <b-col>
            <b-overlay
              :show="loadingOrigins || (activeTab != 0 && isFetchingStartUrls)"
              :opacity="0.9"
            >
              <template #overlay>
                <div class="text-center">
                  <p class="text-secondary">
                    <b-spinner
                      small
                      class="mr-2"
                      style="margin-bottom:2px;"
                    />Loading data sources...
                  </p>
                </div>
              </template>
              <b-btn-group
                class="w-100"
              >
                <b-dropdown
                  :variant="selectedOrigins.length === 0 ? 'warning' : ''"
                  menu-class="primary p-0 mt-1"
                >
                  <template #button-content>
                    <font-awesome-icon
                      v-if="selectedOrigins.length === 0"
                      icon="exclamation-circle"
                    />
                    data sources
                  </template>
                  <b-dropdown-form class="bg-secondary r-25 py-2">
                    <b-form-checkbox
                      v-for="source in availableDataOrigins"
                      :key="source.rawValue"
                      v-model="selectedOrigins"
                      :value="source"
                      class="text-nowrap align-middle"
                    >
                      {{ source.displayName }}
                    </b-form-checkbox>
                  </b-dropdown-form>
                </b-dropdown>
                <b-form-checkbox
                  v-model="includeOutgoing"
                  v-b-tooltip.hover.noninteractive
                  :title="ongoingTitle"
                  button
                  class="btn-group"
                  style="flex: 0 0 auto"
                >
                  {{ statsFilter.includeOngoing ? 'Ongoing included' : 'Ongoing excluded' }}
                </b-form-checkbox>
                <b-btn
                  variant="primary"
                  block
                  :disabled="selectedOrigins.length === 0 || anyLoading"
                  @click="computeKPIs()"
                >
                  <span v-if="anyLoading">
                    <b-spinner small /> Loading statistics
                  </span>
                  <span v-else>
                    Compute
                  </span>
                </b-btn>
              </b-btn-group>
            </b-overlay>
          </b-col>
        </b-row>
      </b-card>
    </b-collapse>
  </div>
</template>
<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import moment from 'moment';
import { getChartLabels } from '@/js/utils';
import { timeMetricOptions, platforms } from '@/js/constants';
import DateTimeLangPicker from '@/components/DateTimeLangPicker.vue';

export default {
  name: 'StatisticsFilter',
  components: {
    DateTimeLangPicker,
  },
  props: {
    showFilters: {
      required: true,
      type: Boolean,
    },
    activeTab: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      timeMetricOptions,
      selectedTabIndex: null,
      createKPINodeModalFields: {
        modalChosenNodeName: null,
      },
      addNodeStatsNodeName: null,
      includeOngoingPlatformsTrue: new Set([
        platforms.TOPDESKINCIDENT,
        platforms.JSONWEBHOOK,
        platforms.ZENDESKSUPPORT,
      ]),
    };
  },
  computed: {
    ...mapGetters('botManipulation/activeBot', [
      'nameOfId',
    ]),
    ...mapGetters('statistics', [
      'getKPIMeta',
      'getKPIDataDistributionPlot',
      'getKPIDataResolutionPlot',
      'getKPIData',
      'isLoaded',
      'isLoading',
      'anyLoading',
      'getNodeStatsNodes',
      'getKPIDataInfo',
      'getNodeVisits',
    ]),
    ...mapGetters('statistics/filters', ['statsFilter', 'getSelectedOrigins', 'loadingOrigins']),
    ...mapGetters('chatlogs', [
      'availableDataOrigins',
      'isFetchingStartUrls',
    ]),
    ...mapGetters('variants', [
      'getNameFromId',
    ]),
    ...mapState('statistics', ['KPIMeta', 'KPIData']),
    ...mapGetters('botManipulation/activeBot/config', ['getPlatforms']),
    nodeVisitsReady() {
      return this.isLoaded('node_visits');
    },
    groupByOptions() {
      return this.activeTab === 0 ? this.timeMetricOptions : [
        { value: null, text: 'Entire period' },
        { value: 'weeks', text: 'Week' },
        { value: 'months', text: 'Month' },
      ];
    },
    ongoingTitle() {
      return `Ongoing conversations ${this.statsFilter.includeOngoing ? 'included' : 'excluded'}`;
    },
    selectedOrigins: {
      get() {
        return this.getSelectedOrigins;
      },
      set(value) {
        this.setOrigins(value);
      },
    },
    includeOutgoing: {
      get() {
        return this.statsFilter.includeOngoing;
      },
      set(value) {
        this.updateStatsFilter({ key: 'includeOngoing', newValue: value });
      },
    },
  },
  watch: {
    activeTab(newVal) {
      if (newVal === 0) {
        this.updateStatsFilter({ key: 'selectedGroupBy', newValue: 'hoursAccum' });
      } else {
        this.updateStatsFilter({ key: 'selectedGroupBy', newValue: null });
      }
    },
  },
  created() {
    const startDate = this.getKPIMeta('start_date');
    const endDate = this.getKPIMeta('end_date');
    if (startDate) {
      this.updateStatsFilter({ key: 'startDate', newValue: new Date(startDate) });
    } else {
      const now = new Date();
      now.setDate(now.getDate() - 7);
      this.updateStatsFilter({ key: 'startDate', newValue: now });
    }
    if (endDate) {
      this.updateStatsFilter({ key: 'endDate', newValue: new Date(endDate) });
    } else {
      this.updateStatsFilter({ key: 'endDate', newValue: new Date() });
    }

    this.updateStatsFilter({
      key: 'selectedLanguage',
      newValue: this.getKPIMeta('language')
        ? this.getKPIMeta('language') : 'any',
    });

    this.updateStatsFilter({ key: 'startTime', newValue: this.getKPIMeta('start_time') ? this.getKPIMeta('start_time') : 0 });
    this.updateStatsFilter({ key: 'endTime', newValue: this.getKPIMeta('end_time') ? this.getKPIMeta('end_time') : 24 });
  },
  async mounted() {
    this.setLoadingOrigins(true);
    this.fetchDataOrigins().then(() => {
      this.setOrigins(this.availableDataOrigins);
      this.setLoadingOrigins(false);
    });
  },
  methods: {
    getChartLabels,
    ...mapMutations('statistics/filters', [
      'updateStatsFilter',
      'setLoadingOrigins',
      'setOrigins',
      'setNeedsRefresh',
    ]),
    ...mapActions('chatlogs', [
      'fetchDataOrigins',
    ]),
    ...mapActions('statistics', [
      'fetchKPIData',
      'setNodeStatsNodeFavorite',
      'addNodeStatsNode',
      'deleteNodeStatsNode',
    ]),
    computeKPIs() {
      if (this.activeTab === 0) {
        this.setNeedsRefresh({ key: 'general', value: false });
      } else {
        this.setNeedsRefresh({ key: 'details', value: false });
      }

      this.fetchKPIData(this.activeTab === 0 ? 'general' : 'details');
    },
    downloadKPIs() {
      if (this.activeTab === 0) {
        this.downloadGeneralKPIs();
      } else {
        this.downloadDetailsKPIs();
      }
    },
    downloadGeneralKPIs() {
      const rows = this.getSharedRows();
      for (const k of ['automation_rate', 'estimated_cost_savings', 'num_chats']) {
        rows.push([k, null, null, null, this.getKPIData(k).info.actual]);
      }
      const convAutomationDistribution = this.getKPIData('conversation_automation_distributions');
      rows.push([null, null, null, null, null]);
      rows.push([null, 'conversation automation distribution', null, null, null]);
      for (const [k, v] of Object.entries(convAutomationDistribution.info)) {
        rows.push([null, null, null, k, v || 0]);
      }
      const questAutomationDistribution = this.getKPIData('question_automation_distributions');
      rows.push([null, null, null, null, null]);
      rows.push([null, 'question automation distribution', null, null, null]);
      for (const [k, v] of Object.entries(questAutomationDistribution.info)) {
        rows.push([null, null, null, k, v || 0]);
      }

      const distributionOverTime = this.getKPIData('distributions_over_time');
      rows.push([null, null, null, null, null]);
      rows.push([null, 'distribution over time', null, null, null]);
      rows.push([null, null, 'label', 'volume', 'flow_coverage']);
      for (const [index, value] of Object.entries(this.getChartLabels(this.statsFilter))) {
        rows.push([null, null, value, distributionOverTime.info.chatCount[index],
          distributionOverTime.info.flowCoverage[index] || 0]);
      }
      // temporary disabled
      rows.push([null, null, null, null, null]);
      rows.push([null, 'chat ratings', null, null, null]);
      const chatRatings = this.getKPIData('chat_ratings');
      for (const [k, v] of Object.entries(chatRatings.info)) {
        rows.push([null, null, k, v, null]);
      }
      this.downloadFile(rows);
    },
    downloadDetailsKPIs() {
      const rows = this.getSharedRows();

      // Major KPIs
      rows.push(['Overall statistics', null, null, null, null]);
      const data = this.$store.state.statistics.KPIData;
      for (const k of this.$store.state.statistics.detailsFields) {
        if (k === 'node_visits' || k === 'node_ratings') {
          continue;
        }
        rows.push([k, null, null, null, data[k].info.actual]);
      }
      // Separator line
      rows.push([null, null, null, null, null]);
      // node visits
      rows.push(['Node visits', null, null, null, null]);
      if (this.nodeVisitsReady) {
        rows.push(['Name', null, null, 'Count', 'Fraction']);
        for (const entry of this.getNodeVisits) {
          rows.push([entry.name, null, null, entry.count, entry.fraction]);
        }
      }
      this.downloadFile(rows);
    },
    getSharedRows() {
      const rows = [];
      // Filtering information
      if (this.statsFilter.selectedVariant === null) {
        rows.push(['variant', null, null, null, 'no variant (main bot)']);
      } else if (this.statsFilter.selectedVariant.length === 0) {
        rows.push(['variant', null, null, null, 'any variant']);
      } else {
        rows.push(['variant', null, null, null, this.getNameFromId(this.statsFilter.selectedVariant)]);
      }
      rows.push(['Ongoing included', null, null, null, this.statsFilter.includeOngoing ? 'yes' : 'no']);
      rows.push(['language', null, null, null, this.statsFilter.selectedLanguage]);
      rows.push(['Start date', null, null, null, moment(this.statsFilter.startDate).format('YYYY-MM-DD')]);
      rows.push(['Start time', null, null, null, this.statsFilter.startTime]);
      rows.push(['End date', null, null, null, moment(this.statsFilter.endDate).format('YYYY-MM-DD')]);
      rows.push(['End time', null, null, null, this.statsFilter.endTime]);
      rows.push(['Selected origins', null, null, null, null]);
      for (const origin of this.selectedOrigins) {
        rows.push([null, null, null, null, origin.displayName]);
      }
      // Separator line
      rows.push([null, null, null, null, null]);
      return rows;
    },
    downloadFile(rows) {
      // write the rows to a string and enforce download
      const DownloadHeader = 'data:text/csv;charset=utf-8,';
      const csv = DownloadHeader + rows.map((x) => x.join(';')).join('\r\n');
      const encodedUri = encodeURI(csv);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', 'botstudio_stats.csv');
      document.body.appendChild(link); // Required for FF
      link.click();
    },
  },
};
</script>
