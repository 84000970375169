<template>
  <div>
    <b-row class="my-2">
      <b-col cols="auto" class="pr-1">
        <b-dropdown
          size="sm"
          text="Choose phrase"
          menu-class="phrase-dropdown"
        >
          <b-dropdown-form form-class="px-2">
            <b-form-input
              v-model="keyword"
              size="sm"
              placeholder="Type to search"
              class="bg-white"
            />
          </b-dropdown-form>
          <b-dropdown-divider />
          <b-dropdown-item
            v-for="(phrase, index) in filteredPhrases"
            :key="index"
            link-class="phrase-dropdown-item"
            @click="$emit('add', phrase)"
          >
            {{ phrase.name }}
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
      <b-col class="pl-1">
        <b-button v-if="!hideAdd" size="sm" @click="$emit('new', null)">
          <font-awesome-icon icon="plus" />
        </b-button>
      </b-col>
      <b-col cols="auto">
        <b-button v-b-modal="`preview-modal-${previewModalId}`" size="sm">
          Preview message
        </b-button>
      </b-col>
    </b-row>
    <b-modal
      :id="`preview-modal-${previewModalId}`"
      hide-footer
      size="lg"
      title="Message preview"
    >
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div class="response-preview" v-html="cleanedPreview" />
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { guidGenerator } from '@/js/utils';

export default {
  name: 'PhraseSelector',
  props: {
    responsePreview: {
      type: String,
      default: '',
    },
    activityId: {
      type: String,
      default: '',
    },
    hideAdd: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      keyword: '',
      previewModalId: guidGenerator(),
    };
  },
  computed: {
    ...mapGetters('botManipulation/activeBot/config', ['wysiwygEnabled']),
    ...mapGetters('botManipulation/activeBot', ['phrases']),
    filteredPhrases() {
      return this.phrases.filter((e) => e.name.includes(this.keyword));
    },
    cleanedPreview() {
      return this.responsePreview.replace(/\n/g, '<br>');
    },
  },
};
</script>
<style scoped>
::v-deep .phrase-dropdown{
  max-width: 400px !important;
}
::v-deep .phrase-dropdown-item{
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}
::v-deep .response-preview p {
  margin-bottom: 1em !important;
  white-space: pre-wrap;
}
</style>
