<template>
  <div class="d-flex flex-column align-items-center my-3">
    <span class="meta">Upload</span>
    <div
      class="bubble p-2 uploadMessage bg-info"
    >
      <b-row>
        <b-col class="text-right font-weight-bold">
          Type:
        </b-col>
        <b-col cols="8">
          {{ upload.data.type }}
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-right font-weight-bold">
          uuid:
        </b-col>
        <b-col cols="8">
          {{ upload.data.uuid }}
        </b-col>
      </b-row>
    </div>
    <span
      class="meta"
    >{{ dateFormatter(upload.timestamp) }}</span>
  </div>
</template>

<script>

export default {
  name: 'ChatUpload',
  props: {
    upload: {
      type: Object,
      required: true,
    },
  },
  methods: {
    dateFormatter(timestamp) {
      return new Date(timestamp * 1000).toLocaleTimeString();
    },
  },
};
</script>

<style scoped>
.bubble.uploadMessage {
  min-width: 80%;
  max-width: 80%;
}
</style>
