<template>
  <div class="p-3">
    <b-row class="save-row">
      <b-col class="mb-1">
        <b-button
          block
          :disabled="isVariantsWorking || isSaveDisabled"
          variant="primary"
          @click="saveOtherActivitiesProxy"
        >
          <b-spinner
            v-if="isVariantsWorking"
            small
          />
          Save All
        </b-button>
      </b-col>
    </b-row>
    <b-table
      ref="responsesTable"
      :items="items"
      :fields="fields"
      :per-page="perPage"
      :current-page="currentPage"
      show-empty
      responsive
      hover
      details-td-class="bg-white cursor-auto table-details"
      class="cursor-pointer"
      @row-clicked="toggleDetails"
    >
      <template #cell(location)="row">
        <span class="font-weight-bold">Element:</span>
        {{ row.item.prependPath.join(' > ') }}
      </template>
      <template #head(unchanged)="data">
        <span
          v-b-tooltip.hover
          title="Shows whether the response was saved after the master bot's text was last changed."
        >
          {{ data.label }}
        </span>
      </template>
      <template #cell(unchanged)="row">
        <font-awesome-icon
          v-if="row.item.unchanged"
          v-b-tooltip.hover
          title="Master unchanged"
          icon="check-circle"
          class="text-success"
        />
        <font-awesome-icon
          v-else-if="row.item.unchanged === false"
          v-b-tooltip.hover
          title="Master updated"
          icon="exclamation-circle"
          class="text-warning"
        />
      </template>
      <template #cell(actions)="{ detailsShowing }">
        <h5 class="mr-1 mb-0">
          <font-awesome-icon :icon="detailsShowing ? 'angle-up' : 'angle-down'" />
        </h5>
      </template>
      <template #row-details="row">
        <div style="overflow-x:hidden">
          <template v-if="row.item.type == SUP_WIZ_INTEGRATION">
            <span class="font-weight-bold">Original activity:</span>
            <SuggestArticles :data="activityMasterValue(row.item)" type="original" />
            <hr>
            <span class="font-weight-bold">Variant activity:</span>
            <SuggestArticles :data="activityVariantValue(row.item)" type="variant" @input="v=>updateVariant(v, row.item)" />
            <b-button
              class="mt-1 mr-1"
              :disabled="isVariantsWorking || !isDirty[row.item.id]"
              variant="success"
              size="sm"
              @click="saveOtherActivitiesProxy({ id: row.item.id })"
            >
              Save
              <b-spinner
                v-if="isVariantsWorking"
                small
              />
            </b-button>
            <b-button
              class="mt-1"
              :disabled="isVariantsWorking || !isDirty[row.item.id]"
              variant="primary"
              size="sm"
              @click="discardChanges(row.item)"
            >
              Discard changes
            </b-button>
          </template>
          <template v-else-if="row.item.type === GENERATIVE_AI">
            <span class="font-weight-bold">Original activity:</span>
            <GenerateReply
              ref="generateReplyMaster"
              :node-id="row.item.prependPath[1]"
              :activity-id="row.item.id"
              :shown="false"
              :data="generateReplyMasterValue(row.item)"
              type="original"
            />
            <hr>
            <span class="font-weight-bold">Variant activity:</span>
            <GenerateReply
              ref="generateReplyVariant"
              :node-id="row.item.prependPath[1]"
              :activity-id="row.item.id"
              :shown="false"
              :data="generateReplyVariantValue(row.item)"
              type="variant"
              @input="v=>updateVariant(v, row.item)"
            />
            <b-button
              class="mt-1 mr-1"
              :disabled="isVariantsWorking || !isDirty[row.item.id]"
              variant="success"
              size="sm"
              @click="saveOtherActivitiesProxy({ id: row.item.id })"
            >
              Save
              <b-spinner
                v-if="isVariantsWorking"
                small
              />
            </b-button>
            <b-button
              class="mt-1"
              :disabled="isVariantsWorking || !isDirty[row.item.id]"
              variant="primary"
              size="sm"
              @click="discardChanges(row.item)"
            >
              Discard changes
            </b-button>
          </template>
        </div>
      </template>
    </b-table>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import SuggestArticles from '@/pages/EditNode/activity/SupWizActivity/SuggestArticles.vue';
import { GENERATIVE_AI, SUP_WIZ_INTEGRATION } from '@/js/activity';
import { cloneDeep } from 'lodash';
import GenerateReply from '@/pages/EditNode/activity/GenerateReply.vue';
import { isSupSearchEnabled } from '@/js/featureFlags';

export default {
  name: 'VariantOtherActivities',
  components: {
    SuggestArticles,
    GenerateReply,
  },
  data() {
    return {
      GENERATIVE_AI,
      SUP_WIZ_INTEGRATION,
      currentPage: 1,
      perPage: 20,
      rows: 0,
      fields: [
        { key: 'typeDisplay', label: 'Type' },
        'location',
        'unchanged',
        {
          key: 'actions',
          label: '',
          thClass: 'text-center',
        },
      ],
      rowDetails: {},
      isDirty: {},
    };
  },
  computed: {
    ...mapGetters('botManipulation/activeBot', [
      'nodeById',
    ]),
    ...mapGetters('variants', [
      'isVariantsRefreshing',
      'isVariantsSaving',
      'selectedOtherActivities',
      'masterPhrases',
      'masterOtherActivities',
    ]),
    isVariantsWorking() {
      return this.isVariantsRefreshing || this.isVariantsSaving;
    },
    items() {
      return Object.entries(this.masterOtherActivities).map(([k, v]) => ({
        id: k,
        unchanged: this.masterUnchanged(k),
        elem: v.params,
        type: v.type,
        typeDisplay: this.typeToText(v.type),
        prependPath: v.prependPath,
        _showDetails: this.rowDetails[k] !== undefined
          ? this.rowDetails[k] : false,
      }));
    },
    isSaveDisabled() {
      return !Object.values(this.isDirty).some((x) => x === true);
    },
  },
  mounted() {
    if (isSupSearchEnabled()) {
      this.fetchRankers();
    }
  },
  methods: {
    ...mapActions('supsearch', ['fetchRankers']),
    ...mapActions('variants', ['saveVariantOtherActivities']),
    ...mapMutations('variants', [
      'setSingleOtherActivity',
    ]),
    typeToText(type) {
      switch (type) {
        case SUP_WIZ_INTEGRATION: return 'Suggest article';
        default: return 'Generate reply';
      }
    },
    activityMasterValue(item) {
      const data = { };
      Object.values(item.elem).forEach((element) => {
        data[element.key] = element.value.text;
      });
      return data;
    },
    activityVariantValue(item) {
      const data = { };
      Object.values(this.selectedOtherActivities[item.id]?.params || {}).forEach((element) => {
        if (element.value.newText !== undefined) {
          data[element.key] = element.value.newText;
        } else {
          data[element.key] = element.value.text;
        }
      });
      return data;
    },
    activityValue(id) {
      return this.formatRow(this.selectedOtherActivities[id]?.params || {}, 'newText');
    },
    generateReplyMasterValue(item) {
      const data = { };
      Object.values(item.elem).forEach((element) => {
        data[element.key] = element.value.text;
      });
      data.contextType = item.elem.contextType?.value?.text;
      this.$nextTick(() => {
        if (data.article && !this.$refs.generateReplyMaster?.getSelectedArticle) {
          this.$refs.generateReplyMaster.searchArticlesProxy(data.article);
        }
      });
      return data;
    },
    generateReplyVariantValue(item) {
      const data = { };
      Object.values(this.selectedOtherActivities[item.id]?.params || {}).forEach((element) => {
        if (typeof element.value.newText === 'number') {
          data[element.key] = element.value.newText;
        } else {
          data[element.key] = element.value.newText || element.value.text;
        }
      });
      data.contextType = item.elem.contextType?.value?.text;
      this.$nextTick(() => {
        if (data.article && !this.$refs.generateReplyVariant?.getSelectedArticle) {
          this.$refs.generateReplyVariant.searchArticlesProxy(data.article);
        }
      });
      return data;
    },
    saveOtherActivitiesProxy({ id }) {
      this.saveVariantOtherActivities({ id });
      if (id) {
        this.$set(this.isDirty, id, false);
      }
    },
    getDirty(item, payload) {
      const elem = this.selectedOtherActivities[item.id]?.params;
      if (elem) {
        const prop = Object.values(elem).find((value) => value.key === payload.param);
        return this.getPhraseDirty(prop.value, payload.value);
      }
      return false;
    },
    getPhraseDirty(elem, newText) {
      if (newText !== undefined) {
        return elem?.text !== newText;
      }
      const ret = elem?.newText !== undefined && elem?.newText !== elem?.text;
      return ret;
    },
    updateDirty(item, payload) {
      this.$set(this.isDirty, item.id, this.getDirty(item, payload));
    },
    discardChanges(item) {
      const params = cloneDeep(this.selectedOtherActivities[item.id].params);
      Object.keys(params).forEach((key) => {
        const payload = { param: key, value: params[key].value.text };
        this.setSingleOtherActivity({ payload, item });
      });
      this.$set(this.isDirty, item.id, false);
    },
    masterUnchanged(key) {
      const params = this.selectedOtherActivities[key]?.params;
      if (!params || Object.values(params).some((e) => !e.value.masterHash)) {
        return undefined;
      }
      return !Object.keys(params).some((prop) => this.masterOtherActivities[key]
        .params[prop].value.hash !== params[prop].value.masterHash);
    },
    toggleDetails(row) {
      this.$set(this.rowDetails, row.id, !row._showDetails);
    },
    updateVariant(payload, item) {
      this.setSingleOtherActivity({ payload, item });
      const elem = cloneDeep(item.elem);
      elem[payload.param].value.text = payload.value;
      this.updateDirty(item, payload);
    },
  },
};
</script>
<style scoped>
.save-row{
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 100;
}
</style>
