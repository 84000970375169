<template>
  <div
    class="d-flex flex-shrink-0 align-items-center border"
    :class="`bg-${compVariant}`"
  >
    <b-button
      class="border-0 bg-transparent shadow-none"
      :class="{ 'p-0': small }"
      :variant="compVariant"
      :style="`font-size:${small ? 'inherit' : '15px'};color:inherit`"
      @click.stop="btnHandler()"
    >
      <b-spinner
        v-if="showSpinner"
        small
      />
      <font-awesome-icon
        v-else
        :icon="compIcon"
      />
      <template v-if="buttonText && !audioData && !small">
        {{ compButtonText }}
      </template>
    </b-button>
    <audio
      ref="audioEl"
      :src="audioData"
      autoplay
      @timeupdate="updateCurrentTime()"
      @onplaying="isPlaying = true"
      @onpause="isPlaying = false"
    />
    <div
      v-if="!small"
      v-b-tooltip.noninteractive
      :class="audioData
        ? 'd-flex flex-grow-1 align-items-center mr-2 position-relative'
        : 'd-none'"
      :title="progressText"
    >
      <canvas
        ref="canvas"
        height="36"
        width="300"
      />
      <canvas
        ref="progressCanvas"
        height="36"
        width="300"
        class="position-absolute linear"
        :style="progressClipPath"
      />
      <b-form-input
        :value="elementCurrentTime"
        type="range"
        min="0"
        class="mr-2 position-absolute"
        style="opacity:0"
        :max="elementDuration"
        @input="(val) => $refs.audioEl.currentTime = val"
      />
    </div>
  </div>
</template>

<script>
/*
  How does this component work?
  This componenent can generate and display waveforms
  for audio when the "small" mode is not used.
  It does this by using the browsers AudioContext API
  which is able to decode the channel data. We can then
  use this for generating the waveform. We use 2 canvas
  elements to draw the waveform on, one for the background
  and one for the foreground. Then we use a clip-path to
  display the progress. Overlayed on top of this, we have
  an invisible range input which allows the user to click
  on the waveform and play from there.
*/
import axios from 'axios';
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  props: {
    chatLogData: {
      type: Object,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    small: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'secondary',
    },
    buttonText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      audioData: null,
      audioFetching: null,
      elementCurrentTime: 0,
      elementDuration: 0,
      isPlaying: false,
      spinnerTimeout: null,
      showSpinner: false,
      error: false,
    };
  },
  computed: {
    ...mapGetters('botManipulation', ['activeBotId']),
    ...mapGetters('chatlogs', ['currentPlaybackSkipTo']),
    progressText() {
      return `${this.formatTime(this.elementCurrentTime)}/${this.formatTime(this.elementDuration)}`;
    },
    compVariant() { return this.error ? 'danger' : this.variant; },
    compIcon() {
      if (this.error) return 'exclamation-circle';
      return `${this.isPlaying
        ? `${this.small ? 'stop' : 'pause'}`
        : 'play'}-circle`;
    },
    compButtonText() {
      if (this.error) return 'Error occured';
      if (this.showSpinner) return 'Loading...';
      return this.buttonText;
    },
    startTimeOffset() {
      return this.chatLogData
        ? new Date(this.chatLogData.logEvents[0].timestamp).getTime()
        : 0;
    },
    skipToTimestamp() {
      return this.chatLogData
        ? this.currentPlaybackSkipTo(this.chatLogData.chatId)
        : 0;
    },
    progressClipPath() {
      const d = this.elementDuration;
      const t = this.elementCurrentTime;
      const percentage = ((d - t) / d) * 100;
      return `clip-path: inset(0 ${percentage || 100}% 0 0)`;
    },
  },
  watch: {
    text() {
      // If text was modified then we want to get a new
      // audio file.
      this.textChanged = true;
      if (!this.isPlaying && this.audioData) this.clearAudio();
    },
    async skipToTimestamp(value) {
      if (!this.audioData || !this.isPlaying) await this.btnHandler();
      this.$refs.audioEl.currentTime = value - this.startTimeOffset;
    },
    isPlaying() {
      if (!this.isPlaying && this.textChanged) this.clearAudio();
    },
  },
  async beforeDestroy() {
    if (this.isPlaying) {
      await this.togglePlay();
    }
  },
  methods: {
    ...mapActions('chatlogs', ['fetchAudio']),
    ...mapMutations('chatlogs', ['updatePlaybackTimestamp']),
    ...mapActions('botManipulation/activeBot', [
      'synthesizeText',
    ]),
    ...mapActions('sidebar', ['showWarning']),
    async fetchAndPlayAudio() {
      // If we already have a timeout going, we clear it. Also reset error status
      clearTimeout(this.spinnerTimeout);
      this.error = false;

      // If we're already fetching, we cancel the fetch.
      if (this.audioFetching !== null) throw this.clearAudio();

      this.spinnerTimeout = setTimeout(() => {
        if (this.audioFetching !== null) this.showSpinner = true;
      }, 200);

      this.audioFetching = axios.CancelToken.source();

      /*
        If we're given the chatlogsdata prop, we use fetchAudio
        If not, we assume we have 'text' and use synthesizeText instead
      */
      try {
        let response;
        if (this.chatLogData !== null) {
          const botId = this.activeBotId;
          const chatId = this.chatLogData.chatId;
          response = await this.fetchAudio({
            botId,
            chatId,
            cancelToken: this.audioFetching,
          });
        } else {
          response = await this.synthesizeText({
            botId: this.activeBotId,
            responseText: this.text,
          });
        }
        if (!response.error) {
          const base64audio = Buffer.from(response.data, 'binary').toString('base64');
          if (!this.small) this.generateWaveform(response.data);
          this.audioData = `data:${response.type};base64,${base64audio}`;
          this.textChanged = false;
        } else {
          this.showWarning({
            title: 'Failed to synthesize text',
            text: response.error,
            variant: 'danger',
          });
        }
      } catch (error) {
        this.error = true;
        if (axios.isCancel(error)) {
          console.log('Cancelled downloading audio.');
        } else {
          console.error(error);
        }
      }

      // We're done fetching and want to hide the spinner
      this.audioFetching = null;
      this.showSpinner = false;
    },
    async btnHandler() {
      // If 'text' has changed or we don't have any data, we need to fetch a new copy
      if (this.textChanged || !this.audioData) await this.fetchAndPlayAudio();
      else {
        await this.togglePlay();
        // If the small variant is used, the pause button will function as stop instead.
        if (this.small) this.$refs.audioEl.currentTime = 0;
      }
    },
    async togglePlay() {
      if (this.isPlaying) {
        this.$refs.audioEl.pause();
      } else {
        try {
          await this.$refs.audioEl.play();
        } catch (e) {
          this.showWarning({
            title: 'Audio playback failed',
            text: 'An error occurred while trying to play audio.',
            variant: 'error',
          });
        }
      }
    },
    updateCurrentTime() {
      const el = this.$refs.audioEl;
      if (el) {
        this.elementDuration = el.duration;
        this.elementCurrentTime = el.currentTime;
        this.isPlaying = !el.paused;
        if (this.chatLogData !== null) {
          this.updatePlaybackTimestamp({
            chatLogId: this.chatLogData.chatId,
            timestamp: this.startTimeOffset + this.elementCurrentTime,
          });
        }
      }
    },
    clearAudio() {
      this.audioData = null;
      if (this.audioFetching !== null) {
        this.audioFetching.cancel();
      }
      this.audioFetching = null;
    },
    generateWaveform(audio) {
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      audioContext.decodeAudioData(audio, (decoded) => {
        const canvas = this.$refs.canvas;
        const progressCanvas = this.$refs.progressCanvas;
        this.drawBuffer(
          canvas.width,
          canvas.height,
          [canvas.getContext('2d'), progressCanvas.getContext('2d')],
          decoded,
        );
      });
    },
    drawBuffer(width, height, contexts, buffer) {
      const channelCount = buffer.numberOfChannels;
      const left = buffer.getChannelData(0);
      let right;
      if (channelCount > 1) {
        right = buffer.getChannelData(1);
      }
      const step = Math.ceil(left.length / width);
      const amp = height / 2;
      // eslint-disable-next-line no-param-reassign
      contexts[0].fillStyle = '#ccc';
      // eslint-disable-next-line no-param-reassign
      contexts[1].fillStyle = '#0079a1';
      for (let i = 0; i < width; i++) {
        let max = 1.0;
        let min = -1.0;
        for (let j = 0; j < step; j++) {
          let data;
          if (channelCount > 1) data = left[(i * step) + j] + right[(i * step) + j];
          else data = left[(i * step) + j];
          if (data < max) { max = data; }
          if (data > min) { min = data; }
        }
        contexts[0].fillRect(i, (1 + max) * amp, 1, Math.max(1, (min - max) * amp));
        contexts[1].fillRect(i, (1 + max) * amp, 1, Math.max(1, (min - max) * amp));
      }
    },
    formatTime(seconds) {
      if (typeof seconds !== 'number') return '';
      const min = Math.floor(seconds / 60);
      const sec = Math.floor(seconds - (60 * min));
      return `${min > 9 ? '' : 0}${min}:${sec > 9 ? '' : 0}${sec}`;
    },
  },
};
</script>

<style scoped>
.linear {
  transition: all .3s linear;
}
</style>
