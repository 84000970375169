<template>
  <main role="main">
    <b-list-group>
      <b-list-group-item
        v-if="totalFinishedTests !== totalRunningTests"
        variant="info"
        class="r-25 health-alert"
      >
        <b-row class="mx-0 py-2 h-100">
          <b-col class="my-auto">
            <h4 class="my-auto">
              Running tests...
            </h4>
          </b-col>
        </b-row>
      </b-list-group-item>
      <b-list-group-item
        v-else
        :variant="getAlertVariant"
      >
        <b-row
          class="mx-0"
          role="tablist"
          align-h="between"
        >
          <b-col
            cols="*"
            class="my-auto"
          >
            <b-button
              v-b-tooltip.hover
              title="Run tests"
              variant="link"
              @click="runUnitTests"
            >
              <font-awesome-icon
                icon="redo"
                size="lg"
              />
            </b-button>
          </b-col>
          <b-col
            v-if="getRunningUnitTests"
            cols="*"
            class="my-auto"
          >
            <b-button
              v-b-tooltip.hover
              title="Clear testrun"
              variant="link"
              @click="clearUnitTests"
            >
              <font-awesome-icon
                icon="eraser"
                size="lg"
              />
            </b-button>
          </b-col>
          <b-col class="py-2">
            <h4
              v-if="totalFinishedTests === totalRunningTests && totalFailedTests === 0"
              class="my-1"
            >
              Finished {{ totalFinishedTests }} out of
              {{ totalRunningTests }} tests ({{ totalFailedTests }} failures).
            </h4>
            <h4
              v-else-if="totalFinishedTests === totalRunningTests && totalFailedTests > 0"
              class="my-1"
            >
              Finished {{ totalFinishedTests }} out of
              {{ totalRunningTests }} tests ({{ totalFailedTests }} failures).
            </h4>
            <h4
              v-else-if="getUnitTests.length === 0"
              class="my-1"
              variant="info"
            >
              There are no tests to run
            </h4>
            <h4
              v-else
              class="my-1"
              variant="info"
            >
              Run the {{ getUnitTests.length > 1 ? 'tests' : 'test' }} to see results.
            </h4>
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>

    <!-- Table for displaying all the unit tests -->
    <div
      class="mt-3 accordion"
      role="tablist"
    >
      <b-card
        v-for="(item, index) in getUnitTests"
        :key="index"
        no-body
        class="border"
      >
        <b-card-header
          header-tag="header"
          class="cursor-pointer p-2 health-header"
          role="tab"
          @click="rowClicked(item, index)"
        >
          <b-row
            role="tablist"
            class="mx-0"
          >
            <b-col
              cols="auto"
              class="my-auto px-1"
            >
              <b-button-group>
                <b-btn
                  variant="outline-info"
                  size="sm"
                  @click.stop="toggleUnitTestEnabled(index)"
                >
                  <font-awesome-icon
                    v-if="item.enabled"
                    icon="check-square"
                  />
                  <font-awesome-icon
                    v-else
                    icon="square"
                  />
                </b-btn>
                <b-btn
                  variant="outline-danger"
                  size="sm"
                  @click.stop="setDeleteUnittest(index)"
                >
                  <font-awesome-icon icon="trash-alt" />
                </b-btn>
                <b-btn
                  variant="outline-primary"
                  size="sm"
                  @click.stop="runSpecificUnitTest(index)"
                >
                  <font-awesome-icon icon="redo" />
                </b-btn>
              </b-button-group>
            </b-col>
            <b-col class="my-auto font-weight-bold break-text px-1">
              {{ item.title }}
            </b-col>
            <b-col
              cols="auto"
              class="px-1 my-auto"
            >
              <h6 class="my-2">
                <div v-if="areTestsRunning">
                  <span
                    v-if="item.key in getRunningUnitTestStatus.info.info
                      && !getRunningUnitTestStatus.info.info[item.key].finished"
                    class="text-primary"
                  >
                    <font-awesome-icon
                      icon="spinner"
                      spin
                    />
                  </span>
                  <span
                    v-if="item.key in getRunningUnitTestStatus.info.info
                      && getRunningUnitTestStatus.info.info[item.key].finished
                      && !getRunningUnitTestStatus.info.info[item.key].failed"
                    class="text-success"
                  >
                    <font-awesome-icon
                      :icon="['far', 'check-circle']"
                      size="lg"
                    />
                  </span>
                  <span
                    v-if="item.key in getRunningUnitTestStatus.info.info
                      && getRunningUnitTestStatus.info.info[item.key].finished
                      && getRunningUnitTestStatus.info.info[item.key].failed"
                    class="text-danger"
                  >
                    <font-awesome-icon
                      :icon="['far', 'times-circle']"
                      size="lg"
                    />
                  </span>
                </div>
              </h6>
            </b-col>
          </b-row>
        </b-card-header>
      </b-card>
    </div>
    <conversation-test-modal
      v-if="showUnitTestModal"
      :to-edit-unit-test-idx="toEditUnittestIdx"
      :to-show-unit-test="toShowUnittest"
      @hideModal="showUnitTestModal = false"
    />

    <b-modal
      id="deleteUnitTestModal"
      :title="'Delete ' + getDeleteUnittestName() + ' Conversation Test'"
      :auto-focus-button="'cancel'"
      ok-title="Delete"
      ok-variant="danger"
      @ok="proxyDeleteUnittest"
    >
      Are you sure you want to delete {{ getDeleteUnittestName() }} test?
    </b-modal>
  </main>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { validationMixin } from 'vuelidate';
import ConversationTestModal from '@/pages/Health/ConversationTests/ConversationTestModal.vue';

export default {
  name: 'ConversationTests',
  components: { ConversationTestModal },
  mixins: [validationMixin],
  data() {
    return {
      toDeleteUnittest: null,
      toShowUnittest: null,
      toEditUnittestIdx: null,
      unitTestFields: [
        {
          key: 'buttons',
          label: 'Actions',
          thClass: 'text-left',
          tdClass: 'text-left',
          thStyle: 'width:150px',
        },
        {
          key: 'titleTemplate',
          label: 'Title',
          tdClass: 'align-middle font-weight-bold',
        },
      ],
      renderEdit: 0,
      showUnitTestModal: false,
    };
  },
  computed: {
    ...mapGetters('botManipulation/activeBot/config', [
      'getBotName',
    ]),
    ...mapGetters('unitTest', [
      'getUnitTests',
      'getRunningUnitTests',
      'getRunningUnitTestStatus',
      'totalRunningTests',
      'totalFinishedTests',
      'totalFailedTests',
      'areTestsRunning',
    ]),
    getAlertVariant() {
      if (this.totalFinishedTests === this.totalRunningTests && this.totalFailedTests === 0) {
        return 'success';
      } if (this.totalFinishedTests === this.totalRunningTests && this.totalFailedTests > 0) {
        return 'danger';
      }
      return 'info';
    },
  },
  methods: {
    ...mapMutations('unitTest', [
      'setRunningUnitTests',
      'setRunningUnitTestStatus',
    ]),
    proxyEditUnittest() {
      this.$store.dispatch('unitTest/editUnitTest', {
        index: this.toEditUnittestIdx,
        title: this.toEditTitle,
        content: this.toEditContent,
        test_path: this.toEditTestPath,
        test_content: this.toEditTestContent,
      });
    },
    setDeleteUnittest(index) {
      this.toDeleteUnittest = index;
      this.$bvModal.show('deleteUnitTestModal');
    },
    getDeleteUnittestName() {
      if (this.toDeleteUnittest == null) {
        return 'N/A';
      }
      return this.getUnitTests[this.toDeleteUnittest].title;
    },
    proxyDeleteUnittest() {
      this.$store.dispatch('unitTest/deleteUnitTest', { index: this.toDeleteUnittest });
      this.toDeleteUnittest = null;
    },
    async runUnitTests() {
      this.$store.dispatch('unitTest/clearRunningTests');
      await this.$store.dispatch('unitTest/runUnitTests');
      this.$store.dispatch('unitTest/updateUnitTestStatus');
    },
    async runSpecificUnitTest(index) {
      const unitTest = this.getUnitTests[index];
      await this.$store.dispatch('unitTest/runSpecificUnitTest', unitTest);
      this.$store.dispatch('unitTest/updateUnitTestStatus');
    },
    clearUnitTests() {
      if (this.getRunningUnitTestStatus) {
        this.$store.dispatch('unitTest/terminateRunningTest');
      }
    },
    toggleUnitTestEnabled(index) {
      const unitTest = JSON.parse(JSON.stringify(this.getUnitTests[index]));
      unitTest.index = index;
      unitTest.enabled = !unitTest.enabled;
      this.$store.dispatch('unitTest/editUnitTest', unitTest);
    },
    rowClass(item) {
      if (item === null) {
        // This situation occurs when table shows empty-text
        return '';
      }
      if (!item.enabled) {
        return 'text-muted table-light';
      }
      return '';
    },
    rowClicked(item, index) {
      this.toShowUnittest = item;
      this.toEditUnittestIdx = index;
      this.$nextTick(() => {
        this.showUnitTestModal = true;
      });
    },
  },
};
</script>
