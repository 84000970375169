<template>
  <div>
    <p class="mb-2">
      Below is result nodes.
    </p>
    <node-list
      :nodes="nodeList"
    >
      <template #default="{ id, name }">
        <div
          class="d-flex justify-content-between"
        >
          {{ name }}
          <div>
            <b-button
              size="sm"
              variant="info"
              @click.stop.prevent="e=>nodeHistory(id, e)"
            >
              History
            </b-button>
            <b-button
              size="sm"
              variant="danger"
              @click.stop.prevent="ignoreNode(id)"
            >
              Ignore
            </b-button>
          </div>
        </div>
      </template>
    </node-list>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import NodeList from '@/components/NodeList.vue';
import { deepCopyJson } from 'supwiz/util/data';

export default {
  name: 'ExcludableNodeList',
  components: {
    NodeList,
  },
  props: {
    metricIdentifier: {
      required: true,
      type: String,
    },
    insightDetails: {
      required: true,
      type: Object,
    },
  },
  computed: {
    ...mapGetters('botManipulation', [
      'getBotId',
    ]),
    ...mapGetters('health', [
      'getConfigForMetric',
    ]),
    ...mapGetters('botManipulation/activeBot', [
      'nodeById',
    ]),
    nodeList() {
      return this.insightDetails.result_nodes.map((node) => this.nodeById(node.nodeId));
    },
    currentConfig() {
      return this.getConfigForMetric(this.getBotId, this.metricIdentifier) || {};
    },
  },
  methods: {
    ...mapActions('health', [
      'updateComputeConfig',
    ]),
    nodeHistory(id, event) {
      this.openCtrlLink({ name: 'history', hash: `#${id}` }, event);
    },
    ignoreNode(id) {
      const newConfig = {
        ...{ excluded_ids: [] },
        ...deepCopyJson(this.currentConfig),
      };
      newConfig.excluded_ids.push(id);
      newConfig.excluded_ids.sort();
      this.updateComputeConfig({
        metricIdentifier: this.metricIdentifier,
        newConfig,
      });
    },
  },
};
</script>

<style scoped>

</style>
