<template>
  <base-activity
    ref="baseActivity"
    :node-id="nodeId"
    :activity-id="activityId"
    :indent="indent"
    bs-variant="primary"
    :allow-expand="shouldAllowExpansion"
    :advanced-icon="advancedIcon"
    @shown="focusInput"
    @hidden="hideInput"
    @toggleExtraContents="toggleExtraContents"
  >
    <template #icon>
      <font-awesome-icon icon="wand-magic-sparkles" />
    </template>
    <template #header>
      {{ cappedActionName }} {{ actionId === 'sleep'
        ? `(${filteredParams[0].value} seconds)` : '' }}
    </template>
    <GenerateReply
      v-if="actionId === 'ai.generateReply'"
      :activity-id="activityId"
      :data="generateReplyData"
      :node-id="nodeId"
      :shown="shown"
      @input="v=>setParamFromDict(v)"
      @setParams="v=>setParams({ params: v })"
    />
    <ReformulateReply
      v-if="actionId === 'ai.formulateReply'"
      :data="formulateReplyData"
      :shown="shown"
      :node-id="nodeId"
      :activity-id="activityId"
      @input="v=>setParamFromDict(v)"
      @setParams="v=>setParams({ params: v })"
    />
    <b-card
      v-else-if="actionId === 'ai.generateSummary'"
      :body-class="graphView ? 'p-0 pr-2' : 'p-3'"
    >
      <b-row
        v-for="(param, index) in filteredParams"
        :key="param.key"
        show
        variant="secondary"
        class="mt-1"
      >
        <template v-if="graphView">
          <b-col cols="12" class="font-weight-bold">
            <label>
              {{ param.key }}:
            </label>
          </b-col>
          <b-col cols="12">
            <component
              :is="$options.componentMap[actionId]"
              v-if="param.key === 'fields'"
              :node-id="nodeId"
              :activity-id="activityId"
            />
            <botscript-validation
              v-else
              :value="param.value"
              :expanded="multilineParam[param.key]"
              :validations="getValidations(param.key)"
              @onChange="text => setParam(param.key, text, index)"
            />
          </b-col>
        </template>
        <template v-else>
          <b-col
            class=" font-weight-bold mt-2"
            :cols="[
              'ai.promptForm',
              'ai.generateSummary',
            ].includes(actionId) ? 2 : 5"
          >
            <label>
              {{ param.key }}:
            </label>
          </b-col>
          <b-col
            :cols="[
              'ai.promptForm',
              'ai.generateSummary',
            ].includes(actionId) ? 10 : 7"
          >
            <component
              :is="$options.componentMap[actionId]"
              v-if="param.key === 'fields'"
              :node-id="nodeId"
              :activity-id="activityId"
            />
            <botscript-validation
              v-else
              :value="param.value"
              :expanded="multilineParam[param.key]"
              :validations="getValidations(param.key)"
              @onChange="text => setParam(param.key, text, index)"
            />
          </b-col>
        </template>
      </b-row>
    </b-card>
    <ActivityFormGroup
      v-if="showExtraContents && hasMockResponse"
      label="Mock response"
    >
      <botscript-validation
        :value="getParam('mockResponse')"
        :expanded="false"
        :validations="['empty']"
        @onChange="value => setParam('mockResponse', value)"
      />
    </ActivityFormGroup>
    <ActivityFormGroup
      v-if="target !== null"
      label="Response variable"
      class="mt-1"
      label-class="font-weight-bold"
    >
      <VariableName
        :value="target"
        @input="target => setTarget({ target })"
      />
    </ActivityFormGroup>
  </base-activity>
</template>

<script>

import { mapGetters, mapMutations } from 'vuex';
import BaseActivity from '@/pages/EditNode/activity/BaseActivity.vue';
import VariableName from '@/components/VariableName.vue';
import { addThisArgs, applyThisArgs } from '@/js/storeHelpers';
import {
  truncateString, includeOrRemoveParams, changeOrAddParam,
} from '@/js/utils';
import generativeAIActions from '@/js/generativeAIActions';
import BotscriptValidation from '@/components/BotscriptValidation.vue';
import { platforms } from '@/js/constants';
import ChatForm from '@/pages/EditNode/activity/ChatForm.vue';
import GenerateReply from '@/pages/EditNode/activity/GenerateReply.vue';
import ReformulateReply from '@/pages/EditNode/activity/ReformulateReply.vue';
import ChatSummary from './ChatSummary.vue';
import ActivityFormGroup from './ActivityFormGroup.vue';

export default {
  name: 'GenerativeAIActivity',
  components: {
    BaseActivity,
    VariableName,
    BotscriptValidation,
    ChatForm,
    ChatSummary,
    GenerateReply,
    ReformulateReply,
    ActivityFormGroup,
  },
  props: {
    nodeId: {
      type: String,
      required: true,
    },
    activityId: {
      type: String,
      required: true,
    },
    indent: {
      type: Number,
      required: true,
    },
  },
  componentMap: {
    'ai.generateSummary': 'chat-summary',
  },
  data() {
    return {
      showExtraContents: false,
      shown: false,
      generateReplyFields: ['contextType', 'ranker', 'query', 'useCustomQuery', 'article', 'customContext',
        'includeWords', 'excludeWords', 'additionalContext', 'advanced', 'replyArticleThreshold',
        'replyGptGroundednessThreshold', 'replyJaccardSimThreshold', 'linksArticleThreshold',
        'replyWithLinks', 'replyWithLinksText',
        'replyAnswerRelevanceThreshold', 'replyEmbeddingSimThreshold',
        'linksText', 'customContextLink', 'customContextTitle', 'useFeedback',
        'customFallbackNode'],
      formulateReplyFields: ['additionalContext'],
    };
  },
  computed: {
    ...mapGetters('botManipulation/activeBot/config', ['getPlatforms']),
    ...applyThisArgs(mapGetters('botManipulation/activeBot', {
      target: 'activityTarget',
      name: 'activityName',
      params: 'activityParams',
      actionId: 'activityId',
    }), 'nodeId', 'activityId'),
    graphView() {
      return this.$route.name === 'graph';
    },
    generateReplyData() {
      return this.getDataForFields(this.generateReplyFields);
    },
    formulateReplyData() {
      return this.getDataForFields(this.formulateReplyFields);
    },
    defaultAction() {
      const defaultAction = generativeAIActions.find((a) => a.id === this.actionId);
      if (!defaultAction) {
        return generativeAIActions.find((a) => a.id === 'MISSING_AI_ACTION');
      }
      return defaultAction;
    },
    cappedActionName() {
      if (!this.target) {
        return truncateString(this.name, 40);
      }
      return truncateString(`${this.target} = ${this.name}`, 40);
    },
    advancedIcon() {
      if ((this.defaultAction.extraParams || []).length > 0) {
        return this.showExtraContents ? 'angle-up' : 'angle-down';
      }
      return '';
    },
    mockResponse() {
      return this.defaultAction.extraParams.find((p) => p.name === 'mockResponse');
    },
    hasMockResponse() {
      return this.mockResponse !== undefined;
    },
    normalParams() {
      // Old bots may be missing added attributes
      if (!this.defaultAction) {
        return this.params;
      }
      const allParams = this.params.slice();
      for (const pDefault of this.defaultAction.params) {
        if (!allParams.some((p) => p.key === pDefault.name)) {
          allParams.push({ key: pDefault.name, value: pDefault.default });
        }
      }
      return allParams;
    },
    shouldAllowExpansion() {
      return this.defaultAction.allowsExpansion;
    },
    multilineParam() {
      const ret = {};
      for (const item of this.defaultAction.params) {
        ret[item.name] = Boolean(item?.multiline);
      }
      return ret;
    },
    filteredParams() {
      return includeOrRemoveParams(this.normalParams,
        this.showExtraContents,
        this.defaultAction.extraParams);
    },
  },
  methods: {
    ...addThisArgs(mapMutations('botManipulation/activeBot', {
      setTarget: 'setActivityTarget',
      setParams: 'setActivityParams',
    }), { nodeId: 'nodeId', activityId: 'activityId' }),
    setParamFromDict(v) {
      this.setParam(v.param, v.value);
    },
    getDataForFields(fields) {
      const data = {};
      fields.forEach((field) => {
        data[field] = this.getParam(field);
      });
      return data;
    },
    getParam(key) {
      return this.filteredParams.find((e) => e.key === key)?.value;
    },
    setParam(key, value, indexHint = -1) {
      const newParams = changeOrAddParam(this.params, key, value, indexHint);
      this.setParams({ params: newParams });
    },
    focusInput() {
      const overlayElement = this.$refs.baseActivity?.$children[4]?.$children[0]?.$children[0];
      if (overlayElement && Object.prototype.hasOwnProperty.call(overlayElement, 'focus')) {
        overlayElement.focus();
      }
      this.shown = true;
    },
    hideInput() {
      this.shown = false;
    },
    focusActivity() {
      this.$refs.baseActivity.focusActivity();
    },
    toggleExtraContents() {
      this.showExtraContents = !this.showExtraContents;
    },
    getValidations(key) {
      const validations = ['empty', 'typecheck-nonbool'];
      if (key === 'tag') {
        validations.push('typecheck-tag');
        if (this.getPlatforms.includes(platforms.ZENDESK)) {
          validations.push('zendeskTag');
        }
      }
      return validations;
    },
  },
};

</script>
