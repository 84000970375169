<template>
  <div>
    <b-container fluid>
      <b-row
        v-if="!alwaysOpen"
        align-v="center"
        align-h="between"
        class="cursor-pointer"
        @click="showActivities = !showActivities"
      >
        <b-col cols="*">
          <h4>
            Activities
            <tooltipped-text
              value="What the node should do"
            />
          </h4>
          <p
            v-if="!showCollapse"
            class="subheader"
          >
            {{ subHeader }}
          </p>
        </b-col>
        <b-col cols="*">
          <h4>
            <span
              v-b-tooltip.hover
              title="Paste activity"
              @click.stop="presentPasteActivityModal"
            >
              <font-awesome-icon
                icon="paste"
                class="text-muted mr-2"
                size="sm"
              />
            </span>
            <span
              v-b-tooltip.hover
              title="Copy all activities"
              @click.stop="copyToPasteboard"
            >
              <font-awesome-icon
                icon="copy"
                class="text-muted mr-3"
                size="sm"
              />
            </span>
            <span
              v-if="!alwaysOpen"
              class="mr-2"
            >
              <font-awesome-icon
                :icon="showActivities ? 'angle-up' : 'angle-down'"
                size="sm"
              />
            </span>
          </h4>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col class="text-right pr-1">
          <h5>
            <span
              v-b-tooltip.hover.noninteractive.viewport
              title="Paste activity"
              @click.stop="presentPasteActivityModal"
            >
              <font-awesome-icon
                icon="paste"
                class="text-muted mr-2 cursor-pointer"
                size="sm"
              />
            </span>
            <span
              v-b-tooltip.hover.noninteractive.viewport
              title="Copy all activities"
              @click.stop="copyToPasteboard"
            >
              <font-awesome-icon
                icon="copy"
                class="text-muted cursor-pointer"
                size="sm"
              />
            </span>
          </h5>
        </b-col>
      </b-row>
    </b-container>

    <b-collapse
      id="collapseActivity"
      v-model="showCollapse"
    >
      <node-edit-activities-content
        :node-id="nodeId"
        :only-allow-new-responses="onlyAllowNewResponses"
      />
    </b-collapse>

    <paste-activity-modal
      :destination-node-id="nodeId"
    />
  </div>
</template>

<script>
import copy from 'clipboard-copy';
import { mapGetters, mapActions } from 'vuex';
import NodeEditActivitiesContent from '@/pages/EditNode/NodeEditActivitiesContent.vue';
import PasteActivityModal from '@/pages/EditNode/PasteActivityModal.vue';
import TooltippedText from '@/components/TooltippedText.vue';
import { applyThisArgs } from '@/js/storeHelpers';
import {
  ACTION,
  CHAT_ACTION,
  RESPONSE,
  SET_VARIABLE,
  CALL_MODEL,
  METRIC_SIGNAL,
  COMPOUND_RESPONSE,
  ENCRYPT,
  GENERATIVE_AI,
  SUP_WIZ_INTEGRATION,
} from '@/js/activity';

export default {
  name: 'NodeEditActivities',
  components: {
    TooltippedText,
    NodeEditActivitiesContent,
    PasteActivityModal,
  },
  props: {
    nodeId: {
      type: String,
      required: true,
    },
    onlyAllowNewResponses: {
      type: Boolean,
      default: false,
    },
    alwaysOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showActivities: false,
    };
  },
  computed: {
    ...applyThisArgs(mapGetters('botManipulation/activeBot', {
      activeNode: 'nodeById',
    }), 'nodeId'),
    ...mapGetters('userSettings', ['tooltipsOn']),
    showCollapse: {
      get() {
        return this.alwaysOpen ? this.alwaysOpen : this.showActivities;
      },
      set(v) {
        this.showActivities = v;
      },
    },
    subHeader() {
      const activityCount = Object.values(this.activeNode.activities)
        .filter((value) => value.type !== 'controlFlow').length;
      if (activityCount === 0) {
        return 'No activities';
      }
      const typeCount = {};
      Object.values(this.activeNode.activities).forEach((value) => {
        if (typeCount[value.type]) {
          typeCount[value.type]++;
        } else {
          typeCount[value.type] = 1;
        }
      });
      const typeNames = {
        [ACTION]: 'integration',
        [CHAT_ACTION]: 'platform action',
        [RESPONSE]: 'response',
        [SET_VARIABLE]: 'variable',
        [CALL_MODEL]: 'model call',
        [METRIC_SIGNAL]: 'metric signal',
        [COMPOUND_RESPONSE]: 'rich response',
        [ENCRYPT]: 'encryption',
        [GENERATIVE_AI]: 'generative AI action',
        [SUP_WIZ_INTEGRATION]: 'SupWiz integration',
      };
      const texts = [];
      // Notice that not all of the above names are listed in the ordered list of names
      // to show
      const typeOrder = [RESPONSE, COMPOUND_RESPONSE, SET_VARIABLE, ACTION, CHAT_ACTION,
        GENERATIVE_AI, SUP_WIZ_INTEGRATION];
      for (const actionType of typeOrder) {
        const count = typeCount[actionType];
        if (count === undefined || count === 0) {
          continue;
        }
        texts.push(`${count} ${typeNames[actionType]}${count > 1 ? 's' : ''}`);
      }
      return texts.join(',');
    },
  },
  methods: {
    ...mapActions('sidebar', ['showWarning']),
    presentPasteActivityModal() {
      this.showActivities = true;
      this.$bvModal.show('paste-activity-modal');
    },
    copyToPasteboard() {
      this.showActivities = true;
      copy(`${this.nodeId}`);
      this.showWarning({
        title: 'Activities copied',
        text: 'Activities copied to pasteboard.',
        variant: 'primary',
      });
    },
  },
};
</script>

<style scoped>
.subheader {
  font-size: smaller;
  margin: 2px;
}
</style>
