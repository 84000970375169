<template>
  <b-container class="pl-0 pr-4">
    <DescriptionBox>
      This activity will automatically generate a summary of the conversation so far.<br />
      In addition to the summary, you can use these extra "fields" to extract and highlight
      certain parts of the conversation either using AI or a variable or named entity.
      <ul class="mt-2 mb-0">
        <li>
          To use AI to extract data from the conversation transcript, select "AI data
          extraction" and provide a fitting name (e.g. "Topic" or "Username", etc.).
          The AI will use this name to extract the corresponding value from the conversation.
          <br />
          In addition you may provide additional context such as "Topic must be one of X,Y,Z" or
          "Username must be 3 letters followed by 3 digits".
        </li>
        <li class="mt-1">
          To append a value from a variable or named entity simply pick "BotScript value" as
          type.
        </li>
      </ul>em>
    </DescriptionBox>
    <draggable
      v-if="params.length"
      :value="params"
      role="tablist"
      filter=".ignore-elements"
      :prevent-on-filter="false"
      @input="params => changeOrder(params)"
    >
      <b-row
        v-for="(item, itemIndex) in params"
        :key="itemIndex"
        style="border-left: 1px solid #005F89"
        class="mb-3 pl-1"
        no-gutters
      >
        <b-col
          cols="auto"
          class="pr-1"
          style="margin-top:9px;"
        >
          <font-awesome-icon icon="up-down-left-right" />
        </b-col>

        <b-col class="ignore-elements">
          <b-button
            v-b-tooltip.hover.noninteractive.viewport
            style="position: absolute; right: -40px; top: 0px;"
            title="Delete element"
            @click="removeSummaryField(itemIndex)"
          >
            <font-awesome-icon icon="trash-alt" />
          </b-button>
          <b-input-group class="mb-2">
            <b-input-group-prepend :style="`width: ${indentationWidth}px`">
              <b-input-group-text class="w-100 justify-content-center">
                Type
              </b-input-group-text>
            </b-input-group-prepend>
            <b-form-select
              :value="item.type"
              :options="fieldTypes"
              @input="updateSummaryField(itemIndex, 'type', $event)"
            />
          </b-input-group>
          <b-input-group class="mb-2">
            <b-input-group-prepend :style="`width: ${indentationWidth}px;`">
              <b-input-group-text class="w-100 justify-content-center">
                Name
              </b-input-group-text>
            </b-input-group-prepend>
            <b-form-input
              :value="item.id"
              :state="!$v.params.$each[itemIndex].id.$invalid"
              placeholder="Enter a name for the field"
              @input="updateSummaryField(itemIndex, 'id', $event)"
            />
            <b-form-invalid-feedback>
              <template v-if="!$v.params.$each[itemIndex].id.isUnique">
                Name must be unique
              </template>
              <template v-else-if="!$v.params.$each[itemIndex].id.startsWithLetter">
                Name must start with a letter
              </template>
              <template v-else>
                Name is required
              </template>
            </b-form-invalid-feedback>
          </b-input-group>

          <b-input-group v-if="item.type === 'variable'" class="mb-2">
            <b-input-group-prepend :style="`width: ${indentationWidth}px;`">
              <b-input-group-text class="w-100 justify-content-center">
                Value
              </b-input-group-text>
            </b-input-group-prepend>
            <botscript-validation
              :value="item.text"
              :validations="['empty']"
              :wrapper-style="`width: calc(100% - ${indentationWidth}px) !important;`"
              input-style="border-top-left-radius: 0px; border-bottom-left-radius: 0px;"
              @onChange="updateSummaryField(itemIndex, 'text', $event)"
            />
          </b-input-group>
          <b-input-group v-else-if="item.type === 'prompt'" class="mb-2">
            <b-input-group-prepend :style="`width: ${indentationWidth}px;`">
              <b-input-group-text class="w-100 justify-content-center">
                Value
              </b-input-group-text>
            </b-input-group-prepend>
            <b-form-input
              :value="item.text"
              :state="!$v.params.$each[itemIndex].text.$invalid"
              :placeholder="'Optional description of how \'' + item.id + '\' should be generated'"
              @input="updateSummaryField(itemIndex, 'text', $event)"
            />
          </b-input-group>
        </b-col>
      </b-row>
    </draggable>
    <div>
      <small
        v-if="!$v.params.isRequiredLength"
        class="text-danger"
      >
        The number of options must be between 0 and 16
      </small>
    </div>
    <b-btn-group>
      <b-button
        variant="primary"
        @click="addSummaryField"
      >
        <font-awesome-icon icon="plus" />
        Add field
      </b-button>
    </b-btn-group>
  </b-container>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import Draggable from 'vuedraggable';
import { validationMixin } from 'vuelidate';
import {
  required,
} from 'vuelidate/lib/validators';
import { cloneDeep } from 'lodash';
import { addThisArgs, applyThisArgs } from '@/js/storeHelpers';
import BotscriptValidation from '@/components/BotscriptValidation.vue';
import DescriptionBox from '@/pages/EditNode/activity/DescriptionBox.vue';

export default {
  name: 'ChatSummary',
  components: {
    Draggable,
    BotscriptValidation,
    DescriptionBox,
  },
  mixins: [validationMixin],
  props: {
    nodeId: {
      type: String,
      required: true,
    },
    activityId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      indentationWidth: 70,
      param: 'fields',
    };
  },
  computed: {
    ...applyThisArgs(mapGetters('botManipulation/activeBot', {
      activityGenerativeAIParams: 'activityParams',
    }), 'nodeId', 'activityId'),
    fieldTypes() {
      /*
        summary: this should generate a summary of the chat
        prompt: here the user can enter a prompt like "list all ticket ids sent by the user"
        variable: just grabs and outputs a state variable
      */
      return [
        { value: 'prompt', text: 'AI data extraction' },
        { value: 'variable', text: 'BotScript value' },
      ];
    },
    params: {
      get() {
        return this.activityGenerativeAIParams[this.paramIndex].value;
      },
      set(v) {
        this.setChatSummaryParams({
          index: this.paramIndex,
          params: v,
          activityId: this.activityId,
        });
      },
    },
    paramIndex() {
      return this.activityGenerativeAIParams.findIndex((e) => e.key === this.param);
    },
  },
  methods: {
    ...addThisArgs(mapMutations('botManipulation/activeBot', [
      'setChatSummaryParams',
    ]), { nodeId: 'nodeId', param: 'paramIndex' }),
    changeOrder(params) {
      this.params = params;
    },
    addSummaryField() {
      this.params = this.params.concat({
        id: '',
        text: '',
        type: 'prompt',
      });
    },
    removeSummaryField(index) {
      this.params = this.params.filter((e) => this.params.indexOf(e) !== index);
    },
    updateSummaryField(index, type, value) {
      const copy = cloneDeep(this.params);
      copy[index][type] = value;
      this.params = copy;
    },
  },
  validations() {
    return {
      params: {
        isRequiredLength(params) {
          return params.length >= 0 && params.length <= 16;
        },
        $each: {
          id: {
            isUnique(value) {
              return this.params.filter((x) => x.id === value).length < 2;
            },
            startsWithLetter(value) {
              return !!value.match(/^[A-Za-z]/);
            },
            required,
          },
          text: { },
        },
      },
    };
  },
};
</script>
<style scoped>
::v-deep .form-control{
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
::v-deep .input-group-text, ::v-deep .input-group-append,
::v-deep .custom-select, ::v-deep .form-control{
  height: 37px !important;
}
</style>
