<template>
  <b-card
    no-body
    class="border"
  >
    <b-card-header
      header-tag="header"
      class="cursor-pointer p-2 health-header"
      role="tab"
      @click="visible = !visible"
    >
      <b-row
        role="tablist"
        align-h="between"
        class="mx-0"
      >
        <b-col
          cols="*"
        >
          <span>
            <b-button
              v-b-tooltip.hover
              :disabled="metricComputationInProgress || metricIsCurrentlyDisabled"
              variant="link"
              title="Recompute metric"
              @click.stop="recomputeAllMetrics([metricIdentifier])"
            >
              <font-awesome-icon
                v-if="metricComputationInProgress"
                icon="spinner"
                spin
              />
              <font-awesome-icon
                v-else
                icon="redo"
                :color="metricIsCurrentlyDisabled ? 'gray' : ''"
              />
            </b-button>

            {{ displayName }}
          </span>
        </b-col>
        <b-col
          cols="*"
          class="text-right my-auto pr-1"
        >
          <!-- Metric does not apply to / is not relevant for this bot-->
          <span
            v-if="!metricIsApplicableForBot"
            v-b-tooltip.hover
            title="Check not applicable for this bot"
          >
            <font-awesome-icon
              icon="parking"
              size="lg"
            />
          </span>
          <!-- Metric failed to compute altogether-->
          <span
            v-else-if="metricComputationFailed"
            v-b-tooltip.hover
            title="Failed to compute"
          >
            <font-awesome-icon
              icon="exclamation-circle"
            />
          </span>
          <!-- Metric was computed succesfully and metric was satisfied -->
          <span
            v-else-if="!progressIsNull && metricIsSatisfied"
            v-b-tooltip.hover
            title="No remarks"
          >
            <font-awesome-icon

              :icon="['far', 'check-circle']"
              :color="metricIsCurrentlyDisabled ? 'gray' : 'green'"
              size="lg"
            />
          </span>
          <!-- Metric was computed succesfully but metric was not satisfied -->
          <span
            v-else-if="!metricComputationInProgress
              && !progressIsNull && metricIsSatisfied === false"
            v-b-tooltip.hover
            title="You should look into this"
          >
            <font-awesome-icon
              icon="exclamation-circle"
              :color="getPriorityColor"
              size="lg"
            />
          </span>
        </b-col>
      </b-row>
    </b-card-header>
    <b-collapse
      :id="metricIdentifier"
      v-model="visible"
      accordion="my-accordion"
      role="tabpanel"
    >
      <b-card-body>
        <b-card-text>
          <div
            v-if="!metricComputationFailed && computeTimestampDisplayValue !== null"
            class="mb-3"
          >
            Computed
            <span
              v-b-tooltip.hover
              :title="computeTimestampAsDateObject"
            >{{ computeTimestampDisplayValue }}</span>
          </div>
          <div
            v-else-if="metricComputationFailed"
            class="mb-3"
          >
            Metric failed to compute.
          </div>
          <div
            v-else-if="metricComputationInProgress"
            class="mb-3"
          >
            Awaiting results...
          </div>
          <div
            v-else-if="metricIsSatisfied"
            class="mb-3"
          >
            <font-awesome-icon
              :icon="['far', 'check-circle']"
              color="green"
            />
            You're all good!
          </div>

          <!-- The description is a textual description of the computation, independent of the
        eventual result of the computation -->
          <div>
            {{ description }}
          </div>
          <simple-result
            v-if="metricComputationSucceeded && metricDetails !== null"
            class="mt-3"
            :metric-identifier="metricIdentifier"
            :insight-details="metricDetails"
          />

          <!-- The resolution gives users practical advice on what they should do to resolve
            the issue. The resolution is only shown when metric is not satisfied.
            Add your metric-specific resolution-pointers to the HealthMetricHowToResolve
            component
          -->

          <health-metric-how-to-resolve-vue
            v-if="!metricComputationFailed && !metricIsSatisfied && metricIsApplicableForBot"
            class="mt-3"
            :metric-identifier="metricIdentifier"
            :insight-details="metricDetails || {}"
          />

          <!-- If metric does not apply to this bot AND backend provided a description why
          (that is, provided a metric_not_applicable_reason field on result) -->
          <p
            v-if="!metricIsApplicableForBot && notApplicableReason !== null"
            class="my-3"
          >
            Why does this check not apply to this bot?<br>
            {{ notApplicableReason }}
          </p>
        </b-card-text>
        <template
          v-if="metricAllowsForComputeCustomization"
        >
          <div
            class="mt-3 cursor-pointer"
            @click="configVisible = !configVisible"
          >
            <font-awesome-icon
              :icon="configVisible ? 'angle-down' : 'angle-right'"
            />
            Settings
          </div>

          <b-collapse
            v-model="configVisible"
          >
            <health-metric-config
              :metric-identifier="metricIdentifier"
              :current-config="metricComputeConfig"
            />
          </b-collapse>
        </template>
        <b-button
          variant="warning"
          class="mt-2"
          @click="disableMetricProxy"
        >
          Disable check
        </b-button>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import SupportedMetrics from '@/js/healthConfig';
import { ComputationTaskStatus } from '@/js/constants';
import HealthMetricHowToResolveVue from '@/pages/Health/HealthMetricHowToResolve.vue';
import SimpleResult from '@/pages/Health/Results/SimpleResult.vue';
import HealthMetricConfig from '@/pages/Health/HealthMetricConfig.vue';

export default {
  name: 'HealthMetric',
  components: {
    HealthMetricHowToResolveVue,
    SimpleResult,
    HealthMetricConfig,
  },
  props: {
    metricIdentifier: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      displayName: null,
      description: null,
      metricAllowsForComputeCustomization: false,
      visible: false,
      configVisible: false,
      priorityColorMap: {
        1: '#E74646',
        2: '#FFA500',
        3: '#73BBC9',
      },
    };
  },
  computed: {
    ...mapGetters('botManipulation', [
      'getBotId',
    ]),
    ...mapGetters('health', {
      metricComputeConfig: 'getHealthConfigForBot',
      getMetricIsCurrentlyDisabled: 'metricIsCurrentlyDisabled',
      getTaskProgress: 'getTaskProgress',
      getMetricResult: 'getMetricResult',
      getMetricResponse: 'getMetricResponse',
    }),
    ...mapGetters('userSettings', ['tooltipsOn']),
    getPriorityColor() {
      return this.metricIsCurrentlyDisabled ? 'gray' : this.priorityColorMap[SupportedMetrics[this.metricIdentifier].priority];
    },
    metricDetails() {
      const metricResult = this.getMetricResult(this.getBotId, this.metricIdentifier);
      if (metricResult === null) {
        return null;
      }
      if (metricResult.details === undefined) {
        return null;
      }
      return metricResult.details;
    },
    computeTimestamp() {
      const metricResult = this.getMetricResponse(this.getBotId, this.metricIdentifier);

      if (metricResult === null) {
        return null;
      }
      if (metricResult.computed_timestamp === undefined) {
        return null;
      }
      return metricResult.computed_timestamp;
    },
    computeTimestampAsDateObject() {
      const rawTimestamp = this.computeTimestamp;
      if (rawTimestamp === null) {
        return null;
      }
      return moment(new Date(rawTimestamp)).format('dddd, MMMM Do YYYY, h:mm:ss a');
    },
    metricIsCurrentlyDisabled() {
      return this.getMetricIsCurrentlyDisabled(this.getBotId, this.metricIdentifier);
    },
    currentMetricProgres() {
      return this.getTaskProgress(this.getBotId, this.metricIdentifier);
    },
    metricComputationSucceeded() {
      return ComputationTaskStatus.SUCCEEDED === this.currentMetricProgres;
    },
    metricComputationFailed() {
      return ComputationTaskStatus.FAILED === this.currentMetricProgres;
    },
    metricComputationInProgress() {
      return this.currentMetricProgres !== ComputationTaskStatus.SUCCEEDED
      && this.currentMetricProgres !== ComputationTaskStatus.FAILED;
    },
    metricIsNotApplicableOnBot() {
      return true;
    },
    computeTimestampDisplayValue() {
      const computeTimestamp = this.computeTimestamp;
      if (computeTimestamp === null) {
        return null;
      }

      const now = moment();
      const timestamp = moment(new Date(computeTimestamp));
      return moment.duration(timestamp.diff(now)).humanize(true);
    },
    metricIsSatisfied() {
      const metricResult = this.getMetricResult(this.getBotId, this.metricIdentifier);
      if (metricResult === null) {
        return false;
      }
      return metricResult?.metric_satisfied === true;
    },
    progressIsNull() {
      return this.getTaskProgress(this.getBotId, this.metricIdentifier) === null;
    },
    metricIsApplicableForBot() {
      if (this.progressIsNull) {
        return true;
      }
      const metricResult = this.getMetricResult(this.getBotId, this.metricIdentifier);
      if (metricResult === null) {
        return false;
      }
      if (metricResult?.metric_applicable === undefined) {
        // Not all computations provide a metric_applicable result. In such case, it is implied
        // that the computation _is_ applicable to bot.
        return true;
      }
      return metricResult.metric_applicable;
    },
    /**
    * The notApplicableReason is an optional field provided by backend, that we're simply displaying
    * as-is
    */
    notApplicableReason() {
      if (this.progressIsNull) {
        return null;
      }
      const metricResult = this.getMetricResult(this.getBotId, this.metricIdentifier);
      if (metricResult === null) {
        return null;
      }
      if (metricResult.metric_not_applicable_reason === undefined) {
        // Not all computations provide a metric_applicable result. In such case, it is implied
        // that the computation _is_ applicable to bot.
        return null;
      }
      return metricResult.metric_not_applicable_reason;
    },
  },
  async mounted() {
    // Configure component with already-known info about this metric.
    this.displayName = SupportedMetrics[this.metricIdentifier].displayName;
    this.description = SupportedMetrics[this.metricIdentifier].description;
    this.comments = SupportedMetrics[this.metricIdentifier].comments;
    this.metricAllowsForComputeCustomization = SupportedMetrics[this.metricIdentifier]
      .allowsForComputeCustomization;
  },
  methods: {
    ...mapActions('health', [
      'disableMetric',
      'enableMetric',
      'recomputeAllMetrics',
    ]),
    enableMetricProxy() {
      this.enableMetric({ metricIdentifier: this.metricIdentifier });
    },
    disableMetricProxy() {
      this.disableMetric({ metricIdentifier: this.metricIdentifier });
    },
  },
};
</script>
<style scoped>
.metric-content{
  font-size: 0.9375rem;
  font-weight: 400;
}
</style>
