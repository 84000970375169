import axios from 'axios';
import { cloneDeep } from 'lodash';
import endpoints from '@/js/urls';

const initialState = {
  optOutNodes: [],
  flowNodes: [],
  transferNodes: [],
};

const getters = {
  optOutNodes(state) {
    return state.optOutNodes;
  },
  flowNodes(state) {
    return state.flowNodes;
  },
  transferNodes(state) {
    return state.transferNodes;
  },
  isFlowNode: (state) => (id) => state.flowNodes.includes(id),
};

const mutations = {
  setOptOutNodes(state, payload) {
    state.optOutNodes = payload.nodeIds;
  },
  setFlowNodes(state, payload) {
    state.flowNodes = payload.nodeIds;
  },
  setTransferNodes(state, payload) {
    state.transferNodes = payload.nodeIds;
  },
};

const actions = {
  async handleDeletionOfNode({ state, dispatch }, nodeId) {
    /**
     * If a node A is deleted, we check if A was referenced as an interpreted node.
     * If yes, we clean up (internally and in backend)
     */
    if (state.optOutNodes.includes(nodeId)) {
      const newListOfOptOutNodes = state.optOutNodes.filter((x) => x !== nodeId);
      dispatch('updateOptOutNodes', { nodeIds: newListOfOptOutNodes });
    }
    if (state.flowNodes.includes(nodeId)) {
      const newListOfFlowNodes = state.flowNodes.filter((x) => x !== nodeId);
      dispatch('updateFlowNodes', { nodeIds: newListOfFlowNodes });
    }
    if (state.transferNodes.includes(nodeId)) {
      const newListOfTransferNodes = state.transferNodes.filter((x) => x !== nodeId);
      dispatch('updateTransferNodes', { nodeIds: newListOfTransferNodes });
    }
  },
  async updateOptOutNodes({ rootState, commit }, payload) {
    axios.put(endpoints.nodeInterpretations,
      {
        bot_id: rootState.botManipulation.activeBotId,
        node_ids: payload.nodeIds,
        type: 'optout',
      },
      {
        headers: {
          Authorization: `JWT ${rootState.auth.jwt}`,
        },
      }).then((response) => {
      if (response.status === 200) {
        commit('setOptOutNodes', { nodeIds: payload.nodeIds });
      }
    });
  },
  async toggleFlowNode({ state, dispatch }, id) {
    const nodeIds = cloneDeep(state.flowNodes);
    if (nodeIds.includes(id)) {
      nodeIds.splice(nodeIds.indexOf(id), 1);
    } else {
      nodeIds.push(id);
    }
    dispatch('updateFlowNodes', { nodeIds });
  },
  async updateFlowNodes({ rootState, commit }, payload) {
    axios.put(endpoints.nodeInterpretations,
      {
        bot_id: rootState.botManipulation.activeBotId,
        node_ids: payload.nodeIds,
        type: 'flow',
      },
      {
        headers: {
          Authorization: `JWT ${rootState.auth.jwt}`,
        },
      }).then((response) => {
      if (response.status === 200) {
        commit('setFlowNodes', { nodeIds: payload.nodeIds });
      }
    });
  },
  async updateTransferNodes({ rootState, commit }, payload) {
    axios.put(endpoints.nodeInterpretations,
      {
        bot_id: rootState.botManipulation.activeBotId,
        node_ids: payload.nodeIds,
        type: 'transfer',
      },
      {
        headers: {
          Authorization: `JWT ${rootState.auth.jwt}`,
        },
      }).then((response) => {
      if (response.status === 200) {
        commit('setTransferNodes', { nodeIds: payload.nodeIds });
      }
    });
  },
  async fetchNodeInterpretations({ rootState, commit }) {
    axios.get(endpoints.nodeInterpretations,
      {
        params: { bot_id: rootState.botManipulation.activeBotId },
        headers: {
          Authorization: `JWT ${rootState.auth.jwt}`,
        },
      }).then((response) => {
      if (response.status === 200) {
        const nodeInterps = response.data.result;
        if ('transfer' in nodeInterps) {
          commit('setTransferNodes', { nodeIds: nodeInterps.transfer });
        }
        if ('flow' in nodeInterps) {
          commit('setFlowNodes', { nodeIds: nodeInterps.flow });
        }
        if ('optout' in nodeInterps) {
          commit('setOptOutNodes', { nodeIds: nodeInterps.optout });
        }
      }
    });
  },
};

export default {
  namespaced: true,
  state: () => initialState,
  getters,
  mutations,
  actions,
};
