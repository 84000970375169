<template>
  <base-activity
    ref="baseActivity"
    :node-id="nodeId"
    :activity-id="activityId"
    :indent="indent"
    bs-variant="warning"
    @shown="focusInput"
  >
    <template #icon>
      <font-awesome-icon icon="key" />
    </template>
    <template #header>
      {{ cappedAssignment }}
    </template>
    <b-row>
      <b-col
        cols="5"
        :class="textClasses"
      >
        <label>
          Encryption key:
        </label>
      </b-col>
      <b-col cols="7">
        <SecretSelector
          :value="key"
          @input="value => setActivityEncryptKey({ key: value })"
        />
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col
        cols="5"
        :class="textClasses"
      >
        <label>
          Code to encrypt:
        </label>
      </b-col>
      <b-col cols="7">
        <botscript-validation
          :expanded="code.length > 24"
          :value="code"
          :validations="['empty', 'typecheck-str']"
          @onChange="value => setActivityEncryptCode({ code: value })"
        />
      </b-col>
    </b-row>
    <hr class="separator">
    <b-row class="mt-1">
      <b-col
        cols="5"
        :class="textClasses"
      >
        <label>
          Variable name:
        </label>
      </b-col>
      <b-col cols="7">
        <VariableName
          ref="targetRef"
          :value="target"
          placeholder="Variable name"
          class="mb-2 mb-sm-0"
          @input="value => setActivityEncryptTarget({ target: value })"
        />
      </b-col>
    </b-row>
  </base-activity>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import BaseActivity from '@/pages/EditNode/activity/BaseActivity.vue';
import BotscriptValidation from '@/components/BotscriptValidation.vue';
import VariableName from '@/components/VariableName.vue';
import SecretSelector from '@/components/SecretSelector.vue';
import { addThisArgs, applyThisArgs } from '@/js/storeHelpers';
import {
  truncateString,
} from '@/js/utils';

export default {
  name: 'EncryptActivity',
  components: {
    BaseActivity,
    BotscriptValidation,
    SecretSelector,
    VariableName,
  },
  props: {
    nodeId: {
      type: String,
      required: true,
    },
    activityId: {
      type: String,
      required: true,
    },
    indent: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      textClasses: ['text-right', 'font-weight-bold', 'mt-2'],
    };
  },
  computed: {
    ...mapGetters('botManipulation/activeBot/config', [
      'isMainBot',
    ]),
    ...applyThisArgs(mapGetters('botManipulation/activeBot', {
      code: 'activityCode',
      key: 'activityKey',
      target: 'activityTarget',
    }), 'nodeId', 'activityId'),
    cappedAssignment() {
      return truncateString(`${this.target} = Encrypt(${this.code})`, 60);
    },
  },
  methods: {
    ...addThisArgs(mapMutations('botManipulation/activeBot', {
      setActivityEncryptCode: 'setActivityCode',
      setActivityEncryptKey: 'setActivityKey',
      setActivityEncryptTarget: 'setActivityTarget',
    }), { nodeId: 'nodeId', activityId: 'activityId' }),
    focusInput() {
      if (this.$refs.targetRef?.$children[0]) {
        this.$refs.targetRef.$children[0].focus();
      }
    },
    focusActivity() {
      this.$refs.baseActivity.focusActivity();
    },
  },
};
</script>
