// Parses botscript, a variant subset of Python expressions

let keywords = ["if", "else"]
let constants = ["True", "False", "None"] // TODO check allowed constants
let namedOps = ["and", "or", "is", "in", "not"]

export class ParsingError {
    // startChar is inclusive, endChar is exclusive
    constructor(startChar, endChar, msg) {
        this.startChar = startChar;
        this.endChar = endChar;
        this.msg = msg;
    }
}
export class Token {
    // startChar is inclusive, endChar is exclusive
    constructor(startChar, endChar, type, content) {
        this.startChar = startChar;
        this.endChar = endChar;
        this.type = type;
        this.content = content;
    }
}

function isWS(char) {
    return char === ' '; // TODO Other whitespace
}
function isNum(char) {
    return char >= '0' && char <= '9';
}
function isHex(char) {
    return (char >= '0' && char <= '9') || (char >= 'a' && char <= 'f') || (char >= 'A' && char <= 'F');
}
function isIden(char) {
    return (char >= 'a' && char <= 'z') || (char >= 'A' && char <= 'Z') || char === '_'; // TODO check allowed identifier symbs
}
function isOp(char) {
    return "+-*/%|&!:<>=.".indexOf(char) !== -1;
}
function isQuote(char) {
    return char === '\'' || char === '"';
}
function isRawQuote(begin, text) {
    return text.startsWith("r'", begin) || text.startsWith('r"', begin);
}
function isMultiLineQuote(begin, text) {
    return text.startsWith("'''", begin) || text.startsWith('"""', begin);
}
function lexNumber(begin, text, tokens, errors) {
    let i = begin;
    let num = "";
    for (; i < text.length && isNum(text.charAt(i)); i++) {
        num += text.charAt(i);
    }
    if (i < text.length && text.charAt(i) === '.') {
        num += text.charAt(i);
        i++;
        for (; i < text.length && isNum(text.charAt(i)); i++) {
            num += text.charAt(i);
        }
    }
    if (isIden(text.charAt(i))) {
        let id = [];
        lexIden(i, text, id, []);
        let sep = id[0].type === 'keyword'? "space" : "comma";
        errors.push(new ParsingError(i, i+1, "Unexpected " + id[0].type + " '" + id[0].content + "' attached to number " + num + " - did you forget a separator, such as a " + sep + "?"));
    }
    tokens.push(new Token(begin, i, "number", num));
    return i;
}
// eslint-disable-next-line no-unused-vars
function lexIden(begin, text, tokens, errors) {
    let i = begin;
    let iden = "";
    for (; i < text.length && (isIden(text.charAt(i)) || isNum(text.charAt(i))); i++) {
        iden += text.charAt(i);
    }
    if (namedOps.indexOf(iden) !== -1) {
        tokens.push(new Token(begin, i, "operator", iden));
    }
    else if (keywords.indexOf(iden) !== -1) {
        tokens.push(new Token(begin, i, "keyword", iden));
    }
    else if (constants.indexOf(iden) !== -1) {
        tokens.push(new Token(begin, i, "constant", iden));
    }
    else {
        tokens.push(new Token(begin, i, "identifier", iden));
    }
    return i;
}
// eslint-disable-next-line no-unused-vars
function lexOp(begin, text, tokens, errors) {
    let i = begin;
    let op = "";
    for (; i < text.length && isOp(text.charAt(i)); i++) {
        op += text.charAt(i);
    }
    tokens.push(new Token(begin, i, "operator", op));
    return i;
}
function lexQuote(begin, text, tokens, errors) {
    let i = begin;
    let q = text.charAt(i);
    let raw = false;
    if (q === 'r') {
        i++;
        raw = true;
        q = text.charAt(i);
    }
    const multiLine = isMultiLineQuote(i, text);
    if (multiLine) {
        i += 2;
    }
    i++;
    let str = "";
    for (; i < text.length && q !== text.charAt(i); i++) {
        const char = text.charAt(i);
        if (char !== '\\') {
            if (char === '\n' && !multiLine) {
                errors.push(new ParsingError(i, i, `Unexpected newline in string ${q + str}. Convert to multi-line string using triple-quotes: ${q + q + q}`));
            }
            str += char;
        }
        else if (raw) {
            if (i + 1 < text.length && text.charAt(i + 1) === q) {
                i++;
                str += q;
            }
            else {
                str += char;
            }
        }
        else {
            i++;
            const unicodeEscapes = {'x': 2, 'u': 4, 'U': 8};
            if (i === text.length) {
                break;
            }
            let esc = text.charAt(i);
            if (esc === 'n') {
                str += '\n';
            }
            else if (['\'', '"', '\\'].indexOf(esc) !== -1) {
                str += esc;
            }
            else if (esc in unicodeEscapes) {
                let escCode = "";
                let len = unicodeEscapes[esc];
                for (let j = 0; j < len; j++) {
                    i++;
                    if (i < text.length && isHex(text.charAt(i))) {
                        escCode += text.charAt(i);
                    }
                    else {
                        errors.push(new ParsingError(i-j, i+1, "Expected " + len + " hex digits after '\\" + esc + "' escape"));
                        break;
                    }
                }
                str += String.fromCharCode(Number.parseInt(escCode,16));
            }
            else {
                // TODO figure out escapes to allow
                errors.push(new ParsingError(i-1, i+1, "Unsupported escape code '\\" + text.charAt(i) + "'"));
            }
        }
    }
    if (multiLine) {
        if (!isMultiLineQuote(i, text)) {
            errors.push(new ParsingError(i, i, "Imbalanced multiline quotes - missing characters " + q + " for string " + q + str + q));
        }
        i += 2;
    }
    if (i === text.length) {
        errors.push(new ParsingError(i, i, "Imbalanced quotes - missing unquote character " + q + " for string " + q + str + q));
    }
    else {
        i++;
    }
    tokens.push(new Token(begin, i, "quote", str));
    return i;
}
let parens = [["paren", "(", ")"], ["map", "{", "}"], ["list", "[", "]"]]
export function lex(text, errors) {
    let tokens = [];
    // if we run into an unexpected character, we will report an error for that character, but try to lex the rest.
    // but we don't want to just keep reporting errors for each character upon encountering an unexpected one; instead
    // we want to wait until we find something that isn't unexpected. to do this, we keep track of whether we have
    // found a new token since we last got an unexpected error, by saving the length of the token array in 'unexpected'.
    let unexpected = -1;
    for (let i = 0; i < text.length;) {
        while (i < text.length && isWS(text.charAt(i))) {
            i++
        }
        if (i === text.length) break;
        if (isNum(text.charAt(i))) {
            i = lexNumber(i, text, tokens, errors);
        }
        else if (isRawQuote(i, text)) {
            i = lexQuote(i, text, tokens, errors);
        }
        else if (isIden(text.charAt(i))) {
            i = lexIden(i, text, tokens, errors);
        }
        else if (isOp(text.charAt(i))) {
            i = lexOp(i, text, tokens, errors);
        }
        else if (isQuote(text.charAt(i))) {
            i = lexQuote(i, text, tokens, errors);
        }
        else if (text.charAt(i) === ',') {
            tokens.push(new Token(i, ++i, "keyword", ","));
        }
        else {
            let isParen = false;
            for (let [kind, open, close] of parens) {
                if (text.charAt(i) === open) {
                    tokens.push(new Token(i, ++i, "open " + kind, open));
                    isParen = true; break;
                }
                else if (text.charAt(i) === close) {
                    tokens.push(new Token(i, ++i, "close " + kind, close));
                    isParen = true; break;
                }
            }
            if (!isParen) {
                if (unexpected < tokens.length) {
                    const char = text.charAt(i);
                    let charString = "character '" + char + "'";
                    if (char === '\n') charString = "New Line";
                    errors.push(new ParsingError(i, ++i, "Unexpected " + charString));
                    unexpected = tokens.length;
                }
                else {
                    let lastError = errors[errors.length-1];
                    lastError.charEnd = ++i;
                }
            }
        }
    }
    tokens.push(new Token(text.length, text.length, "EOF", ""));
    return tokens;
}

export function prettyPrintTokens(text) {
    const parenStack = [];
    const paramStack = [];
    const tokens = lex(text, []);
    let ppText = '';
    for (const token of tokens) {
        if (token.type.startsWith("open ")) {
            const indent = ppText.length - ppText.lastIndexOf('\n');
            parenStack.push(indent);
            paramStack.push(0);
            ppText += token.content;
            continue;
        }
        else if (token.type.startsWith("close ")) {
            const indent = parenStack.pop() || 1;
            const params = paramStack.pop() || 0;
            if (params > 0) {
                ppText += '\n' + ' '.repeat(indent);
            }
            ppText += token.content;
            continue;
        }
        else if (token.type === "keyword" && token.content === ',') {
            const indent = parenStack[parenStack.length - 1] || 1;
            if (paramStack[paramStack.length - 1] !== undefined) {
                paramStack[paramStack.length - 1] += 1;
            }
            ppText += token.content + '\n' + ' '.repeat(indent);
            continue;
        } else if (token.type === "quote") {
            ppText += '\"' + token.content +  '\"';
            continue;
        }
        else if (token.type === "operator") {
            ppText += ' ' + token.content +  ' ';
            continue;
        }

        ppText += token.content;
    }
    ppText += '\n';
    return ppText;
}

export class Visitor {
    // default visit function returns a list of results from visiting each subexpression
    defaultCombine(subexprs) {
        return subexprs;
    }
    visitBinaryOperator(operatorType, left, right, context) {
        return this.defaultCombine([left.visit(this, context), right.visit(this, context)]);
    }
    visitUnaryOperator(operatorType, body, context) {
        return this.defaultCombine([body.visit(this, context)]);
    }
    visitIfElse(body, condition, branch, context) {
        return this.defaultCombine([body.visit(this, context), condition.visit(this, context), branch.visit(this, context)]);
    }
    visitMulti(parts, context) {
        let results = [];
        for (let part of parts) {
            results.push(part.visit(this, context));
        }
        return this.defaultCombine(results);
    }
    visitSeq(parts, context) { return this.visitMulti(parts, context); }
    visitTuple(parts, context) { return this.visitSeq(parts, context); }
    visitList(parts, context) { return this.visitSeq(parts, context); }
    visitSet(parts, context) { return this.visitSeq(parts, context); }
    visitMap(kvPairs, context) {
        let results = [];
        for (let [k, v] of kvPairs) {
            results.push(this.defaultCombine([k.visit(this, context), v.visit(this, context)]));
        }
        return this.defaultCombine(results);
    }
    // eslint-disable-next-line no-unused-vars
    visitLiteral(literalType, content, context) {
        return this.defaultCombine([]);
    }
    // eslint-disable-next-line no-unused-vars
    visitSlice(sliceType, begin, end, context) {
        const res = [];
        if (begin !== undefined) {
            res.push(begin.visit(this, context));
        }
        if (end !== undefined) {
            res.push(end.visit(this, context));
        }
        return this.defaultCombine(res);
    }
    visitSubscript(body, subscript, context) {
        return this.defaultCombine([body.visit(this, context), subscript.visit(this, context)]);
    }
    // eslint-disable-next-line no-unused-vars
    visitFieldAccess(body, field, context) {
        return this.defaultCombine([body.visit(this, context)]);
    }
    visitFunctionCall(func, args, context) {
        return this.visitMulti(args, context);
    }
    // eslint-disable-next-line no-unused-vars
    visitIdentifier(iden, context) {
        return this.defaultCombine([]);
    }
    // eslint-disable-next-line no-unused-vars
    visitSyntaxError(context) {
        throw SyntaxError();
    }
    visit(nodeType, nodeContent, context) {
        if (nodeType.startsWith("operator ")) {
            return this.visitBinaryOperator(nodeType.substring("operator ".length), nodeContent.left, nodeContent.right, context);
        }
        else if (nodeType.startsWith("unary operator ")) {
            return this.visitUnaryOperator(nodeType.substring("unary operator ".length), nodeContent.body, context);
        }
        else if (nodeType === "if-else") {
            return this.visitIfElse(nodeContent.body, nodeContent.condition, nodeContent.branch, context);
        }
        else if (nodeType === "tuple") {
            return this.visitTuple(nodeContent.parts, context);
        }
        else if (nodeType === "list") {
            return this.visitList(nodeContent.elems, context);
        }
        else if (nodeType === "set") {
            return this.visitSet(nodeContent.elems, context);
        }
        else if (nodeType === "map") {
            let kvPairs = [];
            for (let kv of nodeContent.elems) {
                kvPairs.push([kv.content.left, kv.content.right]);
            }
            return this.visitMap(kvPairs, context);
        }
        else if (nodeType.startsWith("literal ")) {
            return this.visitLiteral(nodeType.substring("literal ".length), nodeContent.value, context);
        }
        else if (nodeType === "subscript box") {
            return this.visitSubscript(nodeContent.body, nodeContent.subscript, context);
        }
        else if (nodeType.startsWith("slice ")) {
            return this.visitSlice(nodeType.substring("slice ".length), nodeContent.left, nodeContent.right, context);
        }
        else if (nodeType === "subscript dot") {
            return this.visitFieldAccess(nodeContent.body, nodeContent.field, context);
        }
        else if (nodeType === "function") {
            return this.visitFunctionCall(nodeContent.func, nodeContent.args, context);
        }
        else if (nodeType === "identifier") {
            return this.visitIdentifier(nodeContent.name, context);
        }
        else if (nodeType === "syntax error") {
            return this.visitSyntaxError(context);
        }
        else {
            throw TypeError("Unexpected node type " + nodeType);
        }
    }
}

export class VariablesVisitor extends Visitor {
  constructor() {
    super();
    this.vars = new Set();
  }

  visitIdentifier(variable) {
    this.vars.add(variable);
  }

  // eslint-disable-next-line class-methods-use-this
  visitSyntaxError() {
    // do nothing
  }
}

// the time complexity of this is awful I think, but so far it's not gonna matter because small input
// still consider fixing if it ever becomes a problem
class ToStringVisitor extends Visitor {
    visit(nodeType, nodeContent) {
        let parts = super.visit(nodeType, nodeContent);
        let str = "(" + nodeType;
        for (let part of parts) {
            str += " " + part;
        }
        return str + ")";
    }
    visitLiteral(literalType, content) {
        return [content];
    }
    visitIdentifier(iden) {
        return [iden];
    }
    visitFunctionCall(func, args) {
        return [].concat([func], this.visitMulti(args));
    }
}

class AstNode {
    constructor (type, content) {
        this.type = type;
        this.content = content;
    }
    visit(visitor, context) {
        return visitor.visit(this.type, this.content, context);
    }
    toString() {
        return this.visit(new ToStringVisitor());
    }
}

function Parser(tokens, errors, text) {
    this.tokens = tokens;
    this.index = 0;
    this.text = text;
    class ParserAstNode extends AstNode {
        constructor (tokBegin, tokEnd, type, data) {
            super(type, data);
            this.tokBegin = tokBegin;
            this.tokEnd = tokEnd;
        }
    }
    this.AstNode = ParserAstNode;
    this.error = function(message) {
        errors.push(new ParsingError(this.tokens[this.index].startChar, this.tokens[this.index].endChar, message));
    }
    this.errorRange = function(begin, end, message) {
        errors.push(new ParsingError(this.tokens[begin].startChar, this.tokens[end].endChar, message));
    }
    this.lookahead = function(type, value) {
        return this.tokens[this.index].type === type && this.tokens[this.index].content === value;
    }
    this.matchExact = function(type, value) {
        if (this.lookahead(type, value)) {
            this.index++;
            return true;
        }
        return false;
    }
    this.parseAssocOp = function(prec, incomplete, ops) {
        let begin = this.index;
        let a = this.parsePrec(prec+1, incomplete);
        let found = false;
        do {
            found = false;
            for (let [opType, opName] of ops) {
                if (this.matchExact(opType, opName)) {
                    let b = this.parsePrec(prec+1, "Missing expression after operator '" + opName + "'");
                    a = new this.AstNode(begin, this.index, "operator " + opName, {"left": a, "right": b});
                    found = true;
                    break;
                }
            }
        } while (found);
        return a;
    }
    this.parseBinOp = function(prec, incomplete, ops, negAfterOps, negBeforeOps) {
        let begin = this.index;
        let a = this.parsePrec(prec+1, incomplete);
        for (let [opType, opName] of [].concat(ops, negBeforeOps)) {
            if (this.matchExact(opType, opName)) {
                let b = this.parsePrec(prec+1, "Missing expression after operator '" + opName + "'");
                return new this.AstNode(begin, this.index, "operator " + opName, {"left": a, "right": b});
            }
        }
        for (let [opType, opName] of negAfterOps) {
            if (this.matchExact(opType, opName)) {
                let negated = false;
                if (this.matchExact('operator', 'not')) {
                    negated = true;
                }
                let b = this.parsePrec(prec+1, "Missing expression after operator '" + opName + (negated? " not":"") + "'");
                if (negated) {
                    return new this.AstNode(begin, this.index, "operator " + opName + " not", {"left": a, "right": b});
                }
                else {
                    return new this.AstNode(begin, this.index, "operator " + opName, {"left": a, "right": b});
                }
            }
        }
        if (negBeforeOps.length > 0 && this.matchExact("operator", "not")) {
            for (let [opType, opName] of negBeforeOps) {
                if (this.matchExact(opType, opName)) {
                    let b = this.parsePrec(prec+1, "Missing expression after operator 'not " + opName + "'");
                    return new this.AstNode(begin, this.index, "operator not " + opName, {"left": a, "right": b});
                }
            }
            this.error("Missing operator after 'not' (e.g. '" + negBeforeOps[0][1] + "')");
        }
        return a;
    }
    this.parsePreOp = function(prec, incomplete, ops) {
        let begin = this.index;
        for (let [opType, opName] of ops) {
            if (this.matchExact(opType, opName)) {
                let body = this.parsePrec(prec + 1, "Missing expression after operator '" + opName + "'");
                return new this.AstNode(begin, this.index, "unary operator " + opName, {"body": body});
            }
        }
        return this.parsePrec(prec+1, incomplete);
    }
    this.parseAtom = function(incomplete) {
        let begin = this.index;
        if (this.matchExact("open paren", "(")) {
            let body = this.parsePrec(0, "Missing expression within parentheses");
            if (!this.matchExact("close paren", ")")) {
                if (this.tokens[this.index].type === "EOF") {
                    this.errorRange(begin, this.index, "Missing ')' after '('");
                }
                else {
                    this.errorRange(begin, this.index, "Expected ')' after '(' but got " + this.tokens[this.index].type + " token '" + this.tokens[this.index].content + "'");
                }
            }
            return body;
        }
        else if (this.matchExact("open list", "[")) {
            if (this.matchExact("close list", "]")) {
                return new this.AstNode(begin, this.index, "list", {"elems": []});
            }
            let part = 0;
            let body = this.parseSep(
                () => this.parsePrec(STRUC_EXPR_PREC, part++ === 0? "Missing element within list, or ']' for empty list" : "Missing element within list after ','"),
                "keyword", ",",
                (elems) => elems,
                (elems) => [elems]);
            if (!this.matchExact("close list", "]")) {
                if (this.tokens[this.index].type === "EOF") {
                    this.errorRange(begin, this.index, "Missing ']' for end of list literal");
                }
                else {
                    this.errorRange(begin, this.index, "Expected ']' for end of list literal but got " + this.tokens[this.index].type + " token '" + this.tokens[this.index].content + "'");
                }
            }
            return new this.AstNode(begin, this.index, "list", {"elems": body});
        }
        else if (this.matchExact("open map", "{")) {
            if (this.matchExact("close map", "}")) {
                return new this.AstNode(begin, this.index, "map", {"elems": []});
            }
            let isMap = false;
            let mapExample = null;
            let isSet = false;
            let setExample = null;
            let part = 0;
            let parts = this.parseSep(
                () => this.parseBinOp(
                    STRUC_EXPR_PREC-1,
                    part++===0? "Missing element or '}' for map/set": "Missing element after ','",
                    [["operator", ":"]], [], []),
                "keyword", ",",
                (args) => args,
                (arg) => [arg]);
            if (!this.matchExact("close map", "}")) {
                this.errorRange(begin, this.index, "Missing '}' for end of map/set literal");
            }
            let mapParts = [];
            for (let el of parts) {
                if (el.type === "operator :") {
                    isMap = true;
                    mapExample = this.text.substring(this.tokens[el.tokBegin].startChar, this.tokens[el.tokEnd-1].endChar);
                    mapParts.push(el);
                }
                else {
                    isSet = true;
                    setExample = this.text.substring(this.tokens[el.tokBegin].startChar, this.tokens[el.tokEnd-1].endChar);
                    // Suppose there are both map elements and set elements; we have to output some syntax tree, but
                    // which one? A map node or a set node?
                    // Assumption: the main case where we will enter this scenario is when the user has typed
                    // out a partial script:
                    //    {"hello":0, "goodbye"
                    // In this case, it seems reasonable to assume that "goodbye" was supposed to be a key in the map.
                    // Therefore we should treat it as such.
                    mapParts.push(new this.AstNode(el.tokBegin, el.tokEnd, "operator :",
                        {"left": el,
                         "right": new this.AstNode(el.tokBegin, el.tokEnd, "syntax error", {"tokens": []})}));
                }
            }
            if (isMap && isSet) {
                this.errorRange(begin, this.index, "Key-value pair '" + mapExample + "' implies this is a map, but element '" + setExample + "' implies this is a set; it cannot be both");
            }
            if (isMap) {
                return new this.AstNode(begin, this.index, "map", {"elems": mapParts});
            }
            else {
                return new this.AstNode(begin, this.index, "set", {"elems": parts});
            }
        }
        else if (this.tokens[this.index].type === "number") {
            let value = this.tokens[this.index].content;
            return new this.AstNode(begin, ++this.index, "literal number", {"value": value});
        }
        else if (this.tokens[this.index].type === "quote") {
            let value = this.tokens[this.index].content;
            return new this.AstNode(begin, ++this.index, "literal string", {"value": value});
        }
        else if (this.tokens[this.index].type === "constant") {
            let value = this.tokens[this.index].content;
            return new this.AstNode(begin, ++this.index, "literal constant", {"value": value});
        }
        else if (this.tokens[this.index].type === "identifier") {
            let iden = this.tokens[this.index].content;
            if (!isValidPythonIdentifier(iden)) {
                this.error("'" + iden + "' is not a valid identifier");
            }
            this.index++;
            if (this.matchExact("open paren", "(")) {
                if (this.matchExact("close paren", ")")) {
                    return new this.AstNode(begin, this.index, "function", {"func": iden, "args": []});
                }
                let part = 0;
                let params = this.parseSep(
                    () => this.parsePrec(STRUC_EXPR_PREC, part++===0? "Missing argument or ')' for function '" + iden + "'" : "Missing argument for function '" + iden + "'"),
                    "keyword", ",",
                    (args) => args,
                    (arg) => [arg]);
                if (!this.matchExact("close paren", ")")) {
                    this.errorRange(begin, this.index,"Missing ')' for function call to '" + iden +"'");
                }
                return new this.AstNode(begin, this.index, "function", {"func": iden, "args": params});
            }
            else {
                return new this.AstNode(begin, this.index, "identifier", {"name": iden});
            }
        }
        else {
            if (this.tokens[this.index].type === "EOF") {
                this.error(incomplete);
            }
            else {
                let help = ", expected expression";
                if (this.index > 0) {
                    help = ", expected expression after '" + this.tokens[this.index-1].content + "'";
                }
                this.error("Unexpected " + this.tokens[this.index].type + " token '" + this.tokens[this.index].content + "'" + help);
            }
            return new this.AstNode(begin, this.index, "syntax error", {"tokens": this.tokens.slice(this.index, this.tokens.length)});
        }
    }
    this.parseSep = function(sub, sepType, sepWord, seq, single) {
        let singleRes = single===undefined ? (x) => x : single;
        let body = sub();
        if (this.matchExact(sepType, sepWord)) {
            let parts = [body];
            do {
                parts.push(sub());
            } while (this.matchExact(sepType, sepWord))
            return seq(parts);
        }
        return singleRes(body);
    }
    this.parseSlice = function(incomplete) {
        const begin = this.index;
        if (this.matchExact("operator", ":")) {
            if (this.lookahead("keyword", ",") || this.lookahead("close list", "]")) {
                return new this.AstNode(begin, this.index, "slice unbounded", {});
            }
            else {
                const end = this.parsePrec(STRUC_EXPR_PREC, "Missing expression for end of slice range after ':'");
                return new this.AstNode(begin, this.index, "slice end", {"right": end});
            }
        }
        else {
            const begin = this.parsePrec(STRUC_EXPR_PREC, incomplete);
            if (this.matchExact("operator", ":")) {
                if (this.lookahead("keyword", ",") || this.lookahead("close list", "]")) {
                    return new this.AstNode(begin, this.index, "slice begin", {"left": begin});
                }
                else {
                    const end = this.parsePrec(STRUC_EXPR_PREC, "Missing expression for end of slice range after ':'");
                    return new this.AstNode(begin, this.index, "slice bounded", {"left": begin, "right": end});
                }
            }
            else {
                return begin;
            }
        }
    }
    const STRUC_EXPR_PREC = 1;
    this.parsePrec = function(prec, incomplete) {
        let begin = this.index;
        let level = 0;
        if (prec === level++) { // tuples
            let part = 0;
            return this.parseSep(
                () => this.parsePrec(prec+1, part++ === 0? incomplete : "Missing expression after ','"),
                "keyword", ",",
                (parts) => new this.AstNode(begin, this.index, "tuple", { "parts": parts }));
        }
        else if (prec === level++) { // a-if-b-else-c
            if (prec !== STRUC_EXPR_PREC) {
                throw new Error('Precedence levels not up to date - expected ' + STRUC_EXPR_PREC + ' but got ' + prec);
            }
            let body = this.parsePrec(prec+1, incomplete);
            if (this.matchExact("keyword", "if")) {
                let condition = this.parsePrec(prec+1, "Missing condition after 'if'");
                if (!this.matchExact("keyword", "else")) {
                    this.errorRange(begin, this.index, "Missing 'else' after 'if'");
                    return body;
                }
                let branch = this.parsePrec(prec+1, "Missing body for 'else'");
                return new this.AstNode(begin, this.index, "if-else", {"body": body, "condition": condition, "branch": branch});
            }
            return body;
        }
        else if (prec === level++) {
            return this.parseAssocOp(prec, incomplete, [["operator", "or"]]);
        }
        else if (prec === level++) {
            return this.parseAssocOp(prec, incomplete, [["operator", "and"]]);
        }
        else if (prec === level++) {
            let ast = this.parsePreOp(prec, incomplete, [["operator", "not"], ["operator", "="]]);
            // This warning is supposed to cover the case where the user has a variable action and they put =something
            // into the 'variable code' field; the assignment operation is already implied by a 'set variable' activity,
            // so typing an assigment operator is redundant and causes errors in the backend.
            if (ast.type === "unary operator =") {
                this.errorRange(begin, begin+1, "Warning, prefix assignment operator '=' can cause errors; is this token redundant?")
            }
            return ast;
        }
        else if (prec === level++) {
            let ast = this.parseBinOp(prec, incomplete,
                [["operator", "="], ["operator", "=="], ["operator", "!="],
                    ["operator", "<"], ["operator", "<="], ["operator", ">"], ["operator", ">="]],
                [["operator", "is"]], [["operator", "in"]]);
            // This warning is supposed to cover the case where the user mixes up '=' and '=='.
            if (ast.type === "operator =") {
                this.errorRange(ast.tokBegin, ast.tokEnd, "The assignment operator '=' can cause errors; did you mean equality comparison '=='?");
            }
            return ast;
        }
        else if (prec === level++) {
            return this.parseAssocOp(prec, incomplete, [["operator", "|"]]);
        }
        else if (prec === level++) {
            return this.parseAssocOp(prec, incomplete, [["operator", "&"]]);
        }
        else if (prec === level++) {
            return this.parseAssocOp(prec, incomplete, [["operator", "+"], ["operator", "-"]]);
        }
        else if (prec === level++) {
            return this.parseAssocOp(prec, incomplete, [["operator", "*"], ["operator", "/"], ["operator", "//"], ["operator", "%"]]);
        }
        else if (prec === level++) {
            let ast = this.parsePreOp(prec, incomplete, [["operator", "-"], ["operator", "+"]]);
            if (ast.type === "unary operator +") {
                this.errorRange(begin, begin+1, "Warning, operator '+' in front of an expression has no effect, is this a typo?");
            }
            return ast;
        }
        else {
            let expr = this.parseAtom(incomplete);
            // eslint-disable-next-line no-constant-condition
            while (true) {
                if (this.matchExact("open list", "[")) {
                    let part = 0;
                    let subBegin = this.index;
                    let subscript = this.parseSep(
                        () => this.parseSlice(part++ === 0? "Missing expression for subscript after '['" : "Missing expression after ',' for subscript."),
                        "keyword", ",",
                        (parts) => new this.AstNode(subBegin, this.index, "tuple", { "parts": parts }));
                    if (!this.matchExact("close list", "]")) {
                        this.errorRange(begin, this.index, "Missing ']' to end subscripting begun by '['");
                    }
                    expr = new this.AstNode(begin, this.index, "subscript box", { "body": expr, "subscript": subscript });
                    continue;
                }
                else if (this.matchExact("operator", ".")) {
                    if (this.tokens[this.index].type !== "identifier") {
                        this.error("Missing field after '.'");
                        expr = new this.AstNode(begin, this.index, "subscript dot", { "body": expr, "field": [this.index, this.index, "syntax error", {}] });
                        continue;
                    }
                    let iden = this.tokens[this.index].content;
                    this.index++;
                    expr = new this.AstNode(begin, this.index, "subscript dot", { "body": expr, "field": iden });
                    continue;
                }
                return expr;
            }
        }
    }
}

export function parse(tokens, errors, text) {
    let parser = new Parser(tokens, errors, text);
    let ast = parser.parsePrec(0, "This script should not be empty");
    if (!parser.matchExact("EOF", "")) {
        parser.error("Unexpected fragment '" + text.substring(tokens[parser.index].startChar, text.length) + "' at end of script");
    }
    return ast;
}

export function getVariables(code) {
  try {
    const errors = [];
    const tokens = lex(code, errors);
    let vars = new Set();
    if (tokens.length > 1) {
      const ast = parse(tokens, errors, code);
      const visitor = new VariablesVisitor();
      ast.visit(visitor);
      vars = visitor.vars;
    }
    return vars;
  } catch (e) {
    return new Set();
  }
}

export function isValidPythonIdentifier(input) {
  if (!input) return false;
  if (input.length === 0) return false;
  // Python allows in 2+: https://docs.python.org/3/reference/lexical_analysis.html#identifiers
  const allowedChars = /^[a-zA-Z_][0-9a-zA-Z_]*$/;
  if (!allowedChars.test(input)) return false;
  /* List generated by running this in Python:
  import keyword
  print(keyword.kwlist)
  */
  const reservedPythonKeywords = [
    'False', 'None', 'True', 'and', 'as', 'assert', 'async', 'await', 'break', 'class', 'continue',
    'def', 'del', 'elif', 'else', 'except', 'finally', 'for', 'from', 'global', 'if', 'import',
    'in', 'is', 'lambda', 'nonlocal', 'not', 'or', 'pass', 'raise', 'return', 'try', 'while',
    'with', 'yield'];
  if (reservedPythonKeywords.includes(input)) {
    return false;
  }
  return true;
}

export function isReservedKeyword(input) {
  // This list is compiled and structured following the top-down order of appearance in the
  // BotScript help-page
  const reservedBotScriptKeywords = [
    // Boolean operators
    'and', 'or',
    // Functions
    'join',
    'sum',
    'str', 'int', 'dict', 'tuple', 'list', 'set', 'bool',
    'len',
    'lower', 'upper',
    'removesuffix', 'removeprefix', 'addprefix',
    'mask_email',
    'datetime_from_isostring',
    'datetime_from_timestamp',
    'keys', 'values',
    'dict_update',
    'dict_keys_exists',
    'map',
    'foldl',
    'remove_if',
    'exists',
    'zip',
    'first', 'latest', 'ner_all', 'ner_exists', 'ner_clear', 'ner_pop',
    'any',
    'split_by',
    'random_random',
    'replace',
    're_findall',
    're_sub',
    'endswith', 'startswith',
    'time_time',
    // Constants
    'math', 're',
    // Built-in variables
    'thisMessage', 'thisMessageVerbatim', 'transcript', 'description', 'lastBotMessages',
    'action_results', 'smart_options', 'bs_integration_result',
  ];
  return reservedBotScriptKeywords.includes(input);
}
