<template>
  <b-form>
    <b-form-group
      v-if="!responseOnly"
      label="Inactivity duration (seconds)"
      :label-for="'durationForm' + component_id"
    >
      <b-input
        :id="'durationForm' + component_id"
        :value="value.time"
        placeholder="e.g. 180"
        type="number"
        autofocus
        @input="v=>$emit('input', { type: 'time', value: v })"
      />
    </b-form-group>
    <b-form-group
      label="Message"
      :label-for="'messageForm' + component_id"
    >
      <div
        v-if="voicebotPlatformSelected"
        class="d-flex flex-row justify-content-between"
      >
        <voicebot-audio
          :text="value.msg"
        />
        <advanced-voice-response
          :uid="'advancedVoiceResponse' + component_id"
          @insert="insertAtMarker"
        />
      </div>
      <b-form-textarea
        :id="'messageForm' + component_id"
        ref="textArea"
        :value="value.msg"
        placeholder="e.g. still there?"
        @input="setInactiveMsg"
      />
    </b-form-group>
    <approve-button
      :value="value.responseApproved"
      type="message"
      @input="v=>$emit('input', { type: 'responseApproved', value: v })"
    />
  </b-form>
</template>

<script>
import { mapGetters } from 'vuex';
import ApproveButton from '@/components/ApproveButton.vue';
import VoicebotAudio from '@/components/VoicebotAudio.vue';
import AdvancedVoiceResponse from '../../components/AdvancedVoiceResponse.vue';

export default {
  name: 'EditInactivity',
  components: {
    ApproveButton,
    VoicebotAudio,
    AdvancedVoiceResponse,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    responseOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    component_id() {
      return this._uid;
    },
    ...mapGetters('botManipulation/activeBot/config', [
      'getPlatforms',
    ]),
    ...mapGetters('userSettings', ['isVoicebotPlatform']),
    voicebotPlatformSelected() {
      return this.isVoicebotPlatform(this.getPlatforms);
    },
  },
  methods: {
    setInactiveMsg(value) {
      this.$emit('input', { type: 'msg', value });
    },
    async insertAtMarker(txtToInsert) {
      const textArea = this.$refs.textArea;
      const [start, end] = [textArea.selectionStart, textArea.selectionEnd];
      let text = textArea.value;
      text = text.slice(0, start) + txtToInsert + text.slice(end);
      await this.setInactiveMsg(text);
      textArea.focus();
      textArea.setSelectionRange(start, start + txtToInsert.length);
    },
  },
};
</script>

<style scoped>

</style>
