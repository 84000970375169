import { render, staticRenderFns } from "./AppTemplate.vue?vue&type=template&id=2126cfa2&scoped=true&"
import script from "./AppTemplate.vue?vue&type=script&lang=js&"
export * from "./AppTemplate.vue?vue&type=script&lang=js&"
import style0 from "./AppTemplate.vue?vue&type=style&index=0&id=2126cfa2&prod&scoped=true&lang=css&"
import style1 from "./AppTemplate.vue?vue&type=style&index=1&id=2126cfa2&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../products/botstudio/frontend/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2126cfa2",
  null
  
)

export default component.exports