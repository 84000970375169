import { render, staticRenderFns } from "./LabelContent.vue?vue&type=template&id=56f0285b&scoped=true&"
import script from "./LabelContent.vue?vue&type=script&lang=js&"
export * from "./LabelContent.vue?vue&type=script&lang=js&"
import style0 from "./LabelContent.vue?vue&type=style&index=0&id=56f0285b&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56f0285b",
  null
  
)

export default component.exports