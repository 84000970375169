<template>
  <b-card no-body>
    <b-row>
      <b-col class="mt-2">
        <b-input-group>
          <template #prepend>
            <b-input-group-text class="prepend bg-primary text-white justify-content-center">
              Type
            </b-input-group-text>
          </template>
          <b-form-select
            v-model="typeCache"
            class="h-100"
            :options="panelTypes"
          />
        </b-input-group>
      </b-col>
    </b-row>
    <div
      v-for="typeElement in typeCache.supports"
      :key="typeElement"
    >
      <div v-if="typeElement === 'title'">
        <b-row>
          <b-col class="mt-2">
            <b-input-group>
              <template #prepend>
                <b-input-group-text class="prepend bg-primary text-white justify-content-center">
                  Title
                </b-input-group-text>
              </template>
              <b-form-input
                ref="titleInput"
                v-model="title"
                :required="typeCache.requires.indexOf('title') !== -1"
                @input="changeResponseApproved(false)"
              />
            </b-input-group>
          </b-col>
        </b-row>
      </div>
      <div v-else-if="typeElement === 'image'">
        <b-row>
          <b-col class="mt-2">
            <b-form-group
              class="mb-0"
              :state="imageState"
            >
              <b-input-group>
                <template #prepend>
                  <b-input-group-text class="prepend bg-primary text-white justify-content-center">
                    Image
                  </b-input-group-text>
                </template>
                <b-form-input
                  v-model="image"
                  :state="imageState"
                  :required="typeCache.requires.indexOf('image') !== -1"
                />
                <template
                  v-if="typeCache.supports.indexOf('image') !== -1"
                  #append
                >
                  <b-input-group-text class="p-0">
                    <b-img
                      style="height:35px;"
                      :src="image"
                      class="image"
                    />
                  </b-input-group-text>
                </template>
              </b-input-group>
            </b-form-group>
            <b-form-invalid-feedback style="margin-left:100px;">
              Image link must start with 'http://' or 'https://'
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
      </div>
      <div v-else-if="typeElement === 'image-alt'">
        <b-row>
          <b-col class="mt-2">
            <b-input-group>
              <template #prepend>
                <b-input-group-text class="prepend bg-primary text-white justify-content-center">
                  Image Alt
                </b-input-group-text>
              </template>
              <b-form-input
                v-model="imageAlt"
              />
            </b-input-group>
          </b-col>
        </b-row>
      </div>
      <div v-else-if="typeElement === 'link'">
        <b-row>
          <b-col class="mt-2">
            <b-input-group>
              <template #prepend>
                <b-input-group-text class="prepend bg-primary text-white justify-content-center">
                  Link
                </b-input-group-text>
              </template>
              <b-form-input
                v-model="link"
                :state="linkState"
                :required="typeCache.requires.indexOf('link') !== -1"
              />
              <b-form-invalid-feedback style="margin-left:100px;">
                Link must start with 'http://' or 'https://'
              </b-form-invalid-feedback>
            </b-input-group>
          </b-col>
        </b-row>
      </div>
      <div v-else-if="typeElement === 'link-text'">
        <b-row>
          <b-col class="mt-2">
            <b-input-group>
              <template #prepend>
                <b-input-group-text class="prepend bg-primary text-white justify-content-center">
                  Link Text
                </b-input-group-text>
              </template>
              <b-form-input
                v-model="linkText"
              />
            </b-input-group>
          </b-col>
        </b-row>
      </div>
      <div v-else-if="typeElement === 'message'">
        <b-row>
          <b-col class="mt-2">
            Text:
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <draggable-response-elements
              :node-id="nodeId"
              :activity-id="activityId"
              :input-required="typeCache.id === 'ZendeskPanel'"
              :max-length="typeCache.id === 'ZendeskPanel' ? 150 : 0"
              @editPhrase="v=>editPhrase(v)"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <phrase-selector
              :response-preview="responsePreview"
              :hide-add="!isMainBot"
              @add="v=>addPhraseToResponse(v)"
              @new="newPhrase"
            />
          </b-col>
        </b-row>
      </div>
      <div v-else-if="typeElement === 'buttons'">
        <b-row v-if="buttonIds.length > 0">
          <b-col class="mt-2">
            Buttons:
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <compound-response-activity-button-input
              v-for="(buttonId, index) in buttonIds"
              :key="'button_' + buttonId"
              :prefix="'Button ' + index"
              :node-id="nodeId"
              :activity-id="activityId"
              :button-id="buttonId"
              @update-button-text="changeResponseApproved(false)"
            />
            <b-button
              v-if="buttonIds.length < 3"
              size="sm"
              class="px-3 mt-2"
              variant="primary"
              @click="addButton"
            >
              Add Button
            </b-button>
          </b-col>
        </b-row>
      </div>
    </div>
    <approve-button
      class="mt-2"
      type="texts"
      :value="approved"
      @input="newValue => changeResponseApproved(newValue)"
    />
    <phrase-modal
      :modal-id="activityId"
      :action="phraseAction"
      @phraseAdded="v=>addPhraseToResponse(phrasesDict[v])"
    />
  </b-card>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { cloneDeep } from 'lodash';
import { compoundResponseTypes, PHRASE_REGEX } from '@/js/constants';
import CompoundResponseActivityButtonInput from '@/pages/EditNode/activity/CompoundResponseActivityButtonInput.vue';
import { addThisArgs, applyThisArgs } from '@/js/storeHelpers';
import { mapPhrases, phrase2Id } from '@/js/utils';
import ApproveButton from '@/components/ApproveButton.vue';
import PhraseSelector from '@/pages/Phrases/PhraseSelector.vue';
import PhraseModal from '@/pages/Phrases/PhraseModal.vue';
import DraggableResponseElements from '@/components/DraggableResponseElements.vue';

export default {
  name: 'CompoundResponseActivityInputs',
  components: {
    ApproveButton,
    CompoundResponseActivityButtonInput,
    PhraseModal,
    PhraseSelector,
    DraggableResponseElements,
  },
  props: {
    nodeId: {
      type: String,
      required: true,
    },
    activityId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showExtraContents: false,
      panelTypes: [],
      typeCache: null,
      phraseAction: '',
    };
  },
  computed: {
    ...applyThisArgs(
      mapGetters('botManipulation/activeBot', {
        getCompoundResponseText: 'activityText',
        getCompoundResponseTitle: 'activityCompoundResponseTitle',
        getCompoundResponseImage: 'activityCompoundResponseImage',
        getCompoundResponseImageAlt: 'activityCompoundResponseImageAlt',
        getCompoundResponseLink: 'activityCompoundResponseLink',
        getCompoundResponseLinkText: 'activityCompoundResponseLinkText',
        getCompoundResponseType: 'activityCompoundResponseType',
        buttonIds: 'activityCompoundResponseButtonIds',
        buttons: 'activityCompoundResponseButtons',
        getApproved: 'activityResponseApproved',
      }),

      'nodeId',
      'activityId',
    ),
    ...mapGetters('botManipulation/activeBot/config', [
      'getPlatforms', 'wysiwygEnabled', 'isMainBot',
    ]),
    ...mapGetters('botManipulation/activeBot', ['phrasesDict', 'phrases']),
    responsePreview() {
      return this.textBlocks.map((e) => (e.isPhrase ? this.phrase2Text(e.value) : e.value)).join('');
    },
    approved: {
      get() {
        return this.getApproved;
      },
      set(approved) {
        this.setApproved({ approved });
      },
    },
    textBlocks: {
      get() {
        return mapPhrases(this.getCompoundResponseText);
      },
      set(blocks) {
        this.setCompoundResponseText({ text: blocks.map((e) => e.value).join('') });
      },
    },
    text: {
      get() {
        return this.textBlocks.find((e) => !e.isPhrase)?.value;
      },
      set(text) {
        let cleaned = text;
        const matches = text.match(PHRASE_REGEX);
        if (matches) {
          matches.forEach((e) => {
            if (this.phrasesDict[this.phrase2Id(e)]) {
              this.addPhraseToResponse({ id: this.phrase2Id(e) });
            }
          });
          cleaned = text.split(PHRASE_REGEX).filter((e) => e.length > 0).join('');
        }
        this.setCompoundResponseText({ text: this.getCompleteText(cleaned) });
      },
    },
    title: {
      get() {
        return this.getCompoundResponseTitle;
      },
      set(title) {
        this.setCompoundResponseTitle({ title });
      },
    },
    image: {
      get() {
        return this.getCompoundResponseImage;
      },
      set(imageURL) {
        this.setCompoundResponseImage({ imageURL });
      },
    },
    imageAlt: {
      get() {
        return this.getCompoundResponseImageAlt;
      },
      set(imageAlt) {
        this.setCompoundResponseImageAlt({ imageAlt });
      },
    },
    link: {
      get() {
        return this.getCompoundResponseLink;
      },
      set(link) {
        this.setCompoundResponseLink({ link });
      },
    },
    linkText: {
      get() {
        return this.getCompoundResponseLinkText;
      },
      set(linkText) {
        this.setCompoundResponseLinkText({ linkText });
      },
    },
    type: {
      get() {
        return this.getCompoundResponseType;
      },
      set(type) {
        this.setCompoundResponseType({ type });
      },
    },
    imageState() {
      return this.image && this.validLink(this.image);
    },
    linkState() {
      return this.link && this.validLink(this.link);
    },
  },
  watch: {
    typeCache(newVal, oldVal) {
      if (newVal === undefined) return;
      if (newVal !== oldVal) {
        this.type = newVal.id;
      }
    },
    type(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.typeCache = compoundResponseTypes.find((p) => p.id === newVal);
      }
    },
  },
  beforeMount() {
    for (const type of compoundResponseTypes) {
      const typePlatforms = type.supportedIn;
      const botPlatforms = this.getPlatforms;
      const platformOk = typePlatforms.some((x) => botPlatforms.includes(x));
      if (platformOk) {
        this.panelTypes.push({
          text: type.name,
          value: type,
        });
      }
    }
    const prt = this.getCompoundResponseType;
    if (prt === '' || prt === undefined) {
      this.typeCache = compoundResponseTypes[0];
    } else {
      this.typeCache = compoundResponseTypes.find((p) => p.id === prt);
    }
  },
  methods: {
    ...addThisArgs(
      mapMutations('botManipulation/activeBot', {
        setCompoundResponseType: 'setActivityCompoundResponseType',
        setCompoundResponseText: 'setActivityResponseText',
        setCompoundResponseTitle: 'setActivityCompoundResponseTitle',
        setCompoundResponseImage: 'setActivityCompoundResponseImage',
        setCompoundResponseImageAlt: 'setActivityCompoundResponseImageAlt',
        setCompoundResponseLink: 'setActivityCompoundResponseLink',
        setCompoundResponseLinkText: 'setActivityCompoundResponseLinkText',
        deleteCompoundResponseButton: 'deleteActivityCompoundResponseButton',
        addCompoundResponseButton: 'addActivityCompoundResponseButton',
        setCompoundResponseButtonText: 'setActivityCompoundResponseButtonText',
        setApproved: 'setApproved',
      }),
      { nodeId: 'nodeId', activityId: 'activityId' },
    ),
    phrase2Id,
    ...mapMutations('botManipulation', ['setActivePhraseId']),
    addButton() {
      this.addCompoundResponseButton();
    },
    deleteButton(buttonId) {
      this.deleteCompoundResponseButton({ buttonId });
    },
    setButtonText(buttonId, newText) {
      this.setCompoundResponseButtonText({ buttonId, text: newText });
    },
    focusInput() {
      this.$refs?.titleInput?.[0]?.focus();
    },
    focusActivity() {
      this.$refs.baseActivity.focusActivity();
    },
    changeResponseApproved(value) {
      this.approved = value;
    },
    validLink(value) {
      return value.startsWith('http://') || value.startsWith('https://');
    },
    newPhrase() {
      this.phraseAction = 'Add';
      this.$bvModal.show(`phrase-modal-${this.activityId}`);
    },
    editPhrase(id) {
      this.phraseAction = 'Update';
      this.setActivePhraseId(id);
      this.$bvModal.show(`phrase-modal-${this.activityId}`);
    },
    addPhraseToResponse(phrase) {
      const formattedPhrase = `[phrase:${phrase.id}]`;
      const text = this.text + formattedPhrase;
      this.setCompoundResponseText({ text: this.getCompleteText(text) });
    },
    removePhraseFromResponse(index) {
      const copy = cloneDeep(this.textBlocks);
      copy.splice(index, 1);
      this.textBlocks = copy;
    },
    getCompleteText(text) {
      const blocksCopy = cloneDeep(this.textBlocks);
      let finalText = '';
      blocksCopy.forEach((block) => {
        finalText += block.isPhrase ? block.value : text;
      });
      return finalText;
    },
    phrase2Text(phrase) {
      return this.phrasesDict[this.phrase2Id(phrase)].text;
    },
  },
};
</script>
<style>
.image {
  border-radius: 0px 0.25rem 0.25rem 0px  !important;
}
.prepend{
  width:100px;
}
</style>
