<template>
  <div>
    <div v-if="!responsesOnly">
      <h3>Smart Nodes</h3>
      <b-card
        no-body
      >
        <p class="mb-2">
          Edit the default behavior of smart nodes.
        </p>
        <p>
          Smart nodes will ask the user for further input, when it does not understand
          the user response. Here you can edit the default behavior, but it can also be
          set on the individual nodes.
        </p>
      </b-card>
      <hr>
    </div>

    <b-form-group
      :label-for="'confirmQuery' + component_id"
      label="Single choice message"
      description="Use {description} to get the description of the target node"
    >
      <b-form-textarea
        :id="'confirmQuery' + component_id"
        v-model="confirmQuery"
      />
    </b-form-group>
    <b-form-group
      :label-for="'optionsQuery' + component_id"
      label="Multiple choice message"
      description="Message displayed together with node options"
    >
      <b-form-textarea
        :id="'optionsQuery' + component_id"
        v-model="optionsQuery"
        placeholder="Default message (used if not overridden in smart node)"
        rows="2"
        max-rows="3"
      />
    </b-form-group>
    <b-form-group
      label="Maximum number of options to show (0 = no limit)"
      label-for="limit-options"
    >
      <b-form-input
        id="limit-options"
        ref="limitOptions"
        :key="inputKey"
        v-model="limitOptions"
        :state="!$v.limitOptions.$invalid"
        style="width:130px;"
        type="number"
        min="0"
      />
      <b-form-invalid-feedback>
        <div v-if="!$v.limitOptions.nonNegative">
          Value must be a non-negative integer.
        </div>
        <div v-else-if="!$v.limitOptions.integer">
          Value must be an integer.
        </div>
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
      :label-for="'otherText' + component_id"
      label="Text used for &quot;None of the above&quot;"
    >
      <b-form-input
        :id="'otherText' + component_id"
        v-model="otherText"
      />
    </b-form-group>
    <b-form-group
      v-if="isSupchatPlatform"
      :label-for="'otherDescription' + component_id"
      label="Text used as description for &quot;None of the above&quot;"
    >
      <b-form-input
        :id="'otherDescription' + component_id"
        v-model="otherDescription"
      />
    </b-form-group>
    <approve-button
      v-model="responseApproved"
      type="above texts"
    />
    <div v-if="!responsesOnly">
      <hr class="mt-4">
      <b-form-group
        label="Confirmation classifier"
      >
        <b-form-select
          v-model="smartNodeYesNoConfig.yesNoModelName"
          :options="yesNoModelOptions"
          placeholder="Select NLU Model"
          @change="resetYesNoLabels"
        />
      </b-form-group>
      <template v-if="yesNoModel">
        <b-form-group
          label="Yes label"
        >
          <b-form-select
            v-model="smartNodeYesNoConfig.yesLabel"
            :options="yesNoModel.labels"
            :state="!$v.smartNodeYesNoConfig.yesLabel.$invalid"
            placeholder="Choose a label..."
          />
          <b-form-invalid-feedback :state="$v.smartNodeYesNoConfig.yesLabel.required">
            You must choose a yes label
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          label="No label"
        >
          <b-form-select
            v-model="smartNodeYesNoConfig.noLabel"
            :options="yesNoModel.labels"
            :state="!$v.smartNodeYesNoConfig.noLabel.$invalid"
            placeholder="Choose a label"
          />
          <b-form-invalid-feedback :state="$v.smartNodeYesNoConfig.noLabel.required">
            You must choose a no label
          </b-form-invalid-feedback>
        </b-form-group>
      </template>
      <SaveButton
        :save-disabled="$v.smartNodeYesNoConfig.$invalid"
        :has-unsaved-changes="changesHaveBeenMade"
        @save="saveYesNoClassifierConfig"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { validationMixin } from 'vuelidate';
import { requiredIf, integer, minValue } from 'vuelidate/lib/validators';
import ApproveButton from '@/components/ApproveButton.vue';
import SaveButton from '@/components/SaveButton.vue';

export default {
  name: 'EditSmartNode',
  components: {
    ApproveButton,
    SaveButton,
  },
  mixins: [validationMixin],
  props: {
    responsesOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputKey: 0,
      smartNodeYesNoConfig: {
        yesNoModelName: null,
        yesLabel: null,
        noLabel: null,
      },
    };
  },
  computed: {
    ...mapState('botManipulation/activeBot/config', ['multipleChoice']),
    ...mapGetters('botManipulation/activeBot/config', [
      'getNLUModels',
      'getMultipleChoiceApproved',
      'getPlatforms',
    ]),
    component_id() {
      return this._uid;
    },
    changesHaveBeenMade() {
      // Compare with currently configured yes/no classifier (if any is configured)
      const currentYesNoClassifier = this.multipleChoice;
      if (currentYesNoClassifier.yesNoModelName === '' && this.smartNodeYesNoConfig.yesNoModelName !== null) {
        // A yes-no classifier has been set in UI, but bot-definition does not have it set
        return true;
      }
      if (currentYesNoClassifier.yesNoModelName !== '' && this.smartNodeYesNoConfig.yesNoModelName === '') {
        // A yes-no classifier has been un-set in UI, while bot-definition has one set
        return true;
      }
      if (currentYesNoClassifier.yesNoModelName !== this.smartNodeYesNoConfig.yesNoModelName) {
        // A yes-no classifier has been un-set in UI, while bot-definition has one set
        return true;
      }
      if (currentYesNoClassifier.yesLabel !== this.smartNodeYesNoConfig.yesLabel) {
        return true;
      }
      if (currentYesNoClassifier.noLabel !== this.smartNodeYesNoConfig.noLabel) {
        return true;
      }
      return false;
    },
    yesNoModel() {
      return this.getNLUModels.find((x) => x.name === this.smartNodeYesNoConfig.yesNoModelName);
    },
    nluModelNames() {
      return this.getNLUModels.map((model) => model.name);
    },
    yesNoModelOptions() {
      // We have this function to enable backwards compatibility with models loaded from disk.
      // This is currently used at One.com. Before removing it, ask MC if it is still used.
      const modelOptions = this.nluModelNames;
      modelOptions.unshift({
        value: null,
        text: 'No classifier selected',
      });
      return modelOptions;
    },
    isSupchatPlatform() {
      return this.getPlatforms.includes('supchat');
    },
    optionsQuery: {
      get() {
        return this.multipleChoice.optionsQuery;
      },
      set(query) {
        this.setMultipleChoiceOptionsQuery({ query });
      },
    },
    limitOptions: {
      get() {
        return this.multipleChoice.limitOptions;
      },
      set(value) {
        this.setLimitOptions({ limitOptions: value || 0 });
        this.inputKey++;
        this.$nextTick(() => {
          this.$refs.limitOptions.focus();
        });
      },
    },
    confirmQuery: {
      get() {
        return this.multipleChoice.confirmQuery;
      },
      set(query) {
        this.setMultipleChoiceConfirmQuery({ query });
      },
    },
    otherText: {
      get() {
        return this.multipleChoice.otherText;
      },
      set(otherText) {
        this.setMultipleChoiceOtherText({ otherText });
      },
    },
    otherDescription: {
      get() {
        return this.multipleChoice.otherDescription;
      },
      set(otherDescription) {
        this.setMultipleChoiceOtherDescription({ otherDescription });
      },
    },
    responseApproved: {
      get() {
        return this.getMultipleChoiceApproved;
      },
      set(approved) {
        this.setMultipleChoiceApproved({ approved });
      },
    },
  },
  watch: {
    changesHaveBeenMade(newVal) {
      this.$emit('unsavedChanges', newVal);
    },
  },
  beforeDestroy() {
    this.$emit('unsavedChanges', false);
  },
  mounted() {
    // Populate this.smartNodeYesNoConfig
    const botYesNoClassifier = this.multipleChoice;
    this.smartNodeYesNoConfig = {
      yesNoModelName: botYesNoClassifier.yesNoModelName,
      yesLabel: botYesNoClassifier.yesLabel,
      noLabel: botYesNoClassifier.noLabel,
    };
  },
  methods: {
    ...mapMutations('botManipulation/activeBot/config', [
      'setMultipleChoiceOptionsQuery',
      'setLimitOptions',
      'setMultipleChoiceConfirmQuery',
      'setMultipleChoiceOtherText',
      'setMultipleChoiceOtherDescription',
      'setMultipleChoiceApproved',
    ]),
    // Use this method to reset selected yes and no labels when user picks another YesNoClassifier
    resetYesNoLabels() {
      this.smartNodeYesNoConfig.yesLabel = null;
      this.smartNodeYesNoConfig.noLabel = null;
    },
    saveYesNoClassifierConfig() {
      // Save the new config in one go
      const newMultipleChoiceConfig = {
        ...this.multipleChoice, ...this.smartNodeYesNoConfig,
      };
      this.$store.commit('botManipulation/activeBot/config/setMultipleChoice', newMultipleChoiceConfig);
      this.$emit('unsavedChanges', false);
    },
  },
  validations() {
    return {
      limitOptions: {
        nonNegative: minValue(0),
        integer,
      },
      smartNodeYesNoConfig: {
        yesLabel: {
          required: requiredIf(() => this.smartNodeYesNoConfig.yesNoModelName !== null),
        },
        noLabel: {
          required: requiredIf(() => this.smartNodeYesNoConfig.yesNoModelName !== null),
        },
      },
    };
  },
};
</script>
