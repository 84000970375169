<template>
  <div>
    <b-list-group>
      <b-list-group-item
        v-for="id in ids"
        :key="metricIdentifier + '_' + id"
      >
        <div
          class="d-flex justify-content-between"
        >
          <div>
            <slot v-bind="id">
              {{ id }}
            </slot>
          </div>
          <div>
            <b-button
              size="sm"
              variant="danger"
              @click.stop.prevent="ignoreId(id)"
            >
              Ignore
            </b-button>
          </div>
        </div>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { deepCopyJson } from 'supwiz/util/data';

export default {
  name: 'ExcludableIdsList',
  props: {
    metricIdentifier: {
      required: true,
      type: String,
    },
    ids: {
      required: true,
      type: Array,
    },
  },
  computed: {
    ...mapGetters('botManipulation', [
      'getBotId',
    ]),
    ...mapGetters('health', [
      'getConfigForMetric',
    ]),
    currentConfig() {
      return this.getConfigForMetric(this.getBotId, this.metricIdentifier) || {};
    },
  },
  methods: {
    ...mapActions('health', [
      'updateComputeConfig',
    ]),
    ignoreId(value) {
      const newConfig = {
        ...{ excluded_ids: [] },
        ...deepCopyJson(this.currentConfig),
      };
      if (newConfig.excluded_ids.includes(value)) {
        return;
      }
      newConfig.excluded_ids.push(value);
      this.updateComputeConfig({
        metricIdentifier: this.metricIdentifier,
        newConfig,
      });
    },
  },
};
</script>

<style scoped>

</style>
