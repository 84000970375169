import { platforms } from '@/js/constants';

export const discoverableFeatures = Object.freeze({
  // ----------------------- pre-2023 -----------------------
  SUPCHAT_FORMS: {
    title: 'SupChat form messages',
    icon: 'table-list',
    key: 'supchat_forms',
    link: 'flow',
    version: '2022.4',
    supportedIn: [platforms.SUPCHAT],
  },
  AUTOMATIC_MESSAGE_DELAY: {
    title: 'Automatic message pausing',
    icon: 'hourglass-end',
    key: 'automatic_message_delay',
    link: 'config',
    version: '2022.4',
    supportedIn: null,
  },
  // ----------------------- 2023.1 -----------------------
  GRAPH_VIEW: {
    title: 'Graph view',
    icon: 'diagram-next',
    key: 'graph_visits',
    link: 'graph',
    version: '2023.1',
    supportedIn: null,
  },
  PHRASE_BLOCKS: {
    title: 'Phrase building blocks',
    icon: 'bars-staggered',
    key: 'phrase_blocks',
    link: 'phrases',
    version: '2023.1',
    supportedIn: null,
  },
  // ----------------------- 2023.2 -----------------------
  GENERATIVE_AI: {
    title: 'Generative AI',
    icon: 'wand-magic-sparkles',
    key: 'generative_ai',
    link: 'flow',
    version: '2023.2',
    supportedIn: null,
  },
  RICH_RESPONSES: {
    title: 'Rich responses',
    icon: 'address-card',
    key: 'rich_responses',
    link: 'flow',
    version: '2023.2',
    supportedIn: [platforms.ZENDESK, platforms.PUZZEL],
  },
  SEND_SMS: {
    title: 'SMS sending',
    icon: 'comment-sms',
    key: 'send_sms',
    link: 'flow',
    version: '2023.2',
    supportedIn: null,
  },
  IMPROVED_AUTO_LABELS: {
    title: 'Autolabel improvements',
    icon: 'brain',
    key: 'improved_auto_labels',
    link: 'nlu',
    version: '2023.2',
    supportedIn: null,
  },
  // TODO: I think we should have a feature about how the improvements on
  //  health page + dataset creation + training page can work to create new flows.
  // ----------------------- 2023.3 -----------------------
  HYGIENE_CHECKS_REVAMPED: {
    title: 'Hygiene checks revamped',
    icon: 'pump-soap',
    key: 'hygiene_checks_revamped',
    link: 'health',
    version: '2023.3',
    supportedIn: null,
  },
  EXCEL_RESPONSE_EXPORT: {
    title: 'Export responses as excel',
    icon: 'file-export',
    key: 'excel_response_export',
    link: 'responses',
    version: '2023.3',
    supportedIn: null,
  },
  SUPCHAT_HIDDEN_MESSAGE: {
    title: 'Send hidden message in SupChat',
    icon: 'note-sticky',
    key: 'supchat_hidden_message',
    link: 'flow',
    version: '2023.3',
    supportedIn: [platforms.SUPCHAT],
  },
  INTEGRATION_ERROR_CONTINUE: {
    title: 'Handle integration errors in bot',
    icon: 'code',
    key: 'integration_error_continue',
    link: 'integrations',
    version: '2023.3',
    supportedIn: null,
  },
  // ----------------------- 2024.1 -----------------------
  TOPIC_EXPLORATION: {
    title: 'New visualizations',
    icon: 'chart-area',
    key: 'topic_exploration',
    link: 'health',
    version: '2024.1',
    supportedIn: null,
  },
  BOT_HISTORY: {
    title: 'Bot history',
    icon: 'clock-rotate-left',
    key: 'bot_history',
    link: 'history',
    version: '2024.1',
    supportedIn: null,
  },
  GENERATIVE_AI_IMPROVEMENTS: {
    title: 'Generative AI improvements',
    icon: 'wand-magic-sparkles',
    key: 'generative_ai_improvements',
    link: 'flow',
    version: '2024.1',
    supportedIn: null,
  },
});

export function evaluateDiscoverableFeatures(state, rootGetters) {
  const features = [];
  Object.values(discoverableFeatures).forEach((feature) => {
    if (!state.botStudioSettings.dismissed_features.includes(feature.key)) {
      if (feature.supportedIn === null
          || rootGetters['botManipulation/activeBot/config/getPlatforms'].some((x) => feature.supportedIn.includes(x))) {
        features.push(feature);
      }
    }
  });
  // Put the newest release features first.
  features.sort((a, b) => {
    if (a.version > b.version) {
      return -1;
    }
    return 1;
  });
  return features;
}
