<template>
  <b-row class="justify-content-center pt-5 mx-0">
    <b-col cols="4">
      <b-card
        align="center"
        title="Sign in to BotStudio"
      >
        <b-alert
          v-if="!loginInProgress && errorCode === 2"
          fade
          show
          variant="danger"
        >
          Invalid username or password
        </b-alert>

        <b-alert
          v-else-if="!loginInProgress && errorCode === 1"
          fade
          show
          variant="danger"
        >
          The server is down now. Please contact your administrator or try it later
        </b-alert>

        <b-alert
          v-else-if="!loginInProgress && errorCode !== 0"
          fade
          show
          variant="danger"
        >
          An unexpected error occurred while logging in.
        </b-alert>

        <b-alert
          v-else-if="!loginInProgress && aadError === 'not_allowed'"
          fade
          show
          variant="danger"
        >
          The user you logged in with is not authorized to use BotStudio.
        </b-alert>
        <b-alert
          v-else-if="!loginInProgress && aadError === 'inactive_user'"
          show
          variant="danger"
        >
          The user has been locked out. Contact your BotStudio administrator.
        </b-alert>
        <b-alert
          v-else-if="!loginInProgress && aadError"
          show
          variant="danger"
        >
          An unexpected error occurred when you logged in.
        </b-alert>
        <b-form @submit.prevent="onSubmit">
          <b-form-group
            id="username-group"
            class="text-left"
            label="Username"
            label-for="username"
            label-size="sm"
          >
            <b-form-input
              id="username"
              v-model="form.username"
              type="text"
              required
              placeholder="Enter username"
              autocomplete="username"
              autofocus
            />
          </b-form-group>

          <b-form-group
            id="password-group"
            class="text-left"
            label="Password"
            label-for="password"
            label-size="sm"
          >
            <b-form-input
              id="password"
              v-model="form.password"
              type="password"
              required
              placeholder="Enter password"
              autocomplete="current-password"
            />
          </b-form-group>

          <b-button
            type="submit"
            variant="primary"
            style="width:100%"
            class="mb-2"
            :disabled="loginInProgress"
          >
            <b-spinner
              v-if="loginInProgress"
              small
            />
            Sign in
          </b-button>

          <div v-if="ssoEnabled">
            <b-link :href="ssoPath">
              Sign in with your organization account
            </b-link>
          </div>
          <div
            v-if="resetPasswordEnabled"
          >
            <b-link href="/accounts/password_reset/">
              Reset your BotStudio password
            </b-link>
          </div>
        </b-form>
        <template #footer>
          <div>
            <img
              style="max-width: 25%"
              src="../assets/supwiz_dark_text.svg"
              alt="SupWiz Logo"
            >
          </div>
        </template>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import axios from 'axios';
import endpoints from '@/js/urls';
import { isProduction } from '@/js/featureFlags';

export default {
  name: 'LoginPage',
  data() {
    return {
      form: {
        username: '',
        password: '',
      },
      loginInProgress: false,
      // flag for checking error source, 0 for normal
      // 1 for server down and 2 for wrong username/password
      errorCode: 0,
      ssoEnabledResponse: false,
    };
  },

  computed: {
    ssoEnabled() {
      return this.ssoEnabledResponse;
    },
    ssoPath() {
      return endpoints.ssoLogin;
    },
    resetPasswordEnabled() {
      return !isProduction() || window.showResetPassword.toLowerCase() === 'true';
    },
    aadError() {
      return this.$route.query.aad_error;
    },
  },

  async created() {
    const response = await axios.get(endpoints.ssoEnabled);
    this.ssoEnabledResponse = response.data.toLowerCase() === 'true';
    const redirectPath = this.$route?.query?.originalPath;
    if (redirectPath) localStorage.setItem('redirect-path', redirectPath);
  },

  methods: {
    async onSubmit() {
      try {
        this.errorCode = 0;
        this.loginInProgress = true;
        const { data } = await this.$store.dispatch('auth/login', this.form);
        this.$store.commit('auth/updateAccessToken', data.access);
        this.$store.commit('auth/updateRefreshToken', data.refresh);
        this.$store.commit('auth/updateExpired', false);
        this.$router.push({ name: 'home' });
      } catch (error) {
        const status = error.response?.status;
        if (error.message === 'Network Error') {
          this.errorCode = 1;
        } else if (status >= 400 && status < 500) {
          this.errorCode = 2;
        } else {
          this.errorCode = 3;
        }
        this.loginInProgress = false;
      }
    },
  },
};
</script>

<style scoped>
  #login {
    margin: 50px auto;
    border: 1px solid #eee;
    padding: 20px;
    box-shadow: 0 2px 3px #ccc;
  }
</style>
