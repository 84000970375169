<template>
  <b-container
    fluid
    class="px-0 h-100"
  >
    <b-row
      no-gutters
      class="h-100"
    >
      <template v-if="isBotsLoading">
        <b-col />
        <b-col cols="auto">
          <b-spinner
            class="m-4"
            style="width: 4rem; height: 4rem;"
          />
        </b-col>
        <b-col />
      </template>
      <b-col v-else>
        <router-view />
      </b-col>
    </b-row>
    <bot-studio-toaster />
  </b-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import BotStudioToaster from '@/components/BotStudioToaster.vue';

export default {
  name: 'Bots',
  components: {
    BotStudioToaster,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.updateBotList();
      vm.getCustomNode();
      vm.fetchBotTemplates();
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name === 'bot') {
      this.updateBotList();
    }
    next();
  },
  computed: {
    ...mapState('botManipulation', ['isBotsLoading']),
    ...mapState(['sidebarOpen']),
  },
  methods: {
    ...mapActions('botManipulation', [
      'updateBotList',
      'getCustomNode',
      'fetchBotTemplates',
    ]),
    ...mapActions('dataExploration', ['fetchAllDatasets']),
  },
};
</script>

<style scoped>

</style>
