<template>
  <div>
    <DescriptionBox v-if="isMaster">
      This activity will automatically generate a reply to the user based on the chat
      history/transcript and the selected context.<br />
      Use the filtering options to limit the generated responses.<br />
      The response variable contains information about the generated answer as well
      as the search engine article information in case a search engine context was used.<br />
      The standard functionality sends a reply if it was able to generate a helpful
      answer to the user. If not, the activity will force the bot to go to the fallback
      node.
      Advanced functionality skips message sending and fallback behaviour. Remember to
      design your flow to send a reply manually when using advanced functionality. Note
      that the content filters will not affect the generated answer but it will be
      included when computing the "was_useful" property of the response.
    </DescriptionBox>
    <h3>Context</h3>
    <ActivityFormGroup label="Select context">
      <b-form-select
        v-model="contextType"
        :disabled="!isMaster"
        :state="!$v.contextType.$invalid"
        :options="replyActionOptions"
        aria-describedby="contextTypeFeedback"
      />
      <b-form-feedback id="contextTypeFeedback">
        <div v-if="!$v.contextType.required">
          Context must be selected
        </div>
      </b-form-feedback>
    </ActivityFormGroup>
    <template v-if="getContextType === 'ranker'">
      <ActivityFormGroup label="Select Search Engine">
        <b-overlay :show="isFetchingRankers" spinner-small>
          <b-form-select
            v-model="ranker"
            :disabled="isOriginal"
            :options="rankerOptions"
            :state="isVariant ? null : !$v.ranker.$invalid"
            aria-describedby="rankerFeedback"
          />
          <b-form-feedback id="rankerFeedback">
            <div v-if="!$v.ranker.required">
              Search Engine must be selected.
            </div>
          </b-form-feedback>
        </b-overlay>
      </ActivityFormGroup>
      <ActivityFormGroup label="Search query">
        <b-form-checkbox
          v-model="useCustomQuery"
          :disabled="isOriginal"
          switch
          class="pt-1"
        >
          {{ useCustomQueryText }}
        </b-form-checkbox>
        <botscript-validation
          v-if="useCustomQuery"
          v-model="query"
          class="pt-1"
          :disabled="isOriginal"
          :expanded="false"
          :validations="isVariant ? [] : ['empty', 'typecheck-nonbool']"
        />
      </ActivityFormGroup>
    </template>
    <template v-else-if="getContextType === 'article'">
      <ActivityFormGroup v-if="!isOriginal" label="Search articles">
        <b-input-group>
          <b-input-group-prepend>
            <b-dropdown :text="`${getSelectedSearchFieldsText}`" toggle-class="search-btn">
              <b-dropdown-form>
                <b-form-checkbox-group
                  v-model="selectedSearchFields"
                  :options="searchOptions"
                  stacked
                />
              </b-dropdown-form>
            </b-dropdown>
          </b-input-group-prepend>
          <b-form-input
            v-model="searchKeyword"
            :disabled="isSearchingArticles"
            @keyup.enter="searchArticlesProxy()"
          />
          <b-input-group-append>
            <b-button
              variant="primary"
              :disabled="isSearchingArticles"
              @click="searchArticlesProxy()"
            >
              <b-spinner v-if="isSearchingArticles" small />
              <template v-else>
                Search
              </template>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </ActivityFormGroup>
      <ActivityFormGroup
        label="Select article"
        :state="!$v.getSelectedArticle.$invalid"
      >
        <b-overlay :show="isSearchingArticles">
          <b-dropdown
            :disabled="isOriginal"
            toggle-class="article-dropdown w-100 text-truncate"
            menu-class="select-menu bg-white text-dark pb-0"
            class="w-100"
            right
            :variant="$v.getSelectedArticle.$invalid ? 'danger' : ''"
          >
            <template #button-content>
              <template v-if="getSelectedArticle">
                {{ getSelectedArticle.text }}
                <b-link
                  v-b-tooltip.hover.noninteractive.viewport="'Open article in a new tab'"
                  class="float-right"
                  @click.stop="openArticle(getSelectedArticle.currentUrl)"
                >
                  <font-awesome-icon icon="external-link-alt" />
                </b-link>
              </template>
              <span v-else>
                Select article
              </span>
            </template>
            <div>
              <b-dropdown-form>
                <b-dropdown-item
                  v-for="(item, index) in supsearchArticles"
                  :key="index"
                  class="w-100 d-flex justify-content-between align-items-center"
                  @click="articleClicked('article', item.value)"
                >
                  <b-row>
                    <b-col class="article-text">
                      {{ item.text }}
                    </b-col>
                    <b-col cols="auto">
                      <b-link
                        v-b-tooltip.hover.noninteractive.viewport="'Open article in a new tab'"
                        @click.stop="openArticle(item.currentUrl)"
                      >
                        <font-awesome-icon icon="external-link-alt" />
                      </b-link>
                    </b-col>
                  </b-row>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="supsearchArticles.length === 0"
                  disabled
                >
                  <b-row>
                    <b-col>Search above to select an article</b-col>
                  </b-row>
                </b-dropdown-item>
              </b-dropdown-form>
            </div>
          </b-dropdown>
        </b-overlay>
        <template v-if="!$v.getSelectedArticle.required" slot="invalid-feedback">
          This field should not be empty
        </template>
        <small v-if="supsearchArticles.length === 100" class="text-warning">
          Found numerous articles that match your search, try refining your search query
        </small>
      </ActivityFormGroup>
    </template>
    <template v-else-if="getContextType == 'custom'">
      <ActivityFormGroup label="Manual context">
        <b-form-textarea
          v-model="customContext"
          :disabled="isOriginal"
          :state="!$v.customContext.$invalid"
          aria-describedby="customContextFeedback"
        />
        <b-form-invalid-feedback id="customContextFeedback">
          <div v-if="!$v.customContext.required">
            This field should not be empty
          </div>
        </b-form-invalid-feedback>
      </ActivityFormGroup>
    </template>
    <ActivityFormGroup
      v-if="isMaster"
      label="Additional context"
      class="mb-3 mt-3"
    >
      <b-row v-for="(item, index) of additionalContext" :key="index" class="mb-1">
        <b-col>
          <b-form-input
            :value="item.key"
            :state="!$v.additionalContext.$each[index].key.$invalid"
            @input="value=>updateAdditionalContextItem(index, 'key', value)"
          />
          <b-form-invalid-feedback>
            <template v-if="!$v.additionalContext.$each[index].key.required">
              This field should not be empty.
            </template>
          </b-form-invalid-feedback>
        </b-col>
        <b-col class="pr-0">
          <botscript-validation
            :value="item.value"
            :expanded="false"
            :validations="['empty', 'typecheck-nonbool']"
            @onChange="value => updateAdditionalContextItem(index, 'value', value)"
          />
        </b-col>
        <b-col cols="auto">
          <b-button @click="removeAdditionalContextItem(index)">
            <font-awesome-icon icon="trash-alt" />
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button
            v-b-tooltip.hover.noninteractive.viewport="'Add context'"
            variant="primary"
            class="px-2"
            size="sm"
            @click="addContext"
          >
            <font-awesome-icon icon="plus" />
          </b-button>
        </b-col>
      </b-row>
    </ActivityFormGroup>
    <hr />
    <h3>Control &amp; formatting</h3>
    <ActivityFormGroup label="Default template">
      <b-form-checkbox
        v-model="advancedValue"
        :disabled="!isMaster"
        switch
        class="pt-1"
      >
        Use default template for sending replies
      </b-form-checkbox>
    </ActivityFormGroup>
    <ActivityFormGroup v-if="getContextType == 'ranker' && !advanced" label="Feedback urls">
      <b-form-checkbox
        v-model="useFeedback"
        class="mt-1"
        :disabled="!isMaster"
        switch
      >
        Use SupSearch feedback URLs
      </b-form-checkbox>
    </ActivityFormGroup>
    <h4 class="mt-2">
      1. Check reply safeguards
    </h4>
    <ActivityFormGroup
      class="mb-1 mt-3"
      label="Must include all phrases"
    >
      <b-form-tags
        v-model="includeWords"
        :disabled="isOriginal"
        remove-on-delete
        placeholder="Add phrase..."
      />
    </ActivityFormGroup>
    <ActivityFormGroup label="Cannot include any of the phrases">
      <b-form-tags
        v-model="excludeWords"
        :disabled="isOriginal"
        placeholder="Add phrase..."
        remove-on-delete
      />
    </ActivityFormGroup>
    <h4>
      2. AI guards
    </h4>
    <h6 class="mt-2">
      Avoid hallucination in the generated AI response by applying different AI guards.
      NOTE: "Article Groundedness Guard" and "Answer Relevance Guard" may incur additional
      volume cost for GenAI. Setting the slider to 0 turns the guard off.
    </h6>
    <ActivityFormGroup
      label="Word Similarity Guard"
    >
      <b-form-input
        :value="replyJaccardSimThreshold"
        :disabled="isOriginal"
        type="range"
        :class="graphView ? '' : 'mt-2'"
        min="0"
        max="100"
        @input="setReplyJaccardSimThreshold"
      />
      <div style="float:right">
        {{ replyJaccardSimThreshold }}
      </div>
    </ActivityFormGroup>
    <ActivityFormGroup
      label="Semantic Similarity Guard"
    >
      <b-form-input
        :value="replyEmbeddingSimThreshold"
        :disabled="isOriginal"
        type="range"
        :class="graphView ? '' : 'mt-2'"
        min="0"
        max="100"
        @input="setReplyEmbeddingSimThreshold"
      />
      <div style="float:right">
        {{ replyEmbeddingSimThreshold }}
      </div>
    </ActivityFormGroup>
    <ActivityFormGroup
      label="Response Relevance Guard"
    >
      <b-form-input
        :value="replyAnswerRelevanceThreshold"
        :disabled="isOriginal"
        type="range"
        :class="graphView ? '' : 'mt-2'"
        min="0"
        max="100"
        @input="setReplyAnswerRelevanceThreshold"
      />
      <div style="float:right">
        {{ replyAnswerRelevanceThreshold }}
      </div>
    </ActivityFormGroup>
    <ActivityFormGroup
      label="Article Groundedness Guard"
    >
      <b-form-input
        :value="replyGptGroundednessThreshold"
        :disabled="isOriginal"
        type="range"
        :class="graphView ? '' : 'mt-2'"
        min="0"
        max="100"
        @input="setReplyGptGroundednessThreshold"
      />
      <div style="float:right">
        {{ replyGptGroundednessThreshold }}
      </div>
    </ActivityFormGroup>
    <template v-if="!advanced">
      <h4 class="mt-2">
        3. Send generated reply
      </h4>
      <template v-if="getContextType === 'ranker'">
        <ActivityFormGroup v-if="type === 'variant'" label="Override article threshold">
          <b-form-checkbox v-model="replyArticleThresholdEnabled" class="mt-1" switch />
        </ActivityFormGroup>
        <ActivityFormGroup label="Article confidence threshold">
          <b-form-input
            :value="replyArticleThreshold"
            :disabled="isOriginal || !replyArticleThresholdEnabled"
            type="range"
            :class="graphView ? '' : 'mt-2'"
            min="0"
            max="100"
            @input="setReplyArticleThreshold"
          />
          <div v-if="data.replyArticleThreshold !== -1" style="float:right">
            {{ data.replyArticleThreshold }}
          </div>
        </ActivityFormGroup>
      </template>
      <ActivityFormGroup label="Send article links">
        <b-form-checkbox
          v-model="replyWithLinks"
          :disabled="!isMaster"
          class="mt-1"
          switch
        >
          Add article links to the end of the generated reply
        </b-form-checkbox>
      </ActivityFormGroup>
      <ActivityFormGroup v-if="replyWithLinks" label="Link-text">
        <b-form-textarea
          v-model="replyWithLinksText"
          :disabled="isOriginal"
        />
      </ActivityFormGroup>
      <template v-if="replyWithLinks && getContextType === 'custom'">
        <ActivityFormGroup label="Article title">
          <b-form-input
            v-model.trim="customContextTitle"
            :disabled="isOriginal"
            :class="graphView ? '' : 'mt-2'"
          />
        </ActivityFormGroup>
        <ActivityFormGroup label="Article URL">
          <b-form-input
            v-model.trim="customContextLink"
            :disabled="isOriginal"
            :class="graphView ? '' : 'mt-2'"
          />
        </ActivityFormGroup>
      </template>
    </template>
    <template v-if="getContextType === 'ranker' && !advanced">
      <h4 class="mt-2">
        4. Otherwise send article links
      </h4>
      <ActivityFormGroup v-if="type === 'variant'" label="Override link threshold">
        <b-form-checkbox v-model="linksArticleThresholdEnabled" class="mt-1" switch />
      </ActivityFormGroup>
      <ActivityFormGroup label="Confidence threshold for sending article links">
        <b-form-input
          :value="linksArticleThreshold"
          :disabled="isOriginal || !linksArticleThresholdEnabled"
          type="range"
          :class="graphView ? '' : 'mt-2'"
          min="0"
          max="100"
          @input="setLinksArticleThreshold"
        />
        <div v-if="data.linksArticleThreshold !== -1" style="float:right">
          {{ data.linksArticleThreshold }}
        </div>
      </ActivityFormGroup>
      <ActivityFormGroup label="Link-text">
        <b-form-textarea
          v-model="linksText"
          :disabled="isOriginal"
          :class="graphView ? '' : 'mt-2'"
        />
      </ActivityFormGroup>
    </template>
    <template v-if="!advanced">
      <h4 class="mt-2">
        {{ fallbackHeader }}
      </h4>
      <ActivityFormGroup label="Custom fallback node">
        <template v-if="!customFallbackNode">
          <CompletionInput
            class="d-inline-block"
            placeholder="Choose fallback node"
            :completions="nodeCompletions"
            value=""
            :disabled="!isMaster"
            @input="setCustomFallbackNode"
          />
          <b-button
            v-if="isMaster"
            v-b-modal="customFallbackModalId"
            variant="success"
            :disabled="!isMaster"
            style="margin-left:10px"
            pill
          >
            <font-awesome-icon icon="plus" />
            Create new fallback node
          </b-button>
          <p class="text-muted mt-1">
            Leave empty to go to the default fallback node
          </p>
        </template>
        <div v-else>
          <b-button
            pill
            size="md"
            variant="primary"
            :to="editNodeLink(customFallbackNode)"
          >
            {{ nameOfId(customFallbackNode) }}
            <font-awesome-icon
              :icon="['far', 'times-circle']"
              size="lg"
              @click.prevent="() => { customFallbackNode = ''; }"
            />
          </b-button>
        </div>
        <AddNodeModal
          title="Create fallback node"
          :modal-id="customFallbackModalId"
          @nodeAdded="(value) => { customFallbackNode = value; }"
        />
      </ActivityFormGroup>
      <hr />
    </template>
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import CompletionInput from 'supwiz/components/CompletionInput.vue';
import { validationMixin } from 'vuelidate';
import { changeOrAddParam } from '@/js/utils';
import { required, requiredIf } from 'vuelidate/lib/validators';
import { cloneDeep } from 'lodash';
import AddNodeModal from '@/pages/TreeView/AddNodeModal.vue';
import BotscriptValidation from '@/components/BotscriptValidation.vue';
import { isSupSearchEnabled } from '@/js/featureFlags';
import DescriptionBox from './DescriptionBox.vue';
import ActivityFormGroup from './ActivityFormGroup.vue';

function mapParams(params) {
  const map = {};
  for (const param of params) {
    map[param] = {
      get() {
        if (param in this.data) {
          return this.data[param];
        }
        return this.getBotParam(param).value;
      },
      set(value) {
        this.$emit('input', { param, value });
      },
    };
  }
  return map;
}

export default {
  name: 'GenerateReply',
  components: {
    BotscriptValidation,
    DescriptionBox,
    ActivityFormGroup,
    CompletionInput,
    AddNodeModal,
  },
  mixins: [validationMixin],
  props: {
    type: {
      type: String,
      default: 'master',
    },
    data: {
      type: Object,
      required: true,
    },
    shown: {
      type: Boolean,
      required: true,
    },
    activityId: {
      type: String,
      required: true,
    },
    nodeId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      searchKeyword: '',
      selectedSearchFields: ['title', 'text', 'current_url'],
      searchOptions: [
        { text: 'Title', value: 'title' },
        { text: 'Text', value: 'text' },
        { text: 'Current url', value: 'current_url' },
      ],
      supsearchArticles: [],
      showExtraContents: false,
    };
  },
  computed: {
    ...mapState('supsearch', ['rankers', 'isSearchingArticles', 'isFetchingRankers']),
    ...mapParams([
      'contextType', 'ranker', 'query', 'useCustomQuery', 'customContext', 'includeWords',
      'excludeWords', 'additionalContext', 'advanced',
      'replyArticleThreshold', 'replyGptGroundednessThreshold',
      'replyJaccardSimThreshold', 'linksArticleThreshold',
      'replyAnswerRelevanceThreshold', 'replyEmbeddingSimThreshold',
      'replyWithLinks', 'replyWithLinksText',
      'linksText', 'useFeedback', 'customFallbackNode', 'customContextLink', 'customContextTitle',
    ]),
    ...mapGetters('botManipulation/activeBot', [
      'nodesAsList',
      'nameOfId',
    ]),
    currentNode() {
      return this.nodesAsList.find((e) => e.id === this.nodeId);
    },
    botActivity() {
      return this.currentNode.activities[this.activityId];
    },
    isMaster() {
      return this.type === 'master';
    },
    isOriginal() {
      return this.type === 'original';
    },
    isVariant() {
      return this.type === 'variant';
    },
    getContextType() {
      return this.isMaster ? this.contextType : this.data.contextType;
    },
    graphView() {
      return this.$route.name === 'graph';
    },
    getSelectedSearchFieldsText() {
      return this.selectedSearchFields.map((e) => this.searchOptions
        .find((o) => o.value === e).text).join(', ');
    },
    fieldTypes() {
      /*
        summary: this should generate a summary of the chat
        prompt: here the user can enter a prompt like "list all ticket ids sent by the user"
        variable: just grabs and outputs a state variable
      */
      return [
        { value: 'prompt', text: 'AI data extraction' },
        { value: 'variable', text: 'BotScript value' },
      ];
    },
    replyActionOptions() {
      let options = [
        { value: 'custom', text: 'Manual' },
      ];
      if (isSupSearchEnabled()) {
        options = [
          { value: 'ranker', text: 'Search engine' },
          { value: 'article', text: 'Article' },
          ...options,
        ];
      }
      return options;
    },
    paramIndex() {
      return this.params.findIndex((e) => e.key === this.param);
    },
    rankerOptions() {
      return [{ text: 'Select search engine', value: null }].concat(this.rankers.map((e) => ({ value: e.id, text: e.name })));
    },
    getSelectedArticle() {
      return this.supsearchArticles.find((e) => e.value === this.data.article) || null;
    },
    dataArray() {
      return Object.entries(this.data).map(([k, v]) => ({ key: k, value: v }));
    },
    replyArticleThresholdEnabled: {
      get() {
        return this.data.replyArticleThreshold !== -1;
      },
      set(value) {
        this.$emit('input', { param: 'replyArticleThreshold', value: value ? 80 : -1 });
      },
    },
    linksArticleThresholdEnabled: {
      get() {
        return this.data.linksArticleThreshold !== -1;
      },
      set(value) {
        this.$emit('input', { param: 'linksArticleThreshold', value: value ? 40 : -1 });
      },
    },
    nodeCompletions() {
      const { nodes } = this.$store.state.botManipulation.activeBot;
      const completions = [];
      for (const { id, name } of Object.values(nodes)) {
        if (id !== this.nodeId) {
          completions.push({ key: id, value: name });
        }
      }
      return completions;
    },
    customFallbackModalId() {
      return `custom-fallback-modal-${this._uid}`;
    },
    fallbackHeader() {
      if (this.getContextType === 'ranker') {
        return '5. Otherwise go to fallback';
      }
      return '4. Go to fallback if not useful';
    },
    advancedValue: {
      get() {
        return !this.advanced;
      },
      set(value) {
        this.advanced = !value;
      },
    },
    useCustomQueryText() {
      return this.useCustomQuery ? 'Provide search query' : 'Search automatically';
    },
  },
  watch: {
    async shown(newValue) {
      if (newValue) {
        await this.onShown();
      }
    },
  },
  methods: {
    ...mapActions('supsearch', ['fetchRankers', 'searchArticles']),
    async onShown() {
      if (!this.rankers.length && isSupSearchEnabled()) {
        this.fetchRankers();
      }
      const article = this.data.article;
      if (article) {
        this.searchArticlesProxy(article);
      }
    },
    articleClicked(key, value) {
      this.$emit('input', { param: key, value });
    },
    addContext() {
      const newValue = this.data.additionalContext.concat([{ key: '', value: '' }]);
      const newParams = changeOrAddParam(this.dataArray, 'additionalContext', newValue, -1);
      this.$emit('setParams', newParams);
    },
    updateAdditionalContextItem(index, prop, value) {
      const contextArray = cloneDeep(this.data.additionalContext);
      contextArray[index][prop] = value;
      const newParams = changeOrAddParam(this.dataArray, 'additionalContext', contextArray, -1);
      this.$emit('setParams', newParams);
    },
    removeAdditionalContextItem(index) {
      const contextArray = cloneDeep(this.data.additionalContext);
      contextArray.splice(index, 1);
      const newParams = changeOrAddParam(this.dataArray, 'additionalContext', contextArray, -1);
      this.$emit('setParams', newParams);
    },
    async searchArticlesProxy(id = null) {
      this.supsearchArticles = [];
      let articles = [];
      if (id !== null) {
        articles = await this.searchArticles({ id });
      } else {
        articles = await this.searchArticles(
          { query: this.searchKeyword, search_fields: this.selectedSearchFields });
      }
      this.supsearchArticles = articles
        .map((e) => ({ text: e.title, value: e.id, currentUrl: e.current_url }));
    },
    openArticle(url) {
      window.open(url, '_blank');
    },
    setReplyArticleThreshold(value) {
      const intValue = parseInt(value, 10);
      if (this.linksArticleThreshold > intValue) {
        this.linksArticleThreshold = intValue;
      }
      this.replyArticleThreshold = intValue;
    },
    setReplyGptGroundednessThreshold(value) {
      this.replyGptGroundednessThreshold = parseInt(value, 10);
    },
    setReplyJaccardSimThreshold(value) {
      this.replyJaccardSimThreshold = parseInt(value, 10);
    },
    setReplyAnswerRelevanceThreshold(value) {
      this.replyAnswerRelevanceThreshold = parseInt(value, 10);
    },
    setReplyEmbeddingSimThreshold(value) {
      this.replyEmbeddingSimThreshold = parseInt(value, 10);
    },
    setLinksArticleThreshold(value) {
      const intValue = parseInt(value, 10);
      // The following is a trick to make sure the range component updates correctly.
      this.linksArticleThreshold = intValue;
      this.$nextTick(() => {
        this.linksArticleThreshold = this.replyArticleThreshold !== -1
          ? Math.min(intValue, this.replyArticleThreshold) : intValue;
      });
    },
    setCustomFallbackNode(value) {
      this.customFallbackNode = value;
    },
    getBotParam(key) {
      return this.botActivity.params.find((e) => e.key === key);
    },
  },
  validations() {
    return {
      contextType: { required },
      ranker: {
        required: requiredIf(() => this.contextType === 'ranker'),
      },
      additionalContext: {
        $each: {
          key: { required },
          value: { required },
        },
      },
      customContext: {
        required: requiredIf(() => this.contextType === 'custom'),
      },
      getSelectedArticle: {
        required: requiredIf(() => this.contextType === 'article'),
      },
    };
  },
};
</script>

<style scoped>
::v-deep .spinner-border{
  color: white !important;
  border-radius: 50% !important;
}
::v-deep .select-options {
  max-height: 180px;
  max-width: 500px;
  min-width: 400px;
  overflow-y: auto;
}
:deep(.article-dropdown) {
  background-color: white !important;
  color: #111f2d !important;
}
:deep(.article-dropdown:hover), :deep(.article-dropdown:focus), :deep(.article-dropdown:active) {
  box-shadow: inset 0 0 2px 2px #70d3ff;
}
::v-deep .select-menu{
  max-height: 400px;
  max-width: 600px;
  overflow-y:auto;
  overflow-x: hidden;
}
::v-deep .article-text{
  white-space: initial;
}
</style>
