<template>
  <draggable
    v-model="textBlocks"
    :options="draggableOptions"
  >
    <div v-for="(item, index) in textBlocks" :key="index">
      <b-row v-if="item.isPhrase">
        <b-col cols="auto" class="pr-1 draggable-handle">
          <b-button size="sm" variant="text" disabled class="mt-3 cursor-move">
            <font-awesome-icon icon="arrows-up-down-left-right" />
          </b-button>
        </b-col>
        <b-col>
          <phrase-building-block
            :id="phrase2Id(item.value)"
            @remove="v=>removePhraseFromResponse(index)"
            @edit="v=>$emit('editPhrase', v)"
          />
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col cols="auto" class="pr-1 draggable-handle">
          <b-button size="sm" variant="text" disabled class="mt-3 cursor-move">
            <font-awesome-icon icon="arrows-up-down-left-right" />
          </b-button>
        </b-col>
        <b-col v-if="wysiwygEnabled">
          <quill-wrapper :text.sync="content" />
          <response-activity-enhance
            v-if="enableResponseEnhancement"
            :text-element="() => $refs.textArea[0]"
            class="position-absolute m-1"
            size="sm"
            variant="link"
            style="right: 14px; top: 3px;"
            @result="replaceSelected"
          />
        </b-col>
        <template v-else>
          <b-col class="pr-0">
            <b-form-textarea
              ref="textArea"
              :key="'input_' + nodeId + activityId"
              v-model="content"
              :placeholder="textAreaPlaceholder"
              :required="inputRequired"
              rows="3"
              class="r-25"
              no-auto-shrink
            />
          </b-col>
          <b-col v-if="enableResponseEnhancement" cols="auto" class="pl-0">
            <response-activity-enhance
              :text-element="() => $refs.textArea[0]"
              size="sm"
              variant="link"
              @result="replaceSelected"
            />
          </b-col>
        </template>
      </b-row>
    </div>
  </draggable>
</template>
<script>
import Draggable from 'vuedraggable';
import { mapGetters, mapMutations } from 'vuex';
import { cloneDeep } from 'lodash';
import QuillWrapper from '@/components/QuillWrapper.vue';
import PhraseBuildingBlock from '@/pages/Phrases/PhraseBuildingBlock.vue';
import ResponseActivityEnhance from '@/pages/EditNode/activity/ResponseActivityEnhance.vue';
import { PHRASE_REGEX } from '@/js/constants';
import { addThisArgs, applyThisArgs } from '@/js/storeHelpers';
import { mapPhrases, phrase2Id } from '@/js/utils';

export default {
  name: 'DraggableResponseElements',
  components: {
    QuillWrapper,
    PhraseBuildingBlock,
    Draggable,
    ResponseActivityEnhance,
  },
  props: {
    nodeId: {
      type: String,
      required: true,
    },
    activityId: {
      type: String,
      required: true,
    },
    inputRequired: {
      type: Boolean,
      default: false,
    },
    maxLength: {
      type: Number,
      default: 0,
    },
    allowEnchancement: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      draggableOptions: {
        handle: '.draggable-handle',
      },
    };
  },
  computed: {
    ...mapGetters('botManipulation/activeBot/config', [
      'wysiwygEnabled',
    ]),
    ...applyThisArgs(
      mapGetters('botManipulation/activeBot', {
        getResponseText: 'activityText',
      }),
      'nodeId',
      'activityId',
    ),
    ...mapGetters('userSettings', ['showAIFeatures']),
    enableResponseEnhancement() {
      return this.showAIFeatures && this.allowEnchancement;
    },
    text: {
      get() {
        return this.getResponseText;
      },
      set(text) {
        this.setResponseText({ text });
      },
    },
    textBlocks: {
      get() {
        return mapPhrases(this.text);
      },
      set(blocks) {
        this.text = blocks.map((e) => e.value).join('');
      },
    },
    content: {
      get() {
        return this.textBlocks.find((e) => !e.isPhrase)?.value;
      },
      set(text) {
        let cleaned = text;
        const matches = text.match(PHRASE_REGEX);
        if (matches) {
          matches.forEach((e) => {
            if (this.phrasesDict[this.phrase2Id(e)]) {
              this.addPhraseToResponse({ id: this.phrase2Id(e) });
            }
          });
          cleaned = text.split(PHRASE_REGEX).filter((e) => e.length > 0).join('');
        }
        const blocksCopy = cloneDeep(this.textBlocks);
        let finalText = '';
        blocksCopy.forEach((block) => {
          finalText += block.isPhrase ? block.value : cleaned;
        });
        this.text = finalText;
      },
    },
    textAreaPlaceholder() {
      let placeholder = 'Write response...';
      if (this.maxLength > 0) {
        placeholder += ` Maximum length of final string: ${this.maxLength} characters.`;
      }
      return placeholder;
    },
  },
  methods: {
    ...addThisArgs(
      mapMutations('botManipulation/activeBot', {
        setResponseText: 'setActivityResponseText',
      }),
      { nodeId: 'nodeId', activityId: 'activityId' },
    ),
    phrase2Id,
    async replaceSelected(txtToReplace) {
      const textArea = this.$refs.textArea[0];
      if (textArea.selectionStart !== textArea.selectionEnd) {
        await this.insertAtMarker(txtToReplace);
      } else {
        this.text = txtToReplace;
      }
    },
    removePhraseFromResponse(index) {
      const copy = cloneDeep(this.textBlocks);
      copy.splice(index, 1);
      this.textBlocks = copy;
    },
  },
};
</script>
<style scoped>
.cursor-move{
  cursor:move;
}
</style>
