import { render, staticRenderFns } from "./SingleSignOn.vue?vue&type=template&id=5bfb0150&scoped=true&"
import script from "./SingleSignOn.vue?vue&type=script&lang=js&"
export * from "./SingleSignOn.vue?vue&type=script&lang=js&"
import style0 from "./SingleSignOn.vue?vue&type=style&index=0&id=5bfb0150&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../products/botstudio/frontend/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bfb0150",
  null
  
)

export default component.exports