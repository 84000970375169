import { createSsoEndpoints } from 'supwiz/util/sso';

const BACKEND_PATH = '/api';
export default {
  ...createSsoEndpoints(BACKEND_PATH),
  obtainJWT: `${BACKEND_PATH}/auth/obtain-token/`,
  refreshJWT: `${BACKEND_PATH}/auth/refresh-token/`,
  verifyJWT: `${BACKEND_PATH}/auth/verify-token/`,
  getPermissions: `${BACKEND_PATH}/auth/permissions/`,
  botsBase: `${BACKEND_PATH}/bots/`,
  jsonHistory: `${BACKEND_PATH}/json-history/`,
  botTemplates: `${BACKEND_PATH}/bot-templates/`,
  customNode: `${BACKEND_PATH}/custom-node/`,
  subFlows: `${BACKEND_PATH}/sub-flows/`,
  unitTests: `${BACKEND_PATH}/unittests/`,
  unitTestRunner: `${BACKEND_PATH}/run-unittest/`,
  unitTestTermination: `${BACKEND_PATH}/terminate-unittest/`,
  stagedBots: `${BACKEND_PATH}/staged-bots/`,
  diffTwoBots: `${BACKEND_PATH}/diff-two-bots/`,
  downloadStagedBot: `${BACKEND_PATH}/download-staged-bot/`,
  downloadBot: `${BACKEND_PATH}/download-bot/`,
  nodeLabelDistinctActualLabels: `${BACKEND_PATH}/node-label-distinct-actual-labels/`,
  nodeLabelDistinctValues: `${BACKEND_PATH}/node-label-distinct-values/`,
  nodeDetailsGroups: `${BACKEND_PATH}/kpi-node-group/`,
  nodeStatsNodes: `${BACKEND_PATH}/node-stats-nodes/`,
  nodeLevelStats: `${BACKEND_PATH}/node-level-stats/`,
  kpis: `${BACKEND_PATH}/kpis/`,
  statisticsConfig: `${BACKEND_PATH}/statistics-config/`,
  classifiers: `${BACKEND_PATH}/classifier/`,
  classifiersLight: `${BACKEND_PATH}/classifier-light/`,
  languageModels: `${BACKEND_PATH}/language-model/`,
  nodeInterpretations: `${BACKEND_PATH}/node-interpretations/`,
  diagnostics: `${BACKEND_PATH}/diagnostics/`,
  chatlogsSearch: `${BACKEND_PATH}/chatlogs/search/`,
  chatlogsOrigin: `${BACKEND_PATH}/chatlog-origins/`,
  chatlogsLanguages: `${BACKEND_PATH}/chatlog-languages/`,
  chatlogsStartUrls: `${BACKEND_PATH}/chatlog-start-urls/`,
  chatlogsVariants: `${BACKEND_PATH}/chatlog-variants/`,
  chatlogsSingle: `${BACKEND_PATH}/chatlogs/get-single/`,
  chatlogsBotId: `${BACKEND_PATH}/chatlogs/get-bot-id/`,
  chatlogsAudio: `${BACKEND_PATH}/chatlogs/audio/`,
  chatlogsExcludeFromTraining: `${BACKEND_PATH}/chatlogs/exclude-from-training/`,
  labels: `${BACKEND_PATH}/label/`,
  labelsTask: `${BACKEND_PATH}/label-task/`,
  labelFile: `${BACKEND_PATH}/label-file/`,
  labelNode: `${BACKEND_PATH}/label-node/`,
  labelNodeOptions: `${BACKEND_PATH}/label-node-options/`,
  labelExternal: `${BACKEND_PATH}/label-external/`,
  trainTask: `${BACKEND_PATH}/train-task/`,
  phrases: `${BACKEND_PATH}/phrases/`,
  evaluateScript: `${BACKEND_PATH}/eval-script/`,
  scriptVariables: `${BACKEND_PATH}/script-variables/`,
  scriptVariablesDetailed: `${BACKEND_PATH}/script-variables-detailed/`,
  dataExplorationDatasets: `${BACKEND_PATH}/data-exploration/dataset/`,
  dataExplorationDataSources: `${BACKEND_PATH}/data-exploration/datasource/`,
  dataExplorationDataSourceDeletionTaskStatus: `${BACKEND_PATH}/data-exploration/datasource/deletionstatus/`,
  dataExplorationCategories: `${BACKEND_PATH}/data-exploration/category/`,
  dataExporationCategoryMerge: `${BACKEND_PATH}/data-exploration/category-merge/`,
  dataExplorationData: `${BACKEND_PATH}/data-exploration/data/`,
  dataExplorationSampleDataId: `${BACKEND_PATH}/data-exploration/sample_data_for_labeling/`,
  dataExplorationLabel: `${BACKEND_PATH}/data-exploration/label/`,
  dataExplorationStats: `${BACKEND_PATH}/data-exploration/statistics/`,
  dataExplorationDataPoint: `${BACKEND_PATH}/data-exploration/datapoint/`,
  dataExplorationStatsExamples: `${BACKEND_PATH}/data-exploration/statistics-examples/`,
  dataExplorationAllData: `${BACKEND_PATH}/data-exploration/all_data/`,
  dataExplorationVisualization: `${BACKEND_PATH}/data-exploration/visualization/`,
  dataExplorationVisualizationData: `${BACKEND_PATH}/data-exploration/visualization-data/`,
  dataExplorationVisualizationExamples: `${BACKEND_PATH}/data-exploration/visualization-examples/`,
  dataExplorationDatasetDeletionTaskStatus: `${BACKEND_PATH}/data-exploration/dataset/deletionstatus/`,
  user: `${BACKEND_PATH}/administration/user/`,
  userList: `${BACKEND_PATH}/administration/user/list/`,
  userDetails: `${BACKEND_PATH}/user-details/`,
  group: `${BACKEND_PATH}/administration/group/`,
  lockUser: `${BACKEND_PATH}/administration/user/lock/`,
  health: `${BACKEND_PATH}/bothealth/`,
  healthTaskConfig: `${BACKEND_PATH}/bothealth/config/`,
  healthSuggestions: `${BACKEND_PATH}/bothealth/health-suggestions/`,
  flowPotentialChats: `${BACKEND_PATH}/bothealth/flow-potential-chats/`,
  insights: `${BACKEND_PATH}/insights/`,
  botSecrets: `${BACKEND_PATH}/bot-secrets/`,
  revealBotSecret: `${BACKEND_PATH}/bot-secrets/reveal/`,
  previewAPICall: `${BACKEND_PATH}/inspect-api-call/`,
  invokeIntegrationTestCall: `${BACKEND_PATH}/api-call/`,
  responseAudio: `${BACKEND_PATH}/response-audio/`,
  speechText: `${BACKEND_PATH}/speech-text/`,
  voices: `${BACKEND_PATH}/voices/`,
  userSettings: `${BACKEND_PATH}/user/settings/`,
  classifierVersion: `${BACKEND_PATH}/classifier-version/`,
  task: `${BACKEND_PATH}/task/`,
  variants: `${BACKEND_PATH}/variants/`,
  variantTranslation: `${BACKEND_PATH}/variant-translation/`,
  variantsSingle: `${BACKEND_PATH}/variants-single/`,
  botNodeExamples: `${BACKEND_PATH}/bot-node-examples/`,
  phraseIntegrationConfig: `${BACKEND_PATH}/phrase-integration-config/`,
  phraseIntegration: `${BACKEND_PATH}/phrase-integration/`,
  botAnonymizationConfigId: `${BACKEND_PATH}/bot-anonymization-config-id/`,
  image: `${BACKEND_PATH}/image/`,
  translateClassifierData: `${BACKEND_PATH}/translate-classifier-data/`,
  newClassifierVariant: `${BACKEND_PATH}/new-classifier-variant/`,
  bookmarks: `${BACKEND_PATH}/bookmarks/`,
  externalAuthToken: `${BACKEND_PATH}/auth-keys/`,
  queryLabel: `${BACKEND_PATH}/query-label/`,
  labelDataset: `${BACKEND_PATH}/label-data-set/`,
  aiResponses: `${BACKEND_PATH}/aiengine/responses/`,
  supSearchRanker: `${BACKEND_PATH}/supsearch/ranker/`,
  supSearchArticle: `${BACKEND_PATH}/supsearch/article/`,
  mediaFolder: `${BACKEND_PATH}/media/folder/`,
  latestTestResult: `${BACKEND_PATH}/latest-test-result/`,
  genAiTestResult: `${BACKEND_PATH}/gen-ai-test-result/`,
  genAiFeedback: `${BACKEND_PATH}/update-gen-ai-feedback/`,
};
