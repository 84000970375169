import axios from 'axios';
import endpoints from '@/js/urls';
import { evaluateDiscoverableFeatures } from '@/js/discoverableFeatures';
import { isTranslationEnabled, isVoicebotEnabled, isGenerativeAiEnabled } from '@/js/featureFlags';
import { voicePlatforms, apiModes } from '@/js/constants';

const initSettingState = {
  botStudioSettings: {
    show_qa_page: true,
    show_variants_page: true,
    show_tooltips: true,
    show_demo_panel_default: true,
    demo_panel_default_api: apiModes.MOCK,
    show_voicebot_features: isVoicebotEnabled(),
    show_ai_features: isGenerativeAiEnabled(),
    show_translation_features: isTranslationEnabled(),
    favorite_bots: [],
    dismissed_features: [],
  },
  workingAsync: false,
  discoverableFeatures: [],
};

const getters = {
  highlightedFeatures: (state) => state.discoverableFeatures,
  getBotStudioSettings: (state) => (state.botStudioSettings),
  tooltipsOn: (state) => (state.botStudioSettings.show_tooltips),
  showVoicebotFeatures: (state) => state.botStudioSettings.show_voicebot_features,
  showTranslationFeatures: (state) => state.botStudioSettings.show_translation_features,
  showAIFeatures: (state) => state.botStudioSettings.show_ai_features,
  isVoicebotPlatform: (state) => (platforms) => {
    if (!state.botStudioSettings.show_voicebot_features) {
      return false;
    }
    for (const p of Object.values(voicePlatforms)) {
      if (platforms.indexOf(p) >= 0) {
        return true;
      }
    }
    return false;
  },
};

const mutations = {
  setBotStudioSettings(state, botStudioSettings) {
    state.botStudioSettings = Object.assign(state.botStudioSettings, botStudioSettings);
  },
  setWorkingAsync(state, workingAsync) {
    state.workingAsync = workingAsync;
  },
  setFeatures(state, features) {
    state.discoverableFeatures = features;
  },
  addDismissedFeature(state, key) {
    state.botStudioSettings.dismissed_features.push(key);
  },
  setShowVoicebotFeatures(state, enable) {
    state.botStudioSettings.show_voicebot_features = !!enable;
  },
  setShowTranslationFeatures(state, enable) {
    state.botStudioSettings.show_translation_features = !!enable;
  },
  setShowAIFeatures(state, enable) {
    state.botStudioSettings.show_ai_features = !!enable;
  },
};

const actions = {
  async fetchBotStudioSettings({ rootState, commit, dispatch }) {
    commit('setWorkingAsync', true);
    try {
      const config = {
        headers: { Authorization: `JWT ${rootState.auth.jwt}` },
      };
      const resp = await axios.get(endpoints.userSettings, config);
      commit('setBotStudioSettings', resp.data);
    } catch (e) {
      dispatch('sidebar/showWarning', {
        title: 'Error',
        text: 'Could not load BotStudio user settings',
        variant: 'danger',
      }, { root: true });
    } finally {
      commit('setWorkingAsync', false);
    }
  },
  async addFavoriteBot({ rootState, commit, dispatch }, botId) {
    commit('setWorkingAsync', true);
    try {
      const config = {
        headers: { Authorization: `JWT ${rootState.auth.jwt}` },
      };
      const data = {
        update_type: 'add_favorite_bot',
        bot_id: botId,
      };
      const resp = await axios.patch(endpoints.userSettings, data, config);
      commit('setBotStudioSettings', resp.data);
    } catch (e) {
      dispatch('sidebar/showWarning', {
        title: 'Error',
        text: 'Could not save favorite bot',
        variant: 'danger',
      }, { root: true });
    } finally {
      commit('setWorkingAsync', false);
    }
  },
  async removeFavoriteBot({ rootState, commit, dispatch }, botId) {
    commit('setWorkingAsync', true);
    try {
      const config = {
        headers: { Authorization: `JWT ${rootState.auth.jwt}` },
      };
      const data = {
        update_type: 'remove_favorite_bot',
        bot_id: botId,
      };
      const resp = await axios.patch(endpoints.userSettings, data, config);
      commit('setBotStudioSettings', resp.data);
    } catch (e) {
      dispatch('sidebar/showWarning', {
        title: 'Error',
        text: 'Could not delete favorite bot',
        variant: 'danger',
      }, { root: true });
    } finally {
      commit('setWorkingAsync', false);
    }
  },
  async toggleShowVariants({
    rootState, state, commit, dispatch,
  }) {
    commit('setWorkingAsync', true);
    try {
      const config = {
        headers: { Authorization: `JWT ${rootState.auth.jwt}` },
      };
      const data = {
        update_type: 'update_settings',
        show_variants_page: !state.botStudioSettings.show_variants_page,
      };
      const resp = await axios.patch(endpoints.userSettings, data, config);
      commit('setBotStudioSettings', resp.data);
    } catch (e) {
      console.log(e);
      dispatch('sidebar/showWarning', {
        title: 'Error',
        text: 'Could not toggle variants page',
        variant: 'danger',
      }, { root: true });
    } finally {
      commit('setWorkingAsync', false);
    }
  },
  async toggleShowQA({
    rootState, state, commit, dispatch,
  }) {
    commit('setWorkingAsync', true);
    try {
      const config = {
        headers: { Authorization: `JWT ${rootState.auth.jwt}` },
      };
      const data = {
        update_type: 'update_settings',
        show_qa_page: !state.botStudioSettings.show_qa_page,
      };
      const resp = await axios.patch(endpoints.userSettings, data, config);
      commit('setBotStudioSettings', resp.data);
    } catch (e) {
      dispatch('sidebar/showWarning', {
        title: 'Error',
        text: 'Could not toggle QA page',
        variant: 'danger',
      }, { root: true });
    } finally {
      commit('setWorkingAsync', false);
    }
  },
  async toggleShowTooltips({
    rootState, state, commit, dispatch,
  }) {
    commit('setWorkingAsync', true);
    try {
      const config = {
        headers: { Authorization: `JWT ${rootState.auth.jwt}` },
      };
      const data = {
        update_type: 'update_settings',
        show_tooltips: !state.botStudioSettings.show_tooltips,
      };
      const resp = await axios.patch(endpoints.userSettings, data, config);
      commit('setBotStudioSettings', resp.data);
    } catch (e) {
      dispatch('sidebar/showWarning', {
        title: 'Error',
        text: 'Could not toggle tooltips',
        variant: 'danger',
      }, { root: true });
    } finally {
      commit('setWorkingAsync', false);
    }
  },
  async toggleDemoPanelDefaultApiMode({
    rootState, state, commit, dispatch,
  }) {
    commit('setWorkingAsync', true);
    try {
      const config = {
        headers: { Authorization: `JWT ${rootState.auth.jwt}` },
      };
      const newValue = state.botStudioSettings.demo_panel_default_api === apiModes.MOCK
        ? apiModes.MOCK_EXCEPT_GEN_AI
        : apiModes.MOCK;
      const data = {
        update_type: 'update_settings',
        demo_panel_default_api: newValue,
      };
      const resp = await axios.patch(endpoints.userSettings, data, config);
      commit('setBotStudioSettings', resp.data);
    } catch (e) {
      dispatch('sidebar/showWarning', {
        title: 'Error',
        text: 'Could not toggle demo panel default api setting',
        variant: 'danger',
      }, { root: true });
    } finally {
      commit('setWorkingAsync', false);
    }
  },
  async toggleShowDemoPanel({
    rootState, state, commit, dispatch,
  }) {
    commit('setWorkingAsync', true);
    try {
      const config = {
        headers: { Authorization: `JWT ${rootState.auth.jwt}` },
      };
      const data = {
        update_type: 'update_settings',
        show_demo_panel_default: !state.botStudioSettings.show_demo_panel_default,
      };
      const resp = await axios.patch(endpoints.userSettings, data, config);
      commit('setBotStudioSettings', resp.data);
    } catch (e) {
      dispatch('sidebar/showWarning', {
        title: 'Error',
        text: 'Could not toggle demo panel default setting',
        variant: 'danger',
      }, { root: true });
    } finally {
      commit('setWorkingAsync', false);
    }
  },
  async dismissFeature({
    state, commit, rootState, rootGetters,
  }, key) {
    const config = {
      headers: { Authorization: `JWT ${rootState.auth.jwt}` },
    };
    const resp = await axios.patch(endpoints.userSettings,
      {
        update_type: 'update_settings',
        dismissed_features: state.botStudioSettings.dismissed_features.concat(key),
      },
      config);
    if (resp.status === 200) {
      commit('addDismissedFeature', key);
      commit('setFeatures', evaluateDiscoverableFeatures(state, rootGetters));
    }
  },
  calculateDiscoverableFeatures({ state, rootGetters, commit }) {
    commit('setFeatures', evaluateDiscoverableFeatures(state, rootGetters));
  },
};

export default {
  namespaced: true,
  state: initSettingState,
  getters,
  mutations,
  actions,
};
