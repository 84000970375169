import { platforms } from '@/js/constants';

const generateReplyMockResponse = {
  answer: 'If you are having trouble logging in, you can click on the \'Login problem\' link under'
        + ' the login form to receive an email that will help you reset your password.',
  articles: [{
    external_id: '2',
    extract: 'In order to reset your password, you can go to http://app.com/login',
    id: 2,
    link: 'http://articles.com/login-issue',
    link_with_feedback: 'https://supsearch.mock/redirect/dummy-ID',
    pinned: false,
    score: 0.99,
    source: '',
    summary: null,
    title: 'Login issues',
  }],
  number_of_articles_used: 1,
  suggestionID: 'dummy-ID',
  was_useful: true,
};

export default [
  {
    name: 'Generate chat summary',
    description: 'Generate a configurable summary from the chat.',
    id: 'ai.generateSummary',
    params: [{ name: 'fields', default: [] }],
    extraParams: [{ name: 'mockResponse', default: '' }],
    excludeInNodes: ['init'],
    supportedIn: [...Object.values(platforms)],
    target: 'summary',
    allowsExpansion: true,
  },
  {
    name: 'Generate AI reply',
    description: 'Generate AI reply from provided context.',
    id: 'ai.generateReply',
    params: [
      // IMPORTANT: THIS MUST BE KEPT ALIGNED WITH THE FOLLOWING FILE:
      // https://github.com/SupWiz/supwiz/blob/master/src/supwiz/bot_framework/templates/default_actions.json
      // IF NOT KEPT IN SYNC VARIANTS FUNCTIONALITY WILL BREAK COMPLETELY!
      { name: 'contextType', default: '' },
      { name: 'includeWords', default: [] },
      { name: 'excludeWords', default: [] },
      { name: 'additionalContext', default: [] },
      // contextType == ranker
      { name: 'ranker', default: '' },
      { name: 'query', default: 'thisMessage' },
      { name: 'useCustomQuery', default: false },
      // contextType == article
      { name: 'article', default: '' },
      // contextType == custom
      { name: 'customContext', default: '' },
      { name: 'advanced', default: false },
      // Sending reply
      { name: 'replyArticleThreshold', default: 80 },
      { name: 'replyGptGroundednessThreshold', default: 0 },
      { name: 'replyJaccardSimThreshold', default: 0 },
      { name: 'replyAnswerRelevanceThreshold', default: 0 },
      { name: 'replyEmbeddingSimThreshold', default: 0 },
      { name: 'linksArticleThreshold', default: 40 },
      { name: 'replyWithLinks', default: true },
      { name: 'replyWithLinksText', default: 'Additional info can be found in these articles:' },
      { name: 'customContextTitle', default: '' },
      { name: 'customContextLink', default: '' },
      { name: 'linksText', default: 'Please check one of the articles below for more info:' },
      { name: 'useFeedback', default: true },
      { name: 'customFallbackNode', default: '' },
      // IMPORTANT: SEE COMMENT ABOVE ABOUT KEEPING THIS IN SYNC WITH BACKEND!!!!
    ],
    extraParams: [{ name: 'mockResponse', default: JSON.stringify(generateReplyMockResponse) }],
    supportedIn: Object.values(platforms),
    excludeInNodes: ['init'],
    target: 'reply_target',
    allowsExpansion: true,
  },
  {
    name: 'Formulate AI reply',
    description: '(Re)formulate AI reply from provided reply',
    id: 'ai.formulateReply',
    params: [
      { name: 'additionalContext', default: [] },
    ],
    extraParams: [],
    text: '',
    responseApproved: false,
    supportedIn: Object.values(platforms),
    excludeInNodes: ['init'],
    target: null,
    allowsExpansion: true,
  },
  // This is a special Action for when removing stuff
  {
    name: 'MISSING AI ACTION',
    description: 'This AI action is not supported in current version or no longer supported.',
    id: 'MISSING_AI_ACTION',
    params: [],
    extraParams: [],
    supportedIn: [],
    excludeInNodes: [],
    target: null,
    allowsExpansion: false,
    text: '',
    responseApproved: false,
  },
];
