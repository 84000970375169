import Vue from 'vue';

const initialState = {
  companyName: '',
  historyCount: 3,
  gptPersona: null,
  allowedEmojis: [],
  personaConditions: {
    negative: null,
    neutral: null,
    positive: null,
  },
  userFaced: true,
};

const generativeAIGetters = {
  getCompanyName(state) {
    return state.companyName;
  },
  getHistoryCount(state) {
    return state.historyCount;
  },
  getGptPersona(state) {
    return state.gptPersona;
  },
  getAllowedEmojis(state) {
    return state.allowedEmojis || [];
  },
  getPersonaConditions(state) {
    return state.personaConditions || {};
  },
  getUserFaced(state) {
    return state.userFaced;
  },
  getWordReplacements(state) {
    return state.wordReplacements || [];
  },
};

const mutations = {
  setCompanyName(state, { name }) {
    state.companyName = name;
  },
  setHistoryCount(state, { count }) {
    state.historyCount = count;
  },
  setGptPersona(state, { persona }) {
    state.gptPersona = persona;
  },
  setAllowedEmojis(state, { emojis }) {
    state.allowedEmojis = emojis;
  },
  setPersonaCondition(state, { sentiment, value }) {
    if (!state.personaConditions) {
      state.personaConditions = {};
    }
    Vue.set(state.personaConditions, sentiment, value);
  },
  setUserFaced(state, { value }) {
    state.userFaced = value;
  },
  addWordReplacement(state, payload) {
    if (state.wordReplacements === undefined) {
      Vue.set(state, 'wordReplacements', [payload]);
    } else {
      state.wordReplacements.push(payload);
    }
  },
  updateWordReplacement(state, {
    index, key, value, followCasing,
  }) {
    Vue.set(state.wordReplacements, index, { key, value, followCasing });
  },
  removeWordReplacement(state, index) {
    state.wordReplacements.splice(index, 1);
  },
};

const actions = {
};

export default {
  namespaced: true,
  state: () => initialState,
  getters: generativeAIGetters,
  mutations,
  actions,
};
