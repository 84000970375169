<template>
  <b-modal
    id="new-activity-modal"
    scrollable
    title="Choose an activity"
    ok-only
    ok-title="Cancel"
    @hidden="newActivityType = null"
  >
    <b-breadcrumb>
      <b-breadcrumb-item
        v-for="item in breadcrumbItems"
        :key="item.id"
        :text="item.displayName"
        :disabled="item.disabled"
        @click="item.clickHandler"
      />
    </b-breadcrumb>

    <div v-if="newActivityType === null">
      <b-btn
        v-if="allowedActivities.has(RESPONSE)"
        block
        variant="info"
        @click="setActivityType(RESPONSE)"
      >
        Response
        <tooltipped-text
          value="Respond with text."
          novariant
        />
      </b-btn>
      <b-btn
        v-if="allowedActivities.has(COMPOUND_RESPONSE) && compoundSupported()"
        block
        variant="primary"
        @click="setActivityType(COMPOUND_RESPONSE)"
      >
        Rich Response
        <tooltipped-text
          value="Response with additional components (e.g. buttons, image, title)."
          novariant
        />
      </b-btn>
      <b-btn
        v-if="allowedActivities.has(SET_VARIABLE)"
        block
        variant="secondary"
        @click="setActivityType(SET_VARIABLE)"
      >
        Set variable
        <tooltipped-text
          value="Store information in a variable."
          novariant
        />
      </b-btn>
      <b-btn
        v-if="allowedActivities.has(ACTION)"
        block
        variant="dark"
        @click="setActivityType(ACTION)"
      >
        Integration
        <tooltipped-text
          class="mr-1"
          value="Call an external service."
          novariant
        />
        <font-awesome-icon
          icon="angle-right"
        />
      </b-btn>
      <b-btn
        v-if="allowedActivities.has(CHAT_ACTION)"
        block
        variant="success"
        @click="setActivityType(CHAT_ACTION)"
      >
        Platform Action
        <tooltipped-text
          class="mr-1"
          value="Perform an action inherent to the bot or the chat system."
          novariant
        />
        <font-awesome-icon
          icon="angle-right"
        />
      </b-btn>
      <b-btn
        v-if="allowedActivities.has(CONTROL_FLOW)"
        block
        variant="danger"
        @click="setActivityType(CONTROL_FLOW)"
      >
        Control flow
        <tooltipped-text
          class="mr-1"
          value="Execute activities conditionally or repeatedly."
          novariant
        />
        <font-awesome-icon
          icon="angle-right"
        />
      </b-btn>
      <b-btn
        v-if="allowedActivities.has(CALL_MODEL)"
        block
        variant="primary"
        @click="setActivityType(CALL_MODEL)"
      >
        Call model
        <tooltipped-text
          value="Evaluate a classifier on a custom input."
          novariant
        />
      </b-btn>
      <b-btn
        v-if="allowedActivities.has(METRIC_SIGNAL)"
        block
        variant="dark"
        @click="setActivityType(METRIC_SIGNAL)"
      >
        Metric Signal
        <tooltipped-text
          value="Track visits to the node in the SupWiz Dashboard."
          novariant
        />
      </b-btn>
      <b-btn
        v-if="allowedActivities.has(ENCRYPT)"
        block
        variant="warning"
        @click="setActivityType(ENCRYPT)"
      >
        Encrypt Data
        <tooltipped-text
          value="Encrypts a string using a secret."
          novariant
        />
      </b-btn>
      <b-btn
        v-if="allowedActivities.has(GENERATIVE_AI)"
        :disabled="generativeAIDisabled"
        block
        variant="primary"
        @click="setActivityType(GENERATIVE_AI)"
      >
        Generative AI
        <tooltipped-text
          value="Use AI powered tools to generate or enhance content."
          novariant
        />
        <font-awesome-icon
          icon="angle-right"
        />
        <font-awesome-icon
          v-if="generativeAIDisabled"
          v-b-tooltip.hover.noninteractive.viewport="'The bot MUST have a language specified. Talk to your SupWiz representative if your bot has user data before changing the language.'"
          icon="exclamation-circle"
          class="text-warning ml-2"
        />
      </b-btn>
      <b-btn
        v-if="allowedActivities.has(SUP_WIZ_INTEGRATION)"
        block
        variant="primary"
        @click="setActivityType(SUP_WIZ_INTEGRATION)"
      >
        SupWiz Integrations
        <tooltipped-text
          value="Integrations with other SupWiz products"
          novariant
        />
        <font-awesome-icon
          icon="angle-right"
        />
      </b-btn>
    </div>
    <div v-if="newActivityType === ACTION">
      <div v-if="actionNames.length">
        Choose the name of the integration:
        <b-btn
          v-for="actionName in actionNames"
          :key="actionName"
          block
          variant="primary"
          @click="() => setActionName(actionName)"
        >
          {{ truncateString(actionName, 40) }}
        </b-btn>
      </div>
      <div v-else>
        You have not defined any <router-link :to="{ name: 'integrations' }">
          Integrations.
        </router-link>
      </div>
    </div>
    <div v-if="newActivityType === CHAT_ACTION">
      <template v-if="supportedChatActions.length == 0">
        No actions are available. You'll need to configure what platform this bot targets.<br>
        You do so in
        <ConfigLink category="platforms">
          Platforms
        </ConfigLink>
      </template>
      <template v-else>
        Pick an action below
        <template v-for="chatAction in supportedChatActions">
          <b-btn
            v-if="chatActionSupported(chatAction)"
            :key="chatAction.name"
            block
            variant="success "
            @click="() => setChatAction(chatAction)"
          >
            {{ truncateString(chatAction.name, 40) }}
            <tooltipped-text
              :value="tooltipDescription(chatAction)"
              novariant
            />
          </b-btn>
        </template>
      </template>
    </div>
    <div v-if="newActivityType === GENERATIVE_AI">
      Pick an action below
      <b-btn
        v-for="aiAction in supportedAiActions"
        :key="aiAction.name"
        block
        variant="primary"
        @click="setGenerativeAIAction(aiAction)"
      >
        {{ truncateString(aiAction.name, 40) }}
        <tooltipped-text
          :value="tooltipDescription(aiAction)"
          novariant
        />
      </b-btn>
    </div>
    <div v-if="newActivityType === SUP_WIZ_INTEGRATION">
      Pick an action below
      <b-btn
        v-for="action in supportedSupWIzActions"
        :key="action.name"
        block
        variant="primary"
        @click="setSupWizAction(action)"
      >
        {{ truncateString(action.name, 40) }}
        <tooltipped-text
          :value="tooltipDescription(action)"
          novariant
        />
      </b-btn>
    </div>
    <div v-if="newActivityType === CONTROL_FLOW">
      Choose the control flow statement:
      <b-btn
        v-for="(controlFlowObj, controlFlowIdx) in controlFlows"
        :key="controlFlowIdx"
        block
        variant="danger"
        @click="() => setControlFlow(controlFlowObj)"
      >
        {{ truncateString(controlFlowObj.name) }}
        <tooltipped-text
          :value="controlFlowObj.description"
          novariant
        />
      </b-btn>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { intersection } from 'lodash';
import { addThisArgs } from '@/js/storeHelpers';
import {
  ACTION, CHAT_ACTION, RESPONSE, SET_VARIABLE, CONTROL_FLOW, CALL_MODEL,
  ALL_ACTIONS, METRIC_SIGNAL, ENCRYPT, COMPOUND_RESPONSE, GENERATIVE_AI, SUP_WIZ_INTEGRATION,
} from '@/js/activity';
import controlFlows from '@/js/controlFlow';
import { truncateString } from '@/js/utils';
import ConfigLink from '@/pages/BotConfig/ConfigLink.vue';
import TooltippedText from '@/components/TooltippedText.vue';
import { compoundResponseTypes } from '@/js/constants';
import generativeAIActions from '@/js/generativeAIActions';
import supWizActions from '@/js/supWizActions';

export default {
  name: 'NewActivityModal',
  components: { TooltippedText, ConfigLink },
  props: {
    nodeId: {
      type: String,
      required: true,
    },
    allowedActivities: {
      type: Set,
      default: () => new Set(ALL_ACTIONS),
    },
  },
  data() {
    return {
      newActivityType: null,
      ACTION,
      CALL_MODEL,
      CHAT_ACTION,
      COMPOUND_RESPONSE,
      CONTROL_FLOW,
      ENCRYPT,
      GENERATIVE_AI,
      SUP_WIZ_INTEGRATION,
      METRIC_SIGNAL,
      RESPONSE,
      SET_VARIABLE,
      controlFlows,
    };
  },
  computed: {
    ...mapGetters('botManipulation', {
      actionNames: 'getBotActionsNames',
      chatActions: 'getChatActions',
    }),
    ...mapGetters('botManipulation/activeBot/config', [
      'getPlatforms',
      'getRoutingLanguage',
    ]),
    ...mapGetters('userSettings', ['tooltipsOn']),
    breadcrumbItems() {
      const breadcrumbItems = [];
      breadcrumbItems.push({
        displayName: 'All activities',
        id: 'allActivities',
        disabled: false,
        clickHandler: () => this.goToStart(),
      });
      if (this.newActivityType === null) {
        return breadcrumbItems;
      }
      if (this.newActivityType === ACTION) {
        breadcrumbItems.push({
          displayName: 'Integrations',
          id: 'allIntegrations',
          disabled: true,
          clickHandler: () => console.log('no-op'), // is disabled anyway
        });
      } else if (this.newActivityType === CHAT_ACTION) {
        breadcrumbItems.push({
          displayName: 'Platform actions',
          id: 'chatActions',
          disabled: true,
          clickHandler: () => this.goToStart(),
        });
      } else if (this.newActivityType === METRIC_SIGNAL) {
        breadcrumbItems.push({
          displayName: 'Metric Signal',
          id: 'metricSignal',
          disabled: true,
          clickHandler: () => this.goToStart(),
        });
      } else if (this.newActivityType === CONTROL_FLOW) {
        breadcrumbItems.push({
          displayName: 'Control flow',
          id: 'controlFlow',
          disabled: true,
          clickHandler: () => this.goToStart(),
        });
      } else if (this.newActivityType === GENERATIVE_AI) {
        breadcrumbItems.push({
          displayName: 'Generative AI',
          id: GENERATIVE_AI,
          disabled: true,
          clickHandler: () => this.goToStart(),
        });
      } else if (this.newActivityType === SUP_WIZ_INTEGRATION) {
        breadcrumbItems.push({
          displayName: 'SupWiz Integrations',
          id: SUP_WIZ_INTEGRATION,
          disabled: true,
          clickHandler: () => this.goToStart(),
        });
      }
      return breadcrumbItems;
    },
    supportedChatActions() {
      return this.chatActions.filter((chatAction) => this.chatActionSupported(chatAction));
    },
    supportedAiActions() {
      return generativeAIActions.filter((aiAction) => this.aiActionSupported(aiAction));
    },
    supportedSupWIzActions() {
      return supWizActions.filter((supWizAction) => this.supWizActionSupported(supWizAction));
    },
    tooltipDescription() {
      return (chatAction) => {
        const description = chatAction.description;
        const supportedPlatForms = chatAction.supportedIn.join(', ');
        return `${description} \n\n Supported in ${supportedPlatForms}`;
      };
    },
    generativeAIDisabled() {
      if (!this.allowedActivities.has(GENERATIVE_AI)) {
        return true;
      }
      return !this.getRoutingLanguage;
    },
  },
  methods: {
    truncateString,
    ...addThisArgs(mapActions('botManipulation/activeBot', [
      'addResponseActivity',
      'addCompoundResponseActivity',
      'addSetVariableActivity',
      'addActionActivity',
      'addGenerativeAIActionActivity',
      'addSupWizActionActivity',
      'addChatActionActivity',
      'addControlFlowActivity',
      'addCallModelActivity',
      'addMetricSignalActivity',
      'addEncryptActivity',
    ]), { nodeId: 'nodeId' }),
    goToStart() {
      this.newActivityType = null;
    },
    async setActivityType(activityType) {
      if (activityType === RESPONSE) {
        const activityId = await this.addResponseActivity({});
        this.afterAddingActivity(activityId);
      } else if (activityType === COMPOUND_RESPONSE) {
        const compoundType = this.firstCompoundSupported();
        const activityId = await this.addCompoundResponseActivity({ compoundType });
        this.afterAddingActivity(activityId);
      } else if (activityType === SET_VARIABLE) {
        const activityId = await this.addSetVariableActivity({});
        this.afterAddingActivity(activityId);
      } else if (activityType === ACTION) {
        this.newActivityType = ACTION;
      } else if (activityType === CHAT_ACTION) {
        this.newActivityType = CHAT_ACTION;
      } else if (activityType === CONTROL_FLOW) {
        this.newActivityType = CONTROL_FLOW;
      } else if (activityType === GENERATIVE_AI) {
        this.newActivityType = GENERATIVE_AI;
      } else if (activityType === SUP_WIZ_INTEGRATION) {
        this.newActivityType = SUP_WIZ_INTEGRATION;
      } else if (activityType === CALL_MODEL) {
        const activityId = await this.addCallModelActivity({});
        this.afterAddingActivity(activityId);
      } else if (activityType === METRIC_SIGNAL) {
        const activityId = await this.addMetricSignalActivity({});
        this.afterAddingActivity(activityId);
      } else if (activityType === ENCRYPT) {
        const activityId = await this.addEncryptActivity({});
        this.afterAddingActivity(activityId);
      } else {
        throw new Error(`Unknown activity type "${activityType}"`);
      }
    },
    async setActionName(name) {
      const activityId = await this.addActionActivity({ name });
      this.afterAddingActivity(activityId);
    },
    async setChatAction(chatAction) {
      const activityId = await this.addChatActionActivity({ chatAction });
      this.afterAddingActivity(activityId);
    },
    async setGenerativeAIAction(aiAction) {
      const activityId = await this.addGenerativeAIActionActivity({ aiAction });
      this.afterAddingActivity(activityId);
    },
    async setSupWizAction(action) {
      const activityId = await this.addSupWizActionActivity({ action });
      this.afterAddingActivity(activityId);
    },
    async setControlFlow(controlFlowObj) {
      const activityId = await this.addControlFlowActivity({ controlFlowObj });
      this.afterAddingActivity(activityId);
      if (controlFlowObj.name === 'for') {
        const endFor = this.controlFlows.find((element) => element.name === 'end for');
        this.setControlFlow(endFor);
      }
      if (controlFlowObj.name === 'if') {
        const endFor = this.controlFlows.find((element) => element.name === 'end if');
        this.setControlFlow(endFor);
      }
    },
    async setMetricSignalAction(metricLabel) {
      const activityId = await this.addMetricSignalActivity({ metricLabel });
      this.afterAddingActivity(activityId);
    },
    afterAddingActivity(activityId) {
      this.$bvModal.hide('new-activity-modal');
      this.$emit('activity-created', activityId);
    },
    chatActionSupported(chatAction) {
      const chatActionPlatforms = chatAction.supportedIn;
      const excludeNodes = chatAction.excludeInNodes;
      const botPlatforms = this.getPlatforms;
      const platformOk = chatActionPlatforms.some((x) => botPlatforms.includes(x));
      const nodeOk = !excludeNodes.includes(this.nodeId);
      return platformOk && nodeOk;
    },
    aiActionSupported(aiAction) {
      // We don't check platforms as it is irrelevant for AI engine
      const excludeNodes = aiAction.excludeInNodes;
      const nodeOk = !excludeNodes.includes(this.nodeId);
      const excludedActions = !['MISSING_AI_ACTION', 'ai.formulateReply'].includes(aiAction.id);
      return nodeOk && excludedActions;
    },
    supWizActionSupported(action) {
      const excludeNodes = action.excludeInNodes;
      const nodeOk = !excludeNodes.includes(this.nodeId);
      const excludedActions = !['MISSING_SUPWIZ_ACTION'].includes(action.id);
      return nodeOk && excludedActions;
    },
    compoundSupported() {
      const compondMessagePlatforms = [
        ...new Set(compoundResponseTypes.flatMap((ty) => ty.supportedIn))];
      const botPlatforms = this.getPlatforms;
      return compondMessagePlatforms.some((x) => botPlatforms.includes(x));
    },
    firstCompoundSupported() {
      const compound = compoundResponseTypes
        .find((c) => intersection(c.supportedIn, this.getPlatforms).length);
      return compound.id;
    },
    setNewActivityType(val) {
      this.newActivityType = val;
    },
  },
};
</script>

<style scoped>

</style>
