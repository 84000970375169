<template>
  <div
    class="robotmsg-wrapper ml-2"
  >
    <div
      v-if="showName"
      class="messageContent name"
    >
      Robot
    </div>
    <p
      class="messageContent mb-0"
      :class="showName ? 'pb-2' : 'py-2'"
    >
      <b-row>
        <b-col
          v-if="thisBotIsAVoicebot"
          cols="auto"
          class="pr-0"
        >
          <b-spinner
            v-if="audioLoading && isItMyTurnToPlayAudio(_uid)"
            small
          />
          <b-btn
            v-else
            v-b-tooltip.noninteractive
            variant="muted"
            class="p-0"
            size="sm"
            title="Play this message"
            @click.stop="audioObjectIsPlaying
              ? pauseBotMessage()
              : playBotMessageClicked()"
          >
            <font-awesome-icon
              :icon="`${audioObjectIsPlaying ? 'pause' : 'play'}-circle`"
            />
          </b-btn>
        </b-col>
        <b-col :class="thisBotIsAVoicebot ? 'pl-2 my-auto' : 'my-auto'">
          <template
            v-for="(content, index) in messageComponents"
          >
            <br
              v-if="content.type === 'linebreak'"
              :key="index"
            >
            <span
              v-else-if="content.type === 'text'"
              :key="index"
              :class="isCompoundPanel ? 'cursor-pointer' : ''"
              style="white-space: pre-wrap; overflow-wrap: break-word;"
              @click="textClicked"
            >
              <!-- eslint-disable vue/no-v-html -->
              <span
                v-if="wysiwygEnabled"
                class="wysiwyg-msg-wrapper"
                v-html="content.text"
              />
              <span
                v-else
                v-html="formatMarkdownToHtml(content.text)"
              />
              <!-- eslint-enable -->
            </span>
            <h5
              v-else-if="content.type === 'title'"
              :key="index"
              :class="isCompoundPanel ? 'cursor-pointer' : ''"
              class="mb-0 my-1"
              @click="isCompoundPanel ? openLink(compoundPanelLink) : null"
            >
              <strong>{{ content.title }}</strong>
            </h5>
            <b-btn
              v-else-if="content.type === 'optionLink'"
              :key="index"
              class="optionButton"
              block
              @click="openLink(content)"
            >
              {{ content.text }}
            </b-btn>
            <b-btn
              v-else-if="content.type === 'option'"
              :key="index"
              :title="content.optionDescription"
              class="optionButton"
              block
              :disabled="!content.keepOnReply && hasUserResponse"
              @click="userPicksOption(content.optionText)"
            >
              {{ content.optionText }}
            </b-btn>
            <span
              v-else-if="content.type === 'ssml'"
              :key="index"
              class="small ssmltag bg-primary text-white"
            >
              {{ content.ssmlText }}
            </span>
            <template
              v-else-if="content.type === 'image'"
            >
              <b-img
                :key="'image' + index"
                :class="isCompoundPanel ? 'cursor-pointer' : ''"
                class="imageSizing"
                :src="content.imageAddress"
                :alt="content.alternativeText"
                @click="isCompoundPanel ? openLink(compoundPanelLink) : null"
              />
              <br
                :key="'linebreakAfter' + index"
              >
            </template>
          </template>
        </b-col>
      </b-row>
    </p>
  </div>
</template>

<script>

import { mapActions, mapGetters, mapMutations } from 'vuex';
import { formatText } from 'supwiz/util/formatters';
import { ssmlRegex } from '@/js/voicebotTags';

export default {
  name: 'RobotMessage',
  props: {
    message: {
      type: String,
      required: true,
    },
    showName: {
      type: Boolean,
      required: true,
    },
    hasUserResponse: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      messageComponents: [],
      soundForMessage: null,
      audioObject: null,
      audioLoaded: false,
      audioLoading: false,
      audioObjectIsPlaying: false,
      isCompoundPanel: false,
      compoundPanelLink: null,
      errorMsg: null,
    };
  },
  computed: {
    ...mapGetters('demopanel', [
      'autoplayMessages',
    ]),
    ...mapGetters('audio', [
      'isItMyTurnToPlayAudio',
    ]),
    ...mapGetters('botManipulation', ['activeBotId']),
    ...mapGetters('botManipulation/activeBot/config', [
      'getPlatforms', 'wysiwygEnabled',
    ]),
    ...mapGetters('userSettings', ['isVoicebotPlatform']),
    thisBotIsAVoicebot() {
      return this.isVoicebotPlatform(this.getPlatforms);
    },
    shouldPlayAudio() {
      return this.isItMyTurnToPlayAudio(this._uid) && this.audioLoaded;
    },
  },
  watch: {
    shouldPlayAudio(newValue) {
      if (newValue) {
        // Start playing audio
        this.playBotMessage();
      } else {
        // It's debatable whether we should stop or pause, for now we just pause
        this.pauseBotMessage();
      }
    },
    autoplayMessages(autoplayMessages) {
      // Users may toggle the autoplay-message in order to make it stop playing the messages
      if (!autoplayMessages && this.audioObject !== null && this.audioObjectIsPlaying) {
        this.audioObject.pause();
      }
    },
  },
  async mounted() {
    this.messageComponents = this.splitMessageIntoComponents(this.message, []);

    this.isCompoundPanel = !!this.messageComponents.find((e) => e.type === 'title');
    if (this.thisBotIsAVoicebot) {
      if (this.autoplayMessages) {
        // If auto-play is on, we should queue our message
        this.reserveSpotInPlaybackQueue({
          componentId: this._uid,
        });
      }
      // Eagerly ask backend for the sound-file, so we're (more like to be) ready when user clicks
      // the play icon
      this.audioLoading = true;
      try {
        const response = await this.synthesizeText({
          botId: this.activeBotId,
          responseText: this.audioMessage(this.message),
        });
        this.soundForMessage = response;
        if (response.error) {
          this.errorMsg = response.error;
          this.soundForMessage = null;
        }
        this.audioLoaded = true;
      } catch {
        this.audioLoaded = false;
      }
      this.audioLoading = false;
    }
  },
  destroyed() {
    if (this.audioObjectIsPlaying) this.pauseBotMessage();
    this.unregisterComponent({ componentId: this._uid });
  },
  methods: {
    ...mapActions('audio', [
      'reserveSpotInPlaybackQueue',
      'componentEndedPlayback',
      'unregisterComponent',
    ]),
    ...mapMutations('audio', [
      'clearQueue',
    ]),
    ...mapActions('botManipulation/activeBot', [
      'synthesizeText',
    ]),
    ...mapActions('sidebar', ['showWarning']),
    audioMessage(message) {
      return message.replaceAll('[option] ', '');
    },
    /**
     * Extract links, images, options and text
     */
    splitMessageIntoComponents(remainingText, identifiedComponents) {
      const imageRecognizer = new RegExp('!\\[([^\\]]*)\\]\\(([^\\)]*)\\)');
      const descriptionRecognizer = new RegExp(/ description="([^"]*)"/g);
      const keepOnReplyRecognizer = new RegExp(/ keepOnReply/g);
      const optionRecognizer = new RegExp(
        `\\[option((?:${descriptionRecognizer.source}|${keepOnReplyRecognizer.source})*)\\](.*)\\n*`,
      );
      const optionLinkRecognizer = new RegExp('\\[optionLink\\] \\[([^\\]]*)\\]\\(([^\\)]*)\\)\\n*');
      const titleRecognizer = new RegExp('\\*\\*\\*(.*)\\*\\*\\*\\n*');
      const ssmlRecognizer = ssmlRegex;

      let firstMatchIndex = 10 ** 10;

      let foremostMatch = null;
      let identifiedType = null;

      // Locate all images
      if (!this.wysiwygEnabled) {
        const imageMatches = remainingText.match(imageRecognizer);
        if (imageMatches !== null) {
          const matchIndex = imageMatches.index;
          if (matchIndex < firstMatchIndex) {
            firstMatchIndex = matchIndex;
            foremostMatch = imageMatches;
            identifiedType = 'image';
          }
        }
      }

      // Locate all titles
      const titleMatches = remainingText.match(titleRecognizer);
      if (titleMatches !== null) {
        const matchIndex = titleMatches.index;
        if (matchIndex < firstMatchIndex) {
          firstMatchIndex = matchIndex;
          foremostMatch = titleMatches;
          identifiedType = 'title';
        }
      }

      // Locate all option links
      const optionLinkMatches = remainingText.match(optionLinkRecognizer);
      if (optionLinkMatches !== null) {
        const matchIndex = optionLinkMatches.index;
        if (matchIndex < firstMatchIndex) {
          firstMatchIndex = matchIndex;
          foremostMatch = optionLinkMatches;
          identifiedType = 'optionLink';
        }
      }

      // Locate all options
      const optionMatches = remainingText.match(optionRecognizer);
      if (optionMatches !== null) {
        const matchIndex = optionMatches.index;
        if (matchIndex < firstMatchIndex) {
          firstMatchIndex = matchIndex;
          foremostMatch = optionMatches;
          identifiedType = 'option';
        }
      }

      // Locate all options
      if (this.thisBotIsAVoicebot) {
        const ssmlMatches = [...remainingText.matchAll(ssmlRecognizer)];
        if (ssmlMatches.length > 0) {
        // Just pick the first match (and later recursion will deal with the remainder)
          const matchIndex = ssmlMatches[0].index;
          if (matchIndex < firstMatchIndex) {
            firstMatchIndex = matchIndex;
            foremostMatch = ssmlMatches[0];
            identifiedType = 'ssml';
          }
        }
      }

      if (foremostMatch !== null) {
        // Put anything appearing before match on the stack initially
        const preText = remainingText.slice(0, firstMatchIndex);
        if (preText.replace(/\s/g, '')) {
          identifiedComponents.push({
            type: 'text',
            text: preText,
          });
        }

        // Put it on the stack
        if (identifiedType === 'image') {
          const alternativeText = foremostMatch[1];
          const imageAddress = foremostMatch[2];
          identifiedComponents.push({
            type: 'image',
            alternativeText,
            imageAddress,
          });
        } else if (identifiedType === 'title') {
          const titleText = foremostMatch[1];
          identifiedComponents.push({
            type: 'title',
            title: titleText,
          });
        } else if (identifiedType === 'optionLink') {
          const optionText = foremostMatch[1];
          const optionLink = foremostMatch[2];
          identifiedComponents.push({
            type: 'optionLink',
            text: optionText,
            linkAddress: optionLink,
          });
        } else if (identifiedType === 'option') {
          const attribText = foremostMatch[1];
          const optionText = foremostMatch[3].trim();

          // identify attributes
          const optionMatch = [...attribText.matchAll(descriptionRecognizer)];
          const optionDescription = optionMatch.length > 0
            ? optionMatch.at(-1)[1].trim() : undefined;
          const keepOnReply = attribText.match(keepOnReplyRecognizer) !== null;

          identifiedComponents.push({
            type: 'option',
            optionText,
            optionDescription,
            keepOnReply,
          });
        } else if (identifiedType === 'optionDescription') {
          const optionDescription = foremostMatch[1].trim();
          const optionText = foremostMatch[2].trim();
          identifiedComponents.push({
            type: 'option',
            optionText,
            optionDescription,
          });
        } else if (identifiedType === 'ssml') {
          const ssmlText = foremostMatch[0]
            .replace(/<|\/?>/g, '')
            .trim();
          identifiedComponents.push({
            type: 'ssml',
            ssmlText,
          });
        } else {
          console.error(`Unknown type: ${identifiedType}`);
        }

        // Recurse on the remainder of the text
        const remainingTextStartIndex = firstMatchIndex + foremostMatch[0].length;
        const remainderText = remainingText.substring(remainingTextStartIndex);
        return this.splitMessageIntoComponents(remainderText, identifiedComponents);
      }

      // We had just plain text (left)!
      if (remainingText) {
        identifiedComponents.push({
          type: 'text',
          text: remainingText,
        });
      }
      return identifiedComponents;
    },
    userPicksOption(optionText) {
      this.$emit('userPickedOption', optionText);
    },
    updateAudioObjectIsPlaying(newValue) {
      this.audioObjectIsPlaying = newValue;
    },
    pauseBotMessage() {
      this.audioObject.pause();
    },
    playBotMessage() {
      if (this.soundForMessage === null) {
        return;
      }
      if (this.audioObject === null) {
        // Construct audio object from raw data
        const base64audio = Buffer.from(this.soundForMessage.data, 'binary').toString('base64');
        const audioData = `data:${this.soundForMessage.type};base64,${base64audio}`;
        this.audioObject = new Audio(audioData);
        const self = this;
        this.audioObject.addEventListener('ended', () => self.playbackEnded());
        this.audioObject.addEventListener('ended', () => self.updateAudioObjectIsPlaying(false));
        this.audioObject.addEventListener('pause', () => self.updateAudioObjectIsPlaying(false));
        this.audioObject.addEventListener('play', () => self.updateAudioObjectIsPlaying(true));
      }
      // Start playing
      this.audioObject.play();
    },
    playBotMessageClicked() {
      if (this.errorMsg) {
        this.showWarning({
          title: 'Failed to synthesize text',
          text: this.errorMsg,
          variant: 'danger',
        });
        return;
      }
      if (this.audioObject !== null && this.audioObject.paused) {
        // The audio was paused, the user now wishes the audio to continue playing
        this.audioObject.play();
        return;
      }

      // Put this message in queue front (future will tell if below approach is too crude)
      this.clearQueue();
      this.reserveSpotInPlaybackQueue({
        componentId: this._uid,
      });

      this.playBotMessage();
    },
    playbackEnded() {
      // Signal to audio-store that this component ended playback
      this.componentEndedPlayback({ componentId: this._uid });
    },
    openLink(link) {
      if (link) {
        window.open(link.linkAddress, '_blank');
      }
    },
    formatMarkdownToHtml(txt) {
      const self = this;
      const formattedParts = formatText('robot', txt);
      function formatPart(part) {
        if (part.type === 'heading') {
          return `<h2>${part.text}</h2>`;
        }
        if (part.type === 'break') {
          return '<br>';
        }
        if (part.type === 'url') {
          /**
           * Workaround to remove link from compound panel messages, where we don't want to show the
           * link For puzzel genericCard compound messages, the link will have a description that
           * does not equal 'Link', unless the user explicitely chooses this description,
           * so for those the link is kept
           */
          if (self.isCompoundPanel && part.description === 'Link') {
            self.compoundPanelLink = {
              linkAddress: part.url,
            };
            return '';
          }
          return `<a href=${part.url} target="blank">${part.description}</a>`;
        }
        if (part.type === 'list') {
          const listDeclarator = part.ordered ? 'ol' : 'ul';
          const wrappedItems = part.items.map((x) => `<li>${self.formatMarkdownToHtml(x)}</li>`);
          return `<${listDeclarator}>${wrappedItems.join('')}</${listDeclarator}>`;
        }
        return part.text;
      }
      return formattedParts.map(formatPart).join('').trimEnd();
    },
    textClicked(event) {
      if (event.srcElement.tagName !== 'A' && this.isCompoundPanel) {
        this.openLink(this.compoundPanelLink);
      }
    },
  },
};
</script>

<style scoped>
.robotmsg-wrapper{
    border-radius: 15px;
    width: max-content;
    max-width: 90%;
    background: rgba(240, 240, 240, 0.65);
}
  .imageSizing {
    width: 100%
  }

  .optionButton {
    padding-right: 15px;
    margin-top: 5px;
  }

  .ssmltag {
    display: inline-block;
    border-radius: 100px;
    font-weight: bold;
    padding: 0 0.5rem;
    margin-bottom: 0.05rem;
  }

</style>
<style>
.wysiwyg-msg-wrapper p {
  margin-bottom: 1em !important;
}
.inlineLink{
  display: inline-block;
}
</style>
