<template>
  <main
    role="main"
  >
    <b-card
      class="r-75"
      body-class="p-3"
    >
      <b-row>
        <b-col>
          <b-card-title>
            Bot History
          </b-card-title>
        </b-col>
        <b-col cols="auto">
          <b-btn
            variant="primary"
            @click="goBack"
          >
            <font-awesome-icon icon="arrow-left" />
            Back
          </b-btn>
        </b-col>
      </b-row>
      <p>
        This shows the history of changes for the current bot.
      </p>

      <hr>
      <b-row class="mb-2" align-v="center">
        <b-col cols="auto">
          <b-pagination
            v-model="page"
            size="sm"
            class="my-auto"
            :total-rows="botHistorySize"
            :per-page="pageSize"
            last-number
          />
        </b-col>
        <b-col>
          <b-input-group>
            <b-form-select
              v-model="selectedNode"
              :options="nodeOptions"
            />
            <b-input
              v-model="text"
              placeholder="Enter text to search"
            />
            <b-input-group-append>
              <b-button
                variant="primary"
                :disabled="botHistoryLoading"
                @click="fetchBotHistoryPage(true)"
              >
                <b-spinner
                  v-if="botHistoryLoading"
                  small
                  class="spinner"
                />
                Search
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>

      <b-card
        v-for="d in history"
        :key="d.history_date"
        class="p-0 mt-0 mb-0"
        body-class="p-0 mt-0 mb-0 "
      >
        <b-card-header
          role="tab"
          header-class="p-0 pt-1 r-25"
        >
          <b-button
            block
            @click="showDetails = showDetails === d.history_date ? null : d.history_date"
          >
            <b-row align-v="center">
              <b-col cols="auto" class="pl-3 pr-0">
                <font-awesome-icon :icon="showDetails === d.history_date ? 'angle-down' : 'angle-right'" />
              </b-col>
              <b-col cols="auto" class="pl-2">
                <time-ago :iso="d.history_date" />
                by {{ getDisplayNameFromId(d.history_user) }}
              </b-col>
              <b-col class="text-left">
                {{ d.history_change_reason }}
              </b-col>
              <b-col cols="auto">
                <div
                  v-b-tooltip="{ customClass: 'large-tooltip' }"
                  v-b-tooltip.hover
                  v-b-tooltip.d900
                  :style="`color: ${d.etag === '' ? 'red' : 'lightgrey'}`"
                  :title="`Id: ${d.history_id}\nEtag: ${d.etag}`"
                >
                  <font-awesome-icon
                    :icon="d.etag === '' ? 'link-slash' : 'link'"
                  />
                </div>
              </b-col>
            </b-row>
          </b-button>
        </b-card-header>
        <b-collapse
          :visible="showDetails === d.history_date"
          accordion="diff-accordion"
        >
          <b-card-body v-if="showDetails === d.history_date" body-class="p-2">
            <b-row class="mb-2">
              <b-col cols="auto">
                <b-button
                  v-if="d.node"
                  v-b-tooltip="{ customClass: 'large-tooltip' }"
                  v-b-tooltip.hover
                  variant="primary"
                  class="mr-2"
                  size="sm"
                  :title="`Node: ${nameOfId(d.node)}`"
                  :to="editNodeLink(d.node)"
                >
                  Open node
                </b-button>

                <b-button

                  variant="primary"
                  size="sm"
                  :disabled="d.etag === ''"
                  @click.stop="showJson(d)"
                >
                  View entire bot
                </b-button>
                <b-button
                  v-if="showRestore"
                  variant="warning"
                  class="ml-2"
                  size="sm"
                  :disabled="d.etag === ''"
                  @click.stop="restoreTo(d)"
                >
                  Restore
                </b-button>
              </b-col>
            </b-row>
            <vue-json-pretty
              virtual
              :data="d.cur_diff"
            />
          </b-card-body>
        </b-collapse>
      </b-card>
    </b-card>
    <b-modal
      id="restoreBotModal"
      title="Restore Bot"
      ok-variant="warning"
      ok-title="Restore"
      @ok="doRestore"
    >
      <p>
        Are you sure you want to restore to this point?
      </p>
      <p>
        {{ restore_point.history_date }}
      </p>
    </b-modal>
    <b-modal
      id="historicJson"
      :title="'Bot from ' + formatDate(historic_point.history_date)"
      ok-only
      ok-title="Close"
      scrollable
      size="xl"
      @closed="history_json = null"
    >
      <div v-if="!historic_json" class="text-center">
        <b-spinner />
      </div>
      <div v-else class="bg-light r-25 p-1">
        <pre>{{ historic_json }}</pre>
      </div>
    </b-modal>
  </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import TimeAgo from 'supwiz/components/TimeAgo.vue';

export default {
  name: 'BotHistory',
  components: { VueJsonPretty, TimeAgo },
  props: {
    showRestore: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      text: '',
      page: 1,
      pageSize: 10,
      restore_point: {},
      historic_point: {},
      historic_json: {},
      selectedNode: null,
      showDetails: null,
    };
  },
  computed: {
    ...mapGetters('administration', ['getDisplayNameFromId']),
    ...mapGetters('botManipulation', [
      'botHistoryLoading',
      'botHistorySize',
      'botHistory',
    ]),
    ...mapGetters('botManipulation/activeBot', [
      'allNodesAsList',
      'nameOfId',
      'specialNodes',
      'rootNode',
    ]),
    history() {
      const ret = [];
      if (!this.botHistory) return [];
      for (const entry of this.botHistory) {
        entry.node = this.findNodeId(entry.cur_diff);
        ret.push(entry);
      }
      return this.botHistory;
    },
    nodeOptions() {
      return [{ value: null, text: 'All nodes' }]
        .concat(this.allNodesAsList.map((e) => ({ text: e.name, value: e.id })));
    },
    specialNodesRegex() {
      const map = [];
      for (const node of this.specialNodes) {
        map.push(node.id);
      }
      return map.sort((x, y) => y.length - x.length).join('|');
    },
    rootNodeName() {
      return this.rootNode.id;
    },
  },
  watch: {
    page() {
      this.fetchBotHistoryPage();
    },
  },
  async mounted() {
    if (this.$route.hash) {
      const id = this.$route.hash.replace('#', '');
      const existingNode = this.allNodesAsList.find((e) => e.id === id);
      if (existingNode) {
        this.selectedNode = existingNode.id;
      } else {
        this.text = id;
      }
    }
    await this.fetchUsers();
    await this.fetchBotHistoryPage();
  },
  methods: {
    ...mapActions('administration', ['fetchUsers']),
    ...mapActions('botManipulation/', [
      'fetchBotHistory',
      'fetchBotFromHistory',
      'restoreBotHistory',
    ]),
    goBack() {
      this.$router.go(-1);
    },
    async fetchBotHistoryPage(resetPagination = false) {
      if (resetPagination) {
        this.page = 1;
      }
      await this.fetchBotHistory({
        pageSize: this.pageSize,
        page: this.page,
        text0: this.selectedNode,
        text1: this.text,
      });
    },

    findNodeId(patch) {
      if (!patch) return null;
      if (!Array.isArray(patch)) return null;

      for (const p of patch) {
        if (typeof p !== 'object') continue;
        const path = p.path;
        if (!path) continue;
        const nodeRegex = new RegExp(
          `^(?:/nodes/([a-f0-9-]{36}|${this.rootNodeName})[/$])|/(${this.specialNodesRegex})Node[/$]`,
        );
        const nodePath = path.match(nodeRegex);
        if (!nodePath) continue;
        return nodePath[1] || nodePath[2];
      }
      return null;
    },
    restoreTo(point) {
      this.restore_point = point;
      this.$bvModal.show('restoreBotModal');
    },
    doRestore() {
      this.restoreBotHistory(this.restore_point.history_id);
      this.fetchBotHistoryPage();
    },
    async showJson(point) {
      this.historic_point = point;
      this.historic_json = null;
      this.$bvModal.show('historicJson');
      const data = await this.fetchBotFromHistory(this.historic_point.history_id);
      if (data && data.bot) {
        this.historic_json = data.bot;
      } else {
        this.historic_json = `Failed to load Bot ${point.history_date}`;
      }
    },
    formatDate(value) {
      return new Date(value).toLocaleString('en-GB');
    },
  },
};

</script>

<style scoped>
.large-tooltip::v-deep .tooltip-inner {
  max-width: 400px;
  white-space: pre-line;
}
.spinner{
  border-color: white !important;
  border-radius: 50% !important;
  border-right-color: transparent !important;
}
</style>
