var render = function render(){var _vm=this,_c=_vm._self._c;return _c('app-template',_vm._b({attrs:{"notifications-center":""},scopedSlots:_vm._u([{key:"after-nav",fn:function(){return [_c('b-card',{staticClass:"mt-auto",staticStyle:{"color":"#A4B7CF","min-width":"217px !important"},attrs:{"bg-variant":"transparent","no-body":""}},[_c('div',{staticClass:"text-center"},[(_vm.uncommitedChanges && _vm.isMainBot)?_c('b-button',{staticClass:"mb-2",attrs:{"variant":"secondary","to":{ name: 'diff' }}},[_vm._v(" Unstaged changes ")]):_vm._e()],1),(!_vm.isMainBot)?_c('div',{staticClass:"text-center"},[_c('b-list-group-item',{attrs:{"variant":"warning"}},[_vm._v(" You are in subflow "),_c('b-button',{attrs:{"variant":"warning"},on:{"click":_vm.exitSubFlowEdit}},[_vm._v(" Exit to main bot ")])],1)],1):_vm._e()])]},proxy:true},{key:"header",fn:function(){return [_c('base-header')]},proxy:true},{key:"page-content",fn:function(){return [_c('transition',{attrs:{"mode":"out-in","name":"fade"}},[_c('b-overlay',{staticClass:"h-100",attrs:{"show":_vm.manualSync}},[_c('router-view')],1)],1),_c('app-session-time')]},proxy:true}])},'app-template',{
    pages: _vm.filteredSidebarList,
    showSidebar: _vm.showSidebar,
    userDetails: _vm.userDetails,
    toolDetails: {
      name: 'BotStudio',
      logo: require('./assets/supwiz-logo-flat.svg'),
      env: _vm.botstudioEnvironment,
    },
    showSpinner: _vm.pageLoading,
    loggedIn: _vm.loggedIn,
  },false))
}
var staticRenderFns = []

export { render, staticRenderFns }