// Shared component (for easier maintenance) shared among Node > Activities and Responses-page.
<template>
  <div>
    <div
      v-if="voicebotPlatformSelected"
      class="d-flex flex-row justify-content-between"
    >
      <voicebot-audio
        :text="responsePreview"
      />
      <advanced-voice-response
        :uid="uid"
        @insert="insertAtMarker"
      />
    </div>
    <draggable-response-elements
      ref="draggableElements"
      :node-id="nodeId"
      allow-enchancement
      :activity-id="activityId"
      @editPhrase="v=>editPhrase(v)"
    />
    <phrase-selector
      v-if="!special"
      :activity-id="activityId"
      :response-preview="responsePreview"
      :hide-add="!isMainBot"
      @add="v=>addPhraseToResponse(v)"
      @new="newPhrase"
    />
    <approve-button v-model="approved" type="response" />
    <phrase-modal
      :modal-id="activityId"
      :action="phraseAction"
      @phraseAdded="v=>addPhraseToResponse(phrasesDict[v])"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { cloneDeep } from 'lodash';
import { mapPhrases, phrase2Id } from '@/js/utils';
import AdvancedVoiceResponse from '@/components/AdvancedVoiceResponse.vue';
import VoicebotAudio from '@/components/VoicebotAudio.vue';
import PhraseSelector from '@/pages/Phrases/PhraseSelector.vue';
import PhraseModal from '@/pages/Phrases/PhraseModal.vue';
import ApproveButton from '@/components/ApproveButton.vue';
import DraggableResponseElements from '@/components/DraggableResponseElements.vue';
import { applyThisArgs, addThisArgs } from '@/js/storeHelpers';

export default {
  name: 'ResponseActivityTextInput',
  components: {
    AdvancedVoiceResponse,
    VoicebotAudio,
    PhraseSelector,
    ApproveButton,
    PhraseModal,
    DraggableResponseElements,
  },
  props: {
    nodeId: {
      type: String,
      required: true,
    },
    activityId: {
      type: String,
      required: true,
    },
    uid: {
      type: String,
      required: true,
    },
    special: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      phraseAction: '',
      audioData: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters('botManipulation/activeBot', ['phrasesDict', 'phrases', 'phraseText']),
    ...mapGetters('botManipulation/activeBot/config', [
      'getPlatforms',
      'wysiwygEnabled',
      'isMainBot',
    ]),
    ...mapGetters('userSettings', ['isVoicebotPlatform', 'showAIFeatures']),
    ...applyThisArgs(
      mapGetters('botManipulation/activeBot', {
        getResponseText: 'activityText',
        getApproved: 'activityResponseApproved',
      }),
      'nodeId',
      'activityId',
    ),
    responsePreview() {
      return this.textBlocks.map((e) => (e.isPhrase ? this.phrase2Text(e.value) : e.value)).join('');
    },
    text: {
      get() {
        return this.getResponseText;
      },
      set(text) {
        this.setResponseText({ text });
      },
    },
    approved: {
      get() {
        return this.getApproved;
      },
      set(approved) {
        this.setApproved({ approved });
      },
    },
    textBlocks() {
      return mapPhrases(this.text);
    },
    content() {
      return this.textBlocks.find((e) => !e.isPhrase)?.value;
    },
    voicebotPlatformSelected() {
      return this.isVoicebotPlatform(this.getPlatforms);
    },
  },
  methods: {
    phrase2Id,
    ...addThisArgs(
      mapMutations('botManipulation/activeBot', {
        setResponseText: 'setActivityResponseText',
        setApproved: 'setApproved',
      }),
      { nodeId: 'nodeId', activityId: 'activityId' },
    ),
    ...mapMutations('botManipulation', ['setActivePhraseId']),
    focusActivity() {
      if (this.$refs.draggableElements?.$refs?.textArea?.length) {
        this.$refs.draggableElements.$refs.textArea[0].focus();
      }
    },
    async insertAtMarker(txtToInsert) {
      const textArea = this.$refs.draggableElements.$refs.textArea[0];
      const [start, end] = [textArea.selectionStart, textArea.selectionEnd];
      let text = this.content;
      text = text.slice(0, start) + txtToInsert + text.slice(end);
      this.text = text;
      textArea.focus();
      textArea.setSelectionRange(start, start + txtToInsert.length);
    },
    newPhrase() {
      this.phraseAction = 'Add';
      this.$bvModal.show(`phrase-modal-${this.activityId}`);
    },
    editPhrase(id) {
      this.phraseAction = 'Update';
      this.setActivePhraseId(id);
      this.$bvModal.show(`phrase-modal-${this.activityId}`);
    },
    addPhraseToResponse(phrase) {
      const formattedPhrase = `[phrase:${phrase.id}]`;
      const text = this.content;
      const blocksCopy = cloneDeep(this.textBlocks);
      let finalText = '';
      blocksCopy.forEach((block) => {
        finalText += block.isPhrase ? block.value : text;
      });
      finalText += formattedPhrase;
      this.text = finalText;
    },
    phrase2Text(phrase) {
      return this.phraseText(this.phrase2Id(phrase));
    },
  },
};
</script>
