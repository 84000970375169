<template>
  <main
    role="main"
  >
    <!--The root id is just a random number.
    Because the root node should never be collapsed-->
    <b-card
      class="r-75"
      body-class="p-3"
    >
      <b-row>
        <b-col cols="12">
          <b-alert
            v-if="isUserLimited"
            show
            variant="warning"
          >
            Given your permissions, this page is view-only. Any changes you make here are not
            stored.<br> Contact your administrator if you need write-access to this page.
          </b-alert>
        </b-col>
      </b-row>
      <b-row
        class="pb-2"
        align-v="center"
      >
        <b-col
          class="pr-0"
        >
          <b-form-input
            v-model="name"
            :state="$v.name.$invalid ? false : null"
            style="font-size: x-large; width:100; border:none;"
            class="black-text"
            @blur="userLeftInputField"
          />
          <b-form-invalid-feedback>
            <div v-if="!$v.name.uniqueName">
              A bot already exists with this name
            </div>
            <div v-if="!$v.name.nonEmpty">
              Bot name must be non-empty
            </div>
          </b-form-invalid-feedback>
        </b-col>

        <b-col
          cols="auto"
        >
          <b-dropdown
            v-b-tooltip.hover.noninteractive.viewport
            toggle-class="mr-2"
            no-flip
            title="Special nodes"
          >
            <template #button-content>
              <font-awesome-icon icon="code-branch" />
            </template>
            <b-dropdown-text>
              Special nodes
              <tooltipped-text
                value="The following global nodes have special properties and are always part of the bot"
              />
            </b-dropdown-text>
            <b-dropdown-item :to="editNodeLink('fallback')">
              Fallback node
            </b-dropdown-item>
            <b-dropdown-item :to="editNodeLink('error')">
              Error node
            </b-dropdown-item>
            <b-dropdown-item :disabled="disableInactivityBtn" :to="editNodeLink('inactive')">
              Inactivity node
            </b-dropdown-item>
          </b-dropdown>
          <b-button
            class="mr-2"
            :to="{ name: 'graph' }"
          >
            Graph view
          </b-button>
          <b-btn
            v-b-tooltip.hover
            v-b-modal="'searchModal'"
            title="Search the text in response field"
            variant="primary"
          >
            <font-awesome-icon icon="search" />
            Search
          </b-btn>
        </b-col>
      </b-row>
      <b-alert
        class="font-weight-bold"
        :show="zoomRoot != rootTreeNode"
      >
        You are zoomed in <br>
        <b-btn
          v-b-tooltip.hover
          title="Reset zoom"
          variant="outline-light"
          @click="resetZoom"
        >
          <font-awesome-icon
            icon="home"
          />
        </b-btn>
        <b-btn
          v-b-tooltip.hover
          title="Zoom out 1 step"
          variant="outline-light"
          class="ml-2"
          @click="zoomOut"
        >
          <font-awesome-icon
            icon="search-minus"
          />
        </b-btn>
      </b-alert>
      <app-tree-node
        :id="zoomRoot"
        :depth="0"
      />
      <node-search />
    </b-card>
    <AddNodeModal
      :parent-node-id="botNode.id"
      :modal-id="addNodeModalId"
      @nodeAdded="reactToNodeAdded"
    />
    <link-node-modal
      v-if="linkNodeModalId"
      :node-id="botNode.id"
      :modal-id="linkNodeModalId"
      @nodeAdded="reactToNodeAdded"
    />
    <delete-node-modal
      v-if="botNode.id"
      :node-id="botNode.id"
      @delete="reactToNodeDeleted"
    />
  </main>
</template>

<script>
import { validationMixin } from 'vuelidate';
import {
  mapGetters, mapActions, mapState, mapMutations,
} from 'vuex';
import AppTreeNode from '@/pages/TreeView/app-tree-node.vue';
import NodeSearch from '@/pages/TreeView/NodeSearch.vue';
import { ROOT_TREE_NODE } from '@/js/constants';
import AddNodeModal from '@/pages/TreeView/AddNodeModal.vue';
import LinkNodeModal from '@/pages/TreeView/LinkNodeModal.vue';
import DeleteNodeModal from '@/components/DeleteNodeModal.vue';
import TooltippedText from '@/components/TooltippedText.vue';

export default {
  name: 'AppDebug',
  components: {
    NodeSearch,
    AppTreeNode,
    AddNodeModal,
    DeleteNodeModal,
    LinkNodeModal,
    TooltippedText,
  },
  mixins: [validationMixin],

  beforeRouteLeave(to, from, next) {
    this.$store.commit('treeView/setHighlightEditNode', null);
    next();
  },
  data() {
    return {
      name: null,
      addNodeModalId: '',
      linkNodeModalId: '',
    };
  },
  computed: {
    ...mapGetters('botManipulation', [
      'getOtherBotNames',
    ]),
    ...mapGetters('botManipulation/activeBot', [
      'nodeById',
    ]),
    ...mapGetters('botManipulation/activeBot/config/', [
      'getBotName',
      'getUseInactiveNode',
    ]),
    ...mapGetters('auth', [
      'isUserLimited',
    ]),
    ...mapGetters('treeView', [
      'zoomRoot',
    ]),
    ...mapState('treeView', [
      'tree', 'modalId', 'activeId', 'activeParentId',
    ]),
    disableInactivityBtn() {
      return !(this.getUseInactiveNode);
    },
    rootTreeNode() {
      return ROOT_TREE_NODE;
    },
    // app-tree-node-unit
    showChildren() {
      return this.$store.getters['treeView/children'](this.activeId) !== null;
    },
    botNodeId() {
      return this.tree[this.activeId]?.botNodeId;
    },
    botNode() {
      return this.activeId ? this.$store.getters['treeView/botNode'](this.activeId) : { id: null };
    },
  },
  watch: {
    name(newValue) {
      if (!this.nameIsUnique(newValue)) {
        return;
      }
      if (!this.nameIsNonEmptyAndOtherThanWhitespace(newValue)) {
        return;
      }
      this.setBotName({ name: newValue });
    },
  },
  beforeDestroy() {
    this.setActiveId(null);
    this.$root.$off('add-node-modal');
    this.$root.$off('link-node-modal');
    this.$root.$off('remove-node');
    this.$root.$off('delete-node-modal');
  },
  mounted() {
    this.name = this.getBotName;
    this.$store.commit('treeView/setActiveBotNodeId', { botNodeId: null });
    this.$root.$on('add-node-modal', (modalId) => {
      this.addNodeModalId = modalId;
      this.$nextTick(() => {
        this.$bvModal.show(this.addNodeModalId);
      });
    });
    this.$root.$on('link-node-modal', (modalId) => {
      this.linkNodeModalId = modalId;
      this.$nextTick(() => {
        this.$bvModal.show(this.linkNodeModalId);
      });
    });
    this.$root.$on('remove-node', () => {
      this.reactToNodeRemoved();
    });
    this.$root.$on('delete-node-modal', () => {
      this.$nextTick(() => {
        this.$bvModal.show('delete-node-modal');
      });
    });
  },
  methods: {
    ...mapMutations('treeView', ['setActiveId', 'setActiveParentId']),
    ...mapMutations('botManipulation/activeBot/config', [
      'setBotName',
    ]),
    ...mapActions('nodeInterpretations', ['handleDeletionOfNode']),
    ...mapMutations('treeView', [
      'zoomOut',
      'resetZoom',
    ]),
    nameIsUnique(name) {
      return !this.getOtherBotNames.includes(name);
    },
    nameIsNonEmptyAndOtherThanWhitespace(name) {
      if (name && name.trim()) {
        return true;
      }
      return false;
    },
    userLeftInputField() {
      /**
      * Calling this function on blur on inputfield ensures that when a user has input an invalid
      * botname and leaves the input field, then the field value is set back to last valid name.
      */
      this.name = this.getBotName;
    },
    // app-tree-node-unit
    reactToNodeAdded() {
      if (!this.showChildren) {
        // toggle arrow to show the newly added children if necessary
        this.toggleArrow();
      }
    },
    reactToNodeDeleted() {
      const nodeId = this.botNodeId;
      this.$store.dispatch('botManipulation/activeBot/removeNode', { id: nodeId });
      this.handleDeletionOfNode(nodeId);
      this.clearData();
    },
    reactToNodeRemoved() {
      // for subflows handle outgoing

      const parent = this.nodeById(this.activeParentId);
      if (parent.options.nodeType === 'subflow') {
        const outgoing = JSON.parse(JSON.stringify(parent.subFlowMap.outgoing));
        for (const outmap of Object.values(outgoing)) {
          outmap.childrenIds = outmap.childrenIds.filter((x) => x !== this.botNodeId);
        }
        this.$store.commit(
          'botManipulation/activeBot/updateOutgoing',
          { id: parent.id, value: outgoing },
        );
      }
      // remove parent and child relations (through updateActiveNode)
      let predsCp = JSON.parse(JSON.stringify(this.botNode.preds));
      predsCp = predsCp.filter((p) => p !== this.activeParentId);
      this.$store.dispatch(
        'botManipulation/activeBot/updateActiveNode',
        { value: predsCp, attribute: 'preds', id: this.botNodeId },
      );
      this.clearData();
    },
    toggleArrow() {
      this.$store.dispatch('treeView/toggle', { treeNodeId: this.activeId });
    },
    clearData() {
      this.addNodeModalId = '';
      this.linkNodeModalId = '';
      this.setActiveId(null);
      this.setActiveParentId(null);
    },
  },
  validations: {
    name: {
      uniqueName(value) {
        return this.nameIsUnique(value);
      },
      nonEmpty(value) {
        return this.nameIsNonEmptyAndOtherThanWhitespace(value);
      },
    },
  },
};

</script>
