<template>
  <div>
    <b-list-group ref="list" :class="fixedInput ? 'fixed-height' : ''">
      <b-list-group-item v-for="(item, index) in replacements" ref="wordReplacements" :key="index" class="mb-1">
        <b-input-group>
          <b-form-input
            placeholder="Word to replace"
            :value="item.key"
            @input="v=>updateReplacement(v, index, 'key')"
            @keyup.enter="simulateTab"
          />

          <b-input-group-prepend is-text>
            <font-awesome-icon icon="arrow-right" />
          </b-input-group-prepend>

          <b-form-input
            placeholder="Word used as replacement"
            :value="item.value"
            @input="v=>updateReplacement(v, index, 'value')"
            @keyup.enter="simulateTab"
          />

          <b-input-group-append>
            <b-button
              v-b-tooltip.hover.noninteractive.viewport="'Toggle case-sensitive matching'"
              :variant="item.followCasing ? 'primary' : ''"
              @click="updateReplacement(!item.followCasing, index, 'casing')"
            >
              <font-awesome-icon :class="item.followCasing ? 'append-icon' : ''" icon="font" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <small v-if="validateError(index)" class="text-danger">
          {{ validateError(index) }}
        </small>
      </b-list-group-item>
      <b-list-group-item :class="fixedInput ? 'fixed-input' : ''" class="mt-2">
        <b-input-group ref="newReplacement">
          <b-form-input
            placeholder="New word to replace"
            :value="newReplacement.key"
            @input="v=>updateNewReplacement(v, 'key')"
            @keyup.enter="simulateTab"
          />
          <b-input-group-prepend is-text>
            <font-awesome-icon icon="arrow-right" />
          </b-input-group-prepend>
          <b-form-input
            placeholder="New word used as replacement"
            :value="newReplacement.value"
            @input="v=>updateNewReplacement(v, 'value')"
          />
          <b-input-group-append>
            <b-button
              v-b-tooltip.hover.noninteractive.viewport="'Toggle case-sensitive matching'"
              :variant="newReplacement.followCasing ? 'primary' : ''"
              @click="newReplacement.followCasing = !newReplacement.followCasing"
            >
              <font-awesome-icon :class="newReplacement.followCasing ? 'append-icon' : ''" icon="font" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>
<script>
// import { cloneDeep } from 'lodash';

function setCaretPosition(elem, caretPos) {
  if (elem.createTextRange) {
    const range = elem.createTextRange();
    range.move('character', caretPos);
    range.select();
  } else {
    elem.focus();
    if (elem.selectionStart !== undefined) {
      elem.setSelectionRange(caretPos, caretPos);
    }
  }
}
export default {
  name: 'WordReplacement',
  props: {
    replacements: {
      type: Array,
      default: () => [],
    },
    fixedInput: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      newReplacement: this.newReplacementTemplate(),
    };
  },
  methods: {
    validateError(index) {
      const current = this.replacements[index];
      let invalid = false;
      for (let i = 0; i < this.replacements.length; i++) {
        if (i === index) continue;
        const r = this.replacements[i];
        if (current.followCasing) {
          // validate if word with followCasing=false overrides current
          invalid = !r.followCasing && r.key.toLowerCase() === current.key.toLowerCase();
          // validate if word with followCasing=true is the same as current
          invalid = invalid || r.key === current.key;
        } else {
          // validate if word with followCasing=false is the same as current
          invalid = !r.followCasing && r.key.toLowerCase() === current.key.toLowerCase();
        }
      }
      return invalid ? 'Several rules match this word.' : null;
    },
    updateReplacement(v, index, type) {
      const current = this.replacements[index];
      const key = type === 'key' ? v : current.key;
      const value = type === 'value' ? v : current.value;
      const followCasing = type === 'casing' ? v : current.followCasing;
      if (!value.trim().length && !key.trim().length) {
        this.$emit('remove', index);
        this.$nextTick(() => {
          this.updateFocus('remove', index);
        });
      } else {
        this.$emit('update', {
          key, value, followCasing, index,
        });
      }
    },
    updateNewReplacement(v, type) {
      this.newReplacement[type] = v;
      if (type === 'key') {
        this.$emit('add', { key: v, value: this.newReplacement.value, followCasing: this.newReplacement.followCasing });
        this.$nextTick(() => {
          this.newReplacement = this.newReplacementTemplate();
          this.updateFocus('update', 0);
          if (this.fixedInput) {
            this.scrollToBottom();
          }
        });
      }
    },
    updateFocus(type, index) {
      if (type === 'remove') {
        if (this.replacements.length) {
          const lastChild = this.$refs.wordReplacements[
            Math.min(index, this.replacements.length - 1)];
          const lastInput = lastChild.children[0].children[0];
          setCaretPosition(lastInput, lastInput.value.length);
        } else {
          const lastInput = this.$refs.newReplacement.children[0];
          setCaretPosition(lastInput, 0);
        }
      } else if (type === 'update') {
        const lastChild = this.$refs.wordReplacements[this.$refs.wordReplacements.length - 1];
        const lastInput = lastChild.children[0].children[index];
        setCaretPosition(lastInput, lastInput.value.length);
      }
    },
    simulateTab() {
      const focusableElements = Array.from(document.querySelectorAll('input'));
      const currentIndex = focusableElements.indexOf(document.activeElement);
      const nextIndex = currentIndex + 1;
      if (nextIndex < focusableElements.length) {
        const nextElement = focusableElements[nextIndex];
        nextElement.focus();
      } else {
        const firstElement = focusableElements[0];
        firstElement.focus();
      }
    },
    newReplacementTemplate() {
      return { key: '', value: '', followCasing: false };
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.list;
        container.scrollTop = container.scrollHeight;
      });
    },
  },
};
</script>
<style scoped>
.list-group-item {
    padding: 0;
    border: 0px solid #ccc !important;
}
:deep(.append-icon > path) {
    color: white !important;
}
.fixed-input{
  position:sticky;
  bottom:0;
  z-index: 100 !important;
}
.fixed-height{
  max-height: 500px;
  overflow-y:auto;
}
</style>
