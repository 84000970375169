<template>
  <div>
    <DescriptionBox v-if="isMaster">
      This activity will automatically suggest relevant articles using a specific SupSearch
      Search Engine. <br />
      If at least one article is found, the bot will present the articles to the user either
      as a regular message or a rich response (see options below), and the response variable
      will contain information about the articles presented including a "SuggestionID".
      The SuggestionID can be used to send feedback to SupSearch about the
      usefulness of the suggested articles using the "Suggestion feedback" SupWiz Integration.
      <br />If no articles are suggested, the response variable will contain the value "None".
    </DescriptionBox>
    <ActivityFormGroup label="Select Search Engine">
      <b-overlay :show="isFetchingRankers" spinner-small>
        <b-form-select
          v-model="ranker"
          :disabled="isOriginal"
          :options="rankerOptions"
          :state="!$v.ranker.$invalid"
          aria-describedby="rankerFeedback"
        />
        <b-form-feedback id="rankerFeedback">
          <div v-if="!$v.ranker.required">
            This field should not be empty
          </div>
        </b-form-feedback>
      </b-overlay>
    </ActivityFormGroup>
    <ActivityFormGroup v-if="isMaster" label="Search query">
      <botscript-validation
        v-model="query"
        :expanded="false"
        :validations="['empty', 'typecheck-nonbool']"
      />
    </ActivityFormGroup>
    <ActivityFormGroup v-if="isMaster" label="Max article number">
      <b-form-input
        v-model="presentedArticlesNumber"
        type="number"
        min="0"
        max="10"
      />
    </ActivityFormGroup>
    <ActivityFormGroup v-if="isMaster" label="Present as">
      <b-form-select
        v-model="presentAs"
        :options="presentationOptions"
      />
    </ActivityFormGroup>
    <ActivityFormGroup v-if="type === 'variant'" label="Override master threshold">
      <b-form-checkbox v-model="thresholdEnabled" class="mt-1" switch />
    </ActivityFormGroup>
    <ActivityFormGroup label="Threshold for presenting">
      <b-form-input
        v-model="articleThreshold"
        :disabled="isOriginal || !thresholdEnabled"
        type="range"
        :class="graphView ? '' : 'mt-2'"
        min="0"
        max="100"
      />
      <div style="float:right">
        {{ data.articleThreshold }}
      </div>
    </ActivityFormGroup>
    <ActivityFormGroup label="Suggestion message">
      <b-form-textarea
        v-model="responseMessage"
        :disabled="isOriginal"
      />
    </ActivityFormGroup>
    <ActivityFormGroup label="Additional message">
      <b-form-textarea
        v-model="additionalMessage"
        :disabled="isOriginal"
      />
    </ActivityFormGroup>
    <ActivityFormGroup v-if="isMaster" label="Presented links">
      <b-form-checkbox
        v-model="useFeedback"
        switch
        class="mt-1"
      >
        Use feedback link
      </b-form-checkbox>
    </ActivityFormGroup>
  </div>
</template>
<script>
import ActivityFormGroup from '@/pages/EditNode/activity/ActivityFormGroup.vue';
import DescriptionBox from '@/pages/EditNode/activity/DescriptionBox.vue';
import { mapState } from 'vuex';
import { validationMixin } from 'vuelidate';
import BotscriptValidation from '@/components/BotscriptValidation.vue';

function mapParams(params) {
  const map = {};
  for (const param of params) {
    map[param] = {
      get() {
        return this.data[param];
      },
      set(value) {
        this.$emit('input', { param, value });
      },
    };
  }
  return map;
}

export default {
  name: 'SuggestArticles',
  components: {
    ActivityFormGroup,
    DescriptionBox,
    BotscriptValidation,
  },
  mixins: [validationMixin],
  props: {
    type: {
      type: String,
      default: 'master',
    },
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('supsearch', ['rankers', 'isFetchingRankers']),
    ...mapParams(['ranker', 'query', 'presentedArticlesNumber', 'presentAs', 'articleThreshold',
      'responseMessage', 'additionalMessage', 'useFeedback']),
    isMaster() {
      return this.type === 'master';
    },
    isOriginal() {
      return this.type === 'original';
    },
    graphView() {
      return this.$route.name === 'graph';
    },
    rankerOptions() {
      return this.rankers.map((e) => ({ value: e.id, text: e.name }));
    },
    presentationOptions() {
      return [
        { text: 'Rich message', value: 'UnrestrictedMessage' },
        { text: 'Regular message', value: 'message' },
      ];
    },
    thresholdEnabled: {
      get() {
        return !!this.data.articleThreshold;
      },
      set(value) {
        this.$emit('input', { param: 'articleThreshold', value: value ? 80 : null });
      },
    },
  },
  methods: {

  },
  validations: {
    ranker: {
      required: (value) => value !== null && value >= 0,
    },
  },
};
</script>
