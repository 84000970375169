<template>
  <b-list-group-item
    v-if="table.length > 0"
  >
    <b-row
      v-b-toggle="nospace('responses-for-' + title)"
      align-v="center"
      align-h="between"
      :style="{ cursor: 'pointer' }"
      :class="`p-2 text-light bg-` + variant"
    >
      <b-col cols="*">
        <h4>
          <span class="font-weight-bold">{{ title }}</span> (count: {{ table.length }})
        </h4>
      </b-col>
      <b-col cols="*">
        <h5 class="mr-2">
          <font-awesome-icon icon="angle-down" />
        </h5>
      </b-col>
    </b-row>
    <b-collapse
      :id="nospace('responses-for-' + title)"
      class="my-2"
    >
      <b-container
        v-for="typeName in Object.keys(types)"
        :key="nospace('responses-for-' + title + '-' + typeName)"
        fluid
      >
        <b-row
          v-b-toggle="nospace('responses-for-' + title + typeName)"
          align-v="center"
          align-h="between"
          :style="{ cursor: 'pointer' }"
        >
          <b-col cols="*">
            <h4>
              <span class="font-weight-bold">{{ typeName }}</span>
              (count: {{ types[typeName].length }})
            </h4>
          </b-col>
          <b-col cols="*">
            <h5 class="mr-2">
              <font-awesome-icon icon="angle-down" />
            </h5>
          </b-col>
        </b-row>
        <b-collapse :id="nospace('responses-for-' + title + typeName)">
          <b-container
            v-for="(entry, idx) in types[typeName]"
            :key="nospace('responses-for-' + title + '-' + typeName + '-' + idx + '-' + entry['bot_text'])"
            fluid
            class="border-bottom mb-2"
          >
            <b-row>
              <b-col
                cols="2"
                class="text-right font-weight-bold"
              >
                Entry:
              </b-col>
              <b-col cols="10">
                <b-link :to="link(entry)">
                  {{ linkText(entry) }}
                </b-link>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="2"
                class="text-right font-weight-bold"
              >
                Current Text:
              </b-col>
              <b-col cols="10">
                {{ entry['bot_text'] }}
              </b-col>
            </b-row>
            <b-row :v-if="'text' in entry">
              <b-col
                cols="2"
                class="text-right font-weight-bold"
              >
                Import Text:
              </b-col>
              <b-col cols="10">
                {{ entry['text'] }}
              </b-col>
            </b-row>
          </b-container>
        </b-collapse>
      </b-container>
    </b-collapse>
  </b-list-group-item>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ResponsesForImport',
  components: {},
  props: {
    title: {
      type: String,
      required: true,
    },
    sortKey: {
      type: String,
      required: true,
    },
    table: {
      type: Array,
      required: true,
    },
    variant: {
      type: String,
      default: 'primary',
    },
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters('botManipulation/activeBot', ['nameOfId']),
    ...mapGetters('botManipulation', ['getBotId']),
    types() {
      const ret = {};
      for (const entry of this.table) {
        const msg = entry[this.sortKey];
        if (msg in ret) {
          ret[msg].push(entry);
        } else {
          ret[msg] = [entry];
        }
      }
      return ret;
    },
  },
  methods: {
    nospace(string) {
      return string ? string.replaceAll(' ', '_') : string;
    },
    linkText(entry) {
      if (!('node_id' in entry)) {
        if ('node_name' in entry) {
          return entry.node_name;
        }
        return 'config';
      }
      if ('subflow_id' in entry) {
        if ('node_name' in entry) {
          return entry.node_name;
        }
        // How to get current name of node in subflow?
        return 'SubFlow Link';
      }
      const name = this.nameOfId(entry.node_id);
      if (!name && 'node_name' in entry) {
        return entry.node_name;
      }
      return name;
    },
    link(entry) {
      if ('subflow_id' in entry) {
        return this.editNodeLink(entry.node_id, entry.subflow_id);
      }
      if ('node_id' in entry) {
        return this.editNodeLink(entry.node_id);
      }
      return { name: 'config', params: { botId: this.getBotId } };
    },
  },
};
</script>
