import { render, staticRenderFns } from "./VariantPhrases.vue?vue&type=template&id=0e5e90dc&scoped=true&"
import script from "./VariantPhrases.vue?vue&type=script&lang=js&"
export * from "./VariantPhrases.vue?vue&type=script&lang=js&"
import style0 from "./VariantPhrases.vue?vue&type=style&index=0&id=0e5e90dc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e5e90dc",
  null
  
)

export default component.exports